import { SimulationPayload } from 'domain/simulation'
import { builtYearToAge, separateComma, fitValue } from 'helper/number'
import React, { useCallback } from 'react'
import { isMobileOnly } from 'react-device-detect'
import colors from 'style/colors'
import styled from 'styled-components'
import SpBar, { BaseTag, BlueTag } from '../../Symbols/SpBar'
import { forEach } from 'lodash'
import Number from '../Field/Number'
import Grid from '../Layout/Grid'
import Panel from '../Layout/Panel'
import PanelTitle from '../Layout/PanelTitle'
import CashflowGraph, { CashflowDataProps } from './Graph/Cashflow'
import DatamaticReact, { DatamaticWrapper } from './Graph/Datamatic'
import DepreciationGraph, { DepreciationDataProps } from './Graph/Depreciation'
import GraphComment from './GraphComment'
import Slider from './Income/Slider'
import { toArray } from 'helper/array'
import { GraphBox } from 'components/Symbols/Box'
import { TooltipBase } from 'components/Symbols/Tooltip'
import SpBox from '../Layout/SpBox'
import { MainButton } from 'components/Symbols/Button'

const BoxBase = styled.div`
  padding: 16px;
  border-radius: 5px;
  font-weight: bold;
  display: grid;
  grid-gap: 10px;
  align-items: center;
  white-space: nowrap;
  @media screen and (max-width: 800px) {
    border-radius: 5px;
  }
`

const SingleBox = styled(BoxBase)`
  .label {
    font-size: 15px;
  }
  .price {
    .yen {
      font-size: 18px;
    }
    font-size: 21px;
  }
  p,
  span {
    color: white;
  }
`

// 累積所得控除
const DeductionBox = styled(SingleBox)`
  background: ${colors.main};
  border-radius: 5px 0 0 5px;
  @media screen and (max-width: 800px) {
    background: none;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    p,
    span {
      color: ${colors.main};
      font-size: 20px;
    }
  }
`

const DeductionCurrentBox = styled(DeductionBox)`
  display: none;
  @media screen and (max-width: 800px) {
    p,
    span {
      color: ${colors.base};
    }
  }
`

// ローン残積
const RoanBox = styled(SingleBox)`
  background: ${colors.base};
  @media screen and (max-width: 800px) {
    display: flex;
    justify-content: center;
    background: ${colors.bg_gray};
    border: 1px solid #e0e0e0;
    p,
    span {
      color: ${colors.main};
      font-size: 20px;
    }
    .label {
      font-size: 20px;
    }
  }
  .sp {
    display: none;
    @media screen and (max-width: 800px) {
      display: inline-block;
      margin-right: 5px;
      color: ${colors.main};
      font-size: 20px;
    }
  }
`

const DoubleBox = styled(BoxBase)`
  font-size: 14px;
`

// 控除の内訳
const DeductionDetailBox = styled(DoubleBox)`
  grid-template-columns: 180px 1fr;
  border-radius: 0 5px 5px 0;
  border: 1px solid ${colors.main};
  @media screen and (max-width: 800px) {
    background: #edeff5;
    border: none;
    text-align: right;
  }
  .box-title {
    margin: 0 10px 0 -7px;
    background: ${colors.blue_bg_light};
    padding: 3px 8px;
    border-radius: 5px;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  p,
  span {
    color: ${colors.main};
  }
`

// 売却価格と簿価
const ValueBox = styled(DoubleBox)`
  grid-template-columns: 95px 1fr;
  border: 1px solid ${colors.border_gray};
  background: ${colors.bg_gray};
  @media screen and (max-width: 800px) {
    background: white;
    border: 1px solid #e0e0e0;
    font-size: 13px;
    .value {
      text-align: right;
    }
  }
`

const DeductionContainer = styled.div`
  @media screen and (max-width: 800px) {
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
  }
`

const DeductionSelectBox = styled.div`
  display: none;
  @media screen and (max-width: 800px) {
    display: flex;
    align-items: center;
    padding: 0 10px;
    background: ${colors.bg_gray};
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
  }
`

const CashflowContainer = styled(DeductionContainer)`
  display: none;
  @media screen and (max-width: 800px) {
    display: block;
    margin: 10px 0 5px;
    padding-bottom: 20px;
  }
`

const CashflowCalcBox = styled.div`
  font-weight: bold;
  font-size: 13px;
  padding-left: 30px;
  .calcarea {
    display: grid;
    grid-template-columns: 1fr 120px;
    grid-gap: 15px;
  }
  .small {
    padding-left: 90px;
  }
  .value {
    text-align: right;
  }
`

type IInventPlanProps = {
  payload: SimulationPayload
  ChangeResultPage: any
  onChangeParams: (keypath: string, value: string | boolean | number) => void
}

const IncomeSimulation: React.FC<IInventPlanProps> = ({
  payload,
  onChangeParams,
  ChangeResultPage,
}) => {
  const sliderChange = useCallback(
    (e) => {
      onChangeParams('priorities_when_selling', e)
    },
    [onChangeParams]
  )

  const handleChange = useCallback<React.FormEventHandler<HTMLInputElement>>(
    (e) => {
      const name = e.currentTarget.name
      const value = e.currentTarget.valueAsNumber
      const min = e.currentTarget.min ? parseInt(e.currentTarget.min) : 0
      const max = parseInt(e.currentTarget.max)
      const fittedValue = fitValue(value ? value : 0, min, max)
      onChangeParams(name, fittedValue)
    },
    [onChangeParams]
  )

  const DepreciationDatas: DepreciationDataProps[] = toArray(
    0,
    payload.params.priorities_when_selling
  ).map((_, index) => {
    return {
      year: index + 1,
      other_income: payload.result.other_incomes[0],
      noi: payload.result.noi[index],
      depreciation: -payload.result.annual_depreciation_costs[index],
      tax_income: payload.result.taxable_incomes_result[index], // todo
      interest: -payload.result.borrowing_interest_amount[index],
    }
  })

  const CashflowDatas: CashflowDataProps[] = toArray(
    0,
    payload.params.priorities_when_selling
  ).map((_, index) => {
    return {
      year: index + 1,
      btcf: payload.result.btcf[index],
      tax: payload.result.taxable_incomes_result[index],
      other_income: Math.round(payload.result.other_income_tax / 10000) * 10000,
      atcf: payload.result.atcf[index],
      tax_amount: -payload.result.tax_amount[index].price,
      tax_rate: Math.round(payload.result.tax_amount[index].rate * 100),
    }
  })

  const DatamaticData = toArray(0, payload.params.priorities_when_selling).map(
    (empty, index) => {
      return [
        String(index + 1), // 年
        String(payload.result.other_incomes[0] / 10000),
        String(Math.round(payload.result.other_income_tax / 10000)), // Todo その他所得単独税引後
        String(
          Math.round(payload.result.taxable_incomes_result[index] / 10000)
        ), // 課税所得
        String(Math.round(payload.result.atcf[index] / 10000)),
      ]
    }
  )

  //　累積所得控除
  let totalDeduction = 0
  forEach(payload.result.income_deduction, (val, index) => {
    if (index < payload.params.priorities_when_selling) {
      totalDeduction += val
    }
  })

  return (
    <div>
      <Panel>
        <PanelTitle
          spDesign="bar"
          icon={1}
          label=""
          spLabel="所得控除"
          toolTipElem={
            <TooltipBase
              top="-3px"
              pcHide={true}
              spView={true}
              spTextLeft="-200px"
              spTextRight="-120px"
              color={'white'}
            >
              i
              <span className="tooltip-text">
                <b>
                  判断できること：
                  <br />
                  物件購入による減価償却効果（課税所得への影響）
                  <br />
                  物件購入前後のキャッシュフロー比較
                </b>
                <br />
                物件登録画面より「個人/法人」及び、税金に関わる設定をして頂き、ご覧ください。
                <br />
                シミュレーショングラフにポインタを合わせると、各年度の課税所得及び、
                <br />
                税引き後キャッシュフロー等の数値が把握でき、売却時期カーソルを変更することで、
                <br />
                各売却時期の収支状況が表示されます。
                <br />
                <b>
                  収支シミュレーションでは、本年度から売却までの期間について、シミュレーションを行っています。
                </b>
              </span>
            </TooltipBase>
          }
        >
          <span className="panel-title-pc">
            <span className="panel-title-highlight">
              {payload.params.priorities_when_selling}
            </span>
            年目 ￥
            {separateComma(
              payload.result.income_deduction[
                payload.params.priorities_when_selling - 1
              ]
            )}{' '}
            所得控除
          </span>

          <SpBar background="#EDEDED">
            {payload.result.dead_cross_year ? (
              <BaseTag>
                デッドクロス{payload.result.dead_cross_year}年目
              </BaseTag>
            ) : (
              ''
            )}
          </SpBar>
        </PanelTitle>
        <Grid column={['540px', '1fr']} gap_sp={'10px'}>
          <DeductionContainer>
            <Grid column={['215px', '1fr']} gap={'0'} gap_sp={'10px'}>
              <DeductionSelectBox>
                <Number
                  name="priorities_when_selling"
                  unit_text=""
                  value={payload.params.priorities_when_selling}
                  onChange={handleChange}
                  min={1}
                  max={payload.estate.sell_prices.length}
                />
                年目の所得控除
              </DeductionSelectBox>
              <DeductionCurrentBox>
                <p className="label">所得控除</p>
                <p className="price">
                  <span className="yen">¥ </span>
                  {separateComma(
                    payload.result.income_deduction[
                      payload.params.priorities_when_selling - 1
                    ]
                  )}
                </p>
              </DeductionCurrentBox>
              <DeductionBox>
                <p className="label">累積所得控除</p>
                <p className="price">
                  <span className="yen">¥ </span>
                  {separateComma(totalDeduction)}
                </p>
              </DeductionBox>
              <DeductionDetailBox>
                <p>
                  <span className="box-title">控除の内訳</span> 減価償却費
                </p>
                <p>
                  <span className="yen">¥ </span>
                  {separateComma(
                    payload.result.annual_depreciation_costs
                      .slice(0, payload.params.priorities_when_selling)
                      .reduce((m, n) => m + n)
                  )}
                </p>
                <p>経費計上可能なローン利息</p>
                <p>
                  <span className="yen">¥ </span>
                  {separateComma(
                    payload.result.borrowing_interest_amount
                      .slice(0, payload.params.priorities_when_selling)
                      .reduce((m, n) => m + n)
                  )}
                </p>
              </DeductionDetailBox>
            </Grid>
          </DeductionContainer>
          <Grid column={['250px', '1fr']} gap={'6px'} gap_sp={'10px'}>
            <ValueBox>
              <p>AI査定価格</p>
              <p className="value">
                <span className="yen">¥ </span>
                {separateComma(
                  payload.result.estimated_selling_prices[
                    payload.params.priorities_when_selling - 1
                  ]
                )}
              </p>
              <p>帳簿価格</p>
              <p className="value">
                <span className="yen">¥ </span>
                {separateComma(
                  payload.result.book_values[
                    payload.params.priorities_when_selling - 1
                  ]
                )}
              </p>
            </ValueBox>
            <RoanBox>
              <p className="label">
                ローン残高<span className="sp">は</span>
              </p>
              <p className="price">
                <span className="yen">¥ </span>
                {separateComma(payload.result.debts)}
              </p>
            </RoanBox>
          </Grid>
        </Grid>
        <Slider
          yearLengh={payload.estate.rents.length}
          currentYear={payload.params.priorities_when_selling}
          sliderChange={sliderChange}
        />
      </Panel>

      <Panel>
        <PanelTitle spDesign="bar" icon={2} label="課税所得シミュレーション">
          <SpBar background="#DBDEEB">
            <BlueTag
              icon={
                payload.estate.personal_company_type === 1
                  ? 'person'
                  : 'company'
              }
            >
              {payload.estate.personal_company_type === 1
                ? '個人'
                : '法人 税率30%'}
            </BlueTag>
          </SpBar>
          {payload.result.taxable_income_over_other_year ? (
            <SpBar>
              <BaseTag>
                {payload.result.taxable_income_over_other_year}
                年目に課税所得がその他所得を上回ります。
              </BaseTag>
            </SpBar>
          ) : (
            ''
          )}
        </PanelTitle>
        {isMobileOnly ? (
          <DatamaticWrapper>
            <DatamaticReact
              accountID="110637368763377593479"
              chartID="1hO1y3n2Vd0Kw_Kmj3RpOKn1LAXSLa6Q4"
              chartOption={{ width: window.innerWidth - 30, height: 320 }}
              data={[
                [
                  '年',
                  'その他所得',
                  'その他所得（単独税引後CF）',
                  '課税所得',
                  '税引き後キャッシュフロー',
                ],
                ['', '#DADADA', '#B6B6B6', '#FFA500', '#0673C8'],
              ].concat(DatamaticData)}
            />
          </DatamaticWrapper>
        ) : (
          <></>
        )}

        {/* 課税所得シミュレーショングラフ */}
        {!isMobileOnly && (
          <GraphBox padding="20px">
            <DepreciationGraph
              data={DepreciationDatas}
              selectYear={builtYearToAge(payload.estate.purchased_date.$date)}
              roanPeriod={
                payload.params.borrowing_period -
                payload.result
                  .diff_parchased_to_now /* ここでは、借入期間から過去に精算したローンの年数を引く */
              }
              depreciationPeriod={payload.result.depreciation_length}
              personal_company_type={payload.estate.personal_company_type}
              include_other_income={payload.params.include_other_income}
            />
            <GraphComment>
              <span className="marker">
                購入諸費用のうち建物割合分を減価償却費に含めてあります。
              </span>
              <br />
              物件情報で入力された購入年月と個人/法人それぞれの決済期・税率を考慮した減価償却期間から、NOI（営業純利益）・その他所得・減価償却・利息・課税所得*の推移を表しています。
              <br />
              <b>* 課税所得 = NOI（営業純利益）</b> + その他所得 -
              年間合計控除額（減価償却 + 利息）　*
              課税所得の損失は個人の場合3年、法人の場合は10年の繰越が適用
            </GraphComment>
          </GraphBox>
        )}
      </Panel>

      <Panel>
        <PanelTitle
          spDesign="bar"
          icon={3}
          label="税引後キャッシュフローシミュレーション"
        >
          <SpBar background="#DBDEEB">
            <BlueTag
              icon={
                payload.estate.personal_company_type === 1
                  ? 'person'
                  : 'company'
              }
            >
              {payload.estate.personal_company_type === 1
                ? '個人'
                : '法人 税率30%'}
            </BlueTag>
          </SpBar>
          {payload.result.atcf_over_other_income_year ? (
            <SpBar>
              <BaseTag>
                {payload.result.atcf_over_other_income_year}
                年目に税引き後キャッシュフローがその他所得を下回ります
              </BaseTag>
            </SpBar>
          ) : (
            ''
          )}
        </PanelTitle>
        {/* 課税所得シミュレーショングラフ */}

        {!isMobileOnly && (
          <GraphBox padding="20px">
            <CashflowGraph
              data={CashflowDatas}
              selectYear={builtYearToAge(payload.estate.purchased_date.$date)}
              roanPeriod={
                payload.params.borrowing_period -
                payload.result
                  .diff_parchased_to_now /* ここでは、借入期間から過去に精算したローンの年数を引く */
              }
              depreciationPeriod={payload.result.depreciation_length}
              personal_company_type={payload.estate.personal_company_type}
              include_other_income={payload.params.include_other_income}
            />
            <GraphComment>
              物件情報で入力された購入年月と個人/法人それぞれの決済期・税率を考慮した減価償却期間から、税引前キャッシュフロー・納税額・その他所得・税引後キャッシュフロー*の推移を表しています。
              <br />
              <b>* 年間税引後キャッシュフロー</b> = 税引前キャッシュフロー（NOI
              - ローン返済額）- 納税額（課税所得 × 税率）
            </GraphComment>
          </GraphBox>
        )}
        <CashflowContainer>
          <Grid>
            <DeductionSelectBox>
              <Number
                name="priorities_when_selling"
                unit_text=""
                value={payload.params.priorities_when_selling}
                onChange={handleChange}
                min={1}
                max={payload.estate.sell_prices.length}
              />
              年目のキャッシュフロー
              <br />
              シミュレーション
            </DeductionSelectBox>
            <CashflowCalcBox>
              <div className="calcarea">
                <span className="label">税引前キャッシュフロー</span>
                <span className="value">
                  ¥{' '}
                  {separateComma(
                    payload.result.atcf[
                      payload.params.priorities_when_selling - 1
                    ]
                  )}
                </span>
                <span className="label small">課税所得</span>
                <span className="value">
                  - ¥{' '}
                  {separateComma(
                    payload.result.taxable_incomes_result[
                      payload.params.priorities_when_selling - 1
                    ]
                  )}
                </span>
                <span className="label small">納税額</span>
                <span className="value">
                  - ¥{' '}
                  {separateComma(
                    payload.result.tax_amount[
                      payload.params.priorities_when_selling - 1
                    ].price
                  )}
                </span>
              </div>
              <hr />
              <div className="calcarea">
                <span className="label">税引後キャッシュフロー</span>
                <span className="value">
                  ¥{' '}
                  {separateComma(
                    payload.result.atcf[
                      payload.params.priorities_when_selling - 1
                    ]
                  )}
                </span>
              </div>
            </CashflowCalcBox>
          </Grid>
        </CashflowContainer>
      </Panel>
      <SpBox>
        <MainButton
          onClick={() => ChangeResultPage(4)}
          height={45}
          fontSize="15px"
        >
          キャッシュフロー表
        </MainButton>
      </SpBox>
    </div>
  )
}

export default IncomeSimulation
