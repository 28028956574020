import FieldInput from 'components/Simulation/Field/Input'
import FieldLabel from 'components/Simulation/Field/Label'
import FieldSelect from 'components/Simulation/Field/Select'
import Grid from 'components/Simulation/Layout/Grid'
import { BorderButton, MainButton } from 'components/Symbols/Button'
import { BaseModal } from 'components/Symbols/Modal'
import { UserPlan } from 'domain/plan'
import React, { useEffect, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import styled, { css } from 'styled-components'

type PlanAddModalProps = {
  onClose: () => void
  order: number
  estates: any
  userPlans: any
  createUserPlanGroups: any
}

export const PlanAddModal: React.FC<PlanAddModalProps> = (props) => {
  const [selectedEstateId, selectEstateId] = useState(0)
  const [selectedPlanId, selectPlanId] = useState(0)
  const plansOfSelectedEstate = props.userPlans.filter(
    (p: UserPlan) => p.estate_id === selectedEstateId
  )
  const selectedPlan =
    props.userPlans.find((p: UserPlan) => p._id === selectedPlanId) ||
    plansOfSelectedEstate[0]
  const [inheritedValuationLand, setInheritedValuationLand] = useState(0) // 相続税評価額（土地）
  const [inheritedValuationBuild, setInheritedValuationBuild] = useState(0) // 相続税評価額（物件）
  const [propertyTaxValuationLand, setPropertyTaxValuationLand] = useState(0) //　固定資産税評価額（土地）
  const [propertyTaxValuationBuild, setPropertyTaxValuationBuild] = useState(0) //　固定資産税評価額（物件）

  useEffect(() => {
    setInheritedValuationLand(
      selectedPlan ? selectedPlan.params.inheritedValuationLand || 0 : 0
    )
    setInheritedValuationBuild(
      selectedPlan ? selectedPlan.params.inheritedValuationBuild || 0 : 0
    )
    setPropertyTaxValuationLand(
      selectedPlan ? selectedPlan.params.propertyTaxValuationLand || 0 : 0
    )
    setPropertyTaxValuationBuild(
      selectedPlan ? selectedPlan.params.propertyTaxValuationBuild || 0 : 0
    )
  }, [selectedPlan])

  const [openPropertyTaxLand, setOpenPropertyTaxLand] = useState(false)
  const [openPropertyTaxBuild, setOpenPropertyTaxBuild] = useState(false)

  return (
    <BaseModal className="open">
      <div className="modal-box">
        <div className="modal-close-top" onClick={props.onClose}></div>
        <div className="modal-body">
          <div className="modal-header large">投資プランを追加</div>
          <div className="modal-panel">
            <Grid gap_sp="0" column={['200px', '1fr', '200px', '1fr']}>
              <FieldLabel text="物件名" require={true} />
              <FieldValue>
                <FieldSelect
                  name="property-name"
                  required={true}
                  deprecated_texts={['選択してください'].concat(
                    props.estates.map((e: any) => e.estate_name)
                  )}
                  deprecated_values={[0].concat(
                    props.estates.map((e: any) => e._id)
                  )}
                  changedSelect={(e) => {
                    selectEstateId(parseInt(e.target.value))
                  }}
                  width={'100%'}
                />
              </FieldValue>
              <div />
              <div />
              <FieldLabel text="投資条件" require={true} />
              <FieldValue>
                <FieldSelect
                  name="investment-plan"
                  required={true}
                  deprecated_texts={plansOfSelectedEstate.map(
                    (p: UserPlan) => p.params.label
                  )}
                  deprecated_values={plansOfSelectedEstate.map(
                    (p: UserPlan) => p._id
                  )}
                  changedSelect={(e) => {
                    selectPlanId(Number(e.target.value))
                  }}
                  width={'100%'}
                />
              </FieldValue>
              <div />
              <div />
              <FieldLabel text="相続税評価額（土地）" require={false} />
              <FieldValue>
                <FieldInput
                  name={'inherited-valuation-land'}
                  unit_text="万円"
                  value={inheritedValuationLand}
                  onChange={(e) => {
                    setInheritedValuationLand(parseInt(e.target.value) || 0)
                    setPropertyTaxValuationLand(
                      Math.floor(parseInt(e.target.value) / 0.8) || 0
                    )
                  }}
                />
              </FieldValue>
              {openPropertyTaxLand ? (
                <>
                  <FieldLabel text="固定資産税評価額（土地）" require={false} />
                  <FieldValue>
                    <FieldInput
                      name={'property-tax-valuation-land'}
                      unit_text="万円"
                      value={propertyTaxValuationLand}
                      onChange={(e) => {
                        setPropertyTaxValuationLand(
                          parseInt(e.target.value) || 0
                        )
                        setInheritedValuationLand(
                          Math.floor(parseInt(e.target.value) * 1.143 * 0.8) ||
                            0
                        )
                      }}
                    />
                  </FieldValue>
                </>
              ) : (
                <>
                  <p
                    style={{
                      alignSelf: 'center',
                      padding: '20px 0 20px 20px',
                      whiteSpace: 'nowrap',
                    }}
                    onClick={() => setOpenPropertyTaxLand(true)}
                  >
                    {!isMobileOnly && '＜'}{' '}
                    <span
                      style={{
                        borderBottom: '1px solid #4f5e9c',
                        color: '#4f5e9c',
                      }}
                    >
                      固定資産税評価額から入力する
                    </span>
                  </p>
                  <p></p>
                </>
              )}
              <FieldLabel text="相続税評価額（建物）" require={false} />
              <FieldValue>
                <FieldInput
                  name={'inherited-valuation-build'}
                  unit_text="万円"
                  value={inheritedValuationBuild}
                  onChange={(e) => {
                    setInheritedValuationBuild(parseInt(e.target.value) || 0)
                    setPropertyTaxValuationBuild(
                      Math.round(parseInt(e.target.value) / 0.7) || 0
                    )
                  }}
                />
              </FieldValue>
              {openPropertyTaxBuild ? (
                <>
                  <FieldLabel text="固定資産税評価額（建物）" require={false} />
                  <FieldValue>
                    <FieldInput
                      name={'property-tax-valuation-build'}
                      unit_text="万円"
                      value={propertyTaxValuationBuild}
                      onChange={(e) => {
                        setPropertyTaxValuationBuild(
                          parseInt(e.target.value) || 0
                        )
                        setInheritedValuationBuild(
                          Math.round(parseInt(e.target.value) * 0.7) || 0
                        )
                      }}
                    />
                  </FieldValue>
                </>
              ) : (
                <>
                  <p
                    style={{
                      alignSelf: 'center',
                      padding: '20px 0 20px 20px',
                      whiteSpace: 'nowrap',
                    }}
                    onClick={() => setOpenPropertyTaxBuild(true)}
                  >
                    {!isMobileOnly && '＜'}{' '}
                    <span
                      style={{
                        borderBottom: '1px solid #4f5e9c',
                        color: '#4f5e9c',
                      }}
                    >
                      固定資産税評価額から入力する
                    </span>
                  </p>
                  <p></p>
                </>
              )}
            </Grid>
            <ContactText>
              固定資産評価額からの簡易計算による相続税評価額算定は約10-15％の乖離が生じるため、正しい評価額算定希望の方は、税理士個別相談会をお申込みください。
            </ContactText>
            {/*<ContactBar>*/}
            {/*  VALUE AI*/}
            {/*  監修税理士と投資プランについて個別相談（無料）開催中。お申し込みはこちらから。*/}
            {/*  <MainButton*/}
            {/*    fontSize="14px"*/}
            {/*    onClick={() =>*/}
            {/*      window.open(*/}
            {/*        'https://www.cigr.co.jp/form/biz/reserve/?event=Consultation_meeting'*/}
            {/*      )*/}
            {/*    }*/}
            {/*  >*/}
            {/*    無料個別相談に申し込む*/}
            {/*  </MainButton>*/}
            {/*</ContactBar>*/}
            <Grid gap_sp="0" column={['200px', '1fr', '200px', '1fr']}>
              <FieldLabel text="売却時期" require={false} />
              <FieldValue>
                {selectedPlan ? selectedPlan.params.priorities_when_selling : 0}
                年後
              </FieldValue>
              <div />
              <div />
              <FieldLabel text="自己資金額" require={false} />
              <FieldValue>
                {selectedPlan ? selectedPlan.params.own_resources : 0}万円
              </FieldValue>
              <FieldLabel text="ローン利用額" require={false} />
              <FieldValue>
                {selectedPlan ? selectedPlan.params.loan_usage_amount : 0}万円
              </FieldValue>

              <FieldLabel text="借入金利" require={false} />
              <FieldValue>
                {selectedPlan ? selectedPlan.params.borrowing_interest_rate : 0}
                %
              </FieldValue>

              <FieldLabel text="借入期間" require={false} />
              <FieldValue>
                {selectedPlan ? selectedPlan.params.borrowing_period : 0}年
              </FieldValue>
            </Grid>
            <PlanButtons>
              <MainButton
                className={
                  (selectedEstateId === 0 && selectedPlanId === 0) ||
                  !selectedPlan
                    ? 'isDisable'
                    : ''
                }
                height={50}
                fontSize="16px"
                background="#434260"
                onClick={() => {
                  props.createUserPlanGroups({
                    plan: {
                      ...selectedPlan,
                      params: {
                        ...selectedPlan.params,
                        inheritedValuationLand,
                        inheritedValuationBuild,
                        propertyTaxValuationLand,
                        propertyTaxValuationBuild,
                      },
                    },
                    order: props.order,
                  })
                  props.onClose()
                }}
              >
                追 加
              </MainButton>
              {!isMobileOnly && (
                <BorderButton
                  height={50}
                  fontSize="16px"
                  color="#929292"
                  onClick={() => props.onClose()}
                >
                  キャンセル
                </BorderButton>
              )}
            </PlanButtons>
          </div>
        </div>
        <div className="modal-close-bottom" onClick={props.onClose}>
          閉じる
        </div>
      </div>
    </BaseModal>
  )
}

const FieldValue = styled.div`
  align-self: center;
  font-size: 15px;
  ${isMobileOnly &&
  css`
    padding: 15px 20px;
  `}
`

const PlanButtons = styled.div`
  margin-top: 20px;
  padding: 0 20px;
  ${!isMobileOnly &&
  css`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 120px 150px;
    width: 280px;
    margin: 40px auto 0;
    padding: 0;
  `}
`

const ContactText = styled.p`
  padding: 15px 20px 5px;
  ${isMobileOnly &&
  css`
    padding: 10px 15px 5px;
    font-size: 12px;
    line-height: 1.3;
  `}
`
