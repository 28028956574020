import React from 'react'
import LoadView from './Load'

const SfLoginComponent: React.FC<any> = () => {
  if (window.location.host === 'stg-app.value-ai.jp') {
    window.location.href = 'https://stg.value-ai.jp/sflogin'
  } else {
    window.location.href = 'https://value-ai.jp/sflogin'
  }

  return (
    <div>
      <LoadView isView={true} />
    </div>
  )
}

export default SfLoginComponent
