import colors from 'style/colors'
import styled from 'styled-components'

const PlanCommentaryBase = styled.div`
  margin: ${(props: IPlanCommentaryProps) =>
    props.margin ? props.margin : '0 0 20px'};
  padding: 20px 25px;
  h3 {
    font-size: 20px;
    color: ${colors.main};
    margin-bottom: 15px;
    line-height: 1.5;
    @media screen and (max-width: 800px) {
      margin-bottom: 10px;
      font-size: 18px;
      line-height: 1.3;
    }
  }
  h3.no-margin {
    margin: 0;
  }
  h3.line5 {
    margin: 0;
    line-height: 1.5;
  }
  h4 {
    display: block;
    font-size: 16px;
    color: ${colors.black_middle};
    line-height: 1.5;
  }
  p {
    font-size: 13px;
    line-height: 1.5;
    @media screen and (max-width: 800px) {
      text-align: left;
      padding: 0 10px;
      line-height: 1.7;
      &.no-padding {
        padding: 0;
      }
    }
  }
  .blue-text {
    color: ${colors.main};
  }
  .break-sp {
    display: none;
    @media screen and (max-width: 800px) {
      display: block;
    }
  }
`

export const PlanCommentary = styled(PlanCommentaryBase)`
  text-align: ${(props: IPlanCommentaryProps) =>
    props.textAlign ? props.textAlign : 'center'};
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background: #fafafa;
`

export const PlanCommentaryLarge = styled(PlanCommentaryBase)`
  margin: 0 -25px 20px;
  background: ${colors.blue_bg_light};
  @media screen and (max-width: 800px) {
    display: none;
  }
`

export interface IPlanCommentaryProps {
  margin?: string
  textAlign?: string
}
