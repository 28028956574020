import React from 'react'
import styled from 'styled-components'

const HeaderContainer = styled.div`
  height: 73px;
  margin-top: 20px;
`

const HeaderGroup = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
`
// 縦に並ぶ要素の親として高さを指定する
const HeaderStack = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: 25px 1fr;
`

// 横幅を指定する
const HeaderRow = styled.div<{ width: string }>`
  width: ${(props) => props.width};
`

// セルの背景色をしていする
const HeaderCell = styled.div<{ color?: string }>`
  background-color: ${(props) => props.color || '#4F5E9C'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid white;
  border-top: 1px solid white;
  height: 100%;
  &.row-end {
    border-right: none;
  }

  p {
    color: white;
    font-size: 11px;
    font-weight: bold;
    text-align: center;
    line-height: 1.1;
  }
  .stand {
    display: block;
    margin: 0 auto 5px;
    background: white;
    border-radius: 9px;
    width: 100px;
    height: 13px;
    line-height: 13px;
    color: #4f5e9c;
    font-size: 9px;
    letter-spacing: 1px;
  }
  .underline {
    color: white;
    border-bottom: 1px dotted #fff;
  }

  &.tooltip-parent {
    position: relative;
  }
  .tooltip-text {
    display: none;
    position: absolute;
    top: 20px;
    right: calc(100% - 40px);
    width: 300px;
    text-align: left;
    z-index: 10000;
    line-height: 1.3;
    padding: 12px;
    background: white;
    border: 2px solid #0673c8;
    color: #0673c8;
    font-size: 13px;
    font-weight: normal;
    pointer-events: none;
  }
  &.tooltip-parent:hover {
    cursor: pointer;
    .tooltip-text {
      display: block;
    }
  }
`

export const PortfolioTableHeader: React.FC = () => {
  return (
    <HeaderContainer>
      <HeaderGroup>
        <HeaderRow width="8%">
          <HeaderCell>
            <p>購入価格</p>
          </HeaderCell>
        </HeaderRow>

        <HeaderRow width="32%">
          <HeaderStack>
            <HeaderCell>
              <p>資産</p>
            </HeaderCell>
            <HeaderGroup>
              <HeaderRow width="20%">
                <HeaderCell color="#959EC3">
                  <p>帳簿価額</p>
                </HeaderCell>
              </HeaderRow>
              <HeaderRow width="20%">
                <HeaderCell color="#959EC3">
                  <p>
                    相続税
                    <br />
                    評価額
                    <br />
                    （土地）
                  </p>
                </HeaderCell>
              </HeaderRow>
              <HeaderRow width="20%">
                <HeaderCell color="#959EC3">
                  <p>
                    相続税
                    <br />
                    評価額
                    <br />
                    （建物）
                  </p>
                </HeaderCell>
              </HeaderRow>
              <HeaderRow width="20%">
                <HeaderCell color="#959EC3">
                  <p>AI査定価格</p>
                </HeaderCell>
              </HeaderRow>
              <HeaderRow width="20%">
                <HeaderCell color="#959EC3">
                  <p>評価圧縮率</p>
                </HeaderCell>
              </HeaderRow>
            </HeaderGroup>
          </HeaderStack>
        </HeaderRow>

        <HeaderRow width="24%">
          <HeaderStack>
            <HeaderCell>
              <p>債務</p>
            </HeaderCell>
            <HeaderGroup>
              <HeaderRow width="33.3%">
                <HeaderCell color="#959EC3">
                  <p>自己資金額</p>
                </HeaderCell>
              </HeaderRow>
              <HeaderRow width="33.3%">
                <HeaderCell color="#959EC3">
                  <p>ローン残債</p>
                </HeaderCell>
              </HeaderRow>
              <HeaderRow width="33.3%">
                <HeaderCell color="#959EC3">
                  <p>残存期間</p>
                </HeaderCell>
              </HeaderRow>
            </HeaderGroup>
          </HeaderStack>
        </HeaderRow>
        <HeaderRow width="8%">
          <HeaderCell>
            <p>
              純営業利益
              <br />
              (NOI)
            </p>
          </HeaderCell>
        </HeaderRow>
        <HeaderRow width="8%">
          <HeaderCell>
            <p>NOI利回り</p>
          </HeaderCell>
        </HeaderRow>
        <HeaderRow width="20%">
          <HeaderCell className="row-end tooltip-parent">
            <p>
              <span className="stand">今後10年間の累積</span>
              <span className="underline">
                税引き前
                <br />
                キャッシュフロー
              </span>
            </p>
            <p className="tooltip-text">
              ※キャッシュフロー表の税引き前キャッシュフローの1から10年目の値を合計した値から、その他所得を10年分引いた数値です。
            </p>
          </HeaderCell>
        </HeaderRow>
      </HeaderGroup>
    </HeaderContainer>
  )
}
