import { SimulationContainerActions } from 'containers/SimulationContainer'
import { SimulationPayload } from 'domain/simulation'
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import colors from 'style/colors'
import styled from 'styled-components'
import { BorderButton, GrayButton, MainButton } from '../../Symbols/Button'
import { SpMenuList } from '../../Symbols/List'
import { BaseModal } from '../../Symbols/Modal'
import BlueTable from '../../Symbols/Table'
import FieldInput from '../Field/Input'
import FieldLabel from '../Field/Label'
import GridLayout, { Grid } from '../Layout/Grid'
import { PanelArea } from '../Layout/Panel'
import SettingsModalListItem from './SettingsModalListItem'
import SettingsModalTableItem from './SettingsModalTableItem'

const Dummy = styled.div`
  display: none;
  &.active {
    display: block;
    width: 100%;
    height: 60px;
  }
  @media screen and (min-width: 801px) {
    display: block;
    height: 50px;
  }
`

const SettingsModalElem = styled.div`
  display: none;
  @media screen and (max-width: 800px) {
    &.active {
      display: block;
    }
  }
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 12;
  .setting-modal-toggle {
    position: relative;
    background: ${colors.priority} url('/img/common/ic_arrow_top_white.svg')
      calc(100% - 15px) center / 15px 15px no-repeat;
    color: white;
    text-align: center;
    width: 100%;
    height: 60px;
    line-height: 60px;
    font-weight: bold;
  }
  .setting-modal-content {
    display: none;
  }
  &.open {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .setting-modal-toggle {
      background: ${colors.priority} url('/img/common/ic_arrow_white.svg')
        calc(100% - 15px) center / 15px 15px no-repeat;
    }
    .setting-modal-body {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
    .modal-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${colors.modal_bg};
    }
    .setting-modal-content {
      display: block;
      background: white;
    }
    .setting-modal-header {
      color: white;
      background: ${colors.main};
      height: 60px;
      line-height: 60px;
      text-align: center;
    }
    .setting-modal-comment {
      padding: 0 20px;
      height: 30px;
      line-height: 30px;
      background: ${colors.blue_bg_light};
      color: ${colors.main};
      font-size: 11px;
      font-weight: bold;
    }
    .setting-modal-panel {
      padding: 10px 20px;
    }
    .setting-modal-buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
      margin-bottom: 10px;
    }
  }
`

const SettingsBar = styled.div`
  display: block;
  width: 100%;
  position: fixed;
  transition: bottom 0.3s;
  left: 0;
  bottom: -48px;
  z-index: 20;
  @media screen and (max-width: 800px) {
    display: none;
  }
  &.open {
    bottom: 0;
  }
  .settings-bar-top {
    max-width: 1182px;
    padding: 0 15px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
  }
  .settings-bar-button {
    cursor: pointer;
    display: inline-block;
    height: 20px;
    width: 78px;
    border-radius: 5px 5px 0 0;
    padding: 6px 8px 0px 20px;
    font-size: 10px;
    font-weight: bold;
    color: white;
    background: ${colors.priority} url('/img/common/ic_arrow_top_white.svg') 7px
      7px / 8px 8px no-repeat;
  }
  &.open .settings-bar-button {
    background: ${colors.priority} url('/img/common/ic_arrow_white.svg') 7px 7px /
      8px 8px no-repeat;
  }
  .settings-bar-body {
    background: ${colors.priority};
    padding: 10px 0;
  }
  .settings-bar-content {
    max-width: 1182px;
    padding: 0 15px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .settings-bar-left {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    align-items: center;
    span {
      font-size: 12px;
      font-weight: bold;
      color: white;
    }
  }
  .settings-bar-right {
    width: 150px;
  }
`

interface IProps {
  isView: boolean
  payload: SimulationPayload
  onChangeParams?: (keypath: string, value: string | boolean | number) => void
  ChangeResultPage: any
  plans: any[]
}

const BarButton = styled(BorderButton)`
  height: 28px;
  line-height: 26px;
  background: transparent;
  color: white;
  border-color: white;
`

type ActionMethods = Pick<
  SimulationContainerActions,
  'savePlan' | 'setPlans' | 'deletePlan' | 'saveNewPlan'
>

const SettingsModal: React.FC<IProps & ActionMethods> = ({
  savePlan,
  setPlans,
  deletePlan,
  saveNewPlan,
  ...props
}) => {
  const [isBarOpen, changeBar] = useState(true)
  const [isModalOpen, changeModal] = useState(false)
  const [isSaveOpen, changeSaveModal] = useState(false)
  const [isListOpen, changeListModal] = useState(false)
  const [isSaving, setSaving] = useState(false)

  const ChangeResultPage = (resultNumber: number) => {
    props.ChangeResultPage(2)
    const scrollElm = document.getElementById(`ResultPlan${resultNumber}`)
    console.log(scrollElm)
    setTimeout(() => {
      scrollElm && scrollElm.scrollIntoView()
      window.scrollTo(0, window.scrollY - 130)
    }, 100)
  }

  // form
  const [planLabel, setPlanLabel] = useState('')

  // 他の画面を閉じて切り替えるメソッド
  const changeSaveModalForce = () => {
    changeListModal(false)
    changeSaveModal(!isSaveOpen)
  }
  const changeListModalForce = () => {
    changeSaveModal(false)
    changeListModal(!isListOpen)
  }

  const deleteAndUpdateCondition = (planIndex: number) => {
    const plan = props.plans[planIndex]
    deletePlan(plan.estate_id, plan._id)
  }

  const conditionChange = (estate_id: number, planIndex: number) => {
    const calledPlan = props.plans[planIndex]

    Object.entries(calledPlan.params).forEach(([key, value]) => {
      if (!(key === 'label')) {
        props.onChangeParams &&
          props.onChangeParams(key, value as number | string | boolean)
      }
    })
  }

  const clickSaveNewPlan = () => {
    if (isSaving) {
      return
    }

    setSaving(true)

    if (!planLabel) {
      alert('プラン名を入力してください。')
      setSaving(false)
      return
    }

    saveNewPlan({
      planLabel,
      payload: props.payload,
      plans: props.plans,
      changeSaveModal,
    })
    // setSaving(false) としなくてもコンポーネントごと破棄されてるようだ
  }

  const portalElem = document.getElementById('planSettingModalPortal')
  if (!portalElem) return null

  return ReactDOM.createPortal(
    <>
      <Dummy className={`${props.isView ? 'active' : ''}`} />
      <SettingsModalElem
        className={`${props.isView ? 'active' : ''} ${
          isModalOpen ? 'open' : ''
        }`}
      >
        <div className="modal-bg" onClick={() => changeModal(!isModalOpen)} />
        <div className="setting-modal-body">
          <p
            className="setting-modal-toggle"
            onClick={() => changeModal(!isModalOpen)}
          >
            投資プランの設定
          </p>
          <div className="setting-modal-content">
            <h2 className="setting-modal-header">投資プランの条件</h2>
            <p className="setting-modal-comment">
              編集する項目を選択してください
            </p>
            <SpMenuList>
              <li onClick={() => ChangeResultPage(1)}>売却時期と売却価格</li>
              <li onClick={() => ChangeResultPage(2)}>賃料と空室率</li>
              <li onClick={() => ChangeResultPage(3)}>自己資金とローン</li>
              <li onClick={() => ChangeResultPage(4)}>運営費用</li>
              <li onClick={() => ChangeResultPage(5)}>減価償却</li>
            </SpMenuList>
            <h2 className="setting-modal-header">投資プランの管理</h2>
            <div className="setting-modal-panel">
              <Grid>
                <MainButton
                  height={45}
                  background={colors.priority}
                  onClick={() => changeSaveModal(!isSaveOpen)}
                  fontSize="14px"
                >
                  新規保存
                </MainButton>
                {/* <div className="setting-modal-buttons">
                  <MainButton
                    height={45}
                    background={colors.priority}
                    onClick={() => changeSaveModal(!isSaveOpen)}
                  >
                    新規保存
                  </MainButton>
                  <BorderButton height={45}>上書き保存</BorderButton>
                </div> */}
                <GrayButton
                  height={45}
                  color={colors.base}
                  className="setting-modal-button"
                  onClick={() => changeListModal(!isListOpen)}
                  fontSize="14px"
                >
                  保存した投資プラン
                </GrayButton>
              </Grid>
            </div>
          </div>
        </div>
      </SettingsModalElem>

      <SettingsBar className={isBarOpen ? 'open' : ''}>
        <div className="settings-bar-top">
          <p
            className="settings-bar-button"
            onClick={() => changeBar(!isBarOpen)}
          >
            {isBarOpen ? '折りたたむ' : '表示する'}
          </p>
        </div>
        <div className="settings-bar-body">
          <div className="settings-bar-content">
            <div className="settings-bar-left">
              <span>現在の投資プランを</span>
              <BarButton onClick={() => changeSaveModalForce()}>
                保存する
              </BarButton>
              {/* <BarButton>上書き保存</BarButton> */}
            </div>
            <div className="settings-bar-right">
              <BarButton onClick={() => changeListModalForce()}>
                保存した投資プラン
              </BarButton>
            </div>
          </div>
        </div>
      </SettingsBar>

      <BaseModal className={isSaveOpen ? 'open' : ''}>
        <div className="modal-box">
          <div
            className="modal-close-top"
            onClick={() => changeSaveModal(!isSaveOpen)}
          ></div>
          <div className="modal-body">
            <h2 className="modal-header">投資プランの新規保存</h2>
            <div className="modal-panel">
              <GridLayout>
                <GridLayout column={['200px', '1fr']}>
                  <FieldLabel text="プランタイトル" />
                  <PanelArea>
                    <GridLayout column={['1fr', '120px']}>
                      <FieldInput
                        name="plan_name"
                        type="text"
                        width={510}
                        margin_right={0}
                        value={planLabel}
                        onChange={(e) => setPlanLabel(e.target.value)}
                      />
                      <MainButton
                        height={45}
                        background={colors.priority}
                        onClick={clickSaveNewPlan}
                        fontSize="14px"
                      >
                        新規保存
                      </MainButton>
                    </GridLayout>
                  </PanelArea>
                </GridLayout>
              </GridLayout>
            </div>
          </div>
          <div
            className="modal-close-bottom"
            onClick={() => changeSaveModal(!isSaveOpen)}
          >
            閉じる
          </div>
        </div>
      </BaseModal>

      <BaseModal className={isListOpen ? 'open' : ''}>
        <div className="modal-box">
          <div
            className="modal-close-top"
            onClick={() => changeListModal(!isListOpen)}
          ></div>
          <div className="modal-body">
            <h2 className="modal-header">保存した投資プラン</h2>
            <div className="modal-panel pc">
              <Grid gap={'25px'} gap_sp={'0px'}>
                {/* {!props.plans ||
                    (!props.plans.length && (
                      <p className="modal-area">
                        投資条件がまだ登録されていません
                      </p>
                    ))} */}
                {(() => {
                  const conditionDatas = props.plans.map((plan: any) => {
                    const params = plan.params
                    return {
                      estate_id: plan.estate_id,
                      plan_label: params.label,
                      priorities_when_selling: params.priorities_when_selling,
                      selling_price: params.selling_price,
                      own_resources: params.own_resources,
                      loan_usage_amount: params.loan_usage_amount,
                      borrowing_interest_rate: params.borrowing_interest_rate,
                      borrowing_period: params.borrowing_period,
                    }
                  })

                  return (
                    <div>
                      <p className="modal-comment">
                        {props.payload.estate.estate_name}
                      </p>
                      <ul className="modal-sp-list">
                        {conditionDatas.map((data: any, index: number) => (
                          <SettingsModalListItem
                            key={`SettingsModalListItem${index}`}
                            data={data}
                            estate_id={data.estate_id}
                            planIndex={index}
                            deletable={conditionDatas.length !== 1}
                            conditionChange={conditionChange}
                            conditionDelete={deleteAndUpdateCondition}
                            closeModal={() => changeListModal(false)}
                          />
                        ))}
                      </ul>
                      <BlueTable>
                        <thead>
                          <tr>
                            <th>プランタイトル</th>
                            <th>売却時期</th>
                            {/* <th>売却価格</th> */}
                            <th>購入価格</th>
                            <th>自己資金額</th>
                            <th>ローン利用額</th>
                            <th>借入金利</th>
                            <th>借入期間</th>
                            <th></th>
                            {/* <th>最終資産額</th>
                      <th>全期間利回り</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {conditionDatas.map((data: any, index: number) => (
                            <SettingsModalTableItem
                              key={`SettingsModalTableItem${index}`}
                              estate_id={data.estate_id}
                              planIndex={index}
                              data={data}
                              deletable={conditionDatas.length !== 1}
                              conditionChange={conditionChange}
                              conditionDelete={deleteAndUpdateCondition}
                              closeModal={() => changeListModal(false)}
                            />
                          ))}
                        </tbody>
                      </BlueTable>
                    </div>
                  )
                })()}
              </Grid>
            </div>
          </div>
          <div
            className="modal-close-bottom"
            onClick={() => changeListModal(!isListOpen)}
          >
            閉じる
          </div>
        </div>
      </BaseModal>
    </>,
    portalElem
  )
}

export default SettingsModal
