import React from 'react'
import styled from 'styled-components'
import colors from 'style/colors'

interface IProps {
  text: string // ラベルテキスト
}

const TextLabel = styled.div`
  min-height: 100%;
  background: ${colors.base_gray};
  align-items: center;
  width: 200px;
  max-width: 100%;
  padding: 20px 20px;
  position: relative;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  line-height: 1.3;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 0 -20px;
    height: 45px;
    padding: 0 20px;
    box-sizing: content-box;
  }
  .small {
    font-size: 13px;
  }
  .break-pc {
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
`

class Label extends React.Component<IProps, {}> {
  public static defaultProps = {
    text: '',
  }
  public render() {
    return (
      <TextLabel>
        {this.props.text}
        {this.props.children}
      </TextLabel>
    )
  }
}

export default Label
