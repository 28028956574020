import { ConnectedRouter } from 'connected-react-router'
import { History } from 'history'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import './App.css'
import Header from './components/Header'
import PageTitle, { DefaultPageTitle } from './components/PageTitle'
import DashboardContainer from './containers/DashboardContainer'
import DevLoginContainer from './containers/DevLoginContainer'
import hoge from './containers/hogeContainer'
import SimulationContainer from './containers/SimulationContainer'
import SfLoginComponent from './components/SfLoginComponent'
import SuLoginComponent from './components/SuLoginComponent'
import SsoCallbackComponent from './components/SsoCallbackComponent'

interface AppProps {
  history: History
}

const App = ({ history }: AppProps) => {
  return (
    <ConnectedRouter history={history}>
      <PageTitle title={DefaultPageTitle} prefix={''} />
      <Header />
      <Switch>
        <Route path="/" exact component={DashboardContainer} />
        <Route path="/simulation/:id" component={SimulationContainer} />
        <Route path="/hoge" component={hoge} />
        <Route path="/service/planner/signin" component={DevLoginContainer} />
        <Route path="/sflogin" component={SfLoginComponent} />
        <Route path="/sulogin/:id" component={SuLoginComponent} />
        <Route path="/oauth2/callback" component={SsoCallbackComponent} />

        <Route component={NotFound} />
      </Switch>
    </ConnectedRouter>
  )
}

const NotFound = () => <>Not Found</>

export default App
