import styled from 'styled-components'
import colors from 'style/colors'

interface GraphBoxProps {
  padding?: string
  margin?: string
}
export const GraphBox = styled.div`
  border: 1px solid ${colors.border_gray2};
  margin: ${(props: GraphBoxProps) => props.margin && props.margin};
  padding: ${(props: GraphBoxProps) =>
    props.padding ? props.padding : '10px'};
  h3 {
    padding: 4px 8px;
  }
`
