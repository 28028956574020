import React from 'react'
import styled from 'styled-components'
import colors from 'style/colors'
import { pick } from 'lodash'

interface StyleProps {
  margin_right?: number
  width: number
}

const FormInput = styled.div`
  margin-right: ${(props: StyleProps) =>
    !(props.margin_right === undefined) ? props.margin_right + 'px' : '15px'};
  display: flex;
  align-items: center;
  input {
    display: inline-block;
    max-width: ${(props: StyleProps) => props.width}px;
    width: 100%;
    height: 35px;
    line-height: 35px;
    border: 1px solid ${colors.border_gray};
    border-radius: 3px;
    padding: 0 8px 0 14px;
    font-size: 15px;
  }
  input::placeholder {
    color: #b6b6b6;
  }
  span {
    margin-left: 15px;
    font-size: 15px;
    font-weight: normal;
    white-space: nowrap;
  }
`

type IProps<T extends Exclude<any, undefined> = string> = StyleProps & {
  unit_text: string // 単位を後ろに表示
} & (ControlledInputProps<T> | UncontrolledInputProps<T>)

type ControlledInputProps<T> = InputElementProps & {
  value: T
} & Required<Pick<InputElementProps, 'onChange'>>

type UncontrolledInputProps<T> = InputElementProps & {}

type InputElementProps = Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  | 'type'
  | 'name'
  | 'onChange'
  | 'value'
  | 'onBlur'
  | 'defaultValue'
  | 'placeholder'
  | 'required'
>

const InputNativeProps: (keyof InputElementProps)[] = [
  'required',
  'type',
  'name',
  'value',
  'onChange',
  'onBlur',
  'defaultValue',
  'placeholder',
]

const getHTMLInputAttributes = (
  props: IProps
): React.InputHTMLAttributes<HTMLInputElement> => {
  return pick(props, InputNativeProps)
}

class FieldInput extends React.Component<IProps, {}> {
  public static defaultProps = {
    type: 'number',
    unit_text: '',
    width: 160,
    margin_right: 15,
  }

  public render() {
    return (
      <FormInput
        width={this.props.width}
        margin_right={this.props.margin_right}
      >
        <input {...getHTMLInputAttributes(this.props)} />
        {this.props.unit_text ? (
          <span className="help">{this.props.unit_text}</span>
        ) : (
          ''
        )}
      </FormInput>
    )
  }
}

export default FieldInput
