import React from 'react'
import styled from 'styled-components'
import colors from 'style/colors'
import { isMobileOnly } from 'react-device-detect'

const cashflow_colors = {
  blue: '#EAF3FA',
  red: '#FCEFF1',
  orange: '#FFE2AD',
}

interface IProps {
  className: string
  title: string // 行タイトル
  values: number[] // 表示する値
  page: number
}

const CashRow = styled.tr`
  width: 100%;
  margin: 20px 0;
  &.head {
    background: ${colors.bg_gray};
  }
  .row-title {
    white-space: nowrap;
  }
  &.breakdown {
    .row-title {
      text-align: right;
      color: #8c9096;
    }
  }
  &.blue {
    background: ${cashflow_colors.blue};
    .row-title {
      background: ${cashflow_colors.blue};
    }
  }
  &.red {
    background: ${cashflow_colors.red};
    .row-title {
      background: ${cashflow_colors.red};
    }
  }
  &.orange {
    background: ${cashflow_colors.orange};
    .row-title {
      background: ${cashflow_colors.orange};
    }
  }
  &.border-top {
    border-top: 1px solid ${colors.border_gray2};
  }
  &.border-bottom {
    border-bottom: 1px solid ${colors.border_gray2};
  }
  .row-title {
    width: 200px;
    text-align: left;
    background: ${colors.bg_gray};
  }
  td {
    border-right: 1px solid ${colors.border_gray2};
    border-left: 1px solid ${colors.border_gray2};
    font-size: 10px;
    font-weight: bold;
    padding: 3px 10px;
    color: #000;
    width: 70px;
  }
  td.active {
    background: ${colors.blue_bg_table_attention};
  }
`

const UndefinedToBar = (value: any) => (value === 'undefined' ? '-' : value)

class RowComponent extends React.Component<IProps, {}> {
  public static defaultProps = {
    className: '', // 行タイトル
    title: '', // 行タイトル
    page: 1, // 表示するページ
  }

  public separate(num: number) {
    return String(num).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
  }

  public render() {
    var cells = []
    const datas = isMobileOnly
      ? this.props.values
      : this.props.values.slice(
          (this.props.page - 1) * 10,
          (this.props.page - 1) * 10 + 10
        )

    // モバイルはすべて表示 PCは10年表示
    const columnLength = isMobileOnly ? datas.length : 10

    // 表は1行10年。
    for (let column = 1; column <= columnLength; column++) {
      cells.push(
        <td key={column}>{UndefinedToBar(this.separate(datas[column - 1]))}</td>
      )
    }

    return (
      <CashRow className={this.props.className}>
        <td className="row-title">{this.props.title}</td>
        {cells}
      </CashRow>
    )
  }
}

export default RowComponent
