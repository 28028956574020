import * as React from 'react'
import { HogeState } from '../reducers/hogeReducer'
import { HogeActions } from '../containers/hogeContainer'

interface OwnProps {}

type HogeProps = OwnProps & HogeState & HogeActions

type ActionMethods = Pick<HogeActions, 'updateName' | 'updateEmail' | 'request'>

export const HogeComponent: React.SFC<HogeProps & ActionMethods> = ({
  updateName,
  updateEmail,
  request,
  ...props
}) => {
  return (
    <div>
      <div className="field">
        <input
          type="text"
          placeholder="name"
          value={props.name}
          onChange={(e) => updateName(e.target.value)}
        />
      </div>
      <div className="field">
        <input
          type="email"
          placeholder="email"
          value={props.email}
          onChange={(e) => updateEmail(e.target.value)}
        />
      </div>
      <div>
        <button
          onClick={(e) => {
            console.log('foo')
            request('send')
          }}
        >
          send
        </button>
      </div>
      <p>{request}</p>
    </div>
  )
}
