import { EstatePayload } from 'domain/estate'
import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import {
  PlanGroups,
  AddPlanGroupPayload,
  DeletePlanGroupPayload,
} from '../domain/plan'

const actionCreator = actionCreatorFactory()

export const DashboardActions = {
  requestPortfolio: actionCreator('ACTIONS_REQUEST_PORTFORIO'),
  requestPortfolioSuccess: actionCreator<any>(
    'ACTIONS_REQUEST_PORTFORIO_SUCCESS'
  ),
  requestPortfolioFailed: actionCreator<any>(
    'ACTIONS_REQUEST_PORTFORIO_FAILED'
  ),
  registerNewEstate: actionCreator<EstatePayload>(
    'ACTIONS_REGISTER_NEW_ESTATE'
  ),
  deleteEstate: actionCreator<any>('ACTIONS_DELETE_ESTATES'),
  getUserPlanGroups: actionCreator<void>('ACTIONS_GET_USER_GRUOPS'),
  setUserPlanGroups: actionCreator<void>('ACTIONS_SET_USER_GROUPS'),
  createUserPlanGroups: actionCreator<AddPlanGroupPayload>(
    'ACTION_CREATE_USER_GROUPS'
  ),
  deletePlanFromPlanGroup: actionCreator<DeletePlanGroupPayload>(
    'ACTION_DELETE_PLAN_FROM_PLAN_GROUP'
  ),
}

export interface DashboardState {
  portfolio?: any
  portfolioErrorMessage?: string
  planGroups?: PlanGroups
}

const initialState: DashboardState = {
  portfolio: undefined,
  portfolioErrorMessage: undefined,
  planGroups: undefined,
}

export const DashboardReducer = reducerWithInitialState(initialState)
  .case(DashboardActions.requestPortfolioSuccess, (state, portfolio) => {
    return Object.assign({}, state, { portfolio })
  })
  .case(DashboardActions.requestPortfolioFailed, (state, portfolio) => {
    return Object.assign({}, state, { portfolioErrorMessage: 'Error occuered' })
  })
  .case(DashboardActions.setUserPlanGroups, (state, planGroups) => {
    return Object.assign({}, state, { planGroups: planGroups })
  })
