import React from 'react'
import styled from 'styled-components'

const Simulation = styled.div`
  display: grid;
  grid-gap: 20px;
  .panel {
    padding: 25px;
    background: white;
    border-radius: 5px;
  }
  @media screen and (max-width: 800px) {
    grid-gap: 0;
  }
`

class SimulationLayout extends React.Component {
  render() {
    return <Simulation>{this.props.children}</Simulation>
  }
}

export default SimulationLayout
