import React from 'react'
import styled from 'styled-components'
import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  CartesianGrid,
  YAxis,
  Tooltip,
  Line,
} from 'recharts'
import { xAxisTick, yAxisTick, CustomGroupToolTip } from './Foundation'
import { derivedPlanGroupsType } from 'domain/portfolio'
import { resultSumByKey } from 'domain/plan'

const GraphContainer = styled.div`
  width: 100%;
  height: 230px;
`

export const PortfolioLineGraph: React.FC<{
  values?: derivedPlanGroupsType
  resultKey: string
}> = ({ values, resultKey }) => {
  if (!values) return null
  // todo: convert values => data

  const groupOne = values[1] ? resultSumByKey(values[1].result, resultKey) : []
  const groupTwo = values[2] ? resultSumByKey(values[2].result, resultKey) : []
  const groupThr = values[3] ? resultSumByKey(values[3].result, resultKey) : []
  const maxLength = Math.min(
    Math.max(groupOne.length, groupTwo.length, groupThr.length),
    10
  )
  const data = new Array(maxLength).fill(null).map((_, i) => {
    return {
      name: i.toString(),
      one: groupOne[i] ? Math.round(groupOne[i] / 10000) : 0,
      two: groupTwo[i] ? Math.round(groupTwo[i] / 10000) : 0,
      three: groupThr[i] ? Math.round(groupThr[i] / 10000) : 0,
    }
  })

  return (
    <GraphContainer>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{ top: 0, right: 3, left: -13, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            tickSize={0}
            tickMargin={8}
            tick={xAxisTick}
            dataKey="name"
            padding={{ left: 15, right: 15 }}
          />
          <YAxis
            padding={{ top: 30, bottom: 0 }}
            tickSize={0}
            tick={yAxisTick}
            axisLine={false}
          />
          <Tooltip content={CustomGroupToolTip} />
          <Line
            name="グループⅠ"
            type="linear"
            dataKey="one"
            stroke="#0673c8"
            strokeWidth={2}
            isAnimationActive={false}
            dot={({ dataKey: _, ...props }: any) => (
              <circle {...props} r={4} fill={'#0673c8'} stroke="0" />
            )}
          />
          <Line
            name="グループⅡ"
            type="linear"
            dataKey="two"
            stroke="#FFA600"
            strokeWidth={2}
            isAnimationActive={false}
            dot={({ dataKey: _, ...props }: any) => (
              <circle {...props} r={4} fill={'#FFA600'} stroke="0" />
            )}
          />
          <Line
            name="グループⅢ"
            type="linear"
            dataKey="three"
            stroke="#BECD25"
            strokeWidth={2}
            isAnimationActive={false}
            dot={({ dataKey: _, ...props }: any) => (
              <circle {...props} r={4} fill={'#BECD25'} stroke="0" />
            )}
          />
        </LineChart>
      </ResponsiveContainer>
    </GraphContainer>
  )
}
