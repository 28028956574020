import axios, { AxiosResponse } from 'axios'
import {
  EstatePayload,
  EstateJSON,
  Railway,
  SearchEstateResponse,
} from '../domain/estate'
import { PlanPayload, PlanJSON, UserPlans, PlanGroups } from '../domain/plan'
import { ldebug } from 'helper/log'

const baseURL = () => {
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:8000'
  }

  if (window.location.hostname === 'stg-app.value-ai.jp') {
    return 'https://qa.value-ai.jp'
  }

  return 'https://value-ai.jp'
}

const auth = {
  username: 'va',
  password: 'va',
}

const option = { auth, withCredentials: true }

/***
 * @param {string} email アカウントのメールアドレス
 * @param {string} password アカウントのパスワード
 * @return {any}
 */
export const devLogin = (
  email: string,
  password: string
): Promise<AxiosResponse<any>> => {
  return axios.post(
    baseURL() + '/service/planner/signin',
    { email, password },
    option
  )
}

/***
 * @param {string} query
 */
export const ssoLogin = (query: string) => {
  return axios.get(baseURL() + `/oauth2/callback_api${query}`, option)
}

export const logout = () => {
  return axios.get(baseURL() + '/api/logout', option)
}

/***
 * @return {EstateJSON}
 */
export const getEstateList = (): Promise<AxiosResponse<EstateJSON>> => {
  return axios.get(
    baseURL() +
      '/api/estate/search/list?estate_class_name=PrivateEstate&limit=20',
    option
  )
}

/***
 * @param {string}  id Action PayloadのID
 * @return {EstateJSON & Railway}
 */
export const getEstate = (
  id: string
): Promise<AxiosResponse<EstateJSON & Railway>> => {
  return axios.get(
    baseURL() + `/api/estate/${id}?estate_class_name=PrivateEstate`,
    option
  )
}

/***
 * @param {string} estateName 物件名
 * @return {SearchEstateResponse}
 */
export const estateSearch = (
  estateName: string
): Promise<AxiosResponse<SearchEstateResponse>> => {
  return axios.get(
    baseURL() +
      `/api/estate/database?city=&estate_name=${estateName}&pref_code=`,
    option
  )
}

/**
 * @param  {string} prefCode 都道府県コード
 * @return {EstateJSON}
 */
export const getCities = (
  prefCode: string
): Promise<AxiosResponse<EstateJSON>> => {
  return axios.get(baseURL() + `/citiesfrompref?pref_code=${prefCode}`, option)
}

/**
 * @param {string} zipCode 郵便番号
 * @return {EstateJSON}
 */
export const getAddressFromZip = (
  zipCode: string
): Promise<AxiosResponse<EstateJSON>> => {
  return axios.get(baseURL() + `/addressfromzip?zip_code=${zipCode}`, option)
}

/**
 * @param {EstatePayload} newEstate 物件情報
 * @return {EstateJSON}
 */
export const registerNewEstate = (
  newEstate: EstatePayload
): Promise<AxiosResponse<EstateJSON>> => {
  return axios.post(
    baseURL() + '/planner/private/registernew',
    newEstate,
    option
  )
}

/**
 * @param {any} estate 物件情報
 * @return {EstateJSON}
 */
export const editEstate = async (
  estate: any
): Promise<AxiosResponse<EstateJSON>> => {
  const res1 = await axios.post(
    baseURL() + '/planner/edit/estate/basic',
    estate,
    option
  )
  ldebug(res1)
  const res2 = await axios.post(
    baseURL() + '/planner/edit/estate/occupationandland',
    estate,
    option
  )
  ldebug(res2)
  const res3 = await axios.post(
    baseURL() + '/planner/edit/estate/manageandlimit',
    estate,
    option
  )
  ldebug(res3)
  const res4 = await axios.post(
    baseURL() + '/planner/edit/estate/salesinfo',
    estate,
    option
  )
  ldebug(res4)

  return await axios.get(
    baseURL() +
      `/api/prediction/simulation/${estate._id}?estate_class_name=PrivateEstate`,
    option
  )
}

export const simurateEstate = async (
  id: Number
): Promise<AxiosResponse<EstateJSON>> => {
  return await axios.get(
    baseURL() +
      `/api/prediction/simulation/${id}?estate_class_name=PrivateEstate`,
    option
  )
}

/**
 * @param {number} id 物件ID
 * @return {EstateJSON}
 */
export const deleteEstate = (
  id: number
): Promise<AxiosResponse<EstateJSON>> => {
  return axios.post(
    baseURL() + '/planner/edit/estate/publicstatus',
    {
      _id: id,
      public_status: 4,
    },
    option
  )
}

/**
 * @param {string} prefCode 都道府県コード
 * @return {Railway}
 */
export const getRailways = (
  prefCode: string
): Promise<AxiosResponse<Railway>> => {
  return axios.get(
    baseURL() + `/railwaysfrompref?pref_code=${prefCode}`,
    option
  )
}

/**
 * @param {string} lineCode 路線のID
 * @return {Railway}
 */
export const getStations = (
  lineCode: string
): Promise<AxiosResponse<Railway>> => {
  return axios.get(
    baseURL() + `/stationsfromline?line_code=${lineCode}`,
    option
  )
}

/**
 * @param  {number} estateId 物件ID
 * @return {PlanJSON}
 */
export const getPlans = (
  estateId: number
): Promise<AxiosResponse<PlanJSON>> => {
  return axios.get(
    baseURL() + `/planner/estates/plan?estate_id=${estateId}`,
    option
  )
}

/**
 * @param {PlanPayload} params プランのシミュレーションデータのObject
 * @return {PlanJSON}
 */
export const savePlan = (
  params: PlanPayload,
  id?: number
): Promise<AxiosResponse<PlanJSON>> => {
  console.log(params)
  return axios.post(
    baseURL() + '/planner/estates/plan',
    {
      _id: id,
      estate_id: parseInt(params.estateId),
      params: {
        ...params,
        estate: {
          ...params.estate,
          // サーバ側の実装都合でDatetime Objectはintにする
          built_date:
            typeof params.estate.built_date === 'object'
              ? params.estate.built_date.$date
              : params.estate.built_date,
          purchased_date:
            typeof params.estate.purchased_date === 'object'
              ? params.estate.purchased_date.$date
              : params.estate.purchased_date,
          updated:
            typeof params.estate.updated === 'object'
              ? params.estate.updated.$date
              : params.estate.updated,
        },
      },
    },
    option
  )
}

/**
 * @param {number} estateId 物件ID
 * @param {number} planId プランのID
 * @return {string}
 */
export const deletePlan = (
  estateId: number,
  planId: number
): Promise<AxiosResponse<string>> => {
  return axios.delete(
    baseURL() + `/planner/estates/plan?estate_id=${estateId}&_id=${planId}`,
    option
  )
}

export const getUserPlan = (): Promise<AxiosResponse<UserPlans>> => {
  return axios.get(baseURL() + `/planner/plans`, option)
}

export const getUserPlanGroups = (): Promise<AxiosResponse<PlanGroups>> => {
  return axios.get(baseURL() + `/planner/plan_groups`, option)
}

export const createUserPlanGroups = (
  order: number | string,
  plan_ids: Array<number>
): Promise<AxiosResponse<PlanGroups>> => {
  return axios.post(
    baseURL() + `/planner/plan_groups/${order}`,
    { plan_ids },
    option
  )
}
