import { SimulationParams } from 'domain/simulation'
import { EstateJSON } from 'domain/estate'

export type PlanPayload = SimulationParams & {
  label: string
  estate: EstateJSON
  estateId: string
}

export type PlanJSON = PlanPayload & {
  estate: EstateJSON
  created: DateTime
  updated: DateTime
  estate_id: number
  _id: number
}

export type UserPlan = {
  created: DateTime
  estate_id: number
  params: PlanPayload
  user_id: number
  updated: DateTime
  _id: number
}

export type UserPlans = UserPlan[]

export type PlanGroup = {
  _id: number
  order: number
  plan_ids: Array<number>
  plans?: Array<UserPlan>
}

export type PlanGroups = {
  '1': PlanGroup
  '2': PlanGroup
  '3': PlanGroup
}

export type DateTime = {
  $date: number
}

export type PlanGroupPayload = {
  order: number
  plan_ids: [number]
}

export type AddPlanGroupPayload = {
  plan: UserPlan
  order: number
}

export type DeletePlanGroupPayload = {
  '1': Array<number>
  '2': Array<number>
  '3': Array<number>
}

// 複数のプランの特定のキーの値を合算する。
export const resultSumByKey = (result: any[], key: string) => {
  let sumValues: number[] = []
  for (let i = 0; i < result.length; i++) {
    if (result[i]) {
      const keyArray = result[i][key] as number[]
      for (let j = 0; j < keyArray.length; j++) {
        if (typeof sumValues[j] === 'number') {
          sumValues[j] += keyArray[j]
        } else {
          sumValues[j] = keyArray[j]
        }
      }
    }
  }
  return sumValues
}
