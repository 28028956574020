import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import createRootReducer from './reducers/AppReducer'
import mySaga from './sagas'

export const history = createBrowserHistory()

export default function configureStore(preloadedState?: any) {
  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(
    createRootReducer(history),
    preloadedState,
    compose(applyMiddleware(routerMiddleware(history), sagaMiddleware))
  )

  sagaMiddleware.run(mySaga)

  return store
}
