import React from 'react'
import PanelTitle from 'components/Simulation/Layout/PanelTitle'
import styled from 'styled-components'
import LayoutBetween from 'components/Simulation/Layout/Between'
import { isMobileOnly } from 'react-device-detect'

import { PortfolioLineGraph } from './Graph/LineGraph'
import { PortfolioAreaGraph } from './Graph/AreaGraph'
import { PortfolioGraphBox } from './Graph/GraphBox'
import { GridTabLayout } from './GridTabLayout'
import { PlanGroups } from 'domain/plan'
import { derivedPlanGroupsType } from 'domain/portfolio'

const LegendElem = styled.ul`
  display: flex;
  width: 590px;
  li {
    display: flex;
    align-items: center;
    list-style: none;
    font-size: 10px;
    font-weight: bold;
    margin-left: 10px;
    &::before {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border-radius: 10px;
      margin-right: 6px;
    }
    &:nth-child(1)::before {
      background: #0673c8;
    }
    &:nth-child(2)::before {
      background: #ffa600;
    }
    &:nth-child(3)::before {
      background: #becd25;
    }
  }
`

const CaptionGraph = styled.div`
  padding: 0 0 15px 10px;
  line-height: 1.2;
  font-size: 70%;
`

type PortfolioSummaryType = {
  planGroups?: PlanGroups
  portfolioValues?: derivedPlanGroupsType
}

export const PortfolioSummary: React.FC<PortfolioSummaryType> = (props) => {
  return (
    <>
      <LayoutBetween>
        <PanelTitle
          label="投資プラングループシミュレーション"
          marginBotton={0}
        />
        {!isMobileOnly && (
          <LegendElem>
            <li>投資プラングループ Ⅰ</li>
            <li>投資プラングループ Ⅱ</li>
            <li>投資プラングループ Ⅲ</li>
          </LegendElem>
        )}
      </LayoutBetween>
      <GridTabLayout
        labels={['AI査定価格', '減価償却費', '累積純営業利益']}
        items={[
          <PortfolioGraphBox key="PortfolioGraphBox1" title="AI査定価格">
            <PortfolioLineGraph
              values={props.portfolioValues}
              resultKey="estimated_selling_prices"
            />
            <CaptionGraph className="caption-graph">
              ※AIが査定した推定売却価格の合計値推移グラフです。
              <br />
              　投資プラングループ別に不動産価格変動の傾向がわかります。
            </CaptionGraph>
          </PortfolioGraphBox>,
          <PortfolioGraphBox key="PortfolioGraphBox2" title="減価償却費">
            <PortfolioAreaGraph values={props.portfolioValues} />
            <CaptionGraph className="caption-graph">
              ※減価償却費の合計額推移グラフです。
              <br />
              　投資プラングループ別の減価償却費を比較できます。
            </CaptionGraph>
          </PortfolioGraphBox>,
          <PortfolioGraphBox key="PortfolioGraphBox3" title="累積純営業利益">
            <PortfolioLineGraph
              values={props.portfolioValues}
              resultKey="accumulation_income"
            />
            <CaptionGraph className="caption-graph">
              ※累積となる純営業利益の合計額推移グラフです。
              <br />
              　投資プラングループ別の収益の伸びを比較できます。
            </CaptionGraph>
          </PortfolioGraphBox>,
        ]}
      />
    </>
  )
}
