import React from 'react'
import { Action } from 'typescript-fsa'
import styled from 'styled-components'
import colors from 'style/colors'
import { DashboardActions } from 'containers/DashboardContainer'

export interface IProps {
  address: string
  estate_name: string
  setPlanFromSearchResult: (payload: any) => Action<any>
}

const ModalItem = styled.li`
  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 5px 5px 5px 5px;
  }

  @media screen and (min-width: 801px) {
    width: calc(50% - 14px);
    margin: 0 4px 10px 0;

    &:nth-child(even) {
      margin: 0 0 10px 4px;
    }
  }

  padding: 20px;
  border: solid 2px ${colors.main};
  border-radius: 5px;

  button {
    background-color: transparent;
    border: none;
    display: inline;
    font-size: 16px;
    text-align: initial;
    margin: 0;
    padding: 0;
    line-height: 1;
  }

  h2 {
    color: ${colors.main};
    padding-bottom: 10px;
  }
  p {
    color: ${colors.black_middle};
  }
`

type ActionMethods = Pick<DashboardActions, 'setPlanFromSearchResult'>

const NewModalItem: React.FC<IProps & ActionMethods> = ({
  setPlanFromSearchResult,
  ...props
}) => {
  return (
    <ModalItem>
      <button onClick={setPlanFromSearchResult}>
        <h2>{props.estate_name}</h2>
        <p>{props.address}</p>
      </button>
    </ModalItem>
  )
}

export default NewModalItem
