import Moment from 'moment'
import {
  SimulationPayload,
  runSimulation,
  SimulationResult,
} from './simulation'
import { PlanGroups, PlanGroup } from './plan'

export type portfolioValue = {
  selling_price: number /** 購入価格 : 投資プランシミュレーションにおける購入価格 (万円) */
  book_price: number /** 帳簿価額 : 投資プランシミュレーションにおける「現在」の簿価 (万円) */
  ai_price: number /** AI査定価格 : 投資プランシミュレーション最短値の推定売却価格 (万円) */
  evaluation_compression_ratio:
    | number
    | null /** 評価圧縮率 (%) : 1 - ( 相続税評価額 / 物件価格 ) * 100 */
  own_resources: number /** 自己資金額 : 投資プランの自己資金額と同じ(万円) */
  now_debts: number /** ローン残債 : 投資プランシミュレーションの「現在」のローン残債(万円) */
  loan_remaining_period: number /** ローン残存期間 = 投資プランシミュレーションの「現在」の残存期間 */
  noi: number /** 純営業利益(NOI) : 投資プランシミュレーション最短値のNOI（キャッシュフロー表と同じ）(万円) */
  noi_rate: number /** NOI利回り : SimulationResultのNOI利回りと同じ (%) */
  accumulation_btcf: number /** 今後10年間の累積税引き後キャッシュフロー (万円) */
  inheritedValuationLand: number
  inheritedValuationBuild: number
}

export type inputPlanPortfolio = {
  propertyTaxValuationLand: number /** 固定資産税評価額 : 入力値 */
  propertyTaxValuationBuild: number /** 固定資産税評価額 : 入力値 */
  inheritedValuationLand: number /** 相続税評価額 : 投資プラン追加時に直接値を入力する or 固定資産税評価額を入力して算出（ = 固定資産税評価 * 8 / 7） */
  inheritedValuationBuild: number
}

/** 物件情報と、保存した投資プランと、そこからシミュレーションした結果から、ポートフォリオに出力する情報を計算する。 */
export const derivedPortfolio = (
  payload: SimulationPayload | any
): portfolioValue => {
  const { estate, result, params } = payload
  // 購入した時点から現在までの期間
  const estate_purchased_date = (estate.purchased_date.$date ||
    estate.purchased_date) as number
  const diff_parchased_to_now = Moment().diff(
    Moment(estate_purchased_date),
    'years'
  )
  const ai_price = result.estimated_selling_prices[0]
  const evaluation_compression_ratio =
    params.inheritedValuationLand > 0 && params.inheritedValuationBuild > 0
      ? ((ai_price -
          (params.inheritedValuationLand + params.inheritedValuationBuild) *
            10000) /
          ai_price) *
        100
      : null

  return {
    selling_price: params.selling_price,
    book_price: result.book_values[0],
    ai_price: ai_price,
    evaluation_compression_ratio,
    own_resources: params.own_resources,
    now_debts: result.loan_balances[0],
    loan_remaining_period: params.borrowing_period - diff_parchased_to_now,
    noi: result.noi[1],
    noi_rate: result.noi_rate,
    accumulation_btcf: (result.btcf as [])
      .map((btcf: number) => {
        return btcf - (estate.other_income ? estate.other_income * 10000 : 0)
      })
      .slice(0, 10)
      .reduce((p: number, x: number) => p + x, 0),
    inheritedValuationLand: params.inheritedValuationLand,
    inheritedValuationBuild: params.inheritedValuationBuild,
  }
}

/** 合計テーブルのデータ */
export type portfolioSumValue = {
  selling_price: number
  book_price: number
  ai_price: number
  evaluation_compression_ratio: number | null
  own_resources: number
  now_debts: number
  loan_remaining_period: number
  noi: number
  noi_rate: number
  accumulation_btcf: number
  inheritedValuationLand: number
  inheritedValuationBuild: number
}

/** 複数の投資プランから、合計の値を求める。 */
export const derivedPortfolioSumValue = (
  plans: portfolioValue[] | any
): portfolioSumValue => {
  const inheritedValuationLand =
    plans.filter((plan: portfolioValue) => !plan.inheritedValuationLand)
      .length === 0
      ? plans.reduce((p: any, x: any) => p + x.inheritedValuationLand, 0)
      : null
  const inheritedValuationBuild =
    plans.filter((plan: portfolioValue) => !plan.inheritedValuationBuild)
      .length === 0
      ? plans.reduce((p: any, x: any) => p + x.inheritedValuationBuild, 0)
      : 0
  const ai_price = plans.reduce((p: any, x: any) => p + x.ai_price, 0)
  const evaluation_compression_ratio =
    plans.filter(
      (plan: portfolioValue) => plan.evaluation_compression_ratio === null
    ).length === 0
      ? ((ai_price -
          (inheritedValuationLand + inheritedValuationBuild) * 10000) /
          ai_price) *
        100
      : null
  const selling_price = plans.reduce((p: any, x: any) => p + x.selling_price, 0)
  const noi = plans.reduce((p: any, x: any) => p + x.noi, 0)

  return {
    selling_price,
    book_price: plans.reduce((p: any, x: any) => p + x.book_price, 0),
    ai_price,
    evaluation_compression_ratio,
    own_resources: plans.reduce((p: any, x: any) => p + x.own_resources, 0),
    now_debts: plans.reduce((p: any, x: any) => p + x.now_debts, 0),
    loan_remaining_period: Math.max(
      ...plans.map((plan: any) => plan.loan_remaining_period)
    ),
    noi,
    noi_rate: noi / selling_price / 100,
    accumulation_btcf: plans.reduce(
      (p: any, x: any) => p + x.accumulation_btcf,
      0
    ),
    inheritedValuationLand,
    inheritedValuationBuild,
  }
}
// 投資プラングループのプランをparamsとestateから計算して合計を求める。
export type derivedPlanGroupsType = {
  '1': PortfolioGroupType
  '2': PortfolioGroupType
  '3': PortfolioGroupType
}

export type PortfolioGroupType =
  | {
      result: (SimulationResult | null)[]
      portfolioValue: (portfolioValue | null)[]
    }
  | undefined

// planGroupからそれぞれのシミュレーション結果を求める。
const simulationPlanGroup = (planGroup: PlanGroup) => {
  if (!planGroup.plans) return null
  return planGroup.plans.map((plan, i) => {
    if (!plan.params.estate) return null
    return runSimulation(plan.params.estate, plan.params)
  })
}

// planGroupからそれぞれのシミュレーション結果を求める。
const derivedPlanGroup = (planGroup: PlanGroup): PortfolioGroupType => {
  const result = simulationPlanGroup(planGroup)
  if (!planGroup.plans || !result) return undefined
  return {
    result: result,
    portfolioValue: planGroup.plans
      .map((plan, i) => {
        if (!plan.params.estate || !result[i]) return null
        return derivedPortfolio({
          result: result[i],
          estate: plan.params.estate,
          params: plan.params,
        })
      })
      .filter((v): v is portfolioValue => true),
  }
}

export const derivedPlanGroups = (
  planGroups: PlanGroups
): derivedPlanGroupsType => {
  // 投資プラングループ１のプランをシミュレーションする
  return {
    '1': derivedPlanGroup(planGroups[1]),
    '2': derivedPlanGroup(planGroups[2]),
    '3': derivedPlanGroup(planGroups[3]),
  }
}
