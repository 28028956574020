import React from 'react'
import FieldInput from '../Field/Input'
import FieldLabel from '../Field/Label'
import FieldRadio from '../Field/Radio'
import { PanelForm, PanelArea } from '../Layout/Panel'
import PanelTitle from '../Layout/PanelTitle'
import RegistrationForm from './/Form'

/*
interface IState {
  stationLength: number, // 最寄駅の数
}
*/

//interface anyProps {}
interface IProps {
  form: { [key: string]: string | undefined }
  updateField: any
}

class RegistrationOnlySection extends React.Component<IProps> {
  /*
  public state: IState = {
    stationLength: 2,  // 最寄駅の数
  }
  */

  // 最寄駅の追加
  /*
  addStation() {
    this.setState({stationLength: this.state.stationLength+1})
  }
  */

  public render() {
    /*
    let stationList = [];
    for (let i=1; i<=this.state.stationLength; i++) {
      stationList.push(
        <div className="field-rows-sp">
          <FieldSelect name={"near_line_"+i} values={["JR山手線","JR銀座線"]} texts={["JR山手線","JR銀座線"]} width={222} />
          <div className="field">
            <FieldSelect name={"near_station_"+i} values={["渋谷","恵比寿","品川"]} texts={["渋谷","恵比寿","品川"]} unit_text="駅より 徒歩" />
            <FieldInput name={"near_station_minutes_"+i} unit_text="分" width={80} />
          </div>
        </div>
      );
    }
    */
    return (
      <PanelForm>
        <PanelArea>
          <PanelTitle
            icon={3}
            label="物件の基本情報を登録しましょう。"
            description="物件名・所在地・最寄駅などの物件に関する基本的な情報を登録しましょう。"
          />
        </PanelArea>
        <RegistrationForm>
          <FieldLabel text="部屋番号" />
          <div className="field-area">
            <FieldInput
              name="room_number"
              value={this.props.form.room_number || ''}
              onChange={this.props.updateField}
              placeholder="401"
            />
          </div>

          <FieldLabel text="所在階" />
          <div className="field-area">
            <FieldInput
              name="floor_located"
              unit_text="階"
              value={this.props.form.floor_located || ''}
              onChange={this.props.updateField}
              placeholder="4"
            />
          </div>

          <FieldLabel text="専有面積" />
          <div className="field-area">
            <FieldInput
              name="occupation_area"
              unit_text="㎡"
              value={this.props.form.occupation_area || ''}
              onChange={this.props.updateField}
              placeholder="30"
            />
          </div>

          <FieldLabel text="間取り" />
          <div className="field-area field-rows-sp">
            <FieldInput
              name="layout_rooms"
              value={this.props.form.layout_rooms || ''}
              onChange={this.props.updateField}
              placeholder="2"
            />
            <FieldRadio
              width={100}
              height={35}
              name="layout_structure"
              value={this.props.form.layout_structure + ''}
              deprecated_values={['1', '2', '3', '4']}
              deprecated_texts={['R', 'K', 'DK', 'LDK']}
              onChange={this.props.updateField}
            />
          </div>
        </RegistrationForm>
      </PanelForm>
    )
  }
}

export default RegistrationOnlySection
