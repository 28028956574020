import colors from 'style/colors'
import styled from 'styled-components'

const PanelTag = styled.span`
  font-size: 12px;
  font-weight: bold;
  display: inline-block;
  padding: 4px 8px;
  border-radius: 10px;
  margin-left: 8px;
  background: #f1f1f1;
  &.panel-tag-blue {
    background: #f2f7fb;
    color: ${colors.blue_deep};
  }
  &.panel-tag-transparent {
    background: transparent;
  }
  &.pc {
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
`

export default PanelTag
