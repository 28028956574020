import { css } from 'styled-components'
import { isMobileOnly } from 'react-device-detect'

export const StepListLabels = [
  '物件情報入力',
  'AI査定',
  '投資プラン',
  '収支シミュレーション',
  'キャッシュフロー表',
]

export const StepListInPageLinks = [
  null,
  null,
  'ResultPlan0',
  'ResultIncome',
  'ResultCashflow',
]

export const StepListSections = [
  'ResultPlan0',
  'ResultIncome',
  'ResultCashflow',
]

export const StepListPageAnchors = [
  { hash: '#ResultInfo', step: 3 },
  { hash: '#ResultPlan', step: 3 },
  { hash: '#ResultIncome', step: 4 },
  { hash: '#ResultCashflow', step: 5 },
]

export type StepListNavigationProps = {
  onClickStep?: (step: number) => void
}

export const stepCliclable = (current: number, index: number): boolean => {
  return true
}

export const condencedButtonStyles = css`
  button.condensed {
    border: 0;
    margin: 0;
    padding: 0;
    line-height: inherit;
    background: inherit;
    display: inline-block;
    font-weight: inherit;
    appearance: none;
    cursor: pointer;
    outline: none;

    &:disabled {
      cursor: inherit;
    }
  }
`

export const isLinkEnabled = (idx: number, stepNum: number) => {
  if (isMobileOnly) return false
  if (idx === 2) return false
  if (stepNum > 2) {
    return true
  }

  return stepNum > idx
}
