import styled from 'styled-components'
import colors from 'style/colors'

interface BarProps {
  color?: string
  background?: string
  justify?: string
}

const SpBar = styled.div`
  font-size: 12px;
  @media screen and (min-width: 801px) {
    display: inline;
  }
  @media screen and (max-width: 800px) {
    color: ${(props: BarProps) => (props.color ? props.color : colors.base)};
    background: ${(props: BarProps) =>
      props.background ? props.background : '#EDEDED'};
    height: 30px;
    padding: 0 20px;
    display: flex;
    justify-content: ${(props: BarProps) =>
      props.justify ? props.justify : 'left'};
    align-items: center;
    font-weight: bold;
  }
  &.pc {
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  &.sp {
    @media screen and (min-width: 801px) {
      display: none;
    }
  }
`

export const BaseTag = styled.span`
  font-size: 12px;
  padding: 2px 8px 3px;
  margin-left: 7px;
  display: inline-flex;
  align-items: center;
  border-radius: 16px;
  background: #f1f1f1;
  font-weight: bold;
  @media screen and (max-width: 800px) {
    background: none;
    margin-left: 0;
  }
`

interface BlueTagProps {
  icon?: 'person' | 'company'
}

export const BlueTag = styled(BaseTag)`
  background: #f2f7fb;
  color: ${colors.blue_deep};
  @media screen and (max-width: 800px) {
    color: ${colors.main};
    background: white;
  }
  &::before {
    margin-right: 5px;
    content: ${(props: BlueTagProps) =>
      props.icon ? `url("/img/common/ic_${props.icon}.svg")` : ''};
  }
`

export default SpBar
