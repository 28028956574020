import styled from 'styled-components'
import colors from 'style/colors'

// 基礎的なボタンスタイル
export const BaseButton = styled.button`
  padding: 0;
`

export const GrayButton = styled(BaseButton)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props: ButtonProps) => (props.width ? props.width + 'px' : '100%')};
  height: ${(props: ButtonProps) => (props.height ? props.height : 35)}px;
  line-height: ${(props: ButtonProps) => (props.height ? props.height : 35)}px;
  border-radius: 3px;
  font-weight: bold;
  text-align: center;
  font-size: ${(props: ButtonProps) =>
    props.fontSize ? props.fontSize : '12px'};
  border: 1px solid ${colors.border_gray};
  color: ${(props: ButtonProps) =>
    props.color ? props.color : colors.black_alpha};
  background: linear-gradient(#fff, #ebebeb);

  .icon {
    content: ${(props: ButtonProps) => (props.icon ? props.icon : '')};
    display: inline-block;
    width: 18px;
    height: 18px;
    line-height: 18px;
    border-radius: 18px;
    margin-right: 5px;
    color: white;
    background: ${colors.main};
    font-size: 10px;
  }
  &.isDisable {
    pointer-events: none;
    background: #ffffff;
    color: #dadada;
  }
`

interface ButtonProps {
  width?: number
  height?: number
  background?: string
  visibility?: string
  color?: string
  icon?: string
  fontSize?: string
  isDisable?: boolean
}

export const MainButton = styled(BaseButton)`
  cursor: pointer;
  display: block;
  width: 100%;
  height: ${(props: ButtonProps) => (props.height ? props.height : 35)}px;
  line-height: ${(props: ButtonProps) => (props.height ? props.height : 35)}px;
  border-radius: 3px;
  border: none;
  font-weight: bold;
  text-align: center;
  font-size: ${(props: ButtonProps) =>
    props.fontSize ? props.fontSize : '12px'};
  color: white;
  background: ${(props: ButtonProps) =>
    props.background ? props.background : colors.main};
  visibility: ${(props: ButtonProps) =>
    props.visibility ? props.visibility : 'visible'};
  &.isDisable {
    pointer-events: none;
    border: 1px solid ${colors.border_gray};
    color: ${colors.border_gray};
    background: white;
  }
`

export const BorderButton = styled(BaseButton)`
  cursor: pointer;
  display: block;
  width: 100%;
  padding: 0;
  height: ${(props: ButtonProps) => (props.height ? props.height : 35)}px;
  line-height: ${(props: ButtonProps) => (props.height ? props.height : 35)}px;
  border-radius: 3px;
  border: 1px solid
    ${(props: ButtonProps) => (props.color ? props.color : colors.priority)};
  font-weight: bold;
  text-align: center;
  font-size: ${(props: ButtonProps) =>
    props.fontSize ? props.fontSize : '12px'};

  color: ${(props: ButtonProps) =>
    props.color ? props.color : colors.priority};
  background: ${(props: ButtonProps) =>
    props.background ? props.background : 'white'};
`
