import React from 'react'
import { SimulationPayload } from 'domain/simulation'
import styled from 'styled-components'
import { sum } from 'lodash'
import { GraphBox } from 'components/Symbols/Box'
import Panel from './Layout/Panel'
import PanelTitle from './Layout/PanelTitle'
import Grid from './Layout/Grid'
import { PlanCommentary } from './Result/Plan/Commentary'
import { CapitalStack } from './Result/Graph/CapitalStack'
import {
  IncomeTable,
  CapitalRow,
  CapitalLabel,
  CapitalValue,
  CapitalTable,
} from './Result/Capital/CapitalTable'
import { separateComma } from 'helper/number'
import { TooltipBase } from 'components/Symbols/Tooltip'
import SpBox from './Layout/SpBox'
import { MainButton } from 'components/Symbols/Button'

const IncomeComment = styled.p`
  @media screen and (max-width: 800px) {
    margin-top: 10px;
  }
`
const IncomeGraphBox = styled(GraphBox)`
  margin: 15px;
  padding: 10px 25px 15px;
  @media screen and (max-width: 800px) {
    margin: 15px 0;
    padding: 10px 15px 15px;
  }
`

export const CapitalPanel: React.FC<{
  payload: SimulationPayload
  ChangeResultPage: any
}> = ({ payload: { estate, result, params }, ChangeResultPage }) => {
  return (
    <>
      <Panel>
        <PanelTitle
          icon={1}
          spDesign="bar"
          label="インカム収益・キャピタル収益"
          toolTipElem={
            <TooltipBase
              top="-3px"
              pcHide={true}
              spView={true}
              spTextLeft="-290px"
              spTextRight="-30px"
              color={'white'}
            >
              i
              <span className="tooltip-text">
                <b>判断できること：物件売却のタイミング</b>
                <br />
                AI査定価格から自己資金額を差し引いた額が、ローン残債より大きくなったタイミングで売却する事で、キャピタル益が見込めます。
                <br />
                また、その際に総インカムで見込める額を把握できます。
                <br />
                <b>
                  収益分析では、本年度から売却までの期間について、シミュレーションを行っています。
                </b>
              </span>
            </TooltipBase>
          }
        />
        <IncomeComment>※現在から売却時まで</IncomeComment>
        <IncomeGraphBox>
          <Grid column={['1fr', '1fr']} gap="50px">
            <Grid>
              <Grid column={['160px', '1fr']}>
                <CapitalStack
                  data={[
                    {
                      label: '純賃料収入\n',
                      value: result.total_rent,
                    },
                    {
                      label: 'ローン総額',
                      value: result.total_loan_usage_amount_to_sold,
                    },
                    {
                      label: '運営費用',
                      value: sum(result.operating_expenses),
                    },
                    {
                      label: '空室想定',
                      value: result.vacancy_loss_rent,
                    },
                  ]}
                />

                <IncomeTable>
                  <CapitalRow background="dark">
                    <CapitalLabel>純賃料収入</CapitalLabel>
                    <CapitalValue>
                      {separateComma(result.total_rent)}円
                    </CapitalValue>
                  </CapitalRow>
                  <CapitalRow>
                    <CapitalLabel>空室損失</CapitalLabel>
                    <CapitalValue color="orange">
                      {separateComma(result.vacancy_loss_rent * -1)}円
                    </CapitalValue>
                  </CapitalRow>
                  <CapitalRow background="dark">
                    <CapitalLabel>運営費用</CapitalLabel>
                    <CapitalValue color="orange">
                      {separateComma(sum(result.operating_expenses) * -1)}円
                    </CapitalValue>
                  </CapitalRow>
                  <CapitalRow type="sum">
                    <CapitalLabel>
                      純営業収益
                      <TooltipBase
                        spView={true}
                        spTextLeft="-80px"
                        spTextRight="-160px"
                      >
                        ?
                        <span className="tooltip-text">
                          (想定稼働年間収入) - (運営費用)で計算される純収入。
                        </span>
                      </TooltipBase>
                    </CapitalLabel>
                    <CapitalValue>
                      {separateComma(result.total_income)}円
                    </CapitalValue>
                  </CapitalRow>
                  <CapitalRow background="dark">
                    <CapitalLabel>ローン総額</CapitalLabel>
                    <CapitalValue color="orange">
                      {separateComma(-result.total_loan_repayment_amount)}円
                    </CapitalValue>
                  </CapitalRow>
                  <CapitalRow>
                    <CapitalLabel>返済済み総額</CapitalLabel>
                    <CapitalValue>
                      {separateComma(result.total_parchased_loan)}円
                    </CapitalValue>
                  </CapitalRow>
                  <CapitalRow background="dark">
                    <CapitalLabel>
                      <b>ローン総額</b>
                      <br />
                      <small>現在〜売却まで</small>
                    </CapitalLabel>
                    <CapitalValue color="orange">
                      <p className="lh2">
                        {separateComma(
                          -result.total_loan_repayment_amount +
                            result.total_parchased_loan +
                            result.debts
                        )}
                        円
                      </p>
                    </CapitalValue>
                  </CapitalRow>
                </IncomeTable>
              </Grid>
              <PlanCommentary margin="0 0 10px">
                <h3 className="no-margin">
                  純インカム収益は {separateComma(result.pure_income)}円
                  <TooltipBase
                    top="-3px"
                    spView={true}
                    spTextLeft="-150px"
                    spTextTop="30px"
                    spTextRight="-150px"
                  >
                    ?
                    <span className="tooltip-text">
                      累積純インカム ＝ Σ(想定稼働年間収入) − (運営費用) −
                      (ローン返済額)
                      <br />
                      想定稼働年間収入から運営費用を引いた純営業収益(NOI)からさらに
                      <br />
                      ローン返済額を引いた各年度の純インカム収益の累積額です。
                      <br />
                      純営業収益(NOI)がローン返済額よりも大きい時、累積はプラスになります。
                      <br />
                      ローン完済後はプラスの積み上がりが加速します。
                    </span>
                  </TooltipBase>
                </h3>
              </PlanCommentary>
            </Grid>
            <Grid>
              <Grid column={['160px', '1fr']}>
                <CapitalStack
                  data={[
                    { label: '純キャピタル', value: result.pureCapital },
                    {
                      label: '自己資金額',
                      value: params.own_resources * 10000,
                    },
                    {
                      label: 'ローン残債',
                      value:
                        result.loan_balances[
                          params.priorities_when_selling - 1
                        ],
                    },
                  ]}
                />
                <CapitalTable>
                  <CapitalRow background="dark">
                    <CapitalLabel>AI査定価格</CapitalLabel>
                    <CapitalValue>
                      {separateComma(
                        result.estimated_selling_prices[
                          params.priorities_when_selling - 1
                        ]
                      )}
                      円
                    </CapitalValue>
                  </CapitalRow>
                  <CapitalRow>
                    <CapitalLabel>ローン残債</CapitalLabel>
                    <CapitalValue color="orange">
                      {separateComma(
                        -result.loan_balances[
                          params.priorities_when_selling - 1
                        ]
                      )}
                      円
                    </CapitalValue>
                  </CapitalRow>
                  <CapitalRow background="dark">
                    <CapitalLabel>自己資金額</CapitalLabel>
                    <CapitalValue color="orange">
                      {separateComma(-params.own_resources * 10000)}円
                    </CapitalValue>
                  </CapitalRow>
                </CapitalTable>
              </Grid>
              <PlanCommentary margin="0 0 10px">
                <h3 className="no-margin">
                  純キャピタル収益は {separateComma(result.pureCapital)}円
                  <TooltipBase
                    top="-3px"
                    spView={true}
                    spTextLeft="-190px"
                    spTextRight="-30px"
                  >
                    ?
                    <span className="tooltip-text">
                      純キャピタル収益 ＝ AI査定価格 - 自己資金額 - ローン残債
                      <br />
                      その年のAI査定価格から、自己資金額を控除して、ローン残債をすべて支払ったときの額です。
                    </span>
                  </TooltipBase>
                </h3>
              </PlanCommentary>
            </Grid>
          </Grid>
        </IncomeGraphBox>
      </Panel>

      <SpBox>
        <MainButton
          onClick={() => ChangeResultPage(3)}
          height={45}
          fontSize="15px"
        >
          収支シミュレーション
        </MainButton>
      </SpBox>
    </>
  )
}

export default CapitalPanel
