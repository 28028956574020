import styled from 'styled-components'

export const ScrollAnchor = styled.div`
  position: fixed;
  left: calc(50% + 600px);
  top: 88px;
  width: 100px;
  text-align: center;
  .scroll-anchor-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 4px 4px 0 0;
    background: #4f5e9c;
    font-weight: bold;
    font-size: 15px;
    height: 95px;
    span {
      display: block;
      color: white;
      margin-bottom: 9px;
    }
  }
  .scroll-anchor-image {
    width: 24px;
  }
  .scroll-anchor-body {
    background: white;
    border-radius: 0 0 4px 4px;
    border: 1px solid #959ec3;
  }
  .scroll-anchor-body-row {
    padding: 8px 6px;
  }
  .scroll-anchor-body-row:first-child {
    border-bottom: 1px dashed #959ec3;
  }
  .scroll-anchor-body-button {
    display: block;
    background: #434260;
    color: white;
    font-weight: bold;
    border-radius: 3px;
    line-height: 1.2;
    font-size: 14px;
    padding: 10px 10px;
    cursor: pointer;
  }
  small {
    font-size: 12px;
  }
  .scroll-anchor-body-button_gray {
    display: block;
    background: #f5f5f5;
    color: #666;
    border: 1px solid #aaa;
    font-weight: bold;
    border-radius: 3px;
    line-height: 1.2;
    font-size: 14px;
    padding: 10px 8px;
    cursor: pointer;
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
`
