import styled from 'styled-components'

const InfoItem = styled.div<IProps>`
  display: grid;
  grid-gap: 25px;
  align-items: center;
  @media screen and (min-width: 801px) {
    ${(props) =>
      props.column && `grid-template-columns: ${props.column.join(' ')}`}
  }
  @media screen and (max-width: 800px) {
    grid-gap: 5px;
  }
  .itemText {
    margin: 10px 0;
  }
  .large {
    font-size: 20px;
  }
`

interface IProps {
  column?: string[] // ラベルテキスト
}

InfoItem.defaultProps = { ...InfoItem.defaultProps, column: ['200px', '1fr'] }

export default InfoItem
