import styled from 'styled-components'

export const PortfolioTable = styled.div`
  margin-bottom: 20px;
`
export const PortfolioTableComment = styled.div`
  margin-top: 5px;
  text-align: right;
  font-size: 12px;
`

export const BodyGroup = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  background: white;
`
// 縦に並ぶ要素の親として高さを指定する
export const BodyStack = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: 31px 31px 31px;
`

// 横幅を指定する
export const BodyRow = styled.div<{ width: string }>`
  width: ${(props) => props.width};
  &.row-head {
    border-left: 1px solid #e0e0e0;
    font-size: 10px;
  }
`

// セルの背景色をしていする
export const BodyCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  height: 100%;
  p {
    font-size: 11px;
    text-align: center;
    line-height: 1.1;
  }
  .blue {
    color: #4f5e9c;
    font-weight: bold;
  }
  .bold {
    font-weight: bold;
  }
`
