import React from 'react'
import styled from 'styled-components'
import colors from 'style/colors'

const sampleData = [
  {
    label: 'a',
    value: 100,
  },
  {
    label: 'b',
    value: 50,
  },
  {
    label: 'c',
    value: 200,
  },
  {
    label: 'd',
    value: 20,
  },
  {
    label: 'e',
    value: 10,
  },
]

const garphHeight = 400

const StackColors = [
  '#0673C8',
  colors.simulate_orange,
  '#f8ce7d',
  '#fbe8bf',
  'repeating-linear-gradient(-30deg, white, white 3px, #FFA500 0, #FFA500 5px)',
]

const StackArea = styled.div`
  height: ${garphHeight}px;
  margin: 20px 10px 10px 0;
  display: flex;
  flex-direction: column-reverse;
`

interface ItemProps {
  parse: number
  stackColor: string
}

const StackItem = styled.div`
  width: 170px;
  height: ${(props: ItemProps) => garphHeight * props.parse}px;
  display: flex;
  align-items: center;
  .rect {
    width: 70px;
    height: 100%;
    background: ${(props: ItemProps) => props.stackColor};
  }
  .label {
    display: flex;
    align-items: center;
    font-size: 11px;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 10px;
      height: 1px;
      background: ${colors.border_gray};
      margin-right: 5px;
    }
  }
`

interface CapitalStackDataProps {
  label: string
  value: number
}

interface CapitalStackProps {
  data?: CapitalStackDataProps[]
}

export const CapitalStack: React.FC<CapitalStackProps> = ({
  data = sampleData,
}: CapitalStackProps) => {
  const dataSum = data.map((item) => item.value).reduce((m, n) => m + n)
  return (
    <div>
      <StackArea>
        {data.map((item, index) => {
          return (
            <StackItem
              key={`StackItem${index}`}
              parse={item.value / dataSum}
              stackColor={StackColors[index]}
            >
              <div className="rect"></div>
              <div className="label">{item.label}</div>
            </StackItem>
          )
        })}
      </StackArea>
    </div>
  )
}
