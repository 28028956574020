import React, { FormEventHandler, useCallback } from 'react'
import colors from 'style/colors'
import styled from 'styled-components'
import Between from '../Simulation/Layout/Between'
import PanelTitle from '../Simulation/Layout/PanelTitle'
import PanelTag from '../Simulation/Result/Tag'
import CardProperty from './CardProperty'
import FieldSelect from '../Simulation/Field/Select'

const Property = styled.div`
  .properties {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    @media screen and (max-width: 800px) {
      border-top: 1px solid ${colors.border_gray2};
      display: block;
    }
  }
`

// const real_list_json = `[{
//   "trade_type": 1,
//   "property_type": 2,
//   "estate_name": "エグゼクティブ渋谷",
//   "purchase_price": 50000,
//   "year_rent": 240,
//   "gross_rate": 4.8,
//   "IRR": 2.09,
//   "address": "東京都渋谷区桜丘町1-27-1",
//   "nearest_station_name": "渋谷",
//   "occupation_area": 22.0,
//   "built_date": "1976-08",
//   "layout": "2LDK"
// }]`

// const real_list_data = JSON.parse(real_list_json)

type DashboardProps = {
  estates: {}
  deleteEstate: {}
  showTradeType: number
  setShowTradeType: any
}

const PropertyComponent: React.FC<DashboardProps> = (props: any) => {
  const handleOnChange = useCallback<FormEventHandler<HTMLInputElement>>(
    (e) => {
      props.setShowTradeType(Number(e.currentTarget.value))
    },
    [props]
  )

  console.log(props.estates)

  if (!props.estates) {
    return null
  }

  return (
    <Property>
      <Between marginBottom={15}>
        <PanelTitle icon={'arw'} label="登録物件一覧" marginBotton={0}>
          <PanelTag className="panel-tag-transparent pc">
            {props.estates.length} / 20件
          </PanelTag>
        </PanelTitle>
        <FieldSelect
          className="pc"
          name={'filter_trade'}
          deprecated_values={[0, 1, 2, 3]}
          deprecated_texts={['すべて', '購入', '売却', '保有']}
          changedSelect={handleOnChange}
          width={120}
        />
      </Between>
      <div className="properties">
        {props.estates.map((estate: any) =>
          props.showTradeType === 0 ||
          estate.trade_type === props.showTradeType ? (
            <CardProperty
              key={estate._id}
              data={estate}
              deleteEstate={props.deleteEstate}
            />
          ) : (
            ''
          )
        )}
      </div>
    </Property>
  )
}

export default PropertyComponent
