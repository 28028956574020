import classNames from 'classnames'
import { isInteger } from 'helper/number'
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { RouteComponentProps } from 'react-router-dom'
import colors from 'style/colors'
import styled from 'styled-components'
import {
  condencedButtonStyles,
  isLinkEnabled,
  StepListLabels,
  StepListNavigationProps,
  StepListPageAnchors,
  StepListSections,
} from './StepList.type'
import { toArray } from 'helper/array'

const StepListSPElem = styled.div`
  width: 100%;
  background: white;
  margin: 0;
  &.spfixed {
    position: fixed;
    top: 89px;
    left: 0;
    z-index: 10;
    margin: -20px 0;
  }
  &.bottom {
    margin-top: 10px;
    display: block;
  }
  @media screen and (min-width: 801px) {
    display: none;
    &.fixed {
      position: fixed;
      top: 78px;
      left: 0;
      display: block;
    }
    &.bottom {
      display: none;
    }
  }
  ol {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
  }
`

// タブの番号をステップ番号にマップする
export const mapTabToStep = (tabNum: number) => {
  return tabNum === 4 || tabNum === 6 || tabNum === 5 ? 5 : tabNum === 3 ? 4 : 3
}

// 強制的にStepのクラスを切り替える
export const changeStepNum = (num: number) => {
  toArray(0, 10).forEach((_, index) => {
    const lists = document.getElementsByClassName('stepList')
    lists[index + 1] && lists[index + 1].classList.remove('active')
  })
  const listS = document.getElementById('stickyStepList' + num)
  listS && listS.classList.add('active')
  const listB = document.getElementById('bottomStepList' + num)
  listB && listB.classList.add('active')
}

const ListItemSP = styled.li`
  position: relative;
  list-style: none;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  flex-grow: 1;
  padding: 0 10px 0 10px;
  height: 50px;
  line-height: 50px;
  background-color: white;
  margin: 0 -9px;
  &.active {
    background: #dbdeeb;
  }
  p {
    position: relative;
    display: inline;
  }
  .step-label {
    color: ${colors.border_gray};
    @media screen and (min-width: 801px) {
      display: inline;
    }
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  &.active .step-label {
    color: ${colors.main};
  }
  @media screen and (max-width: 800px) {
    &.active {
      .step-label {
        display: inline;
      }
    }
  }

  .step-number {
    color: ${colors.border_gray};
  }
  &.active .step-number {
    color: ${colors.main};
  }
  .step-name {
    @media screen and (min-width: 801px) {
      display: inline;
      color: ${colors.border_gray};
    }
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  &.active {
    .step-name {
      @media screen and (min-width: 801px) {
        color: ${colors.base};
      }
      @media screen and (max-width: 800px) {
        display: inline;
      }
    }
  }

  &::after {
    content: url('/img/common/try-border.svg');

    width: 23px;
    height: 50px;
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    right: -4px;
  }
  &.active::after {
    content: url('/img/common/try.svg');
  }

  &:last-child {
    &::after {
      content: '';
    }
  }

  ${condencedButtonStyles}
`

export type IStepListStickyProps = {
  sticky?: boolean
  forceStep?: number
} & StepListNavigationProps &
  Pick<RouteComponentProps, 'location'>

/** ページ上部に表示されるStepList */
export const StepListSticky: React.FC<IStepListStickyProps> = memo(
  ({ onClickStep, location, forceStep, ...props }) => {
    const [stepNum, setStepNum] = useState(isInteger(forceStep) ? forceStep : 1)
    const [scroll, setScroll] = useState(0)
    const self = useRef<HTMLDivElement>(null)

    // PC: スクロールごとにstepNumberを同期する
    useEffect(() => {
      if (isMobileOnly) return
      if (!location.pathname.includes('result')) return
      let selfBottomY = 50 + 89 + 44 // 44 はpadding

      if (isInteger(forceStep)) {
        setStepNum(forceStep)
      }

      const scrollHandler = () => {
        if (!self.current) return
        setScroll(window.pageYOffset)
        if (isInteger(forceStep)) {
          return
        }

        const sections = StepListSections.map((id) => {
          if (!id) return null
          const section = document.getElementById(id)
          return section
            ? section.getBoundingClientRect().top - selfBottomY
            : null
        })
        let stepNumber = sections.findIndex((sectionTop) =>
          sectionTop ? sectionTop >= 0 : false
        )
        if (stepNumber === -1) {
          stepNumber = sections.length
        }
        setStepNum(Math.max(stepNumber + 2, 3))
      }
      window.addEventListener('scroll', scrollHandler, { passive: true })
      return () => window.removeEventListener('scroll', scrollHandler)
    }, [setScroll, setStepNum, location, forceStep])

    // フォーム
    useEffect(() => {
      if (isMobileOnly) return
      if (location.pathname.includes('result')) return
      if (isInteger(forceStep)) {
        setStepNum(forceStep)
      }

      const scrollHandler = () => {
        if (!self.current) return
        setScroll(window.pageYOffset)
        if (isInteger(forceStep)) {
          return
        }
        setStepNum(1)
      }
      window.addEventListener('scroll', scrollHandler, { passive: true })

      return () => window.removeEventListener('scroll', scrollHandler)
    }, [setScroll, setStepNum, location, forceStep])

    // Mobile: URLからstepNumを同期する
    useEffect(() => {
      if (!isMobileOnly) return
      // シミュレーションページのデフォルトは3
      if (location.pathname.match('result')) {
        setStepNum(3)
      }
      if (!location.pathname.includes('result')) {
        setStepNum(1)
      }
      if (isInteger(forceStep)) {
        setStepNum(forceStep)
      }

      StepListPageAnchors.forEach((pageHash) => {
        if (pageHash.hash === location.hash) {
          setStepNum(pageHash.step)
        }
      })
    }, [forceStep, location.hash, location.pathname])

    const stepClickHandlers = useMemo(
      () =>
        StepListLabels.map((_, idx) => () => onClickStep && onClickStep(idx)),
      [onClickStep]
    )

    return (
      <StepListSPElem
        id={props.sticky ? 'stickyStepList' : 'bottomStepList'}
        ref={self}
        className={classNames(
          { fixed: scroll > 100 },
          props.sticky ? 'spfixed' : 'bottom'
        )}
      >
        <ol>
          {StepListLabels.map((label, index) => (
            <ListItemSP
              id={
                props.sticky
                  ? 'stickyStepList' + (index + 1)
                  : 'bottomStepList' + (index + 1)
              }
              key={label}
              className={stepNum === index + 1 ? 'active stepList' : 'stepList'}
            >
              <button
                className="condensed"
                onClick={stepClickHandlers[index] || undefined}
                disabled={!isLinkEnabled(index + 1, stepNum)}
              >
                <span className="step-label">STEP.</span>
                <span className="step-number">{index + 1} </span>
                <span className="step-name">{label}</span>
              </button>
            </ListItemSP>
          ))}
        </ol>
      </StepListSPElem>
    )
  }
)
