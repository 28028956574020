import actionCreatorFactory from 'typescript-fsa'

const actionCreator = actionCreatorFactory()

export const hogeActions = {
  updateName: actionCreator<string>('ACTIONS_UPDATE_NAME'),
  updateEmail: actionCreator<string>('ACTIONS_UPDATE_EMAIL'),
  request: actionCreator<string>('ACTIONS_REQUEST'),
  requestSuccess: actionCreator<string>('ACTIONS_REQUEST_SUCCESS'),
  requestFailed: actionCreator<string>('ACTIONS_REQUEST_FAILED'),
  closeModal: actionCreator<void>('ACTIONS_CLOSE_MODAL'),
  openModal: actionCreator<void>('ACTIONS_OPEN_MODAL'),
}
