import { EstateJSON, EstatePropertyPayload } from 'domain/estate'
import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { PlanPayload, UserPlans, UserPlan } from 'domain/plan'
import { SimulationPayload } from 'domain/simulation'

// State

export interface EstateState {
  editingEstate: EstateForm
  searchEstate: EstateForm
  searchResult: SearchModalForm
  planningEstate?: EstateJSON
  railways: any
  stations: any
  simulationLoading: boolean
  simulationError: string
  plans?: any[]
  userPlans?: UserPlan[]
}

export type EstateForm = {
  property_type: number
  trade_type: number
  estate_name: string
  expected_selling_price: number
  zip1: string
  zip2: string
  pref_code: string
  city: string
  aza: string
  chome: string
  ban: string
  gou: string
}

export type SearchModalForm = {
  keyword: string
  data: {
    [key: string]: {
      _id: number
      pref_code: string
      city: string
      address: string
      estate_name: string
    }
  }
  isShow: boolean
}

export type SearchResultForm = {
  property_type: number
  pref_code: string
  estate_name: string
  city: string
  apartment_id: number
}

export type SaveNewPlanForm = {
  planLabel: string
  payload: SimulationPayload
  plans: any[]
  changeSaveModal?: any
}

const draftEstate = {
  zip1: '',
  zip2: '',
  pref_code: '',
  city: '',
  aza: '',
  chome: '',
  property_type: 1,
  estate_name: '',
  expected_selling_price: 0,
  ban: '',
  gou: '',
  railways: [],
  trade_type: 1,
}

const initEstateSearchModal = {
  keyword: '',
  data: {},
  isShow: false,
  setPlanFromSearchResult: {},
}

const initialState: EstateState = {
  editingEstate: draftEstate,
  searchEstate: draftEstate,
  searchResult: initEstateSearchModal,
  railways: [],
  stations: {},
  simulationLoading: false,
  simulationError: '',
}

// Action

const actionCreator = actionCreatorFactory()

export const EstateActions = {
  setEstateDraft: actionCreator<Partial<EstateForm>>('ACTIONS_SET_ESTATE'),
  getEstate: actionCreator<string>('ACTIONS_GET_ESTATE'),
  searchEstate: actionCreator<Partial<EstateForm>>('ACTINOS_ESTATE_SEARCH'),
  showSearchModal: actionCreator<SearchModalForm>('ACTIONS_SEARCH_MODAL'),
  deleteSearchModal: actionCreator<void>('ACTIONS_DELETE_SEARCH_MODAL'),
  setPlanFromSearchResult: actionCreator<SearchResultForm>(
    'ACTIONS_SET_PLAN_FROM_SEARCH_RESULT'
  ),
  getAddressFromZip: actionCreator<string>('ACTIONS_GET_ADDRESS_FROM_ZIP'),
  getRailways: actionCreator<string>('ACTIONS_GET_RAILWAYS'),
  setRailways: actionCreator<any>('ACTIONS_SET_RAILWAYS'),
  getStations: actionCreator<any>('ACTIONS_GET_STATIONS'),
  setStations: actionCreator<any>('ACTIONS_SET_STATIONS'),
  setPlanningEstate: actionCreator<EstateJSON>('ACTIONS_SET_PLANNING_ESTATE'),
  getPlanningEstate: actionCreator<string>('ACTIONS_GET_PLANNING_ESTATE'),
  saveAndSimulation: actionCreator<EstatePropertyPayload>(
    'ACTIONS_SAVE_AND_SIMULATION'
  ),
  setSimulationLoading: actionCreator<boolean>(
    'ACTIONS_SET_SIMULATION_LOADING'
  ),
  saveSimulationFailed: actionCreator<string>('ACTIONS_SIMULATION_FAILED'),
  getPlans: actionCreator<any>('ACTIONS_GET_PLANS'),
  setPlans: actionCreator<any>('ACTIONS_SET_PLANS'),
  savePlan: actionCreator<PlanPayload>('ACTIONS_SAVE_PLANS'),
  deletePlan: actionCreator<any>('ACTIONS_DELETE_PLAN'),
  saveNewPlan: actionCreator<SaveNewPlanForm>('ACTIONS_NEW_SAVE_PLAN'),
  getUserPlan: actionCreator<void>('ACTIONS_GET_USER_PLAN'),
  setUserPlan: actionCreator<UserPlans>('ACTIONS_SET_USER_PLAN'),
}

export const EstateReducer = reducerWithInitialState(initialState)
  .case(
    EstateActions.setEstateDraft,
    (state, estate): EstateState => {
      return {
        ...state,
        editingEstate: {
          ...state.editingEstate,
          ...estate,
        },
      }
    }
  )
  .case(EstateActions.setRailways, (state, railways) => {
    return Object.assign({}, state, { railways })
  })
  .case(EstateActions.setStations, (state, stations) => {
    return Object.assign({}, state, { stations })
  })
  .case(
    EstateActions.setPlanningEstate,
    (state, planningEstate?: EstateJSON) => {
      return {
        ...state,
        planningEstate: planningEstate,
      }
    }
  )
  .case(EstateActions.saveSimulationFailed, (state, message: string) => {
    return {
      ...state,
      simulationError: message,
      simulationLoading: false,
    }
  })
  .case(EstateActions.setSimulationLoading, (state, status: boolean) => {
    return {
      ...state,
      simulationLoading: status,
    }
  })
  .case(EstateActions.setPlans, (state, plans: any[]) => {
    return {
      ...state,
      plans,
    }
  })
  .case(
    EstateActions.showSearchModal,
    (state: EstateState, searchResult: SearchModalForm) => {
      return {
        ...state,
        searchResult,
      }
    }
  )
  .case(EstateActions.deleteSearchModal, (state: any, payload: void) => {
    return {
      ...state,
      searchResult: {
        data: {},
        isShow: false,
      },
    }
  })
  .case(EstateActions.setUserPlan, (state: any, userPlans: UserPlans) => {
    return {
      ...state,
      userPlans,
    }
  })
