import React from 'react'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts'
import {
  xAxisTick,
  yAxisTick,
  GraphContainer,
  CustomGroupToolTip,
} from './Foundation'
import { resultSumByKey } from 'domain/plan'
import { derivedPlanGroupsType } from 'domain/portfolio'

export const PortfolioAreaGraph: React.FC<{
  values?: derivedPlanGroupsType
}> = ({ values }) => {
  if (!values) return null
  const groupOne = values[1]
    ? resultSumByKey(values[1].result, 'annual_depreciation_costs')
    : []
  const groupTwo = values[2]
    ? resultSumByKey(values[2].result, 'annual_depreciation_costs')
    : []
  const groupThr = values[3]
    ? resultSumByKey(values[3].result, 'annual_depreciation_costs')
    : []
  const maxLength = Math.min(
    Math.max(groupOne.length, groupTwo.length, groupThr.length),
    10
  )
  const data = new Array(maxLength).fill(null).map((_, i) => {
    return {
      name: i.toString(),
      one: groupOne[i] ? Math.round(groupOne[i] / 10000) : 0,
      two: groupTwo[i] ? Math.round(groupTwo[i] / 10000) : 0,
      three: groupThr[i] ? Math.round(groupThr[i] / 10000) : 0,
    }
  })

  return (
    <GraphContainer>
      <ResponsiveContainer>
        <AreaChart
          data={data}
          margin={{ top: 0, right: 0, left: -20, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis tickSize={0} tickMargin={8} tick={xAxisTick} dataKey="name" />
          <YAxis
            padding={{ top: 30, bottom: 0 }}
            tickSize={0}
            tick={yAxisTick}
            axisLine={false}
          />
          <Tooltip content={CustomGroupToolTip} />
          <Area
            name="グループⅠ"
            type="linear"
            dataKey="one"
            // stackId="1"
            stroke="#0673c8"
            strokeWidth="3"
            fill="#0673c8"
            fillOpacity="0.1"
            isAnimationActive={false}
          />
          <Area
            name="グループⅡ"
            type="linear"
            dataKey="two"
            // stackId="1"
            stroke="#FFA600"
            strokeWidth="3"
            fill="#FFA600"
            fillOpacity="0.1"
            isAnimationActive={false}
          />
          <Area
            name="グループⅢ"
            type="linear"
            dataKey="three"
            stackId="1"
            stroke="#BECD25"
            strokeWidth="3"
            fill="#BECD25"
            fillOpacity="0.1"
            isAnimationActive={false}
          />
        </AreaChart>
      </ResponsiveContainer>
    </GraphContainer>
  )
}
