import styled from 'styled-components'
import colors from 'style/colors'

interface priceTableOverScrollProps {
  rowLength: number
}

export const PriceTableOverScroll = styled.div`
  display: block;
  margin: 20px 0;
  @media screen and (min-width: 801px) {
    position: relative;
    overflow-x: scroll;
    width: 100%;
    height: ${(props: priceTableOverScrollProps) => props.rowLength * 64}px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const PriceTable = styled.table`
  width: 100%;
  @media screen and (max-width: 800px) {
    margin: 20px 0;
  }
  @media screen and (min-width: 801px) {
    position: absolute;
    width: calc(100% - 1px); /* ボーダーがはみ出るので1px減らす */
    height: calc(100% - 1px);
  }
  th,
  td {
    text-align: center;
    border: 1px solid ${colors.border_gray};
    font-size: 10px;
    font-weight: bold;
    @media screen and (max-width: 800px) {
      font-size: 13px;
      text-align: right;
      padding-right: 20px;
    }
  }
  th {
    background: ${colors.bg_gray};
    color: ${colors.black_middle};
    height: 20px;
    line-height: 20px;
    @media screen and (max-width: 800px) {
      color: ${colors.base};
      background: #f5f5f5;
    }
  }
  td {
    height: 40px;
    line-height: 40px;
    @media screen and (max-width: 800px) {
      font-weight: normal;
    }
  }
  th.active,
  td.active {
    background: ${colors.blue_bg_table_attention};
  }
  @media screen and (max-width: 800px) {
    tr:nth-child(2n) {
      td {
        background: ${colors.bg_gray};
      }
    }
  }
`

export default PriceTable

export interface PriceTableDataProps {
  year: number
  value: number
}

export interface PriceTableProps {
  rowLength: number // 表示する行数
  page: number
  datas: PriceTableDataProps[] // 表示する値
  sold_year: number // 売却年度
  height?: number // CSS高さ
}

export interface ComponentProps {
  height: number
}
