import React from 'react'
import styled from 'styled-components'
import { tradeTypeToText, buildingStructureToText } from 'helper/types'
import { timestampToText } from 'helper/date'
import { UserPlan } from 'domain/plan'

const TableBreakDown = styled.div`
  height: 30px;
  background: #4f5e9c;
  color: white;
  font-size: 13px;
  font-weight: bold;
  margin-top: 10px;
  padding: 10px 20px;
`

export const PortfolioTableBreakDown: React.FC = () => {
  return <TableBreakDown>内訳：</TableBreakDown>
}

const TableProperty = styled.div`
  border-top: 2px solid #4f5e9c;
  border-bottom: 1px solid #e0e0e0;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;

  .property-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
    padding: 0 20px;
    background: #edeff5;
    border-bottom: 1px dashed #b8bed7;
    h2 {
      color: #4f5e9c;
    }
    p {
      color: #4f5e9c;
      font-size: 12px;
    }
    b {
      color: #4f5e9c;
      font-weight: bold;
    }
  }
  .property-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 28px;
    padding: 0 20px;
    background: #fafafa;
    .property-tags {
      display: flex;
      .property-tag {
        margin-right: 5px;
        border: 1px solid #b8bed7;
        border-radius: 8px;
        height: 15px;
        line-height: 13px;
        padding: 0 10px;
        font-size: 11px;
        font-weight: bold;
        color: #4f5e9c;
        background: white;
        &.blue {
          border: none;
          line-height: 15px;
          color: white;
          background: #4f5e9c;
        }
        &.light-blue {
          border: none;
          line-height: 15px;
          color: white;
          background: #3e9bc4;
        }
        &.yellow {
          border: none;
          line-height: 15px;
          color: white;
          background: #fbb900;
        }
        &.green {
          border: none;
          line-height: 15px;
          color: white;
          background: #3ec481;
        }
      }
    }
    .property-irr {
      font-size: 12px;
      font-weight: bold;
      color: #b8bed7;
    }
  }
`

export const PortfolioTableProperty: React.FC<{ value: UserPlan }> = ({
  value,
}) => {
  if (!value.params.estate) return null
  console.log(value.params.estate)
  return (
    <TableProperty>
      <div className="property-title">
        <h2>{value.params.estate.estate_name}</h2>
        <p>
          <b>投資条件：</b>
          {value.params.label}・{value.params.priorities_when_selling}
          年で売却
        </p>
      </div>
      <div className="property-option">
        <div className="property-tags">
          <p className={'property-tag blue'}>
            {value.params.estate.personal_company_type === 1 ? '個人' : '法人'}
          </p>
          <p
            className={
              'property-tag ' +
              (value.params.estate.trade_type === 1
                ? 'light-blue'
                : value.params.estate.trade_type === 2
                ? 'yellow'
                : 'green')
            }
          >
            {tradeTypeToText(value.params.estate.trade_type)}
          </p>
          <p className="property-tag">
            {buildingStructureToText(value.params.estate.building_structure)}造
          </p>
          <p className="property-tag">
            {timestampToText(
              typeof value.params.estate.built_date === 'object'
                ? value.params.estate.built_date.$date
                : value.params.estate.built_date
            )}
          </p>
        </div>
        <p className="property-irr">
          <b>IRR(全期間利回り)：{value.params.estate.IRR}%</b>
        </p>
      </div>
    </TableProperty>
  )
}
