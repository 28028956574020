import React, { createRef } from 'react'
import styled from 'styled-components'
import { ldebug } from 'helper/log'
declare const Datamatic: any

type IDatamaticReactProps = {
  accountID: string
  chartID: string
  data: any
  chartOption: { [key: string]: any }
}

export class DatamaticReact extends React.Component<IDatamaticReactProps> {
  containerRef: React.RefObject<HTMLDivElement>
  graphElement?: HTMLDivElement

  datamatic?: any
  chart?: any

  private cancelded = false

  constructor(props: IDatamaticReactProps) {
    super(props)
    this.containerRef = createRef()
  }

  renderWidget = async () => {
    if (this.cancelded) return
    if (!this.containerRef.current) return

    if (!this.graphElement) {
      const div = document.createElement('div')
      this.graphElement = div
      this.containerRef.current!.appendChild(this.graphElement!)
    }

    if (!('Datamatic' in window)) {
      // didMountしてscriptタグが差し込まれた後、スクリプトの読み込み前にpropsが更新された場合
      ldebug('Datamatic loading in progress, skip render')
      return
    }

    if (!this.datamatic) {
      this.datamatic = new Datamatic(this.props.accountID)
    }

    if (!this.chart) {
      ldebug('Datamatic render widget')
      this.chart = this.datamatic.chart(
        this.props.chartID,
        this.props.chartOption
      )
      this.chart.render(this.graphElement!).then(() => {
        this.chart.setData(this.props.data)
      })
    } else {
      ldebug('Datamatic update widget')
      this.chart.setData(this.props.data)
    }
  }

  componentDidMount() {
    if ('Datamatic' in window) {
      ldebug('Datamatic already loaded')
      this.renderWidget()
      return
    }

    let script = document.getElementById('datamaticloader') as HTMLScriptElement
    if (!script) {
      ldebug('Datamatic not loaded')
      script = document.createElement('script')
      script.src =
        'https://cdn.datamatic.io/runtime/echarts/3.7.2_246/api/api.js'
      script.id = 'datamaticloader'
      script.type = 'text/javascript'
      script.onload = this.renderWidget
      const headElem = document.querySelector('head')
      headElem && headElem.appendChild(script)
    } else {
      ldebug('Datamatic already included but not loaded')
      script.addEventListener('onload', this.renderWidget)
    }
  }

  componentDidUpdate() {
    this.renderWidget()
  }

  componentWillUnmount() {
    this.cancelded = true
    this.graphElement && this.graphElement.remove()
    this.chart = null
    this.datamatic = null
  }

  render() {
    return <div ref={this.containerRef} />
  }
}

export default DatamaticReact

export const DatamaticWrapper = styled.div`
  width: calc(100% + 10px);
  margin: 10px -5px -10px;
`
