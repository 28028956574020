import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { hogeActions } from '../actions/hogeActions'

export interface HogeState {
  name: string
  email: string
  request: string
  isShowModal: boolean
}

const initialState: HogeState = {
  name: '',
  email: '',
  request: '',
  isShowModal: false,
}

export const hogeReducer = reducerWithInitialState(initialState)
  .case(hogeActions.updateName, (state, name) => {
    return Object.assign({}, state, { name })
  })
  .case(hogeActions.updateEmail, (state, email) => {
    return Object.assign({}, state, { email })
  })
  .case(hogeActions.requestSuccess, (state, result) => {
    return Object.assign({}, state, { result })
  })
  .case(hogeActions.closeModal, (state) => {
    return Object.assign({}, state, { isShowModal: false })
  })
  .case(hogeActions.openModal, (state) => {
    return Object.assign({}, state, { isShowModal: true })
  })
