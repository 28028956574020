import { PageButtonElem } from 'components/Simulation/Field/PageButton'
import Grid from 'components/Simulation/Layout/Grid'
import React, { useState, ReactElement } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { PriceTableDataProps, PriceTableOverScroll } from './PriceTableBase'
import PriceTablePC from './PriceTablePC'

interface IProps {
  title: string
  tooltip?: ReactElement
  RowLength: number
  datas: PriceTableDataProps[]
  sold_year: number
}

export const SellPricesTable: React.FC<IProps> = (props: IProps) => {
  const [Page, PageChage] = useState(1) // 現在のページ
  const PageLength = Math.ceil(props.datas.length / (props.RowLength * 10)) // 総ページ数
  const BackButton =
    1 < Page ? (
      <PageButtonElem className="back" onClick={() => PageChage(Page - 1)}>
        {(Page - 1) * props.RowLength * 10}年目以前
      </PageButtonElem>
    ) : (
      <div />
    )
  const NextButton =
    Page < PageLength ? (
      <PageButtonElem className="next" onClick={() => PageChage(Page + 1)}>
        {Page * props.RowLength * 10 + 1}年目以降
      </PageButtonElem>
    ) : (
      <div />
    )
  const Buttons = (
    <>
      {BackButton}
      {NextButton}
    </>
  )

  if (isMobileOnly) {
    return null
  }

  return (
    <>
      <Grid column={['1fr', '108px', '108px']} alignItems="center">
        <h3>
          {props.title}
          {props.tooltip}
        </h3>
        {Buttons}
      </Grid>
      <PriceTableOverScroll rowLength={props.RowLength}>
        <PriceTablePC
          sold_year={props.sold_year}
          datas={props.datas}
          rowLength={props.RowLength}
          page={Page}
        />
      </PriceTableOverScroll>
    </>
  )
}

export default SellPricesTable
