import ToggleRentButton from 'components/Simulation/Field/ToggleButton'
import { GraphBox } from 'components/Symbols/Box'
import { EstateJSON } from 'domain/estate'
import {
  SimulationParams,
  SimulationPayload,
  SimulationResult,
} from 'domain/simulation'
import React, { memo, useCallback, useState } from 'react'
import styled from 'styled-components'
import { separateComma, fitValue, builtYearToAge } from '../../../helper/number'
import { MainButton } from '../../Symbols/Button'
import { HelpButton } from '../../Symbols/Help'
import { BaseModal } from '../../Symbols/Modal'
import SpBar from '../../Symbols/SpBar'
import Number from '../Field/Number'
import Grid from '../Layout/Grid'
import Panel from '../Layout/Panel'
import PanelTitle from '../Layout/PanelTitle'
import { SpBox } from '../Layout/SpBox'
import CircleGraph from './Graph/Circle'
import NewVacancyRate from './Graph/NewVacancyRate'
import RentLineGraph from './Graph/RentLine'
import GraphComment from './GraphComment'
import InfoItem from './InfoItem'
import Label from './Label'
import {
  IPlanCommentaryProps,
  PlanCommentary,
  PlanCommentaryLarge,
} from './Plan/Commentary'
import { PriceTableWrapper } from './Plan/PriceTableWrapper'
import PanelTag from './Tag'
import { TooltipBase } from 'components/Symbols/Tooltip'
import { IconAI } from 'components/Symbols/Icon'
import { SellingBar } from '../SellingBar'

const InfoList = styled.div`
  display: grid;
  grid-gap: 15px;
  margin-bottom: 15px;
`

const ToggleRentText = styled.p`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .toggle-label {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
`

const BreakDownTitle = styled.h3`
  @media screen and (max-width: 800px) {
    margin: 20px -20px -15px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: white;
    background: #727eaf;
    .sp-none {
      display: none;
    }
  }
`

type IInventPlanProps = {
  payload: SimulationPayload
  ChangeResultPage: any
  onChangeParams: (keypath: string, value: string | boolean | number) => void
}

const alignLeftCommentaryProps = { textAlign: 'left' }
export const InvestmentPlan: React.FC<IInventPlanProps> = ({
  payload,
  payload: { params, result, estate },
  onChangeParams,
  ChangeResultPage,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const handleModalToggle = useCallback(() => setModalOpen((v) => !v), [
    setModalOpen,
  ])

  const rentsChange = useCallback(
    (value) => {
      onChangeParams('is_rent_fixed', value)
    },
    [onChangeParams]
  )

  const handleChange = useCallback<React.FormEventHandler<HTMLInputElement>>(
    (e) => {
      const name = e.currentTarget.name
      const value = e.currentTarget.valueAsNumber
      // バリデーション
      const min = e.currentTarget.min ? parseInt(e.currentTarget.min) : 0
      const max = parseInt(e.currentTarget.max)
      const fittedValue = fitValue(value ? value : 0, min, max)
      onChangeParams(name, fittedValue)

      // 合計値に影響を及ぼす場合、子要素も更新する
      // 購入価格と購入諸費用のみが対象
      const childSumID = e.currentTarget.dataset.childsumid
      if (childSumID) {
        const sumTo = document.querySelectorAll(
          `[data-childsumid="${childSumID}"]:not([name="${name}"])`
        )
        const sumToValue = sumTo[0].getAttribute('value') || '0'
        const sumValue = fittedValue + parseInt(sumToValue)
        const childs = document.querySelectorAll(`[data-sumid="${childSumID}"]`)
        const child2 = parseInt(childs[1].getAttribute('value') || '0')
        const newChild1 = sumValue - child2 >= 0 ? sumValue - child2 : 0
        // console.log(newChild1)
        const newChild2 = sumValue - newChild1
        // console.log(newChild2)
        const child1Name = childs[0].getAttribute('name') || ''
        const child2Name = childs[1].getAttribute('name') || ''
        onChangeParams(child1Name, newChild1)
        onChangeParams(child2Name, newChild2)
      }

      // 値を共有しているエレメントを取得
      const shareInput = document.querySelector(
        `[data-sumid="${e.currentTarget.dataset.sumid}"]:not([name="${name}"])`
      )
      if (shareInput) {
        const shareName = shareInput.getAttribute('name')
        if (shareName) {
          const sumValue = e.currentTarget.dataset.sumvalue
            ? e.currentTarget.dataset.sumvalue
            : '0'
          onChangeParams(shareName, parseInt(sumValue) - fittedValue)
        }
      }
    },
    [onChangeParams]
  )

  return (
    <div>
      <SellingBar
        payload={payload}
        value={params.priorities_when_selling}
        onChange={handleChange}
        max={estate.sell_prices.length}
      />
      <TheModal open={modalOpen} toggle={handleModalToggle} />

      <Panel>
        <PlanCommentaryBox />
        <PanelTitle
          id="ResultPlan1"
          spDesign="bar"
          icon={1}
          label="売却時期と売却価格"
        >
          <SpBar className="sp" background="#DBDEEB" justify="flex-end">
            <HelpButton onClick={handleModalToggle}>
              投資プランとは？
            </HelpButton>
          </SpBar>
        </PanelTitle>
        <Plan1Form
          handleChange={handleChange}
          params={params}
          estate={estate}
        />
        <Plan1Simulation params={params} estate={estate} result={result} />
      </Panel>

      <Panel>
        <PanelTitle
          id="ResultPlan2"
          spDesign="bar"
          icon={2}
          label="賃料と空室率"
        />
        <Grid>
          <Grid column={['1fr', '1fr']}>
            <div>
              <Plan2Form
                handleChange={handleChange}
                rentsChange={rentsChange}
                params={params}
              />
              <Plan2Simulation1 params={params} estate={estate} />
            </div>
            <Plan2Simulation2
              result={result}
              estate={estate}
              commentaryProps={alignLeftCommentaryProps}
            />
          </Grid>

          <Grid column={['1fr', '1fr']}>
            <div>
              <ActiveRatesForm params={params} handleChange={handleChange} />

              <PriceTableWrapper
                title="想定稼働年間収入"
                tooltip={
                  <TooltipBase text_left="-60px" text_right="auto">
                    ?
                    <span className="tooltip-text">
                      その年の予想賃料に想定稼働率を掛けた年間賃料収入。
                    </span>
                  </TooltipBase>
                }
                sold_year={params.priorities_when_selling}
                datas={result.annual_effective_rents}
                PCRowLength={1}
              />
            </div>
            <VacancyRageSimulation params={params} estate={estate} />
          </Grid>
        </Grid>
      </Panel>

      <Panel>
        <PanelTitle
          id="ResultPlan3"
          spDesign="bar"
          icon={3}
          label="自己資金とローン"
        />
        <Plan3Form1
          params={params}
          handleChange={handleChange}
          result={result}
        />
        <Plan3Comment1 result={result} />
        <Grid>
          <Grid column={['1fr', '1fr']}>
            <Plan3Form2
              params={params}
              result={result}
              handleChange={handleChange}
            />
            {/* 初期投資額グラフ */}
            <CircleGraph
              data1_label="自己資金額"
              data1_value={Math.round(params.own_resources)}
              data2_label="ローン利用額"
              data2_value={Math.round(params.loan_usage_amount)}
              sum_label="初期投資額"
            />
          </Grid>
          <Plan3Comment2 result={result} />
        </Grid>
      </Panel>

      <Panel>
        <PanelTitle id="ResultPlan4" spDesign="bar" icon={4} label="運営費用" />
        <Grid column={['1fr', '1fr']}>
          <Plan4Form1
            params={params}
            result={result}
            handleChange={handleChange}
          />
        </Grid>
        <Plan4Form2 params={params} handleChange={handleChange} />
        <Plan4Comment1 result={result} />
      </Panel>

      <Panel>
        <PanelTitle
          id="ResultPlan5"
          spDesign="bar"
          icon={5}
          label="減価償却費"
        />
        <Grid column={['1fr', '1fr']}>
          <div>
            <Plan5Form1 params={params} handleChange={handleChange} />
            <Plan5Comment1
              params={params}
              result={result}
              commentaryProps={alignLeftCommentaryProps}
            />
          </div>
          {/* 購入価格のグラフ */}
          <CircleGraph
            data1_label="土地割合"
            data1_value={Math.round(result.land_price)}
            data2_label="建物割合"
            data2_value={Math.round(
              result.building_price -
                (params.selling_expenses * params.building_ratio) / 100
            )}
            sum_label="購入価格"
          />
        </Grid>

        <Grid>
          <BreakDownTitle>
            減価償却費の内訳<span className="sp-none">：</span>
          </BreakDownTitle>
          <Grid column={['1fr', '1fr']}>
            <Grid>
              <Plan5Form2 params={params} handleChange={handleChange} />
            </Grid>
            {/* 建物割合のグラフ */}
            <CircleGraph
              data1_label="本体割合"
              data1_value={Math.round(
                result.building_price * (params.body_ratio / 100)
              )}
              data2_label="設備割合"
              data2_value={Math.round(
                result.building_price * (params.equip_ratio / 100)
              )}
              sum_label="減価償却費"
            />
          </Grid>
        </Grid>

        <Grid>
          <BreakDownTitle>法定耐用年数</BreakDownTitle>
          <Plan5Form3 params={params} handleChange={handleChange} />
        </Grid>
        <Plan5Comment2 result={result} />
      </Panel>

      <SpBox>
        <MainButton
          onClick={() => ChangeResultPage(7)}
          height={45}
          fontSize="15px"
        >
          収益分析
        </MainButton>
      </SpBox>
    </div>
  )
}

const PlanCommentaryBox = () => (
  <PlanCommentaryLarge>
    <h3>
      投資用・賃貸不動産の保有期間を基にさまざまな状況に応じた投資プランの検討が行えます。
    </h3>
    <p>
      初期値として売り出し時の条件や推計される標準値がセットされています。これらを変化させると分析結果が変わり、投資プランの検討を行えます。
      <br />
      <b>
        「今いくらで売れるか？」のような相場を知りたい方は、お気軽にコスモスイニシアまでお問合せ下さい。
        <br />
        ※本サービスにおいて提供される査定及び、情報につき如何なる保証も行うものではありません。
      </b>
    </p>
  </PlanCommentaryLarge>
)

const TheModal: React.FC<{
  open: boolean
  toggle: any
}> = memo(({ open, toggle }) => (
  <BaseModal className={open ? 'open' : ''}>
    <div className="modal-box">
      <div className="modal-close-top" onClick={toggle} />
      <div className="modal-body">
        <h2 className="modal-header question">投資プランとは？</h2>
        <div className="modal-area">
          <Grid>
            <h3 className="modal-area-title">
              投資用・賃貸不動産の保有期間を基にさまざまな状況に応じた投資プランの検討が行えます。
            </h3>
            <p>
              初期値として売り出し時の条件や推計される標準値がセットされています。これらを変化させると分析結果が変わり、投資プランの検討を行えます。
              <br />
              <b className="bold">
                「今いくらで売れるか？」のような相場を知りたい方は、お気軽にコスモスイニシアまでお問合せ下さい。
              </b>
              <br />
              ※本サービスにおいて提供される査定及び、情報につき如何なる保証も行うものではありません。
            </p>
          </Grid>
        </div>
      </div>
      <div className="modal-close-bottom" onClick={toggle}>
        閉じる
      </div>
    </div>
  </BaseModal>
))

const Plan1Form: React.FC<{
  params: SimulationParams
  estate: EstateJSON
  handleChange: any
}> = ({ params, handleChange, estate }) => (
  <InfoList>
    <Grid column={['1fr', '1fr']}>
      <InfoItem>
        <Label text="売却時期" />
        <Number
          name="priorities_when_selling"
          unit_text="年後"
          value={params.priorities_when_selling}
          onChange={handleChange}
          max={estate.sell_prices.length}
          min={1}
        />
      </InfoItem>
      <InfoItem>
        <Label text="売却時の表面利回り" />
        {/* TODO: market_gross_ratesがあっているのかどうか検証が必要 */}
        {/* 売却時期を変化させると、表面利回りも変化するが、表面利回りを一度直接変更すると、売却時期を変えても変動しない */}
        <Number
          name="market_gross_rates"
          unit_text="%"
          step={0.05}
          value={params.market_gross_rates}
          onChange={handleChange}
        />
      </InfoItem>
    </Grid>
  </InfoList>
)

const IconAIInline = styled(IconAI)`
  width: 25px;
  display: inline-block;
  margin: -5px 10px 5px 0;
  position: relative;
  top: 4px;
`

const Plan1Simulation: React.FC<{
  params: SimulationParams
  result: SimulationResult
  estate: EstateJSON
}> = ({ params, result, estate }) => (
  <>
    <PlanCommentary>
      <h3>
        <IconAIInline>
          <img src="/img/common/ic_ai.png" alt="ic_ai" />
        </IconAIInline>
        推定される売却価格は <br className="break-sp" />
        {separateComma(Math.round(result.estimated_selling_price_from))}円 ~{' '}
        {separateComma(Math.round(result.estimated_selling_price_to))}円
      </h3>
      <p>
        購入時の推定表面利回りは&nbsp;
        <b>{result.market_gross_rate_buying}%</b> です。
        <br />
        <b>設定値 {params.market_gross_rates}%</b> は&nbsp;
        {params.priorities_when_selling} 年目の 推定表面利回り&nbsp;
        {result.market_gross_rate_selling}% に対して&nbsp;
        <b>{result.valuationLabel}</b> な設定です。
      </p>
    </PlanCommentary>

    <PriceTableWrapper
      title="設定表面利回りに基づく将来の想定売却価格"
      sold_year={params.priorities_when_selling}
      datas={result.estimated_selling_prices}
      PCRowLength={2}
    />
  </>
)

const Plan2Form: React.FC<{
  params: SimulationParams
  handleChange: any
  rentsChange: any
}> = ({ handleChange, rentsChange, params }) => (
  <InfoList>
    <InfoItem>
      <Label text="現在の賃料" />
      <Grid
        className="sp"
        column={['190px', '1fr']}
        alignItems="center"
        gap_sp="0"
        gap="0"
      >
        <Number
          name="months_rent"
          unit_text="万円"
          value={params.months_rent}
          onChange={handleChange}
        />
        <ToggleRentText>
          <span className="toggle-label">賃料を一定にする</span>
          <ToggleRentButton
            onClick={() => rentsChange(!params.is_rent_fixed)}
            switch={params.is_rent_fixed}
          >
            <span></span>
          </ToggleRentButton>
        </ToggleRentText>
      </Grid>
    </InfoItem>
  </InfoList>
)

const Plan2Simulation1: React.FC<{
  params: SimulationParams
  estate: EstateJSON
}> = ({ params, estate }) => (
  <PlanCommentary textAlign="left">
    <p className="no-padding">
      <b>査定賃料 {Math.round(estate.market_rent / 10000)}万円</b> から&nbsp;
      <b>
        {(
          (params.months_rent / (estate.market_rent / 10000) - 1) *
          100
        ).toFixed(1)}
        %
      </b>
      &nbsp; 離れています。
    </p>
  </PlanCommentary>
)

/* ここに賃料推移グラフ */
const Plan2Simulation2: React.FC<{
  estate: EstateJSON
  result: SimulationResult
  commentaryProps?: IPlanCommentaryProps
}> = memo(({ estate, result, commentaryProps }) => (
  <GraphBox>
    <Grid
      className="any"
      column={['7px', '25px', '1fr']}
      alignItems="center"
      gap="3px"
    >
      <div></div>
      <IconAI>
        <img src="/img/common/ic_ai.png" alt="ic_ai" />
      </IconAI>{' '}
      <h3>賃料推移</h3>
    </Grid>
    <RentLineGraph
      selectYear={builtYearToAge(estate.purchased_date.$date)}
      datas={result.annual_rents.map((rent, index) => {
        if (index === 0) {
          return {
            year: index + 1,
            rent: Math.round(result.annual_rents[index + 1] / 10000),
          }
        }
        return {
          year: index + 1,
          rent: Math.round(rent / 10000),
        }
      })}
    />
  </GraphBox>
))

const ActiveRatesForm: React.FC<{
  params: SimulationParams
  handleChange: any
}> = ({ params, handleChange }) => (
  <InfoList>
    <InfoItem>
      <Label text="想定稼働率" />
      <Number
        name="active_rates"
        unit_text="%"
        value={params.active_rates}
        onChange={handleChange}
        max={100}
      />
    </InfoItem>
  </InfoList>
)

const VacancyRageSimulation: React.FC<{
  params: SimulationParams
  estate: EstateJSON
}> = ({ params, estate }) => (
  <GraphBox>
    <Grid column={['213px', '1fr']} alignItems="center">
      <Grid
        className="any"
        column={['7px', '25px', '1fr']}
        alignItems="center"
        gap="3px"
      >
        <div></div>
        <IconAI>
          <img src="/img/common/ic_ai.png" alt="ic_ai" />
        </IconAI>
        <div>
          <PanelTag className="panel-tag-blue">
            {`想定稼働率 ${
              100 - Math.round(params.min_vacancy_rates[0] * 100 * 10) / 10
            }%〜${
              100 - Math.round(params.max_vacancy_rates[0] * 100 * 10) / 10
            }%`}
          </PanelTag>
        </div>
      </Grid>
      <GraphComment margin="0">
        <small>
          想定空室率は他の同等な物件の空室率から
          <br />
          データ分析を行い算出されています。
        </small>
      </GraphComment>
    </Grid>
    <NewVacancyRate
      rate_min={100 - Math.round(params.max_vacancy_rates[0] * 100 * 10) / 10}
      rate_max={100 - Math.round(params.min_vacancy_rates[0] * 100 * 10) / 10}
      rate_mid={100 - Math.round(estate.vacancy_rates[0] * 100 * 10) / 10}
    />
  </GraphBox>
)

const Plan3Form1: React.FC<{
  params: SimulationParams
  handleChange: any
  result: SimulationResult
}> = ({ params, handleChange, result }) => (
  <InfoList>
    <Grid column={['1fr', '1fr']}>
      <InfoItem>
        <Label text="購入価格" />
        <Number
          name="selling_price"
          unit_text="万円"
          value={Math.floor(params.selling_price)}
          onChange={handleChange}
          childSumId="sumA"
          sumValue={result.initial_cost}
        />
      </InfoItem>
      <InfoItem>
        <Label text="購入諸費用">
          {/*<TooltipBase>*/}
          {/*?*/}
          {/*<div className="tooltip-text">*/}
          {/*購入諸費用の50%が購入年度の減価償却費に計上されます。*/}
          {/*</div>*/}
          {/*</TooltipBase>*/}
        </Label>
        <Number
          name="selling_expenses"
          unit_text="万円"
          value={params.selling_expenses}
          onChange={handleChange}
          childSumId="sumA"
          sumValue={result.initial_cost}
        />
      </InfoItem>
    </Grid>
  </InfoList>
)

const Plan3Comment1: React.FC<{
  result: SimulationResult
}> = ({ result }) => (
  <PlanCommentary>
    <h3>初期投資額は {separateComma(Math.round(result.initial_cost))}万円</h3>
    <p>
      <b>初期投資額</b>とは購入価格+購入諸費用の合計金額のこと。
      <br />
      {/*<b>購入価格 表面利回り<span className="blue-text">X.X</span>%・購入諸費用 物件価格の<span className="blue-text">X.X</span>%</b>で算出しています。*/}
    </p>
  </PlanCommentary>
)

const Plan3Form2: React.FC<{
  params: SimulationParams
  result: SimulationResult
  handleChange: any
}> = ({ params, result, handleChange }) => (
  <InfoList>
    <InfoItem>
      <Label text="自己資金額" />
      <Number
        name="own_resources"
        unit_text="万円"
        value={Math.floor(params.own_resources)}
        onChange={handleChange}
        sumId="sumA"
        sumValue={result.initial_cost}
        max={result.initial_cost}
      />
    </InfoItem>
    <InfoItem>
      <Label text="ローン利用額" />
      <Number
        name="loan_usage_amount"
        unit_text="万円"
        value={params.loan_usage_amount}
        onChange={handleChange}
        sumId="sumA"
        sumValue={result.initial_cost}
        max={result.initial_cost}
      />
    </InfoItem>
    <InfoItem>
      <Label text="借入金利" />
      <Number
        name="borrowing_interest_rate"
        unit_text="%"
        value={params.borrowing_interest_rate}
        onChange={handleChange}
        max={100}
      />
    </InfoItem>
    <InfoItem>
      <Label text="借入期間" />
      <Number
        name="borrowing_period"
        unit_text="年"
        value={params.borrowing_period}
        onChange={handleChange}
        max={100}
      />
    </InfoItem>
  </InfoList>
)

const Plan3Comment2: React.FC<{
  result: SimulationResult
}> = ({ result }) => (
  <PlanCommentary>
    <h3>
      年間のローン返済額は&nbsp;
      {separateComma(Math.round(result.annual_loan_repayment_amount / 10000))}
      万円
      <TooltipBase top="-3px" spView={true} spTextLeft="-250px">
        ?
        <span className="tooltip-text">
          毎年のローン返済。元利均等法により算出。
        </span>
      </TooltipBase>
      <br />
      ローン返済総額は&nbsp;
      {separateComma(Math.round(result.total_loan_repayment_amount / 10000))}
      万円
    </h3>

    {result.is_parchased && (
      <p>
        購入から現在の年度までに{' '}
        <b>
          {separateComma(Math.round(result.total_parchased_loan / 10000))}万円
        </b>{' '}
        のローンを返済済みです。
      </p>
    )}
  </PlanCommentary>
)

const Plan4Form1: React.FC<{
  params: SimulationParams
  result: SimulationResult
  handleChange: any
}> = ({ params, result, handleChange }) => (
  <>
    <InfoList>
      <InfoItem>
        <Label text="BM費（月額）" />
        <Number
          name="bm"
          unit_text="円"
          value={params.bm}
          onChange={handleChange}
        />
      </InfoItem>
      <InfoItem>
        <Label>
          <p>
            その他費用 <br className="break-pc" />
            <span className="small">水道光熱費など（月額）</span>
          </p>
        </Label>
        <Number
          name="other_cost"
          unit_text="円"
          value={params.other_cost}
          onChange={handleChange}
        />
      </InfoItem>
    </InfoList>
    <InfoList>
      <InfoItem>
        <Label>
          <p>
            PM費（月額） <br className="break-pc" />
            <span className="small">賃料に掛け合わせて算出</span>
          </p>
        </Label>
        <div>
          <p className="itemText">
            <b className="large">{separateComma(result.pm_monthly)}円</b>
          </p>
          <p className="itemText">想定稼働賃料（月額）に対して</p>
          <Number
            name="pm_rate"
            unit_text="%"
            value={params.pm_rate}
            onChange={handleChange}
            max={100}
          />
        </div>
      </InfoItem>
    </InfoList>
  </>
)

const Plan4Form2: React.FC<{
  params: SimulationParams
  handleChange: any
}> = ({ params, handleChange }) => (
  <InfoList>
    <InfoItem>
      <Label>
        固定資産税・
        <br className="break-pc" />
        都市計画税 年間合計額
      </Label>
      <Number
        name="property_tax"
        unit_text="円"
        value={params.property_tax}
        onChange={handleChange}
      />
    </InfoItem>
  </InfoList>
)

const Plan4Comment1: React.FC<{ result: SimulationResult }> = ({ result }) => (
  <PlanCommentary>
    <h3 className="no-margin">
      年間の運営費用は {separateComma(result.annual_operating_expenses)}円
      <TooltipBase top="-3px" spView={true} spTextLeft="-270px">
        ?
        <span className="tooltip-text">
          BM費、PM費、固定資産税・都市計画税、その他費用の年間合計額。
        </span>
      </TooltipBase>
    </h3>
  </PlanCommentary>
)

const Plan5Form1: React.FC<{
  params: SimulationParams
  handleChange: any
}> = ({ params, handleChange }) => (
  <InfoList>
    <InfoItem>
      <Label text="土地割合" />
      <Number
        name="land_ratio"
        unit_text="%"
        value={params.land_ratio}
        onChange={handleChange}
        sumId="sumDep"
        sumValue={100}
        min={0}
        max={100}
      />
    </InfoItem>
    <InfoItem>
      <Label text="建物割合" />
      <Number
        name="building_ratio"
        unit_text="%"
        value={params.building_ratio}
        onChange={handleChange}
        sumId="sumDep"
        sumValue={100}
        min={0}
        max={100}
      />
    </InfoItem>
  </InfoList>
)

const Plan5Comment1: React.FC<{
  params: SimulationParams
  result: SimulationResult
  commentaryProps?: IPlanCommentaryProps
}> = ({ params, result, commentaryProps }) => (
  <PlanCommentary {...commentaryProps}>
    <h3 className="line5">
      減価償却費は {separateComma(Math.round(result.building_price))}
      万円
    </h3>
    <h4>
      建物価格：{' '}
      {separateComma(
        Math.round(
          result.building_price -
            (params.selling_expenses * params.building_ratio) / 100
        )
      )}
      万円
    </h4>
    <h4>
      購入諸費用の{params.building_ratio}％：{' '}
      {separateComma(
        Math.round((params.selling_expenses * params.building_ratio) / 100)
      )}
      万円
    </h4>
    {/* <p>*購入価格に対する土地又は建物の内訳のうちローン分</p> */}
  </PlanCommentary>
)

const Plan5Form2: React.FC<{
  params: SimulationParams
  handleChange: any
}> = ({ params, handleChange }) => (
  <InfoList>
    <InfoItem>
      <Label text="本体割合" />
      <Number
        name="body_ratio"
        unit_text="%"
        value={params.body_ratio}
        onChange={handleChange}
        sumId="sumBuild"
        sumValue={100}
        min={0}
        max={100}
      />
    </InfoItem>
    <InfoItem>
      <Label text="設備割合" />
      <Number
        name="equip_ratio"
        unit_text="%"
        value={params.equip_ratio}
        onChange={handleChange}
        sumId="sumBuild"
        sumValue={100}
        min={0}
        max={100}
      />
    </InfoItem>
  </InfoList>
)

const Plan5Form3: React.FC<{
  params: SimulationParams
  handleChange: any
}> = ({ params, handleChange }) => (
  <InfoList>
    <Grid column={['1fr', '1fr']}>
      <InfoItem>
        <Label text="本体" />
        <Number
          name="body_life_ratio"
          unit_text="年"
          value={params.body_life_ratio}
          onChange={handleChange}
          max={100}
        />
      </InfoItem>
      <InfoItem>
        <Label text="設備" />
        <Number
          name="equip_life_ratio"
          unit_text="年"
          value={params.equip_life_ratio}
          onChange={handleChange}
          max={100}
        />
      </InfoItem>
    </Grid>
  </InfoList>
)

const Plan5Comment2: React.FC<{
  result: SimulationResult
  commentaryProps?: IPlanCommentaryProps
}> = ({ result, commentaryProps }) => (
  <PlanCommentary {...commentaryProps}>
    <h3>
      購入時の中古資産耐用年数は <br className="break-sp" />
      本体 {result.old_body_life_ratio}年・設備{' '}
      {result.old_equipment_life_ratio}年
    </h3>
    {result.is_parchased && (
      <p>
        現在は購入から <b>{result.diff_parchased_to_now}年</b> 経過しています。
      </p>
    )}
  </PlanCommentary>
)

export default InvestmentPlan
