import React from 'react'
import styled from 'styled-components'
import colors from 'style/colors'

export const PageButtonElem = styled.button`
  cursor: pointer;
  position: relative;
  height: 28px;
  line-height: 24px;
  border: 1px solid ${colors.border_gray};
  border-radius: 3px;
  text-align: center;
  font-weight: bold;
  color: ${colors.black_alpha};
  background: linear-gradient(#fff, #ebebeb);
  font-size: 12px;
  padding: 2px 10px;
  &.next {
    white-space: nowrap;
    padding-right: 30px;
    &::before {
      content: url('/img/common/ic_arrow.svg');
      transform: rotateZ(180deg);
      position: absolute;
      top: 0;
      right: 12px;
    }
  }
  &.back {
    white-space: nowrap;
    padding-left: 30px;
    &::before {
      content: url('/img/common/ic_arrow.svg');
      top: 2px;
      left: 12px;
      position: absolute;
    }
  }
  &.large {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px;
    line-height: 43px;
    padding: 0;
    .btn-text {
      font-size: 15px;
    }
    @media screen and (max-width: 800px) {
      width: calc(90vw);
    }
  }
  @media screen and (max-width: 800px) {
    &.pc {
      display: none;
    }
  }
  .blue {
    color: ${colors.main};
  }
  .btn-number {
    display: inline-block;
    background: ${colors.main};
    color: white;
    width: 18px;
    height: 18px;
    line-height: 18px;
    margin-right: 8px;
    border-radius: 10px;
  }
`

interface IProps {
  className?: string
  onClick?: any
}

class PageButton extends React.Component<IProps> {
  public static defaultProps = {
    className: '',
  }

  public render() {
    return (
      <PageButtonElem
        className={this.props.className}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </PageButtonElem>
    )
  }
}

export default PageButton
