import React from 'react'
import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  CartesianGrid,
  YAxis,
  Tooltip,
  Line,
  LabelProps,
} from 'recharts'
import {
  yAxisTick,
  xAxisTick,
  GraphContainer,
  groupColor,
  CustomToolTip,
} from './Foundation'
import { PortfolioGroupType } from 'domain/portfolio'
import { resultSumByKey } from 'domain/plan'

type PortfolioLineSingleGraphProps = {
  groupNum: number
  value: PortfolioGroupType
  resultKey: string
}

export const PortfolioLineSingleGraph: React.FC<PortfolioLineSingleGraphProps> = ({
  value,
  resultKey,
  groupNum,
}) => {
  if (!value) return null
  const groupValue = value ? resultSumByKey(value.result, resultKey) : []
  const maxLength = Math.min(groupValue.length, 10)
  const data = new Array(maxLength).fill(null).map((_, i) => {
    return {
      name: i.toString(),
      value: groupValue[i] ? Math.round(groupValue[i] / 10000) : 0,
    }
  })

  const renderLineLabel = (labelProps: LabelProps & { index: number }) => {
    const x = labelProps.x || 0
    const y = labelProps.y || 0
    const isEndIndex = data.length === labelProps.index + 1
    const isStartIndex = 0 === labelProps.index
    const isHalfIndex = Math.round(data.length / 2) === labelProps.index + 1
    const isViewLabel = isEndIndex || isStartIndex || isHalfIndex

    // ラベルの位置がはみ出ないようにする
    const leftOffset = isStartIndex ? 20 : isEndIndex ? -20 : 0
    const labelHeight = 22
    if (isViewLabel) {
      return (
        <g>
          <path
            d={`M${x},${y + 8} ${x + 5},${y + 15} ${x + -5},${y + 15}`}
            fill="#575757"
            fillOpacity="0.8"
          />
          <rect
            x={x - 29 + leftOffset}
            y={y + 14}
            width={58}
            height={labelHeight}
            rx={3}
            ry={3}
            fill="#575757"
            fillOpacity="0.8"
          />
          <text
            x={x + leftOffset}
            y={y + 4 + labelHeight}
            fill={'white'}
            fontSize={10}
            fontWeight={'bold'}
            alignmentBaseline={'middle'}
            textAnchor={'middle'}
          >
            {labelProps.value}万円
          </text>
        </g>
      )
    }
  }

  return (
    <GraphContainer>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{ top: 0, right: 3, left: -13, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            tickSize={0}
            tickMargin={8}
            tick={xAxisTick}
            dataKey="name"
            padding={{ left: 15, right: 15 }}
          />
          <YAxis
            padding={{ top: 30, bottom: 0 }}
            tickSize={0}
            tick={yAxisTick}
            tickLine={false}
            axisLine={false}
          />
          <Tooltip content={CustomToolTip} />
          <Line
            name="グループⅠ"
            type="linear"
            dataKey="value"
            stroke={groupColor[groupNum - 1]}
            strokeWidth={2}
            isAnimationActive={false}
            label={renderLineLabel}
            dot={({ dataKey: _, ...props }: any) => (
              <circle
                {...props}
                r={4}
                fill={groupColor[groupNum - 1]}
                stroke="0"
              />
            )}
          />
        </LineChart>
      </ResponsiveContainer>
    </GraphContainer>
  )
}
