import React, { useCallback, FormEventHandler } from 'react'
import { Action } from 'typescript-fsa'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { DashboardActions } from 'containers/DashboardContainer'
import NewRegistrationModalItem from './NewRegistrationModalItem'
import colors from 'style/colors'
import { SearchResultForm, EstateForm } from 'reducers/EstateReducer'
import Form from '../Simulation/Registration/Form'
import Comment from '../Simulation/Field/Comment'
import FieldLabel from 'components/Simulation/Field/Label'
import FieldInput from 'components/Simulation/Field/Input'
import Grid from 'components/Simulation/Layout/Grid'

type ModalProps = {
  estate: EstateForm
  data: {
    [key: string]: {
      _id: number
      pref_code: string
      address: string
      city: string
      estate_name: string
    }
  }
  keyword: string
  isShow: boolean
  deleteSearchModal: () => Action<void>
  setPlanFromSearchResult: (
    payload: SearchResultForm
  ) => Action<SearchResultForm>
}

const Modal = styled.div`
  background: ${colors.modal_bg};
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  z-index: 999;
`

const ModalWindow = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: 5%;
  margin-bottom: 5%;
  @media screen and (max-width: 850px) {
    margin-top: 20%;
    margin-bottom: 15%;
  }

  @media screen and (min-height: 1000px) {
    margin-bottom: 5%;
  }

  background: white;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 95vh;
  border-radius: 5px;
  position: relative;
`

const ModalTitle = styled.div`
  background-color: ${colors.main};
  padding: 2%;
  text-align: center;
  border-radius: 5px 5px 0 0;
  p {
    color: white;
    font-size: 1.6em;
    font-weight: bold;
  }
`

const ModalContent = styled.div`
  height: calc(90vh - 40px - 40px);
  padding: 20px 40px 20px 40px;
  @media screen and (max-width: 800px) {
    padding: 10px 20px 10px 20px;
  }
  ul {
    list-style-type: none;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    height: 70%;
    @media screen and (max-width: 800px) {
      height: 55%;
    }
  }
`

const ModalMessage = styled.div`
  margin-bottom: 20px;
  margin-left: 2%;
  padding: 1% 0 1% 0;
  width: 95%;
  p {
    color: ${colors.black_middle};
    margin-bottom: 15px;
    @media screen and (max-width: 800px) {
      font-size: 13px;
      margin-bottom: 10px;
    }
  }
`

const ModalClose = styled.div`
  text-align: center;
  margin-bottom: 5%;
  label {
    color: ${colors.main};
  }
`

type ActionMethods = Pick<
  DashboardActions,
  | 'registerNewEstate'
  | 'setEstateDraft'
  | 'deleteSearchModal'
  | 'setPlanFromSearchResult'
>

const NewModal: React.FC<ModalProps & ActionMethods> = ({
  deleteSearchModal,
  setPlanFromSearchResult,
  setEstateDraft,
  registerNewEstate,
  estate,
  ...props
}) => {
  const container = document.querySelector('#modal')

  // 新規物件作成
  const handleRegistrationClick = useCallback(() => {
    registerNewEstate(estate)
  }, [estate, registerNewEstate])

  // 物件名変更
  const handleChange = useCallback<FormEventHandler<HTMLInputElement>>(
    (e) => {
      setEstateDraft({ [e.currentTarget.name]: e.currentTarget.value })
    },
    [setEstateDraft]
  )

  if (!props.isShow) {
    return null
  }

  const bindDeleteSearchModal = () => {
    const registrationForm = document.querySelector('#registrationForm')
    window.scroll({
      top:
        (registrationForm &&
          registrationForm.getClientRects()[0].top -
            50 +
            +window.pageYOffset) ||
        0,
      behavior: 'smooth',
    })
    deleteSearchModal()
  }

  if (container) {
    return createPortal(
      <Modal onClick={bindDeleteSearchModal}>
        <ModalWindow
          onClick={(event) => {
            event.stopPropagation()
          }}
        >
          <ModalTitle>
            <p>物件候補から選択</p>
          </ModalTitle>
          <ModalContent>
            <ModalMessage>
              <p>
                物件候補に表示されない場合は、物件名を入力して登録してください。
              </p>
              <Grid column={['1fr', '120px']}>
                <Form>
                  <FieldLabel text="物件名" margin_minus={false} />
                  <div className="field-area">
                    <FieldInput
                      defaultValue={props.keyword}
                      name="estate_name"
                      onChange={handleChange}
                      type="text"
                      width={520}
                      placeholder="渋谷美竹ハイム"
                    />
                    <Comment comment="新築の場合「渋谷区渋谷２丁目PJ」などの仮称でも可" />
                  </div>
                </Form>
                <div className="card-button-wrap">
                  <RegistrationLink onClick={handleRegistrationClick}>
                    登 録
                  </RegistrationLink>
                </div>
              </Grid>
            </ModalMessage>
            <ul>
              {Object.entries(props.data).map((key) => {
                const value = props.data[key[0]]
                return (
                  <NewRegistrationModalItem
                    {...value}
                    key={key.toString()}
                    setPlanFromSearchResult={() =>
                      setPlanFromSearchResult({
                        pref_code: value.pref_code,
                        estate_name: value.estate_name,
                        city: value.city,
                        property_type: 2,
                        apartment_id: value._id,
                      })
                    }
                  />
                )
              })}
            </ul>
          </ModalContent>
        </ModalWindow>
        <ModalClose>
          <label>× 閉じる</label>
        </ModalClose>
      </Modal>,
      container
    )
  }
  return null
}

export default NewModal

const RegistrationLink = styled.button`
  margin-top: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 3px;
  font-weight: bold;
  color: white;
  background: ${colors.priority};
  height: 48px;
  line-height: 1.2;
  text-align: left;
  @media screen and (max-width: 800px) {
    margin-top: 5px;
  }
`
