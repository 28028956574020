import cn from 'classnames'
import React, { useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import LayoutBetween from 'components/Simulation/Layout/Between'
import { PageButtonElem } from 'components/Simulation/Field/PageButton'
import { PriceTableDataProps } from './PriceTableBase'
import PriceTableMobile from './PriceTableMobile'
import Grid from 'components/Simulation/Layout/Grid'

interface IProps {
  title: string
  datas: PriceTableDataProps[]
  sold_year: number
}

export const SellPricesTable: React.FC<IProps> = (props: IProps) => {
  const [Page, PageChage] = useState(1) // 現在のページ
  const [isOpen, setIsOpen] = useState(false)
  const PageLength = Math.ceil(props.datas.length / 10) // 総ページ数
  const BackButton =
    1 < Page ? (
      <PageButtonElem className="back" onClick={() => PageChage(Page - 1)}>
        {(Page - 1) * 10}年目以前
      </PageButtonElem>
    ) : (
      <div />
    )
  const NextButton =
    Page < PageLength ? (
      <PageButtonElem className="next" onClick={() => PageChage(Page + 1)}>
        {Page * 10 + 1}年目以降
      </PageButtonElem>
    ) : (
      <div />
    )
  const Buttons = (
    <>
      {BackButton}
      <div />
      {NextButton}
    </>
  )

  if (!isMobileOnly) {
    return null
  }

  return (
    <>
      <LayoutBetween>
        <h3
          className={cn('sp-title_accordion', { open: isOpen })}
          onClick={() => setIsOpen(!isOpen)}
        >
          {props.title}
        </h3>
      </LayoutBetween>
      {isOpen && (
        <>
          <PriceTableMobile
            sold_year={props.sold_year}
            datas={props.datas}
            rowLength={1}
            page={Page}
          />
          <Grid column={['108px', '1fr', '108px']} className="any">
            {Buttons}
          </Grid>
        </>
      )}
    </>
  )
}

export default SellPricesTable
