import React from 'react'
import styled from 'styled-components'

const CommentElem = styled.small`
  display: block;
  width: 100%;
  line-height: 1.4;
  margin-top: 10px;
  font-size: 13px;
  font-weight: normal;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin-right: 0;
  }
`

interface IProps {
  comment: string // コメント
}

class CommentField extends React.Component<IProps, {}> {
  public static defaultProps = {
    comment: '',
  }

  public render() {
    return (
      <CommentElem>
        {this.props.comment ? '※' + this.props.comment : ''}
      </CommentElem>
    )
  }
}

export default CommentField
