import {
  PropertyFormContainerActions,
  PropertyFormContainerProps,
} from 'containers/PropertyFormContainer'
import { EstatePropertyPayload, loadEstatePropertyPayload } from 'domain/estate'
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { isMobileOnly } from 'react-device-detect'
import { RouteComponentProps } from 'react-router-dom'
import colors from 'style/colors'
import styled from 'styled-components'
import LoadView from './Load'
import { PageButtonElem } from './Simulation/Field/PageButton'
import { PanelStyle } from './Simulation/Layout/Panel'
import Simulation from './Simulation/Layout/Simulation'
import SpBox from './Simulation/Layout/SpBox'
import SectionRegistrationOne, {
  IRegistrationStepOneProps,
} from './Simulation/Registration/One'
import SectionRegistrationOnlyOne from './Simulation/Registration/OnlyOne'
import SectionRegistrationOnlySection from './Simulation/Registration/OnlySection'
import PropertyStep, { StepBox } from './Simulation/Registration/Step'
import SectionRegistrationTwo, {
  IRegistrationStepTwoProps,
  SectionRegistrationTwoSPFooter,
} from './Simulation/Registration/Two'
import SectionTitle from './Simulation/SectionTitle'
import Load from 'components/Load'
import PageTitle from './PageTitle'

const RegistrationButton = styled.button`
  background: ${colors.priority};
  display: block;
  width: 120px;
  height: 50px;
  line-height: 49px;
  padding: 0;
  border-radius: 5px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  color: white;
  margin: 0 auto;
  ${(props) => props.disabled && `pointer-events: none;`}

  @media screen and (max-width: 800px) {
    width: calc(90vw);
    margin: 0;
  }
`

const ThirdForm = styled.div``

const RegistrationPanel = styled(PanelStyle)`
  @media screen and (max-width: 800px) {
    background: transparent;
    padding: 15px;
  }
`

const PropertyForm: React.FC<
  RouteComponentProps &
    PropertyFormContainerActions &
    PropertyFormContainerProps
> = ({
  getCities,
  getRailways,
  getStations,
  saveAndSimulation,
  planningEstate: estate,
  cities,
  railways,
  stations,
  history,
  simulationError,
  ...props
}) => {
  const [, setPropertyType] = useState('区分')
  const [isLoad, setLoad] = useState(false)

  /* フォーム監視用のフック */
  const [form, setValues] = useState<EstatePropertyPayload>(() =>
    loadEstatePropertyPayload(estate)
  )

  // estate自体の変更でフォームをリセット
  useEffect(() => {
    setValues(loadEstatePropertyPayload(estate))
  }, [estate, setValues])

  //  線路のセット
  useEffect(() => {
    if (form.pref_code) {
      getCities(form.pref_code)
      getRailways(form.pref_code)
    }
  }, [form.pref_code, getCities, getRailways])

  // 駅のセット
  useEffect(() => {
    getStations({
      near_line_1: form.near_line_1,
      near_line_2: form.near_line_2,
      near_line_3: form.near_line_3,
    })
  }, [getStations, form.near_line_1, form.near_line_2, form.near_line_3])

  useEffect(() => {
    setValues((form) => {
      let updatedStations = {}

      if (
        stations.stations1 &&
        stations.stations1.length > 0 &&
        !form.near_station_1
      ) {
        updatedStations = {
          ...updatedStations,
          near_station_1: stations.stations1[0].code,
        }
      }

      if (
        stations.stations2 &&
        stations.stations2.length > 0 &&
        !form.near_station_2
      ) {
        updatedStations = {
          ...updatedStations,
          near_station_2: stations.stations2[0].code,
        }
      }

      if (
        stations.stations3 &&
        stations.stations3.length > 0 &&
        !form.near_station_3
      ) {
        updatedStations = {
          ...updatedStations,
          near_station_3: stations.stations3[0].code,
        }
      }

      return {
        ...form,
        ...updatedStations,
      }
    })
  }, [stations])

  useEffect(() => {
    if (simulationError !== '') {
      setLoad(false)
      alert(simulationError)
    }
  }, [simulationError])

  const [errors, setErrors] = useState<{ [key: string]: string }>({})

  const validateValues = (name: string, value: any): string | undefined => {
    switch (name) {
      case 'estate_name':
        if (!/^.+$/.test(value)) {
          return `${name}を入力してください。`
        }
        break
      case 'postal_first':
        if (!/[0-9]{3}/.test(value)) {
          return `${name}は半角数字3文字で入力をしてください。`
        }
        break
      default:
        break
    }
  }

  const updateField = useCallback((e: FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget

    setValues((form) => {
      let result = {
        ...form,
        [name]: value,
      }

      result.chiban = result.ban
      if (result.gou && result.gou !== '') {
        result.chiban += `-${result.gou}`
      }

      return result
    })
    setErrors(({ [name]: _, ...errors }) => {
      const error = validateValues(name, value)
      if (error) {
        return { ...errors, [name]: error }
      }
      return errors
    })
  }, [])

  const onClickRegistration = useCallback(() => {
    if (!form.trade_type) {
      alert('取引のタイプを選択してください')
      return false
    }

    if (!form.property_type) {
      alert('物件のタイプを選択してください')
      return false
    }

    if (!form.near_line_1) {
      alert('最寄り駅を追加してください')
      return false
    }

    if (!form.building_structure) {
      alert('建物構造を選択してください')
      return false
    }

    if (!form.personal_company_type) {
      alert('個人もしくは法人を選択してください')
      return false
    }

    if (!form.expected_selling_price) {
      alert('価格を入力してください')
      return false
    }

    if (!form.months_rent) {
      alert('現在の賃料を入力してください')
      return false
    }

    if (!form.number_of_houses) {
      alert('総戸数を入力してください')
      return false
    }

    if (!form.number_of_floors) {
      alert('階数を入力してください')
      return false
    }

    setLoad(true)
    saveAndSimulation(form)
  }, [saveAndSimulation, form])

  if (!form || !form._id) {
    return null
  }

  const Presenter: IPropertyFormPresenter = isMobileOnly
    ? PropertyFormPresenterSP
    : PropertyFormPresenterPC

  return (
    <>
      <PageTitle
        title={`物件情報入力${estate ? `: ${estate.estate_name}` : ''}`}
      />
      <Presenter
        errors={errors}
        form={form}
        history={history}
        location={props.location}
        firstStepProps={{
          updateField,
          railways,
          stations,
          setPropertyType,
          cities,
        }}
        secondStepProps={{
          updateField,
        }}
        thirdStepProps={{
          updateField,
          onClickRegistration,
          isLoad,
          hasError: Object.keys(errors).length > 0,
        }}
      />
    </>
  )
}
export default PropertyForm

type IPropertyFormPresenter = React.FC<IPropertyFormPresenterProps>

type IPropertyFormPresenterProps = {
  errors: {
    [key: string]: string | undefined
  }
  history: RouteComponentProps['history']
  location: RouteComponentProps['location']
  form: any
  firstStepProps: Omit<IRegistrationStepOneProps, 'form'>
  secondStepProps: Omit<IRegistrationStepTwoProps, 'form'>
  thirdStepProps: Omit<IThirdStepProps, 'form'>
}

const PropertyFormPresenterPC: React.FC<IPropertyFormPresenterProps> = ({
  ...props
}) => (
  <Simulation>
    <section>
      <SectionTitle label="物件情報入力" />
      <div>
        {Object.keys(props.errors).map((k) => (
          <li key={k}>
            {k}:{props.errors[k]}
          </li>
        ))}
      </div>
      <SectionRegistrationOne
        {...props.firstStepProps}
        id="step1"
        form={props.form}
      />
      <SectionRegistrationTwo
        {...props.secondStepProps}
        id="step2"
        form={props.form}
      />
    </section>

    <SectionRegistrationThree
      {...props.thirdStepProps}
      id="step3"
      form={props.form}
    />
    <LoadView isView={false} />
  </Simulation>
)

const PropertyFormPresenterSP: React.FC<IPropertyFormPresenterProps> = ({
  errors,
  history,
  location,
  ...props
}) => {
  /* ステップ管理 */
  const currentStep = parseStep(location.hash)
  const [step2PanelOpen, setStep2PanelOpen] = useState(currentStep === 3)

  const transitionHandlers = useMemo(
    () => ({
      step1to2: () => {
        history.push('#step2')
        window.scrollTo(0, 0)
      },
      backstep: (current: number) => {
        history.push(`#step1`)
        window.scrollTo(0, 0)
      },
    }),
    [history]
  )

  // step3に飛んできた場合はスクロールしてあげる
  useEffect(() => {
    if (currentStep === 3) {
      const elem = document.getElementById(`step3`)
      elem && elem.scrollIntoView()
    }
  }, [currentStep])

  const onClickStep2PanelOpen = useCallback(() => {
    setStep2PanelOpen((v) => !v)
  }, [setStep2PanelOpen])

  return (
    <Simulation>
      <PropertyStep
        onBackStep={transitionHandlers.backstep}
        current={currentStep}
        steps={2}
      />
      <section>
        <SectionTitle label="物件情報入力" />
        <div>
          {Object.keys(errors).map((k) => (
            <li key={k}>{k}</li>
          ))}
        </div>
        <StepBox current={currentStep} visibleRange={[1]}>
          <SectionRegistrationOne
            id="step1"
            {...props.firstStepProps}
            form={props.form}
          />
          <SpBox>
            <PageButtonElem
              className="large"
              onClick={transitionHandlers.step1to2}
            >
              <span className="btn-number">2</span>
              <span className="btn-text">物件の基本情報</span>
            </PageButtonElem>
          </SpBox>
        </StepBox>
        <StepBox current={currentStep} visibleRange={[2, 3]}>
          <SectionRegistrationTwo
            id="step2"
            {...props.secondStepProps}
            form={props.form}
          />
          <SectionRegistrationTwoSPFooter
            {...props.secondStepProps}
            form={props.form}
            onClickNextStep={onClickStep2PanelOpen}
            onBackStep={transitionHandlers.backstep}
          />
        </StepBox>
      </section>
      <StepBox current={currentStep} visibleRange={[2, 3]}>
        {step2PanelOpen && (
          <SectionRegistrationThree
            id="step3"
            {...props.thirdStepProps}
            form={props.form}
          />
        )}
      </StepBox>

      <LoadView isView={false} />
    </Simulation>
  )
}

const parseStep = (hash: string) => {
  let step = parseInt(hash.replace('#step', ''), 10)
  return isNaN(step) ? 1 : step
}

type IThirdStepProps = {
  form: any
  updateField: any
  hasError?: boolean
  onClickRegistration: any
  id?: string
  isLoad: boolean
}

const SectionRegistrationThree: React.FC<IThirdStepProps> = (props) => (
  <>
    <ThirdForm id={props.id}>
      {props.form.property_type === '1' ? (
        <section>
          <SectionTitle label="区分マンション専用項目" />
          <SectionRegistrationOnlySection
            updateField={props.updateField}
            form={props.form}
          />
        </section>
      ) : (
        <section>
          <SectionTitle label="一棟マンション専用項目" />
          <SectionRegistrationOnlyOne
            form={props.form}
            updateField={props.updateField}
          />
        </section>
      )}
    </ThirdForm>
    <RegistrationPanel>
      <RegistrationButton
        disabled={props.hasError}
        onClick={props.onClickRegistration}
      >
        登 録
      </RegistrationButton>
    </RegistrationPanel>
    <Load isView={props.isLoad} />
  </>
)
