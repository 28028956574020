import React, { memo } from 'react'
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  PieLabelRenderProps,
  ResponsiveContainer,
} from 'recharts'
import colors from 'style/colors'
import styled from 'styled-components'
import { separateComma } from 'helper/number'
import { GraphBox } from 'components/Symbols/Box'
import { isMobileOnly } from 'react-device-detect'

const CircleGraphElm = styled.div`
  width: 100%;
  height: 330px;
  position: relative;
  .graph-sum {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 18px;
    left: 0;
    margin: auto;
    width: 150px;
    height: ${isMobileOnly ? '34px' : '40px'};
    display: inline-block;
    font-weight: bold;
    text-align: center;
    span {
      display: block;
      margin: 3px 0;
    }
    .graph-sum-label {
      font-size: ${isMobileOnly ? '12px' : '14px'};
    }
    .graph-sum-value {
      font-size: ${isMobileOnly ? '12px' : '16px'};
      color: ${colors.graph_blue};
    }
  }
  .recharts-legend-item-text {
    font-weight: bold;
    font-size: 10px;
    position: relative;
  }
`

interface DataProps {
  data1_label: string
  data1_value: number
  data2_label: string
  data2_value: number
  sum_label: string
}

interface CustomLabelProps {
  cx: number
  cy: number
  midAngle: number
  innerRadius: number
  outerRadius: number
  index: number
}

const COLORS = [colors.graph_blue, colors.graph_orange]
const RADIAN = Math.PI / 180

const RenderCustomizedLabel: React.FC<PieLabelRenderProps> = ({
  name,
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  index,
  percent,
  value,
}: PieLabelRenderProps) => {
  const radius =
    toNumber(50, innerRadius) +
    (isMobileOnly ? 20 : 25) +
    toNumber(100, outerRadius) * 0.5
  const x = toNumber(0, cx) + radius * Math.cos(-toNumber(0, midAngle) * RADIAN)
  const y = toNumber(0, cy) + radius * Math.sin(-toNumber(0, midAngle) * RADIAN)

  return (
    <>
      <text
        fontSize={isMobileOnly ? '11px' : '14px'}
        x={x}
        y={y}
        textAnchor={x > toNumber(0, cx) ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {name}
      </text>
      <text
        fontSize={isMobileOnly ? '11px' : '14px'}
        x={x}
        y={y + 15}
        textAnchor={x > toNumber(0, cx) ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${separateComma(value)}万円`}
      </text>
    </>
  )
}

const toNumber = (defaultValue: number, v?: number | string) =>
  typeof v === 'number' ? v : defaultValue

// グラフ本体
export const CircleGraph: React.FC<DataProps> = memo((props: DataProps) => {
  const data = [
    { name: props.data1_label, value: props.data1_value },
    { name: props.data2_label, value: props.data2_value },
  ]

  return (
    <GraphBox padding="0 10px 10px">
      <CircleGraphElm>
        <p className="graph-sum">
          <span className="graph-sum-label">{props.sum_label}</span>
          <span className="graph-sum-value">
            {separateComma(props.data1_value + props.data2_value)}万円
          </span>
        </p>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              startAngle={90}
              endAngle={-450}
              dataKey="value"
              data={data}
              innerRadius={isMobileOnly ? 45 : 50}
              outerRadius={isMobileOnly ? 80 : 100}
              label={RenderCustomizedLabel}
              isAnimationActive={false}
              // labelLine={false}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
            <Legend
              formatter={(label, d, i) => `${label} ${data[i || 0].value}万円`}
              iconSize={12}
              iconType={'circle'}
            />
          </PieChart>
        </ResponsiveContainer>
      </CircleGraphElm>
    </GraphBox>
  )
})

export default CircleGraph
