import React, { useState } from 'react'
import styled from 'styled-components'
import colors from 'style/colors'

const SliderBody = styled.div`
  width: 1021px;
  margin: 35px auto 10px;
  border: 1px solid ${colors.border_gray2};
  border-radius: 5px;
  background: ${colors.bg_gray};
  padding: 50px 30px 30px;
  @media screen and (max-width: 800px) {
    display: none;
  }
  .slideWrapper {
    position: relative;
    height: 24px;
    width: 850px;
    margin: 0 auto;
  }
  .slidearea {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 24px;
    opacity: 0;
  }
`

interface SlideBarProps {
  marginLeft: number
  ageLeft: number
}
const SlideBar = styled.div`
  position: relative;
  top: 10px;
  background: #ccc;
  height: 4px;
  &::before {
    transition: all 0.3s;
    content: '';
    display: block;
    position: absolute;
    width: ${(props: SlideBarProps) => props.ageLeft}%;
    height: 4px;
    background: ${colors.main};
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: ${(props: SlideBarProps) => props.marginLeft}%;
    height: 4px;
    background: ${colors.bg_gray};
  }
`

const BaseIcon = styled.div`
  position: absolute;
  border-radius: 50px;
  background: ${colors.main};
  &::before {
    position: relative;
    top: -25px;
    left: -10px;
    display: block;
    width: 40px;
    text-align: center;
    color: ${colors.main};
    font-size: 12px;
    font-weight: bold;
  }
`

interface SlideProps {
  x: number
  year: number
}

const SliderElm = styled(BaseIcon)`
  top: 0;
  left: calc( ${(props: SlideProps) => props.x}% - 12px );
  width: 24px;
  height: 24px;
  transition: all 0.3s;
  &::before {
    content: "${(props: SlideProps) => props.year}年";
    left: -15px;
    width: 50px;
    font-size: 15px;
    background: ${colors.bg_gray};
  }
`

const YearElm = styled(BaseIcon)`
  top: 3px;
  left: calc( ${(props: SlideProps) => props.x}% - 10px );
  width: 18px;
  height: 18px;
  background: #C2C2C2;
  &::before {
    content: "${(props: SlideProps) => props.year}年";
    left: -10px;
    width: 40px;
    font-size: 12px;
    color: #9B9B9B;
  }
  &.active {
    background: ${colors.main};
    &::before {
      color: ${colors.main};
    }
  }
`

interface DataProps {
  currentYear?: number
  yearLengh: number
  sliderChange?: any
}

const Slider: React.FC<DataProps> = (props: DataProps) => {
  const currentYear = props.currentYear ? props.currentYear : 1
  const yearLength = props.yearLengh
  const yearAxis = [...Array(Math.floor(yearLength / 10))].map(
    (empty, index) => (index + 1) * 10
  ) // 10年単位の目盛りを作る
  const years = [1, yearLength].concat(yearAxis)
  const [moveIs, changeMoveIs] = useState(false)

  // 年数から座標を計算する
  const yearToX = (year: number) => (year / yearLength) * 100

  // マウス座標から年数を計算（0年は1年にする）
  const calcMouseYear = (x: number) => {
    // 座標の％を計算する
    const BarWidth = 850
    const xPosition = x / BarWidth
    // ％から年数にする
    const xYear = Math.round(xPosition * yearLength)
    return xYear === 0 ? 1 : xYear
  }

  const MouseMove = (e: any) => {
    if (moveIs) {
      const MouseX = e.pageX - e.target.getBoundingClientRect().x
      props.sliderChange(calcMouseYear(MouseX))
    }
  }
  const MouseDown = (e: any) => {
    changeMoveIs(true)
    const MouseX = e.pageX - e.target.getBoundingClientRect().x
    props.sliderChange(calcMouseYear(MouseX))
  }

  return (
    <SliderBody>
      <div className="slideWrapper">
        <SlideBar
          marginLeft={yearToX(years[0])}
          ageLeft={yearToX(currentYear)}
        />
        {years.map((year, index) => (
          <YearElm
            key={index}
            className={currentYear >= year ? 'active' : ''}
            x={yearToX(year)}
            year={year}
          />
        ))}
        <SliderElm x={yearToX(currentYear)} year={currentYear} />
        <div
          className="slidearea"
          onMouseDown={(e) => MouseDown(e)}
          onMouseUp={() => changeMoveIs(false)}
          onMouseLeave={() => changeMoveIs(false)}
          onMouseMove={(e) => MouseMove(e)}
        />
      </div>
    </SliderBody>
  )
}

export default Slider
