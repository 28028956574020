import React from 'react'
import {
  CartesianGrid,
  LabelProps,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import colors from 'style/colors'
import styled from 'styled-components'

const LineGraphElm = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  height: 310px;
`

interface data {
  year: number
  rent: number
}
interface datasProps {
  datas: data[]
  selectYear?: number
}

export default class Example extends React.Component<datasProps> {
  render() {
    const data = this.props.datas

    const xAxisLabelProps: LabelProps = {
      value: '年',
      position: 'right',
      dx: 20,
      dy: -13,
      fontSize: 14,
    }
    const yAxisLabelProps: LabelProps = {
      value: '万円（年間）',
      position: 'top',
      dx: 30,
      offset: 15,
      fontSize: 14,
    }

    return (
      <LineGraphElm>
        <ResponsiveContainer>
          <LineChart
            width={500}
            height={200}
            data={data}
            margin={{
              top: 40,
              right: 55,
              left: -5,
              bottom: 15,
            }}
          >
            <CartesianGrid
              horizontalPoints={[-1]}
              stroke="#ececec"
              strokeDasharray="0"
            />
            <XAxis
              label={xAxisLabelProps}
              dataKey="year"
              axisLine={false}
              tickLine={false}
              stroke={colors.base}
              ticks={[1].concat(
                data.filter((d, index) => d.year % 5 === 0).map((d) => d.year)
              )}
              interval={0}
            />
            <YAxis
              label={yAxisLabelProps}
              stroke={colors.base}
              tickLine={{ stroke: colors.graph_blue }}
              axisLine={false}
            />
            <Tooltip
              labelFormatter={(a) => `${a}年`}
              formatter={(value) => `${value}万円（年間）`}
            />
            <Line
              type="step"
              dot={false}
              name="賃料"
              dataKey="rent"
              stroke={colors.graph_blue}
              strokeWidth={2}
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </LineGraphElm>
    )
  }
}
