import React from 'react'
import colors from 'style/colors'
import styled from 'styled-components'
import Grid from '../Layout/Grid'
import Panel from '../Layout/Panel'
import { isMobileOnly } from 'react-device-detect'

// PanelTitleを参考にしました
interface vsTitleProps {
  id?: string
  label: string
  spDesign?: 'bar' | 'flex'
  spLabel?: string
}

// card-link を参考にしました
const ButtonToForm = styled.div`
  display: block;
  height: 50px;
  line-height: 50px;
  margin-bottom: 15px;
  text-align: center;
  background: #434260;
  color: white;
  font-weight: bold;
  border-radius: 3px;
  font-size: 16px;
  @media screen and (max-width: 800px) {
    width: 100%;
    font-size: 12px;
    height: 35px;
    line-height: 35px;
  }
`

const VSTitleElem = styled.div`
  width: 100%;
  margin-bottom: 0;
  line-height: 1.3;
  .body-title {
    display: flex;
    align-items: center;
    @media screen and (min-width: 801px) {
      margin-left: 0;
    }
    .panel-title-pc {
      @media screen and (max-width: 800px) {
        display: none;
      }
    }
    .title-text {
      font-size: 24px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 800px) {
        display: block;
        font-size: 18px;
        width: calc(100% - 28px);
      }
    }
    .panel-title-highlight {
      font-size: 30px;
      color: ${colors.main};
    }
  }
  .body-description {
    margin-top: 10px;
    line-height: 1.4;
    font-size: 13px;
    font-weight: normal;
    @media screen and (max-width: 800px) {
      width: calc(100vw - 84px);
      margin-left: 28px;
    }
  }

  &.bar {
    .title-sp-label {
      display: none;
    }
    @media screen and (max-width: 800px) {
      width: calc(100% + 40px);
      margin: 0 -20px;
      .body-title {
        display: block;
      }
      .title-text {
        width: 100%;
      }
      .title-label {
        display: block;
        text-align: center;
        height: 50px;
        line-height: 50px;
        color: white;
        background: ${colors.main};
        margin-top: -20px;
      }
      .title-sp-label {
        display: block;
        color: white;
      }
    }
  }
`

const VSText = styled.p`
  line-height: 1.7;
  @media screen and (max-width: 800px) {
    font-size: 14px;
  }
`

const VSTextSmall = styled.small`
  display: block;
  font-size: 12px;
  line-height: 1.5;
  margin: 10px 0 0 12px;
  text-indent: -12px;
`

class VSTitle extends React.Component<vsTitleProps, {}> {
  public static defaultProps = {
    id: '',
    label: '',
    labelElem: <></>,
  }
  render() {
    return (
      <VSTitleElem id={this.props.id} className={this.props.spDesign}>
        <h2 className="body-title">
          <span className="title-text">
            <span className="title-label">
              {this.props.label}
              <span className="title-sp-label">{this.props.spLabel}</span>
            </span>
            {this.props.children}
          </span>
        </h2>
      </VSTitleElem>
    )
  }
}

class VariousService extends React.Component<{ className?: string }> {
  public render() {
    return (
      <Panel className={'result-service'}>
        <Grid column={['6fr', '3fr']} marginBottom="30px">
          <div>
            <VSTitle label="お気軽にコスモスイニシアにお問い合わせください。"></VSTitle>
            <br />
            <VSText>
              知識や経験豊富なコスモスイニシアの不動産コンサルタントへご相談いただけます。
            </VSText>
          </div>
          <a
            target="_blank" // eslint-disable-line react/jsx-no-target-blank
            rel="noopener"
            href="https://go.cigr.co.jp/satei.campain"
          >
            <ButtonToForm>無料査定フォームはこちら</ButtonToForm>
          </a>
        </Grid>

        <Grid column={['6fr', '3fr']} marginBottom="30px">
          <div>
            <VSTitle label="未公開物件メールマガジン"></VSTitle>
            <br />
            <VSText>
              弊社ホームページで未公開の最新物件情報をお届けするメールマガジン（無料）
              <br />
              <VSTextSmall>
                ※ご登録には「不動産健康診断サービス（無料）」へのお申込が必要です。
                <br />
                お申込画面の「サービスを利用する」で「未公開物件情報のメルマガ購読」をご選択ください。
              </VSTextSmall>
            </VSText>
          </div>
          <a
            target="_blank" // eslint-disable-line react/jsx-no-target-blank
            rel="noopener"
            href="https://www.cigr.co.jp/form/biz/member_registration/contact/"
          >
            {isMobileOnly ? (
              <ButtonToForm>未公開物件メールマガジンはこちら</ButtonToForm>
            ) : (
              <img
                src="/img/service/service_banner_03.jpg"
                alt="投資用不動産の紹介"
              />
            )}
          </a>
        </Grid>

        {/* <Grid column={['6fr', '3fr']} marginBottom="30px">
          <div>
            <VSTitle label="カチプラス売却"></VSTitle>
            <br />
            <VSText>
              「高値売却」をお手伝い。一棟投資用不動産仲介サービス「カチプラス売却」
            </VSText>
          </div>
          <a
            target="_blank" // eslint-disable-line react/jsx-no-target-blank
            rel="noopener"
            href="https://www.cigr.co.jp/biz/sale/kachiplus/"
          >
            {isMobileOnly ? (
              <ButtonToForm>カチプラス売却はこちら</ButtonToForm>
            ) : (
              <img src="/img/service/service_banner_01.jpg" alt="カチプラス" />
            )}
          </a>
        </Grid> */}

        <Grid column={['6fr', '3fr']} marginBottom="10px">
          <div>
            <VSTitle label="「セレサージュ」シリーズ"></VSTitle>
            <br />
            <VSText>
              手軽で安心感のある資産運用。都心の優良不動産に共同出資を「セレサージュ」シリーズ
            </VSText>
          </div>
          <a
            target="_blank" // eslint-disable-line react/jsx-no-target-blank
            rel="noopener"
            href="https://www.cigr.co.jp/biz/service/selesage/"
          >
            {isMobileOnly ? (
              <ButtonToForm>「セレサージュ」シリーズはこちら</ButtonToForm>
            ) : (
              <img
                src="/img/service/service_banner_02.jpg"
                alt="セレサージュ"
              />
            )}
          </a>
        </Grid>
      </Panel>
    )
  }
}

export default VariousService
