import React from 'react'
import styled from 'styled-components'

const GridLayout = styled.div`
  display: grid;
  grid-gap: ${(props: IProps) => props.gap_sp};
  align-items: ${(props: IProps) => props.alignItems};
  margin-bottom: ${(props: IProps) => props.marginBottom};

  &.pc {
    @media screen and (min-width: 801px) {
      grid-gap: ${(props: IProps) => props.gap};
      grid-template-columns: ${(props: IProps) => props.column.join(' ')};
    }
  }
  &.sp {
    @media screen and (max-width: 800px) {
      grid-gap: ${(props: IProps) => props.gap};
      grid-template-columns: ${(props: IProps) => props.column.join(' ')};
    }
  }
  &.any {
    grid-gap: ${(props: IProps) => props.gap};
    grid-template-columns: ${(props: IProps) => props.column.join(' ')};
  }
`

interface IProps {
  column: string[] // ラベルテキスト
  gap: string
  gap_sp: string
  alignItems: string
  className?: 'pc' | 'sp' | 'any'
  marginBottom?: string
}

export class Grid extends React.Component<IProps, {}> {
  public static defaultProps = {
    column: ['1fr'],
    gap: '15px',
    gap_sp: '15px',
    alignItems: '',
    className: 'pc',
    marginBottom: '0px',
  }

  public render() {
    return <GridLayout {...this.props}>{this.props.children}</GridLayout>
  }
}

export default Grid
