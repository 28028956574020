import React, { memo, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import colors from 'style/colors'
import styled from 'styled-components'
import {
  condencedButtonStyles,
  isLinkEnabled,
  StepListLabels,
  StepListNavigationProps,
} from './StepList.type'

const StepListPCElem = styled.div`
  position: relative;
  background: white;
  width: 1152px;
  padding: 20px 60px 30px;
  @media screen and (max-width: 800px) {
    display: none;
  }
  ol {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 -50px 20px;
  }
  .step-bar {
    width: 912px;
    margin: 0 auto;
    height: 4px;
    background: ${colors.border_gray};
    border-left: ${(props: BarProps) => (props.step - 1) * 228}px solid
      ${colors.main};
    transition: all 0.3s;
  }
`

interface IProps {
  step: number
}

interface BarProps {
  step: number
}

interface PCProps {
  is_stepped: boolean
}

const ListItemPC = styled.li`
  position: relative;
  list-style: none;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  flex-grow: 1;
  width: 20%;
  p {
    position: relative;
    display: inline;
  }
  .step-label {
    color: ${(props: PCProps) =>
      props.is_stepped ? colors.main : colors.border_gray};
  }
  .step-number {
    color: ${(props: PCProps) =>
      props.is_stepped ? colors.main : colors.border_gray};
  }
  .step-name {
    color: ${(props: PCProps) =>
      props.is_stepped ? 'black' : colors.border_gray};
  }
  p::before {
    content: url('/img/common/ic_check.svg');
    display: ${(props: PCProps) => (props.is_stepped ? 'block' : 'none')};
    position: absolute;
    top: -1px;
    left: -20px;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 25px;
    border-radius: 10px;
    margin: auto;
    width: 18px;
    height: 18px;
    background: ${(props: PCProps) =>
      props.is_stepped ? colors.main : colors.border_gray};
  }

  ${condencedButtonStyles}
`

export type IStepListHeaderProps = {} & StepListNavigationProps &
  Pick<RouteComponentProps, 'location'>

export const StepListHeader: React.FC<IStepListHeaderProps> = memo(
  ({ location, onClickStep }) => {
    const [stepNum, setStepNum] = useState(1)

    // URLからstepNumを同期する
    useEffect(() => {
      setStepNum(location.pathname.match('result') ? 3 : 1)
    }, [location.pathname])

    const stepClickHandlers = useMemo(
      () =>
        StepListLabels.map((_, idx) => () => onClickStep && onClickStep(idx)),
      [onClickStep]
    )

    return (
      <StepListPCElem step={stepNum}>
        <ol>
          {StepListLabels.map((label, index) => (
            <ListItemPC key={label} is_stepped={stepNum >= index + 1}>
              <button
                className="condensed"
                onClick={stepClickHandlers[index] || undefined}
                disabled={!isLinkEnabled(index + 1, stepNum)}
              >
                <span className="step-label">STEP.</span>
                <span className="step-number">{index + 1} </span>
                <span className="step-name">{label}</span>
              </button>
            </ListItemPC>
          ))}
        </ol>
        <div className="step-bar"></div>
      </StepListPCElem>
    )
  }
)
