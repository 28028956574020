import styled from 'styled-components'

const GraphComment = styled.p<IProps>`
  ${(props) => `margin: ${props.margin}`}
  font-size: 10px;
  line-height: 1.2;
  .marker {
    background: #ffff99;
    font-weight: bold;
  }
  small {
    color: #9b9b9b;
  }
`

GraphComment.defaultProps = { ...GraphComment.defaultProps, margin: '30px 0' }

interface IProps {
  margin?: string
}

export default GraphComment
