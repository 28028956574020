import styled from 'styled-components'

export const IncomeTable = styled.div`
  margin: 80px 0 0;
  @media screen and (max-width: 800px) {
    margin: 10px 0 0;
  }
`
export const CapitalTable = styled.div`
  margin: 253px 0 0;
  @media screen and (max-width: 800px) {
    margin: 10px 0 0;
  }
`

interface CapitalRowProps {
  background?: 'dark'
  type?: 'sum'
}

export const CapitalRow = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${(props: CapitalRowProps) => props.background && '#FAFAFA'};
  font-weight: ${(props: CapitalRowProps) => props.type && 'bold'};
  border-top: ${(props: CapitalRowProps) => props.type && '1px solid #B6B6B6'};
  padding: ${(props: CapitalRowProps) =>
    props.type ? '10px 12px 30px' : '10px 12px'};
`

interface CapitalValueProps {
  color?: 'orange'
}

export const CapitalLabel = styled.span`
  display: block;
  font-size: 15px;
`

export const CapitalValue = styled.span`
  display: block;
  font-size: 18px;
  font-weight: bold;
  color: ${(props: CapitalValueProps) => props.color && '#FFA500'};
  .lh2 {
    line-height: 1.5;
    color: ${(props: CapitalValueProps) => props.color && '#FFA500'};
  }
`
