import React from 'react'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  LabelProps,
} from 'recharts'
import {
  yAxisTick,
  xAxisTick,
  GraphContainer,
  groupColor,
  CustomToolTip,
} from './Foundation'
import { resultSumByKey } from 'domain/plan'
import { PortfolioGroupType } from 'domain/portfolio'

type PortfolioBarGraphProps = {
  groupNum: number
  value: PortfolioGroupType
}

export const PortfolioBarGraph: React.FC<PortfolioBarGraphProps> = ({
  value,
  groupNum,
}) => {
  if (!value) return null
  const groupValue = value
    ? resultSumByKey(value.result, 'annual_depreciation_costs')
    : []
  const maxLength = Math.min(groupValue.length, 10)
  const data = new Array(maxLength).fill(null).map((_, i) => {
    return {
      name: i.toString(),
      value: groupValue[i] ? Math.round(groupValue[i] / 10000) : 0,
    }
  })

  const renderBarLabel = (labelProps: LabelProps & { index: number }) => {
    const isEndIndex = data.length === labelProps.index + 1
    const isStartIndex = 0 === labelProps.index
    const isHalfIndex = Math.round(data.length / 2) === labelProps.index + 1
    const isViewLabel = isEndIndex || isStartIndex || isHalfIndex
    if (isViewLabel) {
      return (
        <text
          x={labelProps.x}
          textAnchor={'middle'}
          y={labelProps.y}
          fontSize="10"
          dx={10}
          dy="-3"
          fontWeight="bold"
        >
          {labelProps.value}万円
        </text>
      )
    }
  }

  return (
    <GraphContainer>
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{ top: 0, right: 0, left: -20, bottom: 0 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            tickSize={0}
            tickMargin={8}
            tick={xAxisTick}
            dataKey="name"
            padding={{ left: 10, right: 10 }}
          />
          <YAxis
            padding={{ top: 30, bottom: 0 }}
            tickSize={0}
            tick={yAxisTick}
            axisLine={false}
          />
          <Tooltip content={CustomToolTip} />
          <Bar
            name="費用"
            dataKey="value"
            fill={groupColor[groupNum - 1]}
            maxBarSize={15}
            isAnimationActive={false}
            label={renderBarLabel}
          />
        </BarChart>
      </ResponsiveContainer>
    </GraphContainer>
  )
}
