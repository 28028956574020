import React from 'react'
import styled from 'styled-components'
import colors from 'style/colors'
import { zipWith } from 'lodash'

const FormRadio = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(
    auto-fill,
    ${(props: ComponentProps) => props.width}px
  );
  align-items: center;
  max-width: ${(props: ComponentProps) => (props.width + 20) * props.length}px;
  width: 100%;
  @media screen and (max-width: 800px) {
    grid-template-columns: repeat(
      auto-fill,
      ${(props: ComponentProps) =>
        props.sp_width ? props.sp_width : props.width - 20}px
    );
    grid-gap: 5px;
  }
  input {
    display: none;
  }
  span {
    cursor: pointer;
    display: block;
    margin-right: 20px;
    width: ${(props: ComponentProps) => props.width}px;
    height: ${(props: ComponentProps) => props.height}px;
    line-height: ${(props: ComponentProps) => props.height - 2}px;
    border: 1px solid ${colors.border_gray};
    border-radius: 5px;
    text-align: center;
    color: #aaa;
    font-weight: bold;
    @media screen and (max-width: 800px) {
      width: ${(props: ComponentProps) =>
        props.sp_width ? props.sp_width : props.width - 20}px;
    }
  }
  input:checked + span {
    background: ${colors.main};
    border-color: ${colors.main};
    color: white;
  }
`

type IProps<T = string | number> = {
  name: string // Input name属性
  width: number
  sp_width?: number
  height: number
  onChange?: (params: any) => void
  value?: string
} & (
  | {
      items: IRadioItem<T>[]
    }
  | {
      /** @deprecated */
      deprecated_values: string[] // 選択肢のVALUE配列
      /** @deprecated */
      deprecated_texts: string[] // 選択肢のテキスト配列
    }
)

export type IRadioItem<T = any> = {
  value: T
  key: string
  label?: string
}

interface ComponentProps {
  width: number
  sp_width?: number
  height: number
  length: number
}

class RadioField extends React.Component<IProps, {}> {
  public static defaultProps = {
    width: 160,
    height: 50,
  }

  public render() {
    const items =
      'items' in this.props
        ? this.props.items
        : zipWith(
            this.props.deprecated_texts,
            this.props.deprecated_values,
            (label, value) =>
              ({
                label,
                key: value,
                value: value,
              } as IRadioItem)
          )

    return (
      <FormRadio
        width={this.props.width}
        height={this.props.height}
        length={items.length}
        sp_width={this.props.sp_width}
      >
        {items.map((item) => (
          <label key={item.key}>
            <input
              name={this.props.name}
              type="radio"
              value={item.value}
              checked={this.props.value === item.value}
              onChange={this.props.onChange}
            />
            <span>{item.label || item.value}</span>
          </label>
        ))}
      </FormRadio>
    )
  }
}

export default RadioField
