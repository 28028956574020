import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import PriceTable, { PriceTableProps } from './PriceTableBase'
import cn from 'classnames'

export const PriceTablePC: React.FC<PriceTableProps> = (
  props: PriceTableProps
) => {
  if (isMobileOnly) {
    return null
  }

  const { page, rowLength, sold_year, datas } = props
  // valuesから表示するデータをsliceする
  const useDatas = datas.slice(
    (page - 1) * rowLength * 10,
    (page - 1) * rowLength * 10 + rowLength * 10
  )
  // datasを10個ずつにsliceする
  const separateLength = [...new Array(Math.ceil(useDatas.length / 10))]
  const separateDatas = separateLength.map((empty, index) =>
    useDatas.slice(index * 10, index * 10 + 10)
  )
  // セルを作る
  const separateElms = separateDatas.map((datas, index) => {
    const ths = datas.map((data) => (
      <th key={data.year} className={cn({ active: data.year === sold_year })}>
        {data.year}年
      </th>
    ))
    const tds = datas.map((data) => (
      <td key={data.year} className={cn({ active: data.year === sold_year })}>
        {data.value.toLocaleString()}
      </td>
    ))
    return (
      <React.Fragment key={index}>
        <tr>{ths}</tr>
        <tr>{tds}</tr>
      </React.Fragment>
    )
  })
  return (
    <PriceTable>
      <tbody>{separateElms}</tbody>
    </PriceTable>
  )
}

export default PriceTablePC
