import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Action } from 'typescript-fsa'
import { DashboardComponent } from '../components/DashboardComponent'
import { AddressActions } from '../reducers/AddressReducer'
import { AppState } from '../reducers/AppReducer'
import { DashboardActions } from '../reducers/DashboardReducer'
import {
  EstateActions,
  EstateForm,
  SearchModalForm,
  SearchResultForm,
} from '../reducers/EstateReducer'
import { AddPlanGroupPayload, DeletePlanGroupPayload } from 'domain/plan'

function mapDispatchToProps(dispatch: Dispatch<Action<any>>) {
  return {
    requestPortfolio: () => dispatch(DashboardActions.requestPortfolio()),
    registerNewEstate: (newEstate: EstateForm) => {
      if (!newEstate.estate_name) {
        alert('物件名を入力してください')
        return false
      }

      if (!newEstate.trade_type) {
        alert('取引のタイプを選択してください')
        return false
      }

      if (!newEstate.property_type) {
        alert('物件のタイプを選択してください')
        return false
      }

      if (!newEstate.expected_selling_price) {
        alert('価格を入力してください')
        return false
      }

      dispatch(
        DashboardActions.registerNewEstate({
          ...newEstate,
          chiban: '',
          expected_selling_price: Number(newEstate.expected_selling_price),
          property_type: Number(newEstate.property_type),
          trade_type: Number(newEstate.trade_type),
        })
      )
    },
    setEstateDraft: (estate: Partial<EstateForm>) =>
      dispatch(EstateActions.setEstateDraft(estate)),
    getCities: (prefCode: string) =>
      dispatch(AddressActions.getCities(prefCode)),
    getAddressFromZip: (zipCode: string) =>
      dispatch(EstateActions.getAddressFromZip(zipCode)),
    deleteEstate: (id: number) => dispatch(DashboardActions.deleteEstate(id)),
    searchEstate: (estate: Partial<EstateForm>) =>
      dispatch(EstateActions.searchEstate(estate)),
    estateSearchModal: (estate: SearchModalForm) =>
      dispatch(EstateActions.showSearchModal(estate)),
    deleteSearchModal: () => dispatch(EstateActions.deleteSearchModal()),
    setPlanFromSearchResult: (payload: SearchResultForm) =>
      dispatch(EstateActions.setPlanFromSearchResult(payload)),
    getUserPlan: () => dispatch(EstateActions.getUserPlan()),
    getUserPlanGroups: () => dispatch(DashboardActions.getUserPlanGroups()),
    createUserPlanGroups: (payload: AddPlanGroupPayload) =>
      dispatch(DashboardActions.createUserPlanGroups(payload)),
    deletePlanFromPlanGroup: (payload: DeletePlanGroupPayload) =>
      dispatch(DashboardActions.deletePlanFromPlanGroup(payload)),
  }
}

export type DashboardActions = ReturnType<typeof mapDispatchToProps>

function mapStateToProps(appState: AppState) {
  return {
    ...appState.dashboard,
    ...appState.address,
    ...appState.estate,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent)
