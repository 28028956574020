import { SimulationPayload } from 'domain/simulation'
import { toArray } from 'helper/array'
import React, { useCallback, useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import colors from 'style/colors'
import styled from 'styled-components'
import { timestampToText } from '../../../helper/date'
import { separateComma } from '../../../helper/number'
import { buildingStructureToText } from '../../../helper/types'
import SpBar, { BlueTag } from '../../Symbols/SpBar'
import PageButton from '../Field/PageButton'
import ToggleRentButton, {
  ToggleOtherIncomeButton,
} from '../Field/ToggleButton'
import Grid from '../Layout/Grid'
import Panel from '../Layout/Panel'
import PanelTitle from '../Layout/PanelTitle'
import Row from './Cashflow/Row'
import { MainButton } from 'components/Symbols/Button'
import { arrToCSV, csvDownload } from 'helper/csv'
import { TooltipBase } from 'components/Symbols/Tooltip'
import SpBox from '../Layout/SpBox'

const CashflowTable = styled.table`
  width: 100%;
  border-top: 1px solid ${colors.border_gray2};
  border-bottom: 1px solid ${colors.border_gray2};
  @media screen and (max-width: 800px) {
    max-width: 1100px;
  }

  thead {
    text-align: center;
    height: 25px;
    line-height: 25px;
  }
  tbody {
    height: 15px;
    line-height: 15px;
    text-align: right;
  }
  .no-row {
    height: 10px;
    border-bottom: 1px solid ${colors.border_gray2};
  }
`

const CashflowTableWrapper = styled.div`
  max-width: 100%;
  overflow-x: scroll;
  @media screen and (max-width: 800px) {
    margin: 20px 0 30px;
    width: ${window.outerWidth - 40}px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
`

const CashflowCondisionTables = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
  .comment-row {
    margin: 8px 0 0;
    font-size: 10px;
    font-weight: normal;
  }
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    margin-bottom: 10px;
  }
`

const CashflowCondisionTable = styled.table`
  width: 100%;
  font-weight: bold;
  text-align: left;
  border-collapse: collapse;
  min-height: 180px;
  position: relative;
  @media screen and (max-width: 800px) {
    min-height: auto;
  }

  th,
  td {
    color: #000;
    font-size: 11px;
    padding: 8px;
    border: 1px solid ${colors.border_gray2};
    line-height: 1.2;
    vertical-align: middle;
    @media screen and (max-width: 800px) {
      padding: 12px;
    }
  }
  th {
    background: ${colors.bg_gray};
    white-space: nowrap;
    @media screen and (max-width: 800px) {
      min-width: 130px;
    }
  }
  td {
    width: calc(100%);
  }
  .title-row th,
  .comment-row th {
    border: none;
    background: white;
    @media screen and (max-width: 800px) {
      padding: 12px 0;
    }
  }
  .title-row th {
    font-size: 13px;
    height: 20px;
    @media screen and (max-width: 800px) {
      padding: 0 0 10px;
      font-size: 16px;
    }
    .number {
      display: none;
      @media screen and (max-width: 800px) {
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        border-radius: 20px;
        background: #434260;
        color: white;
        display: inline-block;
        margin-right: 6px;
      }
    }
  }
  .help {
    display: inline-block;
    margin: -6px 0 -6px 6px;
    width: 21px;
    height: 21px;
    line-height: 18px;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    color: #0673c8;
    border: 2px solid #0673c8;
    cursor: pointer;
    @media screen and (min-width: 801px) {
      position: relative;
    }
  }
  .help_text {
    display: none;
    position: absolute;
    z-index: 10000;
    top: 30px;
    right: -30px;
    white-space: nowrap;
    text-align: left;
    padding: 12px;
    background: white;
    border: 2px solid #0673c8;
    color: #0673c8;
    font-size: 13px;
    pointer-events: none;
    font-weight: normal;

    b {
      display: block;
      color: #0673c8;
      margin-bottom: 6px;
    }

    @media screen and (min-width: 801px) {
      &::before {
        content: '';
      }
    }
    @media screen and (max-width: 800px) {
      top: -10px;
      width: 350px;
      left: 0;
      white-space: normal;
    }
  }
  .help:hover .help_text {
    display: block;
  }
  .hide {
    display: none;
  }
`

const PrintButton = MainButton.withComponent('a')

const Cashflow: React.FC<{
  payload: SimulationPayload
  onChangeParams: (keypath: string, value: string | boolean | number) => void
  ChangeResultPage: any
}> = ({
  payload: { estate, params, result },
  onChangeParams,
  ChangeResultPage,
}) => {
  const includeOtherIncomeChange = useCallback(
    (value) => {
      onChangeParams('include_other_income', value)
    },
    [onChangeParams]
  )

  const [page, setPage] = useState(1)
  const sold_year = params.priorities_when_selling
  // const testArray = toArray(2, sold_year)
  const BackButton =
    1 < page ? (
      <PageButton className="back" onClick={() => setPage(page - 1)}>
        {(page - 1) * 10}年目以前
      </PageButton>
    ) : (
      <div />
    )
  const NextButton =
    page < Math.ceil(sold_year / 10) ? (
      <PageButton className="next" onClick={() => setPage(page + 1)}>
        {page * 10 + 1}年目以降
      </PageButton>
    ) : (
      <div />
    )
  const Buttons = !isMobileOnly ? (
    <Grid column={['108px', '108px']} className="pc">
      {BackButton}
      {NextButton}
    </Grid>
  ) : null
  return (
    <>
      <Panel>
        <Grid column={['1fr', '108px', '108px']} marginBottom="20px">
          <PanelTitle
            spDesign="bar"
            icon={1}
            label="投資条件"
            marginBotton={0}
            toolTipElem={
              <TooltipBase
                top="-3px"
                pcHide={true}
                spView={true}
                spTextLeft="-200px"
                spTextRight="-120px"
                color={'white'}
              >
                i
                <span className="tooltip-text">
                  キャッシュフローとは、「現金の流れ」です。
                  <br />
                  不動産投資から得られる帳簿上の利益とキャッシュフローの金額は異なるため、
                  <br />
                  収支との比較及び、資金繰りの観点で活用できます。
                  <br />
                  全期間利回り（IRR）とは、投資期間内における1年あたりのインカムと
                  <br />
                  キャピタルによる利回りです。投資プラン（ローン条件、期間等）によって
                  <br />
                  変化するため、検討物件に対する様々な投資プランを検討できます。
                  <br />
                  <b>
                    キャッシュフローでは、本年度から売却までの期間について、シミュレーションを行っています。
                  </b>
                </span>
              </TooltipBase>
            }
          ></PanelTitle>
          {!isMobileOnly && (
            <>
              <PrintButton
                background={colors.priority}
                visibility={'hidden'}
                href={result.printURL}
                target="_blank"
                rel="noopener"
              >
                印刷
              </PrintButton>
              <MainButton
                onClick={() =>
                  csvDownload(
                    arrToCSV(result.csvData),
                    'VALUE-AI_' + estate.estate_name + '.csv'
                  )
                }
              >
                CSV出力
              </MainButton>
            </>
          )}
        </Grid>
        <CashflowCondisionTables>
          <CashflowCondisionTable>
            <tbody>
              <tr className="title-row">
                <th colSpan={2}>
                  <span className="number">1</span>物件
                </th>
              </tr>
              <tr>
                <th>所在地</th>
                <td>{estate.address}</td>
              </tr>
              <tr className={estate.property_type === 2 ? 'hide' : ''}>
                <th>部屋番号</th>
                <td>{estate.room_number}</td>
              </tr>
              <tr>
                <th>最寄駅</th>
                <td>
                  {estate.railways[0].line_name}{' '}
                  {estate.railways[0].station_name}駅 徒歩
                  {estate.railways[0].walking_minutes}分
                </td>
              </tr>
              <tr>
                <th>{estate.property_type === 1 ? '専有面積' : '延床面積'}</th>
                <td>
                  {estate.property_type === 1
                    ? estate.occupation_area === null
                      ? '-'
                      : `${Math.round(Number(estate.occupation_area))}㎡`
                    : estate.total_floor_space === null
                    ? '-'
                    : `${Math.round(Number(estate.total_floor_space))}㎡`}
                </td>
              </tr>
              <tr>
                <th>築年月</th>
                <td>{timestampToText(estate.built_date.$date)}</td>
              </tr>
              <tr>
                <th>建物構造</th>
                <td>{buildingStructureToText(estate.building_structure)}造</td>
              </tr>
            </tbody>
          </CashflowCondisionTable>
          <CashflowCondisionTable>
            <tbody>
              <tr className="title-row">
                <th colSpan={2}>
                  <span className="number">2</span>初期費用
                </th>
              </tr>
              <tr>
                <th>物件購入額</th>
                <td>{separateComma(params.selling_price)}万円</td>
              </tr>
              <tr>
                <th>購入諸費用</th>
                <td>{separateComma(params.selling_expenses)}万円</td>
              </tr>
              <tr>
                <th>総額</th>
                <td>{separateComma(result.initial_cost)}万円</td>
              </tr>
            </tbody>
          </CashflowCondisionTable>
          <CashflowCondisionTable>
            <tbody>
              <tr className="title-row">
                <th colSpan={2}>
                  <span className="number">3</span>ローン
                </th>
              </tr>
              <tr>
                <th>ローン額</th>
                <td>{separateComma(params.loan_usage_amount)}万円</td>
              </tr>
              <tr>
                <th>自己資金</th>
                <td>{separateComma(params.own_resources)}万円</td>
              </tr>
              <tr>
                <th>負債比率</th>
                <td>
                  {separateComma(
                    Math.round(
                      (1 - params.own_resources / params.loan_usage_amount) *
                        100
                    )
                  )}
                  %
                </td>
              </tr>
              <tr>
                <th>借入金利</th>
                <td>{separateComma(params.borrowing_interest_rate)}%</td>
              </tr>
              <tr>
                <th>借入期間</th>
                <td>{separateComma(params.borrowing_period)}年</td>
              </tr>
            </tbody>
          </CashflowCondisionTable>

          <CashflowCondisionTable>
            <tbody>
              <tr className="title-row">
                <th colSpan={2}>
                  <span className="number">4</span>売却
                </th>
              </tr>
              <tr>
                <th>売却想定時期</th>
                <td>{params.priorities_when_selling}年後</td>
              </tr>
              <tr>
                <th>AI査定価格</th>
                <td>
                  {separateComma(
                    Math.round(
                      result.estimated_selling_prices[
                        params.priorities_when_selling - 1
                      ] / 10000
                    )
                  )}
                  万円
                </td>
              </tr>
              {/*<tr>*/}
              {/*<th>全期間予想獲得賃料</th>*/}
              {/*<td>{separateComma(result.total_effective_rents)}万円</td>*/}
              {/*</tr>*/}
              <tr>
                <th>売却時ローン残債</th>
                <td>
                  {separateComma(
                    Math.round(
                      result.loan_balances[params.priorities_when_selling - 1] /
                        10000
                    )
                  )}
                  万円
                </td>
              </tr>
            </tbody>
          </CashflowCondisionTable>

          <div>
            <CashflowCondisionTable>
              <tbody>
                <tr className="title-row">
                  <th colSpan={2}>
                    <span className="number">5</span>利回り{' '}
                    <span className="help">
                      ?
                      <span className="help_text">
                        <b>全期間利回り(IRR)について</b>
                        全期間利回り(IRR)とは、投資期間内における1年あたりのインカムとキャピタルによる利回りです。
                        <br />
                        投資プラン（ローン条件、期間等）によって変化するため、物件に対する様々な投資プランを検討できます。
                        <br />
                        <b>
                          過去に購入され、現在保有中の物件はキャッシュフロー表内での全期間利回り(IRR)は算出されません。
                        </b>
                      </span>
                    </span>
                  </th>
                </tr>
                <tr>
                  <th>表面利回り</th>
                  <td>{result.gross_rate.toFixed(2)}%</td>
                </tr>
                <tr>
                  <th>NOI利回り*</th>
                  <td>{result.noi_rate.toFixed(2)}%</td>
                </tr>
                <tr>
                  <th>全期間利回り (IRR)</th>
                  <td>
                    {result.irr && !result.is_parchased
                      ? result.irr + '%'
                      : '-'}
                  </td>
                </tr>
              </tbody>
            </CashflowCondisionTable>
            <div className="comment-row">
              ※NOI利回りは想定稼働率を加味して算出しています。
            </div>
          </div>
        </CashflowCondisionTables>
      </Panel>
      <Panel>
        <Grid column={['1fr', '231px']} marginBottom="20px">
          <PanelTitle
            spDesign="bar"
            icon={2}
            label="不動産投資分析"
            marginBotton={0}
          >
            <SpBar background="#DBDEEB">
              <BlueTag>1年目 ~ {sold_year}年目</BlueTag>
            </SpBar>
            <ToggleOtherIncomeButton
              className={estate.personal_company_type === 1 ? '' : 'hide'}
            >
              その他所得を含める
              <ToggleRentButton
                onClick={() =>
                  includeOtherIncomeChange(!params.include_other_income)
                }
                switch={params.include_other_income}
              >
                <span />
              </ToggleRentButton>
            </ToggleOtherIncomeButton>
          </PanelTitle>
          {Buttons}
        </Grid>

        <CashflowTableWrapper>
          <CashflowTable>
            <thead>
              <Row
                className="head border-bottom"
                page={page}
                values={toArray(0, sold_year).map((empty, index) => index + 1)}
              />
            </thead>
            <tbody>
              <Row
                title="満室想定年間賃料"
                page={page}
                values={result.annual_rents.slice(0, sold_year)}
              />
              <Row
                title="想定稼働年間収入"
                page={page}
                values={result.annual_effective_rents.slice(0, sold_year)}
              />
              <Row
                title="運営費用"
                className="border-bottom"
                page={page}
                values={result.operating_expenses}
              />

              <Row
                title="うち年間BM費"
                className="breakdown"
                page={page}
                values={result.bms}
              />
              <Row
                title="うち固定資産税・都市計画税"
                className="breakdown"
                page={page}
                values={result.property_taxs}
              />
              <Row
                title="うち年間PM費"
                className="breakdown"
                page={page}
                values={result.pms}
              />

              <Row
                title="その他費用"
                className="breakdown border-bottom"
                page={page}
                values={result.other_costs}
              />

              <Row
                title="純営業利益(NOI)"
                className="blue border-top"
                page={page}
                values={result.noi.slice(0, params.priorities_when_selling)}
              />
              {params.include_other_income && (
                <Row
                  title="その他所得"
                  className={
                    estate.personal_company_type === 1 ? 'blue' : 'hide'
                  }
                  page={page}
                  values={result.other_incomes.slice(
                    0,
                    params.priorities_when_selling
                  )}
                />
              )}
              <Row
                title="ローン返済額(年間)"
                className="red"
                page={page}
                values={result.annual_loan_repayment_amounts.slice(
                  0,
                  params.priorities_when_selling
                )}
              />
              {/*<Row*/}
              {/*title="ローン元本返済額(年間)"*/}
              {/*className="red"*/}
              {/*page={page}*/}
              {/*values={result.annual_loan_usages.slice(*/}
              {/*0,*/}
              {/*params.priorities_when_selling*/}
              {/*)}*/}
              {/*/>*/}
              {/*<Row*/}
              {/*title="ローン利息返済額(年間)"*/}
              {/*className="red"*/}
              {/*page={page}*/}
              {/*values={result.borrowing_interest_amount.slice(*/}
              {/*0,*/}
              {/*params.priorities_when_selling*/}
              {/*)}*/}
              {/*/>*/}
              <Row
                title="税引前キャッシュフロー"
                className="orange border-bottom"
                page={page}
                values={result.btcf.slice(0, params.priorities_when_selling)}
              />
              <tr className="no-row"></tr>

              <Row
                title="純営業利益(NOI)"
                className="blue border-top"
                page={page}
                values={result.noi.slice(0, params.priorities_when_selling)}
              />
              {params.include_other_income && (
                <Row
                  title="その他所得"
                  className={
                    estate.personal_company_type === 1 ? 'blue' : 'hide'
                  }
                  page={page}
                  values={result.other_incomes.slice(
                    0,
                    params.priorities_when_selling
                  )}
                />
              )}
              <Row
                title="減価償却費"
                className="red"
                page={page}
                values={result.annual_depreciation_costs.slice(
                  0,
                  params.priorities_when_selling
                )}
              />
              <Row
                title="費用計上可能な利息額"
                className="red"
                page={page}
                values={result.borrowing_interest_amount.slice(
                  0,
                  params.priorities_when_selling
                )}
              />
              <Row
                title="課税所得"
                className="orange border-bottom"
                page={page}
                values={result.taxable_incomes_result.slice(
                  0,
                  params.priorities_when_selling
                )}
              />
              <tr className="no-row"></tr>

              <Row
                title="税引前キャッシュフロー"
                className="blue border-top"
                page={page}
                values={result.btcf.slice(0, params.priorities_when_selling)}
              />
              <Row
                title="納税額"
                className="red"
                page={page}
                values={result.tax_amount
                  .map((tax) => tax.price)
                  .slice(0, params.priorities_when_selling)}
              />
              <Row
                title="税引後キャッシュフロー"
                className="orange"
                page={page}
                values={result.atcf.slice(0, params.priorities_when_selling)}
              />
            </tbody>
          </CashflowTable>
        </CashflowTableWrapper>
      </Panel>
      <SpBox>
        <MainButton
          onClick={() => ChangeResultPage(6)}
          height={45}
          fontSize="15px"
        >
          推定結果
        </MainButton>
      </SpBox>
    </>
  )
}

export default Cashflow
