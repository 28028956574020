import styled from 'styled-components'
import colors from 'style/colors'

const BaseTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`

const BlueTable = styled(BaseTable)`
  @media screen and (max-width: 800px) {
    display: none;
  }
  tr {
    width: 100%;
    .data-name {
      font-weight: bold;
      width: 200px;
    }
    td.red {
      color: ${colors.red};
    }
    td.bold {
      font-weight: bold;
    }
  }
  th {
    background: #959ec3;
    color: white;
  }
  tr.canRead {
    cursor: pointer;
    &:hover {
      background: ${colors.blue_bg_light};
    }
  }
  td,
  th {
    border: 1px solid ${colors.border_gray2};
    padding: 10px;
    text-align: left;
    font-size: 11px;
    vertical-align: middle;
  }
`

// const TableItem = styled.tr``

export default BlueTable
