import { separateComma, isInteger } from 'helper/number'
import {
  propertyTypeToText,
  tradeTypeToColor,
  tradeTypeToText,
  LayoutStructureToText,
} from 'helper/types'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import colors from 'style/colors'
import Moment from 'moment'
import styled from 'styled-components'
import { PortfolioEstate, isEstateSimulatable } from 'domain/estate'

interface IState {
  isOpen: boolean
}

// 物件一覧などで用いるカードコンポーネント
const CardPropertyElem = styled.div`
  position: relative;
  background: white;
  border-bottom: 1px solid ${colors.border_gray2};
  border-radius: 3px;
  padding: 15px 15px 0 15px;
  @media screen and (min-width: 801px) {
    border: 1px solid ${colors.border_gray2};
  }
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (min-width: 801px) {
      margin-bottom: 14px;
    }
  }
  .card-type {
    padding: 5px 20px;
    color: white;
    font-size: 12px;
    font-weight: bold;
    border-radius: 20px;
    margin-right: 10px;
    background: ${colors.base};
    @media screen and (max-width: 800px) {
      padding: 3px 8px;
      font-size: 10px;
      margin-right: 8px;
    }
  }
  .card-type.blue {
    background: #3e9bc4;
  }
  .card-type.yellow {
    background: #fbb900;
  }
  .card-type.green {
    background: #3ec481;
  }
  .card-type.white {
    background-color: #ffffff !important;
    color: #6e6e6e;
    border: 1px solid #6e6e6e;
  }
  .card-type.base {
    background: ${colors.base};
  }
  .card-buttons {
    position: absolute;
    right: 10px;
    top: 11px;
    display: flex;
    @media screen and (max-width: 800px) {
      top: auto;
      bottom: 15px;
    }
  }
  .card-button {
    display: flex;
    margin: 0 6px;
    border-radius: 3px;
    justify-content: center;
    align-items: center;
    background: #ededed;
    padding: 1px;
    width: 34px;
    height: 24px;
    @media screen and (max-width: 800px) {
      width: 40px;
      height: 30px;
    }
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    img {
      transform: scale(0.8);
    }
    span {
      font-weight: bold;
      font-size: 11px;
      @media screen and (max-width: 800px) {
        font-size: 12px;
      }
    }
  }
  .card-title {
    font-size: 20px;
    @media screen and (min-width: 801px) {
      display: -webkit-box;
      margin-bottom: 15px;
      height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-break: break-all;
    }
    @media screen and (max-width: 800px) {
      width: calc(100% - 90px);
      height: 15px;
      font-size: 15px;
      padding-right: 20px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
  }
  hr {
    margin: 0 -15px 15px;
    border-top-color: ${colors.border_gray2};
  }
  .card-info-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px 10px;
    margin-bottom: 20px;
    @media screen and (max-width: 800px) {
      grid-gap: 15px 10px;
    }
  }
  .card-info {
    @media screen and (min-width: 801px) {
      height: 55px;
    }
    dt {
      font-size: 12px;
      margin-bottom: 5px;
      color: ${colors.base};
      margin-bottom: 10px;
    }
    dd {
      font-weight: bold;
    }
    small {
      display: block;
      font-weight: normal;
      margin: 5px 0 0;
      font-size: 12px;
    }
  }
  .card-profit-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .card-profit {
    display: flex;
    width: calc(50% - 10px);
    font-weight: bold;
    justify-content: space-between;
    dt {
      margin-top: 4px;
      margin-bottom: 5px;
      font-size: 14px;
      white-space: nowrap;
      .help {
        margin: -3px 0 0 5px;
        display: inline-block;
        width: 18px;
        height: 18px;
        line-height: 14px;
        vertical-align: top;
        border-radius: 10px;
        text-align: center;
        font-size: 11px;
        color: #0673c8;
        border: 2px solid #0673c8;
        transform: scale(0.9);
        cursor: pointer;
        @media screen and (max-width: 800px) {
          right: 80px;
        }
      }
      .help_text {
        display: none;
        position: absolute;
        text-align: left;
        z-index: 10000;
        top: 60%;
        right: 25%;
        margin: 0 0 0 10px;
        line-height: 1.3;
        padding: 12px;
        background: white;
        border: 2px solid #0673c8;
        color: #0673c8;
        font-size: 13px;
        font-weight: normal;
        pointer-events: none;

        b {
          display: block;
          color: #0673c8;
          margin-bottom: 6px;
        }

        @media screen and (max-width: 800px) {
          top: -10px;
          right: 0%;
          right: 0%;
        }
      }
      .help:hover + .help_text {
        display: block;
      }
      small {
        display: block;
        font-size: 10px;
        margin-top: 5px;
        font-weight: normal;
        white-space: nowrap;
      }
    }
    dd {
      vertical-align: middle;
      font-size: 19px;
      text-align: right;
      color: ${colors.main};
    }
  }
  .card-link-comment {
    position: relative;
    span {
      font-size: 10px;
      font-weight: bold;
      transform-origin: left;
      white-space: nowrap;
      transform: scale(0.9) translate(0, -22px);
      position: absolute;
      left: 0;
      @media screen and (max-width: 800px) {
        transform: scale(0.9) translate(0, -14px);
      }
    }
  }
  .card-link {
    display: block;
    height: 50px;
    line-height: 50px;
    margin-bottom: 15px;
    text-align: center;
    background: ${colors.main};
    color: white;
    font-weight: bold;
    border-radius: 3px;
    font-size: 16px;
    &.blue {
    }
    &.gray {
      background: #ededed;
      color: #333;
      margin-bottom: 30px;
      @media screen and (max-width: 800px) {
        margin-bottom: 15px;
      }
    }
    @media screen and (max-width: 800px) {
      margin-bottom: 15px;
      width: 200px;
      font-size: 14px;
      height: 30px;
      line-height: 30px;
      &.blue {
        top: 0;
      }
    }
  }
  .card-header-sp {
    @media screen and (max-width: 800px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      margin-bottom: 15px;
      &::after {
        position: absolute;
        right: 0;
        top: 0;
        content: '';
        display: block;
        width: 15px;
        height: 15px;
        background: url('/img/common/ic_arrow.svg') center center / 14px 14px;
        transform: rotateZ(270deg);
      }
      &.open {
        margin-bottom: 15px;
        &::after {
          transform: rotateZ(90deg);
        }
      }
    }
  }
  .card-body {
    @media screen and (max-width: 800px) {
      display: block;
      overflow: hidden;
      max-height: 0;
      transition-duration: 0.4s;
      transition-property: max-height;
      &.open {
        max-height: 300px;
      }
      .hidden {
        transition-delay: 0.4s;
        transition-property: opacity;
        display: none;
        opacity: 0;
      }
    }
  }
`

interface IProps {
  data: PortfolioEstate
  deleteEstate: (id: any) => any
}

const CardProperty: React.FC<IProps> = (props: IProps) => {
  const [isOpen, toggle] = useState(false)
  const { data, deleteEstate } = props
  return (
    <CardPropertyElem className={'animated zoomInUp'}>
      <div
        className={`card-header-sp ${isOpen ? 'open' : ''}`}
        onClick={() => toggle((v) => !v)}
      >
        <div className="card-header">
          <div className="card-types">
            <span
              className={`card-type card-type-operation ${tradeTypeToColor(
                data.trade_type
              )}`}
            >
              {tradeTypeToText(data.trade_type)}
            </span>
            <span
              className={`card-type card-type-build base ${
                propertyTypeToText(data.property_type) === '区分' ? 'white' : ''
              }`}
            >
              {propertyTypeToText(data.property_type)}
            </span>
          </div>
        </div>
        <h3 className="card-title">{data.estate_name}</h3>
      </div>
      <div className={`card-body ${isOpen ? 'open' : ''}`}>
        <div className={`card-buttons ${isOpen ? 'open' : 'hidden'}`}>
          <Link
            to={`/simulation/${data._id}/`}
            className="card-button card-edit"
          >
            {/* <img src="img/common/ico-edit.svg" alt="編集" /> */}
            <span>編集</span>
          </Link>
          <span
            className="card-button card-delete"
            onClick={() => {
              if (window.confirm(`${data.estate_name}を削除しますか？`)) {
                deleteEstate(data._id)
              }
            }}
          >
            {/* <img src="img/common/ico-delete.svg" alt="削除" /> */}
            <span>削除</span>
          </span>
        </div>

        <hr />
        <div className="card-info-list">
          <dl className="card-info">
            <dt>設定価格</dt>
            <dd>
              {isInteger(data.expected_selling_price) ? (
                <>{separateComma(data.expected_selling_price / 10000)}万円</>
              ) : (
                '-'
              )}
              {/* <small>築{builtYearToAge(data.built_date.$date)}年</small> */}
            </dd>
          </dl>
          <dl className="card-info">
            <dt>設定賃料</dt>
            <dd>
              {isInteger(data.max_years_rent) ? (
                <>
                  {separateComma(data.max_years_rent / 10000)}万円
                  <small>
                    月額{' '}
                    {separateComma(
                      Math.round(data.max_years_rent / 10000 / 12)
                    )}
                    万円
                  </small>
                </>
              ) : (
                '-'
              )}
            </dd>
          </dl>
          <dl className="card-info">
            <dt>
              {propertyTypeToText(data.property_type) === '区分'
                ? '専有面積'
                : '延床面積'}
            </dt>
            <dd>
              {isInteger(data.occupation_area) ? (
                <>
                  {propertyTypeToText(data.property_type) === '区分'
                    ? Math.round(data.occupation_area)
                    : separateComma(data.total_floor_space || 0)}
                  ㎡
                  <small>
                    {propertyTypeToText(data.property_type) === '区分'
                      ? (data.layout_rooms || 1) +
                        LayoutStructureToText(data.layout_structure || 0)
                      : ''}
                  </small>
                </>
              ) : (
                '-'
              )}
            </dd>
          </dl>
          <dl className="card-info">
            <dt>築年月</dt>
            <dd>
              {data.built_date ? (
                <>
                  {Moment(data.built_date.$date).format('YYYY年M月')}
                  <small>
                    築{Moment().diff(Moment(data.built_date.$date), 'years')}年
                  </small>
                </>
              ) : (
                '-'
              )}
            </dd>
          </dl>
        </div>
        <hr />
        <div className="card-profit-list">
          <dl className="card-profit">
            <dt>表面利回り</dt>
            <dd>
              {isInteger(data.gross_rate) ? (
                <>{Math.round(data.gross_rate * 100) / 100}%</>
              ) : (
                '-'
              )}
            </dd>
          </dl>
          <dl className="card-profit">
            <dt>
              IRR<span className="help">?</span>
              <p className="help_text">
                <b>登録物件比較の観点から、下記条件で表示させています</b>
                ・売却諸費用: 0円 <br />
                ・購入諸費用: 0円 <br />
                ・ローン利用額: 0円 <br />
                ・運営費用: 0円 <br />
                ・売却時表面利回り: 査定表面利回り10年目を設定
              </p>
              <small>* 全期間利回り</small>
            </dt>
            <dd>
              {isInteger(data.IRR) ? (
                <>{Math.round(data.IRR * 100) / 100}%</>
              ) : (
                '-'
              )}
            </dd>
          </dl>
        </div>
        {isEstateSimulatable(data) ? (
          <Link
            to={`/simulation/${data._id}/result`}
            className="card-link blue"
          >
            投資プランシミュレーション
          </Link>
        ) : (
          <>
            <Link to={`/simulation/${data._id}`} className="card-link gray">
              物件情報入力
            </Link>
            <p className="card-link-comment">
              <span>
                シミュレーションは物件情報を入力した後にご利用いただけます。
              </span>
            </p>
          </>
        )}
      </div>
    </CardPropertyElem>
  )
}

export default CardProperty
