import React, { useState } from 'react'
import styled from 'styled-components'
import Grid from 'components/Simulation/Layout/Grid'
import { isMobileOnly } from 'react-device-detect'

type GridTabLayoutProps = {
  labels: string[]
  items: React.ReactElement[]
  itemHeader?: React.ReactElement
}

const TabElem = styled.ul`
  padding: 10px 24px 0;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #b8bed7;
  li {
    position: relative;
    top: 1px;
    list-style: none;
    width: 105px;
    height: 40px;
    line-height: 40px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    background: #4f5e9c;
    color: white;
    border-radius: 5px 5px 0 0;
  }
  li.active {
    background: white;
    color: #4f5e9c;
    border: 1px solid #b8bed7;
    border-bottom-color: white;
  }
`

const ItemContainer = styled.div`
  padding: 30px 0 0;
  &.mobile {
    padding: 15px;
  }
`

// PCの時は3つ並びでitemsを表示し、SPの時はTab表示に切り替わる。
export const GridTabLayout: React.FC<GridTabLayoutProps> = (props) => {
  const [tabindex, setTabindex] = useState(1)
  return (
    <>
      {isMobileOnly && (
        <TabElem>
          {props.labels.map((label, index) => {
            return (
              <li
                className={tabindex === index + 1 ? 'active' : ''}
                onClick={() => setTabindex(index + 1)}
                key={label}
              >
                {label}
              </li>
            )
          })}
        </TabElem>
      )}
      <ItemContainer className={isMobileOnly ? 'mobile' : ''}>
        <Grid column={['1fr', '1fr', '1fr']}>
          {props.itemHeader}
          {props.items.map((item, index) => {
            return (!isMobileOnly || index + 1 === tabindex) && item
          })}
        </Grid>
      </ItemContainer>
    </>
  )
}
