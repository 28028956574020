import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from '../reducers/AppReducer'
import { DevLogin, authActions } from '../reducers/AuthReducer'
import { DevLoginComponent } from '../components/DevLoginComponent'

function mapDispatchToProps(dispatch: Dispatch<Action<any>>) {
  return {
    devLogin: (v: DevLogin) => {
      dispatch(authActions.devLogin(v))
    },
  }
}

function mapStateToProps(appState: AppState) {
  return Object.assign({}, appState.auth)
}

export default connect(mapStateToProps, mapDispatchToProps)(DevLoginComponent)
