import styled from 'styled-components'
import colors from 'style/colors'

interface ComponentProps {
  switch?: boolean
}

export const ToggleRentButton = styled.span`
  cursor: pointer;
  display: inline-block;
  padding: 1px;
  width: 40px;
  height: 20px;
  border-radius: 60px;
  transition: all 0.4s;
  position: relative;
  margin-left: 8px;
  background-color: ${(props: ComponentProps) =>
    props.switch ? colors.main : '#c1c1c1'};
  top: 1px;
  span {
    position: absolute;
    top: 4px;
    left: ${(props: ComponentProps) => (props.switch ? 22 : 6)}px;
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 52px;
    transition: all 0.4s;
  }
`

export default ToggleRentButton

export const ToggleOtherIncomeButton = styled.span`
  font-size: 12px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  @media screen and (max-width: 800px) {
    padding: 20px 20px 0;
    justify-content: space-between;
  }
`
