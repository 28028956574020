// カンマ区切りにする
export const separateComma = (value: any) => {
  if (!value) {
    value = 0
  }
  return String(value).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
}

// 現在の築年数を求める
export const builtYearToAge = (unix_timestamp: number) => {
  const builtYear = new Date(unix_timestamp).getFullYear()
  const nowYear = new Date().getFullYear()
  return nowYear - builtYear
}

// 1950年から現在年度までの配列
export const ageYearArray = () => {
  const ageFirst = 1950
  const currentYear = new Date().getFullYear()
  const age_length = [...Array(currentYear - ageFirst + 2)]
  return age_length.map((empty, index) => ageFirst + index)
}

export const NanToZero = (data: any) => (isNaN(data) ? 0 : data)

export const isInteger = (n: any): n is number => Number.isFinite(n)

// min以上max以下に変換する
export const fitValue = (value: number, min?: number, max?: number) => {
  const minFitValue = min !== undefined ? (min > value ? min : value) : value
  const fitValue =
    max !== undefined ? (max < minFitValue ? max : minFitValue) : minFitValue
  return fitValue
}
