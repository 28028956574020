import { portfolioValue, portfolioSumValue } from 'domain/portfolio'
import React from 'react'
import styled from 'styled-components'
import { BodyCell, BodyGroup, BodyRow } from './Foundation'
import { separateComma } from '../../../../helper/number'

export const BodyContainer = styled.div`
  height: 93px;
`

export const PortfolioTableBody: React.FC<{
  value: portfolioValue | portfolioSumValue
}> = ({ value }) => {
  return (
    <BodyContainer>
      <BodyGroup>
        {/* 内容 */}
        <BodyRow className="row-head" width="8%">
          <BodyCell>
            <p>
              <b>{separateComma(value.selling_price)}万円</b>
            </p>
          </BodyCell>
        </BodyRow>

        {/* 帳簿価額 */}
        <BodyRow width="6.4%">
          <BodyCell>
            <p>
              <b>{separateComma(Math.round(value.book_price / 10000))}万円</b>
            </p>
          </BodyCell>
        </BodyRow>

        {/* 相続税評価額（土地） */}
        <BodyRow width="6.4%">
          <BodyCell>
            <p>
              <b>
                {value.inheritedValuationLand
                  ? separateComma(Math.round(value.inheritedValuationLand)) +
                    '万円'
                  : '-'}
              </b>
            </p>
          </BodyCell>
        </BodyRow>

        {/* 相続税評価額（建物） */}
        <BodyRow width="6.4%">
          <BodyCell>
            <p>
              <b>
                {value.inheritedValuationBuild
                  ? separateComma(Math.round(value.inheritedValuationBuild)) +
                    '万円'
                  : '-'}
              </b>
            </p>
          </BodyCell>
        </BodyRow>

        {/* AI査定価格 */}
        <BodyRow width="6.4%">
          <BodyCell>
            <p>
              <b>{separateComma(Math.round(value.ai_price / 10000))}万円</b>
            </p>
          </BodyCell>
        </BodyRow>

        {/* 評価圧縮率 */}
        <BodyRow width="6.4%">
          <BodyCell>
            <p>
              <b>
                {value.evaluation_compression_ratio
                  ? Math.round(value.evaluation_compression_ratio * 10) / 10 +
                    '%'
                  : '-'}
              </b>
            </p>
          </BodyCell>
        </BodyRow>

        {/* 自己資金額 */}
        <BodyRow width="8%">
          <BodyCell>
            <p>
              <b>{separateComma(value.own_resources)}万円</b>
            </p>
          </BodyCell>
        </BodyRow>

        {/* ローン残債 */}
        <BodyRow width="8%">
          <BodyCell>
            <p className="blue">
              <b>{separateComma(Math.round(value.now_debts / 10000))}万円</b>
            </p>
          </BodyCell>
        </BodyRow>

        {/* 残存期間 */}
        <BodyRow width="8%">
          <BodyCell>
            <p>
              <b>{value.loan_remaining_period}年</b>
            </p>
          </BodyCell>
        </BodyRow>

        {/* 純営業利益(NOI) */}
        <BodyRow width="8%">
          <BodyCell>
            <p>
              <b>{separateComma(Math.round(value.noi / 10000))}万円</b>
            </p>
          </BodyCell>
        </BodyRow>

        {/* 純営業利益(NOI)利回り */}
        <BodyRow width="8%">
          <BodyCell>
            <p>
              <b>
                {value.noi_rate
                  ? Math.round(value.noi_rate * 100) / 100 + '%'
                  : '-'}
              </b>
            </p>
          </BodyCell>
        </BodyRow>

        {/* 累計税引き後キャッシュフロー */}
        <BodyRow width="20%">
          <BodyCell>
            <p className="blue">
              {separateComma(Math.round(value.accumulation_btcf / 10000))}万円
            </p>
          </BodyCell>
        </BodyRow>
      </BodyGroup>
    </BodyContainer>
  )
}
