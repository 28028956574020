import styled from 'styled-components'
import colors from 'style/colors'

export const SpMenuList = styled.ul`
  border-top: 1px solid ${colors.border_gray2};
  li {
    background: white url('/img/common/ic_arrow_circle.svg') calc(100% - 15px)
      center / 24px 24px no-repeat;
    padding: 0 20px;
    height: 60px;
    line-height: 60px;
    font-weight: bold;
    border-bottom: 1px solid ${colors.border_gray2};
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
`

export const SpToggleListItem = styled.li`
  @media screen and (min-width: 801px) {
    display: none;
  }
  list-style: none;
  .list-title {
    background: white url('/img/common/ic_arrow_bottom.svg') calc(100% - 15px)
      center / 14px 14px no-repeat;
    padding: 0 20px;
    height: 45px;
    line-height: 45px;
    font-weight: bold;
    border-bottom: 1px solid ${colors.border_gray2};
  }
  .list-body {
    background: ${colors.bg_gray};
    padding: 15px 20px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    display: none;
  }
  &.open {
    .list-title {
      background: white url('/img/common/ic_arrow_top.svg') calc(100% - 15px)
        center / 14px 14px no-repeat;
    }
    .list-body {
      display: block;
    }
  }
  dt {
    margin-bottom: 10px;
  }
  dd {
    font-weight: bold;
    color: ${colors.main};
  }
  dd.red {
    color: ${colors.red};
  }
`
