import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from '../reducers/AppReducer'
import { hogeActions } from '../actions/hogeActions'
import { HogeComponent } from '../components/hogeComponent'

export interface HogeActions {
  updateName: (v: string) => Action<string>
  updateEmail: (v: string) => Action<string>
  request: (v: string) => Action<string>
  closeModal: () => Action<void>
  openModal: () => Action<void>
}

function mapDispatchToProps(dispatch: Dispatch<Action<any>>) {
  return {
    updateName: (v: string) => dispatch(hogeActions.updateName(v)),
    updateEmail: (v: string) => dispatch(hogeActions.updateEmail(v)),
    closeModal: () => dispatch(hogeActions.closeModal()),
    openModal: () => dispatch(hogeActions.openModal()),
    request: (v: string) => {
      console.log('bar')
      return dispatch(hogeActions.request(v))
    },
  }
}

function mapStateToProps(appState: AppState) {
  console.log(appState)
  return Object.assign({}, appState.hoge)
}

export default connect(mapStateToProps, mapDispatchToProps)(HogeComponent)
