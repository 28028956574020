import classNames from 'classnames'
import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import colors from 'style/colors'
import styled from 'styled-components'
import * as api from '../apis'
import ContactModal from './ContactModal'

const HeaderWrapper = styled.div`
  .header-margin {
    height: 58px;
    @media screen and (max-width: 800px) {
      height: 69px;
    }
  }
`

const HeaderElem = styled.header`
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100vw;
  height: 58px;
  align-items: center;
  background: #f8f9fa;
  @media screen and (max-width: 800px) {
    height: 69px;
    border-bottom: 4px solid;
    border-image: linear-gradient(
      90deg,
      ${colors.main} 0%,
      ${colors.priority} 100%
    );
    border-image-slice: 1;
  }

  .header-left {
    position: absolute;
    left: 15px;
    width: 230px;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  .header-center {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 250px;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  .header-center-sp {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 180px;
    @media screen and (min-width: 801px) {
      display: none;
    }
  }

  .header-right {
    position: absolute;
    right: 15px;
    display: flex;
    cursor: pointer;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  .header-right-item {
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10px;
    color: #666;
    img {
      display: block;
      height: 24px;
    }
  }
  .header-right-item-top {
    img {
      height: 24px;
    }
  }

  .header-sp-title {
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    @media screen and (min-width: 801px) {
      display: none;
    }
  }
  .header-sp-title-top {
    transform: scale(0.6);
    margin: 0;
    margin-bottom: 2px;
  }
  .header-sp-title-bottom {
    transform: scale(0.9);
    letter-spacing: 2px;
    margin-top: 3px;
  }

  /* スマホ用モーダルメニューの展開ボタン */
  .header-modal-button {
    display: none;
    @media screen and (max-width: 800px) {
      display: flex;
      position: absolute;
      top: 0;
      right: 20px;
      bottom: 0;
      width: 25px;
      align-items: center;
      text-align: center;
    }
    img {
      margin: 0 auto;
    }
    .close {
      width: 20px;
    }
    &.default {
      .open {
        display: block;
      }
      .close {
        display: none;
      }
    }
    &.active {
      .open {
        display: none;
      }
      .close {
        display: block;
      }
    }
  }

  /* スマホ用モーダルメニュー */
  .header-modal {
    display: none;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 69px;
    left: 0;
    background: rgba(237, 239, 245, 0.85);
    &.active {
      display: block;
    }
    p {
      text-align: center;
      height: 50px;
      line-height: 50px;
      font-size: 13px;
      font-weight: bold;
      background: white;
      color: ${colors.main};
    }
  }
  .header-modal-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
  }
  .header-modal-list {
    position: relative;
    &::after {
      content: '';
      display: block;
      height: 4px;
      background: linear-gradient(
        90deg,
        ${colors.main} 0%,
        ${colors.priority} 100%
      );
    }
    a,
    span {
      display: block;
      border-bottom: 1px solid ${colors.border_gray2};
      height: 60px;
      line-height: 60px;
      padding: 0 20px;
      background: white url('/img/common/ic_arrow_circle.svg') calc(100% - 20px)
        center / 22px no-repeat;
      font-size: 15px;
      font-weight: bold;
      color: ${colors.base};
      text-align: left;
    }
    a.list-dark {
      background: ${colors.main} url('/img/common/ic_arrow_circle_white.svg')
        calc(100% - 22px) center / 17px no-repeat;
      color: white;
    }
  }
`

const Header: React.FC = () => {
  const [menuIsActive, setMenuIsActive] = useState(false)
  const [contactOpen, setContactOpen] = useState(false)

  const closeContact = useCallback(
    (touched: boolean) => {
      if (touched) {
        if (!window.confirm('送信せずに閉じてもよろしいでしょうか?')) {
          return
        }
      }
      setContactOpen(false)
    },
    [setContactOpen]
  )
  const openContact = useCallback(() => setContactOpen(true), [setContactOpen])
  const handleSubmitContact = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const formData = new FormData(e.currentTarget)
      const formPayload = Object.fromEntries(formData.entries())
      console.error('TODO!!', formPayload)
    },
    []
  )

  const closeMenu = useCallback(() => setMenuIsActive(false), [setMenuIsActive])
  const toggleMenu = useCallback(() => setMenuIsActive((open) => !open), [
    setMenuIsActive,
  ])

  const logout = useCallback(() => {
    api.logout().then((_) => (window.location.href = 'https://value-ai.jp'))
  }, [])

  return (
    <>
      <ContactModal
        isOpen={contactOpen}
        onRequestClose={closeContact}
        onSubmit={handleSubmitContact}
      />
      <HeaderWrapper>
        <HeaderElem>
          <a
            className="header-left"
            href="https://www.cigr.co.jp/biz/"
            target="_blank" // eslint-disable-line react/jsx-no-target-blank
            rel="noopener"
          >
            <img
              src="/img/common/logo_cosmos.gif"
              alt="株式会社コスモスイニシア"
            />
          </a>

          <a className="header-center" href="/">
            <img src="/img/common/logo_va.svg" alt="value ai" />
          </a>

          <a href="/" className="header-center-sp">
            <p className="header-sp-title header-sp-title-top">
              コスモスイニシアの〈バリューアイ〉&nbsp;
            </p>
            <img
              className="header-sp-logo"
              src="/img/common/logo_text.svg"
              alt="value ai"
            />
            <p className="header-sp-title header-sp-title-bottom">
              投資不動産AI診断サービス&nbsp;
            </p>
          </a>

          <div className="header-right">
            <Link to="/" className="header-right-item header-right-item-top">
              <img src="/img/common/icon_dashboard.svg" alt="planner topへ" />
              トップページ
            </Link>
            <div
              className="header-right-item header-right-item-logout"
              onClick={logout}
            >
              <img src="/img/common/icon_logout.svg" alt="ログアウト" />
              ログアウト
            </div>
          </div>

          <div
            onClick={toggleMenu}
            className={
              menuIsActive
                ? 'header-modal-button active'
                : 'header-modal-button default'
            }
          >
            <img
              alt="メニューを開く"
              className="open"
              src="/img/common/menu.svg"
            />
            <img
              alt="メニューを閉じる"
              className="close"
              src="/img/common/close.svg"
            />
          </div>

          <div className={classNames('header-modal', menuIsActive && 'active')}>
            <div className="header-modal-bg" onClick={closeMenu} />
            <nav className="header-modal-nav">
              <ul className="header-modal-list">
                <li>
                  <Link to="/" onClick={closeMenu}>
                    トップページ
                  </Link>
                </li>
                <li onClick={openContact}>
                  <span>お問い合わせ</span>
                </li>
                <li>
                  <Link to="/service/planner/signin" onClick={closeMenu}>
                    ログイン/ログアウト
                  </Link>
                </li>
              </ul>
              <p onClick={closeMenu}>メニューを閉じる</p>
            </nav>
          </div>
        </HeaderElem>
        <div className="header-margin"></div>
      </HeaderWrapper>
    </>
  )
}

export default Header
