import React from 'react'
import styled from 'styled-components'
import colors from 'style/colors'

interface StyleProps {
  margin_minus?: boolean
}

interface IProps extends StyleProps {
  text: string // ラベルテキスト
  require: boolean // 必須かどうか
  help: boolean // ヘルプがあるかどうか
  help_text: string // ヘルプテキスト
}

const FormLabel = styled.div`
  min-height: 100%;
  background: ${colors.base_gray};
  align-items: center;
  padding: 25px 20px;
  position: relative;
  font-size: 15px;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  @media screen and (max-width: 800px) {
    width: 100%;
    margin: 0;
    height: 45px;
    padding: 20px;
    box-sizing: border-box;
  }
  .req {
    font-size: 13px;
    color: ${colors.red};
    @media screen and (max-width: 800px) {
      background-color: ${colors.red};
      color: white;
      padding: 5px;
      border-radius: 4px;
      font-size: 11px;
    }
  }
  .help {
    position: absolute;
    right: 53px;
    top: -3px;
    bottom: 0;
    margin: auto;
    width: 22px;
    height: 22px;
    line-height: 18px;
    border-radius: 22px;
    text-align: center;
    font-size: 14px;
    color: #0673c8;
    border: 2px solid #0673c8;
    @media screen and (max-width: 800px) {
      right: 80px;
    }
  }
  .help_text {
    display: none;
    position: absolute;
    left: 20px;
    top: 0;
    padding: 4px;
    background: white;
    border: 2px solid #0673c8;
    color: #0673c8;
    font-size: 13px;
    @media screen and (max-width: 800px) {
      top: -10px;
    }
  }
  .help:hover + .help_text {
    display: block;
  }
`

class FieldLabel extends React.Component<IProps, {}> {
  public static defaultProps = {
    require: true,
    help: false,
    help_text: '',
    margin_minus: true,
  }

  public render() {
    return (
      <FormLabel>
        {this.props.text}
        {this.props.require ? <span className="req">必須</span> : ''}
        {this.props.help ? <span className="help">?</span> : ''}
        {this.props.help_text !== '' ? (
          <span className="help_text">{this.props.help_text}</span>
        ) : (
          ''
        )}
      </FormLabel>
    )
  }
}

export default FieldLabel
