import React from 'react'
import styled from 'styled-components'
import colors from '../../../style/colors'

interface StyleProps {
  margin_right?: number
  width: number
}

const FormTextArea = styled.div`
  margin-right: ${(props: StyleProps) =>
    !(props.margin_right === undefined) ? props.margin_right + 'px' : '15px'};
  display: flex;
  align-items: center;
  textarea {
    display: inline-block;
    max-width: ${(props: StyleProps) => props.width}px;
    width: 100%;
    height: 130px;
    line-height: 35px;
    border: 1px solid ${colors.border_gray};
    border-radius: 3px;
    padding: 0 8px 0 14px;
    font-size: 15px;
  }
  span {
    margin-left: 15px;
    font-size: 15px;
    font-weight: normal;
    white-space: nowrap;
  }
`

type IProps<T extends Exclude<any, undefined> = string> = StyleProps & {
  name: string // TextArea name属性
  type: string
  unit_text: string // 単位を後ろに表示
} & (ControlledTextAreaProps<T> | UncontrolledTextAreaProps<T>)

type ControlledTextAreaProps<T> = {
  value: T
  onChange: React.FormEventHandler<HTMLTextAreaElement>
  required?: boolean
  onBlur?: React.FormEventHandler<HTMLTextAreaElement>
}

type UncontrolledTextAreaProps<T> = {
  defaultValue?: T
  onChange?: React.FormEventHandler<HTMLTextAreaElement>
  required?: boolean
  onBlur?: React.FormEventHandler<HTMLTextAreaElement>
}

const getHTMLTextAreaAttributes = (
  props: IProps
): React.TextareaHTMLAttributes<HTMLTextAreaElement> => {
  if ('value' in props) {
    return {
      name: props.name,
      onChange: props.onChange,
      value: props.value,
      required: props.required,
      onBlur: props.onBlur,
    }
  }

  return {
    name: props.name,
    onChange: props.onChange,
    defaultValue: props.defaultValue,
    required: props.required,
    onBlur: props.onBlur,
  }
}

export class FieldTextArea extends React.Component<IProps, {}> {
  public static defaultProps = {
    type: 'number',
    unit_text: '',
    width: 160,
    margin_right: 15,
  }

  public render() {
    return (
      <FormTextArea
        width={this.props.width}
        margin_right={this.props.margin_right}
      >
        <textarea {...getHTMLTextAreaAttributes(this.props)} />
        {this.props.unit_text ? (
          <span className="help">{this.props.unit_text}</span>
        ) : (
          ''
        )}
      </FormTextArea>
    )
  }
}

export default FieldTextArea
