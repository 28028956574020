import React from 'react'
import styled from 'styled-components'
import AddButton from '../Field/AddButton'
import Comment from '../Field/Comment'
import FieldInput from '../Field/Input'
import FieldLabel from '../Field/Label'
import FieldRadio from '../Field/Radio'
import FieldSelect from '../Field/Select'
import { PanelForm, PanelArea } from '../Layout/Panel'
import PanelTitle from '../Layout/PanelTitle'
import RegistrationForm from './/Form'

const prefectureTexts = [
  '',
  '東京都',
  '神奈川県',
  '千葉県',
  '埼玉県',
  '愛知県',
  '大阪府',
  '福岡県',
]

const prefectureCodes = ['', '13', '14', '12', '11', '23', '27', '40']

interface IState {
  stationLength: number // 最寄駅の数
}

interface StyleProps {}

export interface IRegistrationStepOneProps extends StyleProps {
  id?: string
  setPropertyType: any
  form: any
  updateField: any
  railways: any
  stations?: any[]
  cities: any
}

const FormOne = styled.div``

class RegistrationStepOne extends React.Component<any> {
  public state: IState = {
    // 最寄駅の数
    stationLength: this.props.form.railways
      ? this.props.form.railways.length
      : 1,
  }

  // 最寄駅の追加
  addStation() {
    if (this.state.stationLength < 3) {
      this.setState({ stationLength: this.state.stationLength + 1 })
    }
  }

  public render() {
    let stationList = []
    for (let i = 1; i <= this.state.stationLength; i++) {
      let stations = []
      if (this.props.stations && this.props.stations[`stations${i}`]) {
        for (const r of this.props.stations[`stations${i}`]) {
          stations.push({
            label: r.name,
            value: r.code,
            key: r.code,
          })
        }
      }

      stationList.push(
        <div key={i} className="field-rows-sp">
          <FieldSelect
            name={'near_line_' + i}
            items={this.props.railways.map((r: any) => ({
              label: r.name,
              value: r.code,
              key: r.code,
            }))}
            selectedValue={this.props.form[`near_line_${i}`]}
            changedSelect={this.props.updateField}
            width={222}
          />
          <div className="field">
            <FieldSelect
              name={'near_station_' + i}
              items={stations}
              selectedValue={this.props.form[`near_station_${i}`]}
              changedSelect={this.props.updateField}
              unit_text="駅より 徒歩"
            />
            <FieldInput
              name={'near_station_minutes_' + i}
              unit_text="分"
              value={this.props.form[`near_station_minutes_${i}`] || 1}
              onChange={this.props.updateField}
              width={80}
              placeholder="6"
            />
          </div>
        </div>
      )
    }
    return (
      <FormOne id={this.props.id}>
        <PanelForm>
          <PanelArea>
            <PanelTitle
              icon={1}
              label="物件の基本情報を登録しましょう。"
              description="物件名・所在地・最寄駅などの物件に関する基本的な情報を登録しましょう。"
            />
          </PanelArea>
          <RegistrationForm>
            <FieldLabel text="取引のタイプ" />

            <div className="field-area">
              <FieldRadio
                name="trade_type"
                sp_width={105}
                deprecated_texts={['購入', '売却', '保有']}
                deprecated_values={['1', '2', '3']}
                value={this.props.form.trade_type + ''}
                onChange={this.props.updateField}
              />
              <Comment comment="「購入」選択時は資産ポートフォリオ、投資プランシミュレーション共に現時点に購入したと暫定し、購入時から売却までの期間を想定してローンや減価償却の計算が行われます。" />
            </div>

            <FieldLabel text="物件のタイプ" />
            <div className="field-area">
              <FieldRadio
                name="property_type"
                deprecated_texts={['一棟', '区分']}
                deprecated_values={['2', '1']}
                value={this.props.form.property_type + ''}
                onChange={this.props.updateField}
              />
            </div>

            <FieldLabel text="物件名" />
            <div className="field-area">
              <FieldInput
                name="estate_name"
                type="text"
                width={520}
                value={this.props.form.estate_name || ''}
                onChange={this.props.updateField}
                placeholder="渋谷美竹ハイム"
              />
              <Comment comment="新築の場合「渋谷区渋谷２丁目PJ」などの仮称でも可" />
            </div>

            <FieldLabel text="住所・所在地" />
            <div className="field-area field-rows">
              <div className="field-rows-sp">
                <div className="field" style={{ display: 'none' }}>
                  <span className="field-text">〒</span>
                  <FieldInput
                    name="zip1"
                    width={80}
                    unit_text="-"
                    value={this.props.form.zip1}
                    onChange={this.props.updateField}
                    placeholder="150"
                  />
                  <FieldInput
                    name="zip2"
                    width={80}
                    value={this.props.form.zip2}
                    onChange={this.props.updateField}
                    placeholder="0021"
                  />
                </div>
                <div className="field">
                  <FieldSelect
                    name="pref_code"
                    selectedValue={this.props.form.pref_code}
                    deprecated_values={prefectureCodes}
                    deprecated_texts={prefectureTexts}
                    changedSelect={this.props.updateField}
                  />
                  <FieldSelect
                    name="city"
                    selectedValue={this.props.form.city}
                    deprecated_values={this.props.cities}
                    deprecated_texts={this.props.cities}
                    changedSelect={this.props.updateField}
                  />
                </div>
              </div>
              <div className="field-rows-sp">
                <FieldInput
                  name="aza"
                  type="text"
                  width={222}
                  value={this.props.form.aza}
                  onChange={this.props.updateField}
                  placeholder="渋谷"
                />
                <div className="field">
                  <FieldInput
                    name="chome"
                    value={this.props.form.chome || ''}
                    onChange={this.props.updateField}
                    width={80}
                    unit_text="丁目"
                    placeholder="1"
                  />
                  <FieldInput
                    name="ban"
                    value={this.props.form.ban || ''}
                    onChange={this.props.updateField}
                    width={80}
                    unit_text="-"
                    placeholder="20"
                  />
                  <FieldInput
                    name="gou"
                    value={this.props.form.gou || ''}
                    onChange={this.props.updateField}
                    width={80}
                    placeholder="17"
                  />
                </div>
              </div>
            </div>

            <FieldLabel text="最寄駅" />
            <div className="field-area field field-rows">
              {stationList}
              <AddButton onClick={() => this.addStation()} />
            </div>
          </RegistrationForm>
        </PanelForm>
      </FormOne>
    )
  }
}

export default RegistrationStepOne
