import React, { memo, ReactElement } from 'react'
import PriceTablePCSet from './PriceTablePCSet'
import PriceTableMobileSet from './PriceTableMobileSet'

export interface IProps {
  title: string
  sold_year: number
  datas: any
  PCRowLength: number
  tooltip?: ReactElement
}

export const PriceTableWrapper: React.FC<IProps> = memo((props: IProps) => {
  // 配列のデータを年と価格のデータに置き直す
  const mapDatas = props.datas.map((data: any, index: any) => {
    return {
      year: index + 1,
      value: data,
    }
  })
  return (
    <>
      <PriceTablePCSet
        title={props.title}
        tooltip={props.tooltip}
        sold_year={props.sold_year}
        RowLength={props.PCRowLength}
        datas={mapDatas}
      />
      <PriceTableMobileSet title={props.title} sold_year={1} datas={mapDatas} />
    </>
  )
})

export default PriceTableWrapper
