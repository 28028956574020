import React from 'react'
import { SimulationPayload } from 'domain/simulation'
import Panel from './Simulation/Layout/Panel'
import styled from 'styled-components'
import { separateComma } from 'helper/number'
import SpBox from './Simulation/Layout/SpBox'
import { MainButton } from './Symbols/Button'

const SummaryContent = styled.div`
  text-align: center;
  @media screen and (max-width: 800px) {
    text-align: left;
  }
  h2 {
    font-size: 20px;
    margin: 25px 0;
    @media screen and (max-width: 800px) {
      font-size: 18px;
      line-height: 1.5;
    }
  }
  .blue {
    color: #0673c8;
  }
  p {
    font-size: 13px;
    margin: 25px 0;
    @media screen and (max-width: 800px) {
      font-size: 13px;
      line-height: 1.5;
    }
  }
  .break-sp {
    display: none;
    @media screen and (max-width: 800px) {
      display: block;
    }
  }
`

export const SummaryPanel: React.FC<{
  payload: SimulationPayload
  ChangeResultPage: any
}> = ({ payload: { estate, result, params }, ChangeResultPage }) => {
  return (
    <>
      <Panel>
        <SummaryContent>
          <h2>
            {estate.estate_name}に<br className="break-sp" />
            今後{' '}
            <span className="blue">
              {params.priorities_when_selling}年間
            </span>{' '}
            投資した場合、
            <br className="break-sp" />約{' '}
            <span className="blue">
              {separateComma(Math.round(result.final_asset / 10000))}万円
            </span>{' '}
            の投資収益が見込めます。
          </h2>
          <p>
            稼働率が <b className="bold">{params.active_rates}%</b> ・売却価格が{' '}
            <b className="bold">
              {separateComma(
                Math.round(
                  result.estimated_selling_prices[
                    params.priorities_when_selling - 1
                  ] / 10000
                )
              )}
              万円
            </b>{' '}
            <br className="break-sp" />
            の場合の結果です。
          </p>
        </SummaryContent>
      </Panel>
      <SpBox>
        <MainButton
          onClick={() => ChangeResultPage(5)}
          height={45}
          fontSize="15px"
        >
          各種サービス
        </MainButton>
      </SpBox>
    </>
  )
}

export default SummaryPanel
