import 'react-app-polyfill/stable'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import configureStore, { history } from './configureStore'
import './index.css'
import * as serviceWorker from './serviceWorker'
import { ldebug } from 'helper/log'
import * as Sentry from '@sentry/browser'

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React)
}

if (process.env.REACT_APP_SENTRY_ENV) {
  const sentryConfig = {
    dsn: 'https://6af4d6d6187c4d3caad0bd92d289322f@sentry.io/1889298',
    environment: process.env.REACT_APP_SENTRY_ENV,
  }

  Sentry.init(sentryConfig)
}

const store = configureStore()

const renderApp = () =>
  ReactDOM.render(
    <Provider store={store}>
      <App history={history} />
    </Provider>,
    document.getElementById('root') as HTMLElement
  )

if (process.env.NODE_ENV === 'development') {
  if (module.hot) {
    module.hot.accept('./App', () => {
      ldebug('HMR: React')
      renderApp()
    })
  }
}

renderApp()

serviceWorker.unregister()
