import actionCreatorFactory, { Action } from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

const actionCreator = actionCreatorFactory()

export interface DevLogin {
  email: string
  password: string
}

export interface AuthActions {
  devLogin: (v: DevLogin) => void
  devLoginFailed: (v: string) => Action<string>
  logout: () => void
}

export const authActions = {
  devLogin: actionCreator<DevLogin>('ACTIONS_DEV_LOGIN'),
  devLoginFailed: actionCreator<string>('ACTIONS_REQUEST_FAILED'),
  logout: actionCreator<any>('ACTIONS_LOGOUT'),
}

export interface AuthState {
  message: string
}

const initialState: AuthState = {
  message: '',
}

export const AuthReducer = reducerWithInitialState(initialState).case(
  authActions.devLoginFailed,
  (state, message) => {
    return Object.assign({}, state, { message })
  }
)
