import Load from 'components/Load'
import {
  SimulationContainerActions,
  SimulationContainerProps,
} from 'containers/SimulationContainer'
import { isEstateSimulatable } from 'domain/estate'
import React, { useCallback, useEffect } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom'
import styled from 'styled-components'
import PropertyFormContainer from '../containers/PropertyFormContainer'
import { StepListHeader, StepListSticky } from './Simulation/StepList'
import { StepListInPageLinks } from './Simulation/StepList/StepList.type'
import SimulationResult from './SimulationResult'
import PageTitle from './PageTitle'

const Simulation = styled.div`
  background: #edeff5;
  min-height: 100vh;
  padding: 20px 0;
  border-radius: 5px;
  .simulation-content {
    width: 1152px;
    max-width: 100%;
    margin: 0 auto;
    display: grid;
    grid-gap: 20px;
    @media screen and (max-width: 800px) {
      grid-gap: 0;
    }
  }
`

const SimulationPage: React.FC<
  SimulationContainerProps &
    SimulationContainerActions &
    RouteComponentProps<{
      id: string
    }>
> = (props) => {
  const {
    getPlanningEstate,
    setPlans,
    savePlan,
    deletePlan,
    match,
    history,
    planningEstate,
    plans,
    saveNewPlan,
  } = props

  const haveEstate =
    planningEstate && planningEstate._id.toString() === match.params.id
  const estateID =
    (planningEstate && planningEstate && planningEstate._id.toString()) ||
    match.params.id

  useEffect(() => {
    if (!haveEstate) {
      getPlanningEstate(match.params.id)
    }
  }, [getPlanningEstate, haveEstate, match.params.id])

  // ページ遷移
  const handleClickStep = useCallback(
    (index) => {
      if (isMobileOnly) return
      switch (index) {
        case 0:
          history.push(`/simulation/${estateID}/`)
          window.scrollTo(0, 0)
          return
        default:
          // 結果ページ
          const anchor = StepListInPageLinks[index]
          if (anchor) {
            const elem = document.getElementById(anchor)
            if (elem) {
              elem.scrollIntoView(true)
              var scrolledY = window.scrollY
              if (scrolledY) {
                const fixedHeaderHeight = isMobileOnly ? 50 + 69 : 58 + 50
                window.scroll(0, scrolledY - fixedHeaderHeight)
              }
            } else {
              console.error('not found in page element', anchor)
            }
          } else {
            console.error(anchor)
          }
      }
    },
    [estateID, history]
  )

  if (!haveEstate)
    return (
      <>
        <Load isView={true} />
      </>
    )

  return (
    <>
      {props.planningEstate?.estate_name && (
        <PageTitle title={props.planningEstate.estate_name} />
      )}
      <Simulation>
        <div className="simulation-content">
          <div id="sellingBarContainer" />
          <StepListHeader
            onClickStep={handleClickStep}
            location={props.location}
          />

          <Switch>
            <Route
              exact
              path={props.match.url}
              component={PropertyFormContainer}
              match={props.match}
            />
            <Route
              path={`${props.match.url}/result`}
              component={(pageProps: any) =>
                // シミュレーションできない場合は入力画面にリダイレクト
                isEstateSimulatable(planningEstate!) ? (
                  <SimulationResult
                    {...pageProps}
                    savePlan={savePlan}
                    setPlans={setPlans}
                    deletePlan={deletePlan}
                    plans={plans}
                    estate={planningEstate!}
                    saveNewPlan={saveNewPlan}
                  />
                ) : (
                  <Redirect to={props.match.url} />
                )
              }
            />
          </Switch>
          <StepListSticky
            onClickStep={handleClickStep}
            location={props.location}
            sticky={false}
          />
          <div id="planSettingModalPortal" />
        </div>
      </Simulation>
    </>
  )
}

export default SimulationPage
