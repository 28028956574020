import { EstateJSON } from 'domain/estate'
import {
  runSimulation,
  SimulationPayload,
  mergeSimulationParams,
  SimulationParams,
} from 'domain/simulation'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link, RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import { PageButtonElem } from './Simulation/Field/PageButton'
import Simulation from './Simulation/Layout/Simulation'
import CashFlow from './Simulation/Result/Cashflow'
import IncomeSimulation from './Simulation/Result/IncomeSimulation'
import { Information } from './Simulation/Result/Infomation'
import InvestmentPlan from './Simulation/Result/InvestmentPlan'
import SettingsModal from './Simulation/Result/SettingsModal'
import ResultTab, { TabNameToIndex } from './Simulation/Result/Tab'
import VariousService from './Simulation/Result/VariousService'
import SectionTitle from './Simulation/SectionTitle'
import { debounce } from 'lodash'
import { SummaryPanel } from './Summary'
import CapitalPanel from './Simulation/CapitalPanel'
import { changeStepNum, mapTabToStep } from './Simulation/StepList'
import { SimulationContainerActions } from 'containers/SimulationContainer'

const ResultSection = styled.section`
  @media screen and (max-width: 800px) {
    display: none;
    &.active {
      display: block;
    }
  }
`

const PCLinkButton = PageButtonElem.withComponent(Link)

interface IProps {
  estate: EstateJSON
  plans: any[]
}

type ActionMethods = Pick<
  SimulationContainerActions,
  'savePlan' | 'deletePlan' | 'setPlans' | 'saveNewPlan'
>

const SimulationResult: React.FC<
  RouteComponentProps & IProps & ActionMethods
> = ({
  savePlan,
  deletePlan,
  setPlans,
  saveNewPlan,
  estate,
  location,
  ...props
}) => {
  const [page, setPage] = useState(1)
  const handlePageChange = useCallback(
    (num: number) => {
      setPage(num)
      changeStepNum(mapTabToStep(num))
      window.scrollTo(0, 0)
    },
    [setPage]
  )

  const hash = location.hash
  const ScrollToPlan = () => {
    const scrollElm = document.getElementById(`ResultPlan0`)
    scrollElm && scrollElm.scrollIntoView()
    window.scrollTo(0, window.scrollY - 130)
  }

  useEffect(() => {
    switch (hash) {
      case '': {
        setTimeout(() => {
          window.scrollTo(0, 0)
        }, 100)
        return
      }
      default:
        // タブ名
        const idx = TabNameToIndex[hash]
        if (idx !== undefined) {
          setPage(idx)
          return
        }

        // #ResultPlanへ飛んだらスクロール
        const ResutlPlanNumber = parsePlan(hash)
        if (ResutlPlanNumber === 0) {
          const elem = document.getElementById(`ResultPlan0`)
          elem && elem.scrollIntoView()
        } else if (ResutlPlanNumber > 0) {
          setPage(2)
          const elem = document.getElementById(`ResultPlan${ResutlPlanNumber}`)
          setTimeout(() => {
            elem && elem.scrollIntoView()
          }, 300)
        }
    }
  }, [hash])

  const [payload, setPayload] = useState<SimulationPayload>(() => {
    // 初期値で計算
    const params =
      (props.plans && props.plans[props.plans.length - 1].params) ||
      mergeSimulationParams(estate)
    const result = runSimulation(estate, params)
    const payload = { estate, result, params }
    return payload
  })

  // シミュレーション実行
  // 頻繁に呼ばれても呼ばれてから一定時間待たないと無視する
  const calculate = useMemo(
    () =>
      debounce((params: SimulationParams) => {
        requestAnimationFrame(() => {
          const result = runSimulation(estate, params)
          setPayload((payload) => ({ ...payload, result }))
        })
      }, 500),
    [estate]
  )

  const handleChangeParams = useCallback(
    (keypath: string, value: any) => {
      // フォームに反映して計算
      setPayload((payload) => {
        const newPayload = {
          ...payload,
          params: mergeSimulationParams(
            estate,
            { ...payload.params, [keypath]: value },
            payload.params
          ),
        }
        calculate(newPayload.params)
        return newPayload
      })
    },
    [calculate, estate]
  )

  if (!estate) {
    return null
  }

  return (
    <Simulation>
      <ResultTab
        history={props.history}
        ResultPage={page}
        ChangeResultPage={handlePageChange}
      />
      <ResultSection id="ResultInfo" className={page === 1 ? 'active' : ''}>
        <SectionTitle className="pc" label="物件情報">
          <PCLinkButton
            to={`/simulation/${estate._id}/#step1`}
            className="back pc"
          >
            <span className="blue">STEP.1</span> 物件情報を編集
          </PCLinkButton>
        </SectionTitle>
        <Information estate={estate} ChangeResultPage={handlePageChange} />
      </ResultSection>
      <ResultSection id="ResultPlan0" className={page === 2 ? 'active' : ''}>
        <SectionTitle className="pc" label="投資プラン" />
        <InvestmentPlan
          onChangeParams={handleChangeParams}
          payload={payload}
          ChangeResultPage={handlePageChange}
        />
      </ResultSection>

      <ResultSection id="ResultCaptal" className={page === 7 ? 'active' : ''}>
        <SectionTitle
          className="pc"
          label="収益分析"
          help={true}
          helpTipe="info"
        />
        <CapitalPanel payload={payload} ChangeResultPage={handlePageChange} />
      </ResultSection>

      <ResultSection id="ResultIncome" className={page === 3 ? 'active' : ''}>
        <SectionTitle
          className="pc"
          label="収支シミュレーション"
          help={true}
          helpTipe="info"
        >
          <PageButtonElem onClick={() => ScrollToPlan()} className="back pc">
            <span className="blue">STEP.3</span> 投資プランを編集
          </PageButtonElem>
        </SectionTitle>
        <IncomeSimulation
          onChangeParams={handleChangeParams}
          payload={payload}
          ChangeResultPage={handlePageChange}
        />
      </ResultSection>

      <ResultSection id="ResultCashflow" className={page === 4 ? 'active' : ''}>
        <SectionTitle
          className="pc"
          label="キャッシュフロー表・全期間利回り"
          help={true}
          helpTipe="info"
        >
          <PageButtonElem onClick={() => ScrollToPlan()} className="back pc">
            <span className="blue">STEP.3</span> 投資プランを編集
          </PageButtonElem>
        </SectionTitle>
        <CashFlow
          payload={payload}
          onChangeParams={handleChangeParams}
          ChangeResultPage={handlePageChange}
        />
      </ResultSection>

      <ResultSection id="ResultSummary" className={page === 6 ? 'active' : ''}>
        <SectionTitle label="推定結果" />
        <SummaryPanel payload={payload} ChangeResultPage={handlePageChange} />
      </ResultSection>

      <ResultSection id="ResultService" className={page === 5 ? 'active' : ''}>
        <SectionTitle label="各種サービス" />
        <VariousService />
      </ResultSection>
      <SettingsModal
        isView={page >= 3}
        payload={payload}
        savePlan={savePlan}
        setPlans={setPlans}
        deletePlan={deletePlan}
        plans={props.plans}
        saveNewPlan={saveNewPlan}
        onChangeParams={handleChangeParams}
        ChangeResultPage={handlePageChange}
      />
    </Simulation>
  )
}

const parsePlan = (hash: string) => {
  let step = parseInt(hash.replace('#ResultPlan', ''), 10)
  return isNaN(step) ? 0 : step
}

export default SimulationResult
