import React from 'react'
import styled from 'styled-components'
import colors from 'style/colors'

const AddButtonElem = styled.button`
  cursor: pointer;
  display: block;
  margin-right: 20px;
  width: 100px;
  height: 35px;
  line-height: 33px;
  border: 1px solid ${colors.border_gray};
  border-radius: 3px;
  text-align: center;
  font-weight: bold;
  color: ${colors.black_alpha};
  background: linear-gradient(#fff, #ebebeb);
  font-size: 12px;
`

interface IProps {
  onClick: () => void
}

class AddButton extends React.Component<IProps> {
  public render() {
    return (
      <AddButtonElem onClick={() => this.props.onClick()}>
        最寄駅を追加
      </AddButtonElem>
    )
  }
}

export default AddButton
