import { toArray } from 'helper/array'
import { ageYearArray } from 'helper/number'
import React, { useCallback, useState } from 'react'
import colors from 'style/colors'
import styled from 'styled-components'
import { GrayButton } from '../../Symbols/Button'
import { HelpButton } from '../../Symbols/Help'
import { BaseModal } from '../../Symbols/Modal'
import Comment from '../Field/Comment'
import FieldInput from '../Field/Input'
import FieldLabel from '../Field/Label'
import SpButton from '../Field/PageButton'
import FieldRadio from '../Field/Radio'
import FieldSelect, { ISelectItem } from '../Field/Select'
import Grid from '../Layout/Grid'
import { PanelForm, PanelArea } from '../Layout/Panel'
import PanelTitle from '../Layout/PanelTitle'
import SpBox from '../Layout/SpBox'
import RegistrationForm from './/Form'

interface StyleProps {}

const FormTwo = styled.div``

//interface anyProps {}
export interface IRegistrationStepTwoProps extends StyleProps {
  id?: string
  form: any
  updateField: any
}

const RegistrationStepTwo: React.FC<IRegistrationStepTwoProps> = (props) => {
  return (
    <FormTwo id={props.id}>
      <PanelForm>
        <PanelArea>
          <PanelTitle
            icon={2}
            label="物件の基本情報を登録しましょう。"
            description="物件名・所在地・最寄駅などの物件に関する基本的な情報を登録しましょう。"
          />
        </PanelArea>
        <RegistrationForm>
          <FieldLabel text="建物構造" />
          <div className="field-area">
            <FieldRadio
              width={100}
              name="building_structure"
              deprecated_values={['1', '2', '3', '4']}
              deprecated_texts={['SRC造', 'RC造', '木造', '鉄骨造']}
              value={props.form.building_structure + ''}
              onChange={props.updateField}
            />
          </div>

          <FieldLabel text="築年月" />
          <div className="field-area">
            <FieldSelect
              name="age_year"
              deprecated_values={ageYearArray()}
              deprecated_texts={ageYearArray().map(
                (year) => String(year) + '年'
              )}
              selectedValue={props.form.age_year}
              changedSelect={props.updateField}
            />
            <FieldSelect
              name="age_month"
              deprecated_values={[...Array(12)].map(
                (empty, index) => index + 1
              )}
              deprecated_texts={[...Array(12)].map(
                (empty, index) => String(index + 1) + '月'
              )}
              selectedValue={props.form.age_month}
              changedSelect={props.updateField}
            />
          </div>

          <FieldLabel text="総戸数" />
          <div className="field-area">
            <FieldInput
              name="number_of_houses"
              value={props.form.number_of_houses || ''}
              onChange={props.updateField}
              unit_text="戸"
              placeholder="76"
            />
            <Comment comment="地階を含まない階数を入力してください。" />
          </div>

          <FieldLabel text="階数" />
          <div className="field-area">
            <FieldInput
              name="number_of_floors"
              value={props.form.number_of_floors || ''}
              onChange={props.updateField}
              unit_text="階建て"
              placeholder="8"
            />
            <Comment comment="地階を含まない階数を入力してください。" />
          </div>

          <FieldLabel text="価格設定" />
          <div className="field-area">
            <FieldInput
              name="expected_selling_price"
              value={props.form.expected_selling_price || ''}
              onChange={props.updateField}
              unit_text="万円"
              placeholder="10000"
            />
            <Comment comment="販売価格または希望価格を入力してください。" />
          </div>

          <FieldLabel text="個人・法人区分" />
          <div className="field-area">
            <FieldRadio
              name="personal_company_type"
              deprecated_values={['1', '2']}
              deprecated_texts={['個人', '法人']}
              value={props.form.personal_company_type + ''}
              onChange={props.updateField}
            />
            <Comment comment="設定によって、収支シミュレーションの結果が変動します。" />
          </div>

          <FieldLabel text="購入年月" />
          <div className="field-area">
            <FieldSelect
              name="purchased_year"
              deprecated_values={ageYearArray()}
              deprecated_texts={ageYearArray().map(
                (year) => String(year) + '年'
              )}
              changedSelect={props.updateField}
              selectedValue={props.form.purchased_year}
            />
            <FieldSelect
              name="purchased_month"
              deprecated_values={[...Array(12)].map(
                (empty, index) => index + 1
              )}
              deprecated_texts={[...Array(12)].map(
                (empty, index) => String(index + 1) + '月'
              )}
              selectedValue={props.form.purchased_month}
              changedSelect={props.updateField}
            />
          </div>
          {props.form.personal_company_type + '' === '1' ? (
            <>
              <FieldLabel text="その他収入" require={false} />
              <div className="field-area">
                <FieldInput
                  name="other_income"
                  value={props.form.other_income || ''}
                  onChange={props.updateField}
                  unit_text="万円（年額）"
                  placeholder="800"
                />
              </div>
            </>
          ) : null}
          {props.form.personal_company_type + '' === '2' ? (
            <>
              <FieldLabel text="決算月" />
              <div className="field-area">
                <FieldSelect
                  name="final_account_month"
                  items={FiscalMonth}
                  selectedValue={props.form.final_account_month}
                  changedSelect={props.updateField}
                />
              </div>
            </>
          ) : null}

          <FieldLabel text="現在の賃料" />
          <div className="field-area">
            <FieldInput
              name="months_rent"
              value={props.form.months_rent || ''}
              onChange={props.updateField}
              unit_text="万円（月額）"
              placeholder="300"
            />
          </div>

          <FieldLabel
            text="BM費"
            help={true}
            help_text="BM費＝運営費用 建物管理費"
            require={false}
          />
          <div className="field-area">
            <FieldInput
              name="months_management_fees"
              value={props.form.months_management_fees || ''}
              onChange={props.updateField}
              unit_text="万円（月額）"
              placeholder="5"
            />
          </div>
        </RegistrationForm>
      </PanelForm>
    </FormTwo>
  )
}

const FiscalMonth: ISelectItem[] = toArray(0, 12).map((_, index) => {
  return {
    label: `${index + 1}月`,
    key: `${index}`,
    value: index + 1,
  }
})

export const SectionRegistrationTwoSPFooter: React.FC<
  IRegistrationStepTwoProps & {
    onClickNextStep: any
    onBackStep: (current: number) => any
  }
> = (props) => {
  const [isHelpOpen, changeHelpOpen] = useState(false)
  const toggleHelp = useCallback(() => {
    changeHelpOpen((v) => !v)
  }, [changeHelpOpen])

  return (
    <SpBox>
      <SpButton className="large" onClick={props.onClickNextStep}>
        <span className="btn-number">3</span>
        <span className="btn-text">
          {props.form.property_type === '1' ? '区分' : '一棟'}
          マンション専用項目
        </span>
      </SpButton>
      <HelpButton onClick={toggleHelp}>
        {props.form.property_type === '1' ? '区分' : '一棟'}
        マンションではありませんか？
      </HelpButton>

      <BaseModal className={isHelpOpen ? 'open' : ''}>
        <div className="modal-box">
          <div
            className="modal-close-top"
            onClick={() => changeHelpOpen(!isHelpOpen)}
          />
          <div className="modal-body">
            <h2 className="modal-header question">
              ご登録中の物件は
              {props.form.property_type === '1' ? '区分' : '一棟'}
              マンションではありませんか？
            </h2>
            <div className="modal-area">
              <Grid>
                <p>
                  表示されている物件タイプ（一棟・区分）がご登録中の物件と異なる場合、
                  <b className="bold">物件情報入力ページ</b>の[
                  <b className="bold">物件のタイプ</b>
                  ]を編集して変更できます。
                </p>
                <GrayButton
                  onClick={() => props.onBackStep(2)}
                  height={45}
                  color={colors.base}
                >
                  物件タイプを編集
                </GrayButton>
                <span
                  className="modal-cancel"
                  onClick={() => changeHelpOpen(!isHelpOpen)}
                >
                  キャンセル
                </span>
              </Grid>
            </div>
            {/* <h3 className="modal-more-title">こちらもご覧ください</h3>
            <div className="modal-area">
              <Grid>
                <MainButton height={45}>操作マニュアル</MainButton>
                <HelpPanel>
                  <HelpTitle>この問題は解決しましたか？</HelpTitle>
                  <HelpText>
                    問題が解決しない場合、
                    <a className="help-link" href="#todo-contact">
                      カスタマーサポート
                    </a>
                    よりお問い合わせください。
                  </HelpText>
                </HelpPanel>
              </Grid>
            </div> */}
          </div>
          <div
            onClick={() => changeHelpOpen(!isHelpOpen)}
            className="modal-close-bottom"
          >
            閉じる
          </div>
        </div>
      </BaseModal>
    </SpBox>
  )
}

export default RegistrationStepTwo
