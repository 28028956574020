import React from 'react'
import { isMobileOnly } from 'react-device-detect'
import cn from 'classnames'
import PriceTable, { PriceTableProps } from './PriceTableBase'
import { separateComma } from 'helper/number'

export const PriceTableMobile: React.FC<PriceTableProps> = (
  props: PriceTableProps
) => {
  if (!isMobileOnly) {
    return null
  }

  const { page, sold_year, datas } = props
  // valuesから表示するデータをsliceする
  const useDatas = datas.slice((page - 1) * 10, (page - 1) * 10 + 10)
  const separateElms = useDatas.map((data) => {
    const className = cn({ active: data.year === sold_year })
    return (
      <tr key={data.year}>
        <th className={className}>{data.year}年目</th>
        <td className={className}>¥{separateComma(data.value)}</td>
      </tr>
    )
  })
  return (
    <PriceTable>
      <tbody>{separateElms}</tbody>
    </PriceTable>
  )
}

export default PriceTableMobile
