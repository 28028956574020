import { EstateJSON } from 'domain/estate'
import { timestampToText } from 'helper/date'
import {
  buildingStructureToText,
  LayoutStructureToText,
  personalCompanyTypeToText,
  propertyTypeToText,
  tradeTypeToText,
} from 'helper/types'
import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { separateComma } from '../../../helper/number'
import { GrayButton, MainButton } from '../../Symbols/Button'
import Panel from '../Layout/Panel'
import PanelTitle from '../Layout/PanelTitle'
import SpBox from '../Layout/SpBox'
import InfoItem from './InfoItem'
import Label from './Label'

const InfoList = styled.div`
  display: grid;
  grid-gap: 15px;
  @media screen and (max-width: 800px) {
    grid-gap: 10px;
  }

  .grid-3 {
    display: grid;
    grid-gap: 15px;
    @media screen and (min-width: 801px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media screen and (max-width: 800px) {
      grid-gap: 10px;
    }
  }

  p {
    line-height: 2;
    padding: 10px 0;
    @media screen and (max-width: 800px) {
      font-size: 15px;
      padding: 0;
    }
  }
`

const SpLinkButton = GrayButton.withComponent(Link)
SpLinkButton.defaultProps = { ...SpLinkButton.defaultProps, height: 45 }

// ラベルサイズを150pxにしたバージョン
const MinInfoItem = styled(InfoItem)``
MinInfoItem.defaultProps = {
  ...MinInfoItem.defaultProps,
  column: ['150px', '1fr'],
}
MinInfoItem.displayName = 'MinInfoItem'

interface IProps {
  estate: EstateJSON
  ChangeResultPage: any
}

export const Information: React.FC<IProps> = memo(
  ({ estate, ChangeResultPage }) => {
    const propertyTypeSection = (propertyType: number) => {
      if (propertyType === 1) {
        return (
          <>
            <Panel>
              <PanelTitle
                spDesign="bar"
                icon={3}
                label="区分マンション専用項目"
              />
              <InfoList>
                <div className="grid-3">
                  <MinInfoItem>
                    <Label text="部屋番号" />
                    <p>{estate.room_number}</p>
                  </MinInfoItem>
                  <MinInfoItem>
                    <Label text="所在階" />
                    <p>{estate.floor_located}階</p>
                  </MinInfoItem>
                  <MinInfoItem>
                    <Label text="専有面積" />
                    <p>
                      {estate.occupation_area === null
                        ? '-'
                        : `${Math.round(Number(estate.occupation_area))}㎡`}
                    </p>
                  </MinInfoItem>
                </div>

                <MinInfoItem>
                  <Label text="間取り" />
                  <p>
                    {estate.layout_rooms +
                      LayoutStructureToText(estate.layout_structure)}
                  </p>
                </MinInfoItem>
              </InfoList>
            </Panel>
            <SpBox>
              <SpLinkButton
                fontSize="15px"
                to={`/simulation/${estate._id}/#step3`}
              >
                <span className="icon">3</span>
                区分マンション専用項目を編集
              </SpLinkButton>
            </SpBox>
          </>
        )
      } else {
        return (
          <>
            <Panel>
              <PanelTitle
                spDesign="bar"
                icon={3}
                label="一棟マンション専用項目"
              />
              <InfoList>
                <MinInfoItem>
                  <Label text="延床面積" />
                  <p>
                    {estate.property_type === 1
                      ? Math.round(estate.occupation_area)
                      : Math.round(
                          estate.occupation_area * estate.number_of_houses
                        )}
                    ㎡
                  </p>
                </MinInfoItem>
              </InfoList>
            </Panel>

            <SpBox>
              <SpLinkButton
                fontSize="15px"
                to={`/simulation/${estate._id}/#step3`}
              >
                <span className="icon">3</span>
                一棟マンション専用項目を編集
              </SpLinkButton>
            </SpBox>
          </>
        )
      }
    }

    return (
      <div>
        <Panel>
          <PanelTitle spDesign="bar" icon={1} label="物件の基本情報" />
          <InfoList>
            <div className="grid-3">
              <MinInfoItem>
                <Label text="取引のタイプ" />
                <p>{tradeTypeToText(estate.trade_type)}</p>
              </MinInfoItem>
              <MinInfoItem>
                <Label text="物件のタイプ" />
                <p>{propertyTypeToText(estate.property_type)}</p>
              </MinInfoItem>
            </div>
            <MinInfoItem>
              <Label text="物件名" />
              <p>{estate.estate_name}</p>
            </MinInfoItem>
            <MinInfoItem>
              <Label text="住所・所在地" />
              <p>{estate.address}</p>
            </MinInfoItem>
            <MinInfoItem>
              <Label text="最寄駅" />
              <p>
                {estate.railways[0].line_name} {estate.railways[0].station_name}
                駅 徒歩{estate.railways[0].walking_minutes}分
              </p>
            </MinInfoItem>
          </InfoList>
        </Panel>

        <SpBox>
          <SpLinkButton fontSize="15px" to={`/simulation/${estate._id}/#step1`}>
            <span className="icon">1</span>
            物件の基本情報を編集
          </SpLinkButton>
        </SpBox>

        <Panel>
          <PanelTitle spDesign="bar" icon={2} label="物件の基本情報" />
          <InfoList>
            <div className="grid-3">
              <MinInfoItem>
                <Label text="建物構造" />
                <p>{buildingStructureToText(estate.building_structure)}造</p>
              </MinInfoItem>
              <MinInfoItem>
                <Label text="築年月" />
                <p>{timestampToText(estate.built_date.$date)}</p>
              </MinInfoItem>
              <MinInfoItem>
                <Label text="総戸数" />
                <p>{estate.number_of_houses}戸</p>
              </MinInfoItem>
            </div>
            <div className="grid-3">
              <MinInfoItem>
                <Label text="階数" />
                <p>{estate.number_of_floors}階建</p>
              </MinInfoItem>
              <MinInfoItem>
                <Label text="価格設定" />
                <p>{separateComma(estate.expected_selling_price)}万円</p>
              </MinInfoItem>
              <MinInfoItem>
                <Label text="個人・法人区分" />
                <p>{personalCompanyTypeToText(estate.personal_company_type)}</p>
              </MinInfoItem>
            </div>
            <div className="grid-3">
              <MinInfoItem>
                <Label text="購入年月" />
                <p>{timestampToText(estate.purchased_date.$date)}</p>
              </MinInfoItem>
              <MinInfoItem>
                <Label text="その他収入" />
                <p>
                  {estate.personal_company_type === 1
                    ? `${separateComma(estate.other_income)}万円（年額）`
                    : '-'}
                </p>
              </MinInfoItem>
              <MinInfoItem>
                <Label text="現在の賃料" />
                <p>{estate.months_rent}万円（月額）</p>
              </MinInfoItem>
              <MinInfoItem>
                <Label text="BM費" />
                <p>{estate.months_management_fees}万円（月額）</p>
              </MinInfoItem>
            </div>
          </InfoList>
        </Panel>

        <SpBox>
          <SpLinkButton fontSize="15px" to={`/simulation/${estate._id}/#step2`}>
            <span className="icon">2</span>
            物件の基本情報を編集
          </SpLinkButton>
        </SpBox>

        {propertyTypeSection(estate.property_type)}

        <SpBox>
          <MainButton
            onClick={() => ChangeResultPage(2)}
            height={45}
            fontSize="15px"
          >
            投資プラン
          </MainButton>
        </SpBox>
      </div>
    )
  }
)
