import React from 'react'
import * as api from '../apis'
import LoadView from './Load'

const SsoCallbackComponent: React.FC<any> = () => {
  api.ssoLogin(window.location.search).then((response) => {
    window.location.href = '/'
  })
  return (
    <div>
      <LoadView isView={true} />
    </div>
  )
}

export default SsoCallbackComponent
