import React, { useEffect, useState } from 'react'
import colors from 'style/colors'
import styled from 'styled-components'
import { DashboardActions } from '../containers/DashboardContainer'
import { AddressState } from '../reducers/AddressReducer'
import { DashboardState } from '../reducers/DashboardReducer'
import { EstateState } from '../reducers/EstateReducer'
import NewProperty from './Dashboard/NewProperty'
import Property from './Dashboard/PropertyComponent'
import PageTitle from './PageTitle'
import SectionTitle from './Simulation/SectionTitle'
import NewRegistrationModal from './Dashboard/NewRegistrationModal'
import { PortfolioSummary } from './Dashboard/Portfolio/PortfolioSummary'
import { PortfolioGroups } from './Dashboard/Portfolio/PortfolioGroups'
import { GrayButton } from './Symbols/Button'
import { isMobileOnly } from 'react-device-detect'
import SpBox from './Simulation/Layout/SpBox'
import { PlanManagerSP } from './Dashboard/Portfolio/PlanManager/PlanManagerSP'
import { derivedPlanGroups } from 'domain/portfolio'
import { ScrollAnchor } from './ScrollAnchor'

interface OwnProps {}

type DashboardProps = OwnProps &
  DashboardState &
  AddressState &
  EstateState &
  DashboardActions

const Dashboard = styled.div`
  background: ${colors.blue_bg_light};
  @media screen and (min-width: 801px) {
    padding: 30px;
  }
  .dashbord-content {
    margin: 0 auto;
    width: 100%;
    max-width: 1152px;
    display: grid;
    grid-gap: 20px;
  }
  .dashbord-area {
    background: white;
    border-radius: 5px;
    &.news {
      position: relative;
    }
  }
  .panel-body {
    @media screen and (min-width: 801px) {
      padding: 20px 30px;
    }
    @media screen and (max-width: 800px) {
      padding: 15px 0 0;
    }
  }
  .simulation-panels {
    @media screen and (max-width: 800px) {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .border {
    border-color: ${colors.border_gray2};
    margin: -5px 0 10px;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
`

type ActionMethods = Pick<
  DashboardActions,
  | 'deleteSearchModal'
  | 'setPlanFromSearchResult'
  | 'setEstateDraft'
  | 'registerNewEstate'
  | 'searchEstate'
  | 'deleteEstate'
  | 'getCities'
  | 'getAddressFromZip'
  | 'getUserPlan'
  | 'getUserPlanGroups'
  | 'createUserPlanGroups'
  | 'deletePlanFromPlanGroup'
>

export const DashboardComponent: React.FC<DashboardProps & ActionMethods> = ({
  requestPortfolio,
  portfolio,
  planGroups,
  searchResult,
  deleteSearchModal,
  setPlanFromSearchResult,
  setEstateDraft,
  registerNewEstate,
  searchEstate,
  deleteEstate,
  getCities,
  getAddressFromZip,
  getUserPlan,
  userPlans,
  getUserPlanGroups,
  createUserPlanGroups,
  deletePlanFromPlanGroup,
  ...props
}: DashboardProps) => {
  const [showTradeType, setShowTradeType] = useState(0)
  const [isOpenPMPC, setIsOpenPMPC] = useState(false)

  useEffect(() => {
    if (!portfolio) {
      requestPortfolio()
    }
  }, [portfolio, requestPortfolio])

  // for debug
  useEffect(() => {
    getUserPlan()
  }, [getUserPlan])

  useEffect(() => {
    getUserPlanGroups()
  }, [getUserPlanGroups])

  const portfolioValues = planGroups ? derivedPlanGroups(planGroups) : undefined

  const scrollToForm = (scrollDiff: number) => {
    const registrationForm = document.querySelector('#registrationForm')
    window.scroll({
      top:
        (registrationForm &&
          registrationForm.getClientRects()[0].top +
            scrollDiff +
            window.pageYOffset) ||
        0,
      behavior: 'smooth',
    })
  }
  const handleScrollForm = () => {
    scrollToForm(-50)
  }
  const handleScrollList = () => {
    scrollToForm(200)
  }

  return (
    <Dashboard>
      <NewRegistrationModal
        data={searchResult.data}
        isShow={searchResult.isShow}
        keyword={searchResult.keyword}
        deleteSearchModal={deleteSearchModal}
        setPlanFromSearchResult={setPlanFromSearchResult}
        registerNewEstate={registerNewEstate}
        estate={props.editingEstate}
        setEstateDraft={setEstateDraft}
      />
      <PageTitle title="ダッシュボード" />
      <div className="dashbord-content">
        <div className="dashbord-area news">
          <ScrollAnchor className="scroll-anchor">
            <p className="scroll-anchor-head">
              <span>物件登録</span>
              <img
                className="scroll-anchor-image"
                src="/img/common/building-white.svg"
                alt="物件登録イメージ"
              />
            </p>

            <div className="scroll-anchor-body">
              <div className="scroll-anchor-body-row">
                <p
                  onClick={handleScrollForm}
                  className="scroll-anchor-body-button"
                >
                  物件情報を入力
                </p>
                <small>※仮称でも可</small>
              </div>
              <div className="scroll-anchor-body-row">
                <p
                  onClick={handleScrollList}
                  className="scroll-anchor-body-button_gray"
                >
                  登録物件一覧
                </p>
              </div>
            </div>
          </ScrollAnchor>
        </div>
        <div className="dashbord-area portfolio">
          <SectionTitle label="資産ポートフォリオ" className="dark" />
          <div className="panel-body">
            <PortfolioSummary
              planGroups={planGroups}
              portfolioValues={portfolioValues}
            />
          </div>
          <PortfolioGroups
            portfolio={portfolio}
            userPlans={userPlans}
            planGroups={planGroups}
            createUserPlanGroups={createUserPlanGroups}
            deletePlanFromPlanGroup={deletePlanFromPlanGroup}
          />
        </div>

        {isMobileOnly && (
          <SpBox padding="0 15px">
            <GrayButton
              onClick={() => setIsOpenPMPC(true)}
              fontSize={'15px'}
              color={colors.base}
              height={45}
            >
              投資プラングループの管理
            </GrayButton>
            {isOpenPMPC && (
              <PlanManagerSP
                portfolio={portfolio}
                userPlans={userPlans}
                planGroups={planGroups}
                createUserPlanGroups={createUserPlanGroups}
                deletePlanFromPlanGroup={deletePlanFromPlanGroup}
                closePMPC={() => setIsOpenPMPC(false)}
              />
            )}
          </SpBox>
        )}

        <div id="registrationForm" className="dashbord-area simulation">
          <SectionTitle label="投資プラン シミュレーション" className="dark" />
          <div className="simulation-panels">
            <div className="panel-body">
              <NewProperty
                estate={props.editingEstate}
                setEstateDraft={setEstateDraft}
                registerNewEstate={registerNewEstate}
                searchEstate={searchEstate}
              />
            </div>
            <hr className="border" />
            <div className="panel-body">
              {portfolio && (
                <Property
                  estates={portfolio.estates}
                  deleteEstate={deleteEstate}
                  showTradeType={showTradeType}
                  setShowTradeType={setShowTradeType}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Dashboard>
  )
}
