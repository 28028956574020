import cn from 'classnames'
import { DashboardActions } from 'containers/DashboardContainer'
import React, { FormEventHandler, useCallback, useState } from 'react'
import { EstateForm } from 'reducers/EstateReducer'
import colors from 'style/colors'
import styled from 'styled-components'
import Comment from '../Simulation/Field/Comment'
import FieldInput from '../Simulation/Field/Input'
import FieldLabel from '../Simulation/Field/Label'
import FieldRadio from '../Simulation/Field/Radio'
import Form from '../Simulation/Registration/Form'

const NewPropertyElemDummy = styled.div`
  @media screen and (max-width: 800px) {
    height: 50px;
  }
`

const NewPropertyElemBG = styled.div`
  display: none;
  &.open {
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 11;
    top: 0;
    left: 0;
    background: rgba(237, 239, 245, 0.85);
  }
`

const NewPropertyElem = styled.div`
  background: white;
  @media screen and (min-width: 801px) {
    padding: 15px;
  }
  @media screen and (max-width: 800px) {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 10;
    &.open {
      z-index: 12;
    }
  }
  h2 {
    color: white;
    height: 60px;
    line-height: 60px;
    padding: 0 30px;
    background: #4f5e9c;
    @media screen and (min-width: 801px) {
      border-radius: 5px 5px 0 0;
      font-size: 20px;
    }
    @media screen and (max-width: 800px) {
      margin: 0;
      height: 50px;
      line-height: 50px;
      font-size: 15px;
      text-align: center;
      background: #4f5e9c url('/img/common/ic_arrow_top_white.svg')
        calc(100% - 15px) center / 15px 15px no-repeat;
      &.open {
        background: #4f5e9c url('/img/common/ic_arrow_white.svg')
          calc(100% - 15px) center / 15px 15px no-repeat;
      }
    }
  }
  .card-title {
    text-align: center;
    margin-bottom: 20px;
    line-height: 1.3;
  }
  .bord {
    padding: 15px 30px;
    background: ${colors.bg_gray};
    border: 1px solid ${colors.border_gray2};
    @media screen and (max-width: 800px) {
      padding: 0 0 15px;
      background: white;
      display: none;
      .field-area {
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        padding: 0 20px;
        box-sizing: border-box;
      }
      &.open {
        display: block;
      }
    }
  }
  .card-button-wrap {
    @media screen and (min-width: 801px) {
      margin: 10px auto 0 auto;
      padding-top: 5px;
      display: flex;
      width: 400px;
    }
    @media screen and (max-width: 800px) {
      margin-top: 20px;
      padding: 0 20px;
    }
  }
`

const RegistrationLink = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  border-radius: 3px;
  font-weight: bold;
  color: white;
  background: ${colors.priority};
  height: 48px;
  line-height: 1.2;
  text-align: left;
`

const ShowModalLink = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-right: 10px;
  @media screen and (max-width: 800px) {
    margin-bottom: 20px;
  }
  border-radius: 3px;
  font-weight: bold;
  color: ${colors.black_middle};
  background: ${colors.bg_gray};
  height: 48px;
  line-height: 1.2;

  p {
    width: 200px;
  }

  i {
    border: solid ${colors.black_middle};
    border-width: 0 1px 1px 0;
    display: inline-block;
    margin-left: 5px;
    padding: 2px;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
`

type actionMethods = Pick<
  DashboardActions,
  'registerNewEstate' | 'setEstateDraft' | 'searchEstate'
>

const NewProperty: React.FC<
  {
    estate: EstateForm
  } & actionMethods
> = ({ registerNewEstate, setEstateDraft, estate, searchEstate, ...props }) => {
  // NOTE: ここでconnectしたほうがいい
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleRegistrationClick = useCallback(() => {
    registerNewEstate(estate)
  }, [estate, registerNewEstate])

  const handleShowModalClick = useCallback(() => {
    searchEstate(estate)
  }, [estate, searchEstate])

  const toggleOpen = useCallback(() => {
    setIsOpen((v) => !v)
  }, [setIsOpen])

  const handleChange = useCallback<FormEventHandler<HTMLInputElement>>(
    (e) => {
      setEstateDraft({ [e.currentTarget.name]: e.currentTarget.value })
    },
    [setEstateDraft]
  )

  return (
    <>
      <NewPropertyElemDummy />
      <NewPropertyElemBG
        className={cn({ open: isOpen })}
        onClick={toggleOpen}
      />
      <NewPropertyElem className={cn({ open: isOpen })}>
        <h2 className={cn({ open: isOpen })} onClick={toggleOpen}>
          新しい物件を追加
        </h2>
        <div className={cn('bord', { open: isOpen })}>
          <Form>
            <FieldLabel text="物件名" margin_minus={false} />
            <div className="field-area">
              <FieldInput
                name="estate_name"
                onChange={handleChange}
                type="text"
                width={520}
                placeholder="渋谷美竹ハイム"
              />
              <Comment comment="新築の場合「渋谷区渋谷２丁目PJ」などの仮称でも可" />
            </div>

            <FieldLabel text="取引のタイプ" />
            <div className="field-area">
              <FieldRadio
                name="trade_type"
                items={[
                  { key: '1', label: '購入', value: '1' },
                  { key: '2', label: '売却', value: '2' },
                  { key: '3', label: '保有', value: '3' },
                ]}
                onChange={handleChange}
                value={String(estate.trade_type)}
              />
              <Comment comment="「購入」選択時は資産ポートフォリオ、投資プランシミュレーション共に現時点に購入したと暫定し、購入時から売却までの期間を想定してローンや減価償却の計算が行われます。" />
            </div>

            <FieldLabel text="物件のタイプ" />
            <div className="field-area">
              <FieldRadio
                name="property_type"
                items={[
                  { key: '2', label: '一棟', value: '2' },
                  { key: '1', label: '区分', value: '1' },
                ]}
                onChange={handleChange}
                value={String(estate.property_type)}
              />
            </div>

            <FieldLabel text="価格設定" />
            <div className="field-area">
              <FieldInput
                name="expected_selling_price"
                onChange={handleChange}
                type="number"
                unit_text="万円"
                width={250}
                placeholder="600000"
                value={String(estate.expected_selling_price)}
              />
              <Comment comment="販売価格または希望価格を入力してください。" />
            </div>
          </Form>

          <div className="card-button-wrap">
            <ShowModalLink onClick={handleShowModalClick}>
              <p>物件名から絞込む</p>
            </ShowModalLink>
            <RegistrationLink onClick={handleRegistrationClick}>
              登 録
            </RegistrationLink>
          </div>
        </div>
      </NewPropertyElem>
    </>
  )
}

export default NewProperty
