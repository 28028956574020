import styled from 'styled-components'

type SpBoxProps = {
  padding?: string
}

export const SpBox = styled.div<SpBoxProps>`
  display: none;
  @media screen and (max-width: 800px) {
    display: block;
    padding: ${(props) => props.padding || '15px'};
  }
`

export default SpBox
