import { NanToZero, separateComma } from 'helper/number'
import React, { useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'
import colors from 'style/colors'
import {
  CustomLegendElm,
  CustomToolTip,
  GraphCommonProps,
  renderAreaTick,
  renderMoneyTick,
  renderYearTick,
  SimulationButtons,
  SimulationGraphElm,
  TickProps,
} from './Simulate'

export interface DepreciationDataProps {
  year: number
  other_income: number
  noi: number
  depreciation: number
  tax_income: number
  interest: number
}
export interface IncomeDatasProps {
  data: DepreciationDataProps[]
}

type GraphProps = IncomeDatasProps & GraphCommonProps

export class DepreciationGraph extends React.Component<GraphProps> {
  render() {
    const page = this.props.page ? this.props.page : 1
    const depreciationData = isMobileOnly
      ? this.props.data.slice((page - 1) * 10, page * 10)
      : this.props.data
    const { roanPeriod, depreciationPeriod } = this.props

    const toolTipContent = (tooltipProps: TooltipProps) => {
      const { active, payload, label } = tooltipProps
      if (active && payload) {
        return (
          <CustomToolTip className="custom-tooltip">
            <p className="tooltip-title">{label}年後</p>
            <dl className="tooltip-item flex-item">
              <dt>NOI</dt>
              <dd>{separateComma(NanToZero(payload[0].payload.noi))}</dd>
            </dl>
            {this.props.personal_company_type === 1 &&
              this.props.include_other_income && (
                <dl className="tooltip-item flex-item">
                  <dt>その他所得</dt>
                  <dd>
                    {separateComma(NanToZero(payload[0].payload.other_income))}
                  </dd>
                </dl>
              )}
            <dl className="tooltip-item flex-item">
              <dt>減価償却費</dt>
              <dd>
                {separateComma(NanToZero(payload[0].payload.depreciation))}
              </dd>
            </dl>
            <dl className="tooltip-item flex-item">
              <dt>利息</dt>
              <dd>{separateComma(NanToZero(payload[0].payload.interest))}</dd>
            </dl>
            <hr />
            <dl className="tooltip-item flex-item">
              <dt>課税所得</dt>
              <dd>{separateComma(NanToZero(payload[0].payload.tax_income))}</dd>
            </dl>
          </CustomToolTip>
        )
      }
    }

    const CustomRenderYearTick = (tickProps: TickProps) =>
      renderYearTick(tickProps)

    const renderDepreciationTick = (tickProps: TickProps) => {
      return renderAreaTick(
        tickProps,
        'depreciation',
        depreciationPeriod,
        this.props.data.length
      )
    }
    const renderRoanTick = (tickProps: TickProps) => {
      return renderAreaTick(
        tickProps,
        'roan',
        roanPeriod,
        this.props.data.length
      )
    }

    const CustomLegend = () => {
      return (
        <CustomLegendElm>
          <ul>
            <li className="rect-icon icon-blue-thin">NOI(営業純利益)</li>
            {this.props.personal_company_type === 1 &&
              this.props.include_other_income && (
                <li className="rect-icon icon-blue">その他所得</li>
              )}
            <li className="rect-icon icon-orange">減価償却</li>
            <li className="rect-icon icon-gray">利息</li>
            <li className="circle-icon icon-yellow">課税所得</li>
          </ul>
        </CustomLegendElm>
      )
    }

    return (
      <SimulationGraphElm>
        <ResponsiveContainer>
          <ComposedChart
            data={depreciationData}
            stackOffset="sign"
            margin={{
              top: 80,
              right: 20,
              bottom: 30,
              left: 20,
            }}
          >
            {/* 減価償却期間 */}
            <XAxis
              dataKey="year"
              orientation="top"
              axisLine={false}
              tickLine={false}
              interval={0}
              tick={renderRoanTick}
              height={1}
              scale="band"
              xAxisId="quartera"
            />
            <XAxis
              dataKey="year"
              orientation="top"
              axisLine={false}
              tickLine={false}
              interval={0}
              tick={renderDepreciationTick}
              height={1}
              scale="band"
              xAxisId="quarter"
            />
            <XAxis
              dataKey="year"
              tick={CustomRenderYearTick}
              tickLine={false}
              axisLine={false}
            />
            <CartesianGrid
              stroke="#d8d8d8"
              strokeWidth={1.5}
              vertical={false}
            />
            <YAxis tickLine={false} tick={renderMoneyTick} axisLine={false} />
            <Tooltip content={toolTipContent} />
            <Legend
              wrapperStyle={{ bottom: 0, fontWeight: 'bold' }}
              iconSize={12}
              content={CustomLegend}
            />

            {this.props.personal_company_type === 1 &&
              this.props.include_other_income && (
                <Bar
                  name="その他所得"
                  dataKey="other_income"
                  stackId="a"
                  barSize={10}
                  fill={colors.simulate_blue}
                />
              )}

            <Bar
              name="NOI(営業純利益)"
              dataKey="noi"
              stackId="a"
              barSize={10}
              fill={colors.simulate_blue_thin}
            />

            <ReferenceLine y={0} strokeWidth={2.5} />
            <Bar
              name="減価償却"
              dataKey="depreciation"
              stackId="a"
              fill={colors.simulate_orange}
            />
            <Bar
              name="利息"
              dataKey="interest"
              stackId="a"
              fill={colors.simulate_gray}
            />
            <Line
              name="課税所得"
              dataKey="tax_income"
              strokeWidth={2}
              stroke={colors.simulate_orange_thin}
              isAnimationActive={false}
              dot={({ dataKey: _, ...props }: any) => (
                <circle
                  {...props}
                  r={6}
                  fill={colors.simutate_yellow}
                  stroke="0"
                />
              )}
              activeDot={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </SimulationGraphElm>
    )
  }
}

export const DepreciationGraphWrapper: React.FC<GraphProps> = (
  props: GraphProps
) => {
  const dataLength = props.data.length
  const [page, setPage] = useState(1)
  return (
    <>
      <DepreciationGraph {...props} page={page} />
      <SimulationButtons
        page={page}
        up={() => setPage(page + 1)}
        down={() => setPage(page - 1)}
        dataLength={dataLength}
      />
    </>
  )
}

export default DepreciationGraphWrapper
