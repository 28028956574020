import React, { useCallback } from 'react'
import styled from 'styled-components'
import Button, { PageButtonElem } from '../Field/PageButton'
import colors from 'style/colors'
import { Link } from 'react-router-dom'

export interface IStepProps {
  onBackStep: (current: number) => any
  current: number
  steps: number
}

const PropertyStep = styled.div`
  display: none;
  @media screen and (max-width: 800px) {
    position: relative;
    padding: 0 15px 10px;
    display: flex;
    align-items: center;
    justify-content: left;
    p {
      position: absolute;
      width: 100px;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      font-size: 10px;
      font-weight: bold;
      color: ${colors.main};
    }
  }
`

const PageLink = PageButtonElem.withComponent(Link)

const Step: React.FC<IStepProps> = ({ current, onBackStep, ...props }) => {
  const hasPrevious = current >= 2
  const onClickBackButton = useCallback(() => {
    onBackStep(current)
  }, [current, onBackStep])
  return (
    <PropertyStep>
      {hasPrevious ? (
        <Button className="back" onClick={onClickBackButton}>
          <span className="blue">前のページ</span>
        </Button>
      ) : (
        <PageLink to="/" className="back" onClick={onClickBackButton}>
          <span className="blue">トップページ</span>
        </PageLink>
      )}
      <p>
        {Math.min(current, props.steps)} / {props.steps} ページ
      </p>
    </PropertyStep>
  )
}

export const StepBox: React.FC<{
  current: number
  visibleRange: number[]
}> = (props) => {
  return props.visibleRange.includes(props.current) ? (
    <>{props.children}</>
  ) : null
}

export default Step
