import React from 'react'
import styled from 'styled-components'
import { isMobileOnly } from 'react-device-detect'

type PortfolioGraphBoxProps = {
  title: string
}

const PortfolioGraphBoxElem = styled.div`
  padding: 0 5px;
  border: 1px solid #e4e4e4;
  background: white;
  &.mobile {
    padding: 10px 8px 10px;
    border: 1px solid #e4e4e4;
  }

  .title {
    height: 26px;
    line-height: 26px;
    width: 160px;
    margin: 15px auto 15px;
    border-radius: 20px;
    background: #4f5e9c;
    text-align: center;
    font-size: 16px;
    color: white;
    font-weight: bold;
  }
`

export const PortfolioGraphBox: React.FC<PortfolioGraphBoxProps> = (props) => {
  return (
    <PortfolioGraphBoxElem className={isMobileOnly ? 'mobile' : ''}>
      {!isMobileOnly && <div className="title">{props.title}</div>}
      {props.children}
    </PortfolioGraphBoxElem>
  )
}
