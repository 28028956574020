import { GrayButton } from 'components/Symbols/Button'
import { BaseModal } from 'components/Symbols/Modal'
import { DashboardActions } from 'containers/DashboardContainer'
import { PlanGroups, UserPlan } from 'domain/plan'
import React, { useState, useEffect } from 'react'
import { EstateState } from 'reducers/EstateReducer'
import colors from 'style/colors'
import styled from 'styled-components'
import { PlanAddModal } from './PlanAddModal'
import { PlanTable } from './PlanTable'

type PlanManagerPC = {
  isOpen: boolean
  onClosePMPC: () => void
} & Pick<DashboardActions, 'createUserPlanGroups'> &
  Pick<DashboardActions, 'deletePlanFromPlanGroup'> &
  Pick<EstateState, 'userPlans'> & {
    estates: any
  } & {
    planGroups: PlanGroups
  }

type checkListItemType = {
  group_check: boolean
  plans_check: { plan_id: number; checked: boolean }[]
}

type checkListType = {
  [key: number]: checkListItemType
}

// checkListのstateの初期設定
const initialCheck = (plans: UserPlan[] | undefined) => {
  if (!plans) return []
  return plans.map((plan) => {
    return {
      plan_id: plan._id,
      checked: false,
    }
  })
}

export const PlanManagerPC: React.FC<PlanManagerPC> = (props) => {
  const [checkList, setCheckList] = useState<checkListType | null>()

  useEffect(() => {
    setCheckList({
      1: {
        group_check: false,
        plans_check: initialCheck(props.planGroups['1'].plans),
      },
      2: {
        group_check: false,
        plans_check: initialCheck(props.planGroups['2'].plans),
      },
      3: {
        group_check: false,
        plans_check: initialCheck(props.planGroups['3'].plans),
      },
    })
  }, [props.planGroups])

  if (!checkList) return null

  // グループと各プランのチェックから、消してもいいプランの配列を返す。
  const checkDeleteArray = (checkList: checkListItemType) => {
    return checkList.plans_check
      .filter((plan) => plan.checked)
      .map((plan) => plan.plan_id)
  }
  // チェックがtrueなプランを削除する。groupIndexがある場合はそのgroupだけ削除する。
  const DeleteCheckedPlans = (groupIndex?: number) => {
    if (!window.confirm('選択した投資プランをグループから削除しますか？'))
      return
    props.deletePlanFromPlanGroup({
      1: !groupIndex || groupIndex === 1 ? checkDeleteArray(checkList[1]) : [],
      2: !groupIndex || groupIndex === 2 ? checkDeleteArray(checkList[2]) : [],
      3: !groupIndex || groupIndex === 3 ? checkDeleteArray(checkList[3]) : [],
    })
    window.alert('投資プランをグループから削除しました。')
  }

  // グループまとめてチェックを切り替える
  const toggleGroupCheck = (groupIndex: number) => {
    const newCheckList = { ...checkList }
    newCheckList[groupIndex].group_check = !newCheckList[groupIndex].group_check
    newCheckList[groupIndex].plans_check = newCheckList[
      groupIndex
    ].plans_check.map((plan) => {
      return {
        ...plan,
        checked: newCheckList[groupIndex].group_check,
      }
    })
    setCheckList(newCheckList)
  }

  // プランごとにチェックを切り替える
  const toggleCheck = (groupIndex: number, planIndex: number) => {
    const newCheckList = { ...checkList }
    newCheckList[groupIndex].plans_check[planIndex].checked = !newCheckList[
      groupIndex
    ].plans_check[planIndex].checked
    setCheckList(newCheckList)
  }

  return (
    <BaseModal className={props.isOpen ? 'open' : ''}>
      <div className="modal-box">
        <div className="modal-close-top" onClick={props.onClosePMPC}></div>
        <div className="modal-body">
          <div className="modal-header large">投資プラングループの管理</div>
          <div className="modal-panel">
            <PlanDeleteBar onDelete={DeleteCheckedPlans} />
            <PlanGroup
              title="投資プラングループ I"
              order={1}
              createUserPlanGroups={props.createUserPlanGroups}
              deleteCheckedPlans={DeleteCheckedPlans}
              toggleGroupCheck={toggleGroupCheck}
              toggleCheck={toggleCheck}
              checkList={checkList}
              onAdd={() => null}
              onDelete={() => null}
              plans={props.planGroups['1'].plans}
              estates={props.estates}
              userPlans={props.userPlans}
            />
            <PlanGroup
              title="投資プラングループ Ⅱ"
              order={2}
              createUserPlanGroups={props.createUserPlanGroups}
              deleteCheckedPlans={DeleteCheckedPlans}
              toggleGroupCheck={toggleGroupCheck}
              toggleCheck={toggleCheck}
              checkList={checkList}
              onAdd={() => null}
              onDelete={() => null}
              plans={props.planGroups['2'].plans}
              estates={props.estates}
              userPlans={props.userPlans}
            />
            <PlanGroup
              title="投資プラングループ Ⅲ"
              order={3}
              createUserPlanGroups={props.createUserPlanGroups}
              deleteCheckedPlans={DeleteCheckedPlans}
              toggleGroupCheck={toggleGroupCheck}
              toggleCheck={toggleCheck}
              checkList={checkList}
              onAdd={() => null}
              onDelete={() => null}
              plans={props.planGroups['3'].plans}
              estates={props.estates}
              userPlans={props.userPlans}
            />
            <PlanDeleteBar onDelete={DeleteCheckedPlans} />
          </div>
        </div>
        <div className="modal-close-bottom" onClick={props.onClosePMPC}>
          閉じる
        </div>
      </div>
    </BaseModal>
  )
}

const PlanDeleteBarElem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0 0;
  padding: 10px 5px;
  background: #edeff5;
  color: ${colors.base};
  font-size: 12px;
  p {
    margin-right: 5px;
  }
`

// プランの選択削除ボタン
type PlanDeleteBarProps = {
  onDelete: () => void
}
const PlanDeleteBar: React.FC<PlanDeleteBarProps> = (props) => {
  return (
    <PlanDeleteBarElem>
      <p>チェックした項目を</p>
      <GrayButton onClick={() => props.onDelete()} height={30} width={60}>
        削除
      </GrayButton>
    </PlanDeleteBarElem>
  )
}

// プラングループ
type PlanGroupProps = Pick<DashboardActions, 'createUserPlanGroups'> & {
  title: string
  onAdd: () => void
  onDelete: () => void
  deleteCheckedPlans: (groupIndex?: number) => void
  toggleGroupCheck: (groupIndex: number) => void
  toggleCheck: (groupIndex: number, planIndex: number) => void
  checkList: checkListType
  order: number
  plans: any // プランデータ
  estates: any
  userPlans: any
}

const PlanGroupHeaderElem = styled.div`
  display: grid;
  grid-template-columns: 190px 1fr 220px;
  align-items: center;
  margin: 20px 0 15px;
  .group-title {
    font-size: 18px;
    font-weight: bold;
    color: #4f5e9c;
  }
  p {
    font-size: 12px;
    color: #d0021b;
  }
`
const PlanGroupHeaderMenu = styled.div`
  margin-left: auto;
  display: grid;
  align-items: center;
  grid-template-columns: 75px 60px 60px;
  grid-gap: 10px;
  p {
    font-size: 12px;
    font-weight: bold;
    color: #4f5e9c;
  }
`

const PlanGroup: React.FC<PlanGroupProps> = (props) => {
  const [isOpenAddModal, setIsOpenAddModal] = useState(false)

  console.log(props.plans)
  if (!props.plans) {
    return null
  }
  const items = props.plans.map((p: any, index: number) => {
    return [
      props.checkList[props.order].plans_check[index] ? (
        <p onClick={() => props.toggleCheck(props.order, index)}>
          <PlanCheckBox
            isChecked={props.checkList[props.order].plans_check[index].checked}
          />
        </p>
      ) : null,
      p.params.estate.estate_name,
      p.params.label,
      p.params.inheritedValuationLand
        ? p.params.inheritedValuationLand + '万円'
        : '-',
      p.params.inheritedValuationBuild
        ? p.params.inheritedValuationBuild + '万円'
        : '-',
      p.params.priorities_when_selling + '年',
      p.params.own_resources + '万円',
      p.params.loan_usage_amount + '万円',
      p.params.borrowing_interest_rate + '%',
      p.params.borrowing_period + '年',
    ]
  })
  return (
    <>
      <PlanGroupHeaderElem>
        <p className="group-title">{props.title}</p>
        {!props.plans || props.plans.length === 0 ? (
          <p>投資プランが設定されていません</p>
        ) : (
          <p></p>
        )}
        <PlanGroupHeaderMenu>
          <p>投資プランを</p>
          <GrayButton
            onClick={() => setIsOpenAddModal(true)}
            height={30}
            width={60}
          >
            追加
          </GrayButton>
          {isOpenAddModal && (
            <PlanAddModal
              order={props.order}
              estates={props.estates}
              userPlans={props.userPlans}
              createUserPlanGroups={props.createUserPlanGroups}
              onClose={() => setIsOpenAddModal(false)}
            />
          )}

          <GrayButton
            className={
              !props.plans || props.plans.length === 0 ? 'isDisable' : ''
            }
            onClick={() => props.deleteCheckedPlans(props.order)}
            height={30}
            width={60}
          >
            削除
          </GrayButton>
        </PlanGroupHeaderMenu>
      </PlanGroupHeaderElem>

      <PlanTable
        rows={['4%', '16%', '16%', '8%', '8%', '8%', '8%', '8%', '8%', '8%']}
        headerItems={[
          <p onClick={() => props.toggleGroupCheck(props.order)}>
            <PlanCheckBox
              isChecked={props.checkList[props.order].group_check}
            />
          </p>,
          '物件名',
          '投資条件',
          '相続税評価額（土地）',
          '相続税評価額（建物）',
          '売却時期',
          '自己資金額',
          'ローン利用額',
          '借入金利',
          '借入期間',
        ]}
        items={items}
      ></PlanTable>
    </>
  )
}

const CheckBoxElem = styled.label`
  display: block;
  width: 19px;
  height: 19px;
  margin: 0 auto;
  background: white;
  border: 1px solid #dadada;
  border-radius: 2px;
  text-align: center;
  i {
    display: none;
    line-height: 18px;
    color: #4f5e9c;
  }
  &.checked i {
    display: block;
  }
`

type PlanCheckBoxProps = {
  isChecked: boolean
}
const PlanCheckBox: React.FC<PlanCheckBoxProps> = ({ isChecked }) => {
  return (
    <>
      <CheckBoxElem className={isChecked ? 'checked' : ''}>
        <i className="fas fa-check"></i>
      </CheckBoxElem>
    </>
  )
}
