import React, { useState } from 'react'
import styled from 'styled-components'
import { PortfolioAcodion } from '../Acodion'
import { GrayButton } from 'components/Symbols/Button'
import { PlanAddModal } from './PlanAddModal'
import { DashboardState } from 'reducers/DashboardReducer'
import { EstateState } from 'reducers/EstateReducer'
import { DashboardActions } from 'containers/DashboardContainer'

const PlanManagerSPElem = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100vh - 65px);
  overflow-y: scroll;
  background: #edeff5;
  z-index: 11;
`

const PlanHeaderElem = styled.div`
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  background-color: #4f5e9c;
  color: white;
`

const PlanFooterElem = styled.div`
  padding: 20px;
`

const PlanListElem = styled.ul`
  border-top: 1px solid #e0e0e0;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    padding: 14px 20px 14px 48px;
    border-bottom: 1px solid #e0e0e0;
    font-size: 15px;
    font-weight: bold;
    background: white;
  }
  li:nth-child(2n + 1) {
    background: #fafafa;
  }
  span {
    display: block;
    background: #ededed;
    border-radius: 3px;
    width: 20px;
    height: 20px;
    line-height: 22px;
    text-align: center;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    font-size: 13px;
    color: #4a4a4a;
    opacity: 0.7;
  }
  span::after {
    content: '\f1f8';
  }
`

const PlanAddElem = styled.div`
  padding: 10px 20px 20px 20px;
  text-align: right;
  color: #4f5e9c;
  font-weight: bold;
  font-size: 11px;
`

type PlanManagerSPProps = {
  closePMPC: () => void
} & Pick<DashboardState, 'planGroups' | 'portfolio'> &
  Pick<EstateState, 'userPlans'> &
  Pick<DashboardActions, 'createUserPlanGroups' | 'deletePlanFromPlanGroup'>

export const PlanManagerSP: React.FC<PlanManagerSPProps> = (props) => {
  const [isOpenAddModal, setIsOpenAddModal] = useState<number>(0)
  // チェックがtrueなプランを削除する。groupIndexがある場合はそのgroupだけ削除する。
  const deleteCheckedPlans = (groupIndex: number, plan_id: number) => {
    if (!window.confirm('選択した投資プランをグループから削除しますか？'))
      return
    props.deletePlanFromPlanGroup({
      1: groupIndex === 1 ? [plan_id] : [],
      2: groupIndex === 2 ? [plan_id] : [],
      3: groupIndex === 3 ? [plan_id] : [],
    })
    window.alert('投資プランをグループから削除しました。')
  }
  return (
    <>
      <PlanManagerSPElem>
        <PlanHeaderElem>投資プラングループの管理</PlanHeaderElem>
        <PortfolioAcodion label="投資プラングループⅠ" icon={1} isOpen={true}>
          <PlanListElem>
            {props.planGroups &&
              props.planGroups['1'].plans &&
              props.planGroups['1'].plans.map((plan) => {
                return (
                  <li>
                    {plan.params.estate.estate_name}
                    {/* fix me */}
                    <span onClick={() => deleteCheckedPlans(1, plan._id)} />
                  </li>
                )
              })}
          </PlanListElem>
        </PortfolioAcodion>
        <PlanAddElem onClick={() => setIsOpenAddModal(1)}>
          + 投資プランを追加
        </PlanAddElem>
        <PortfolioAcodion label="投資プラングループⅡ" icon={2} isOpen={true}>
          <PlanListElem>
            {props.planGroups &&
              props.planGroups['2'].plans &&
              props.planGroups['2'].plans.map((plan) => {
                return (
                  <li>
                    {plan.params.estate.estate_name}
                    {/* fix me */}
                    <span onClick={() => deleteCheckedPlans(2, plan._id)} />
                  </li>
                )
              })}
          </PlanListElem>
        </PortfolioAcodion>
        <PlanAddElem onClick={() => setIsOpenAddModal(2)}>
          + 投資プランを追加
        </PlanAddElem>
        <PortfolioAcodion label="投資プラングループⅢ" icon={3} isOpen={true}>
          <PlanListElem>
            {props.planGroups &&
              props.planGroups['3'].plans &&
              props.planGroups['3'].plans.map((plan) => {
                return (
                  <li>
                    {plan.params.estate.estate_name}
                    {/* fix me */}
                    <span onClick={() => deleteCheckedPlans(3, plan._id)} />
                  </li>
                )
              })}
          </PlanListElem>
        </PortfolioAcodion>
        <PlanAddElem onClick={() => setIsOpenAddModal(3)}>
          + 投資プランを追加
        </PlanAddElem>
        <PlanFooterElem>
          <GrayButton height={40} fontSize="14px" onClick={props.closePMPC}>
            閉じる
          </GrayButton>
        </PlanFooterElem>
      </PlanManagerSPElem>
      {/* FIXME */}
      {isOpenAddModal && (
        <PlanAddModal
          order={isOpenAddModal}
          estates={props.portfolio.estates}
          userPlans={props.userPlans}
          createUserPlanGroups={props.createUserPlanGroups}
          onClose={() => setIsOpenAddModal(0)}
        />
      )}
    </>
  )
}
