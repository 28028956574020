import React, { useState } from 'react'
import { AuthActions, AuthState } from '../reducers/AuthReducer'

interface OwnProps {}

type DevLoginProps = OwnProps & AuthState & AuthActions

export const DevLoginComponent: React.SFC<DevLoginProps> = (
  props: DevLoginProps
) => {
  const [formValue, setFormValue] = useState({ email: '', password: '' })

  return (
    <div>
      <div>
        <h2>関係者ログイン</h2>
      </div>
      <div className="field">
        <label>メールアドレス</label>
        <input
          type="text"
          placeholder="email"
          onChange={(e) =>
            setFormValue(Object.assign(formValue, { email: e.target.value }))
          }
        />
      </div>
      <div className="field">
        <label>パスワード</label>
        <input
          type="password"
          placeholder="password"
          onChange={(e) =>
            setFormValue(Object.assign(formValue, { password: e.target.value }))
          }
        />
      </div>
      <div>
        <button
          onClick={(e) => {
            props.devLogin(formValue)
          }}
        >
          send
        </button>
      </div>
      <div>
        <p>{props.message}</p>
      </div>
    </div>
  )
}
