import React from 'react'
import styled from 'styled-components'
import colors from 'style/colors'

export const PanelStyle = styled.div`
  max-width: 1152px;
  /* overflow: hidden; */
  padding: 25px 65px;
  background: white;
  border-bottom: 1px solid ${colors.border_gray2};
  border-radius: 0 0 5px 5px;
  &.result-service {
    padding: 25px 65px 35px;
    @media screen and (max-width: 800px) {
      padding: 25px 20px 25px;
    }
  }
  @media screen and (max-width: 800px) {
    padding: 20px;
  }
`

export const PanelForm = styled(PanelStyle)`
  @media screen and (max-width: 800px) {
    padding: 20px 0;
  }
`
export const PanelArea = styled.div`
  @media screen and (max-width: 800px) {
    padding: 0 20px;
  }
`

class Panel extends React.Component<{ className?: string }> {
  render() {
    return (
      <PanelStyle className={this.props.className}>
        {this.props.children}
      </PanelStyle>
    )
  }
}

export default Panel
