import React, { useState } from 'react'
import styled from 'styled-components'
import colors from 'style/colors'

const SectionTitleElem = styled.h2`
  position: relative;
  padding: 25px;
  border-radius: 5px 5px 0 0;
  text-align: center;
  font-size: 28px;
  background: ${colors.main};
  color: white;
  &.dark {
    background: ${colors.priority};
  }
  @media screen and (max-width: 800px) {
    width: calc(100vw);
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    padding: 0;
    border-radius: 0;
  }
  &.pc {
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  .left-button {
    position: absolute;
    left: 25px;
    top: 25px;
    display: flex;
    align-items: center;
  }
  @keyframes anime1 {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .help {
    position: relative;
    margin: 0 0 0 10px;
    display: inline-block;
    width: 22px;
    height: 22px;
    line-height: 20px;
    vertical-align: top;
    border-radius: 13px;
    text-align: center;
    font-size: 14px;
    color: #4f5e9c;
    background: white;
    border: 2px solid;
    /* border: 2px solid white; */
    cursor: pointer;
    @media screen and (max-width: 800px) {
      right: 80px;
    }
  }
  .notCheckedHelp {
    animation: anime1 0.7s ease -2s infinite alternate;
  }
  .help_text {
    display: none;
    position: absolute;
    z-index: 10000;
    top: 40px;
    left: -330px;
    white-space: nowrap;
    text-align: left;
    padding: 12px;
    background: white;
    border: 2px solid #0673c8;
    color: #0673c8;
    font-size: 13px;
    pointer-events: none;
    font-weight: normal;
    b {
      display: block;
      color: #0673c8;
      margin-bottom: 6px;
    }
    @media screen and (max-width: 800px) {
      top: -10px;
    }
  }
  .help:hover .help_text {
    display: block;
  }
`

interface IProps {
  label: string
  className?: string
  help?: boolean // ヘルプがあるかどうか
  helpTipe?: 'help' | 'info'
}

const SectionTitle: React.FC<IProps> = (props) => {
  const [checkedHelp, setCheckedHelp] = useState(false)

  return (
    <SectionTitleElem className={props.className}>
      <div className="left-button">{props.children}</div>
      {props.label}
      {props.help ? (
        <span
          className={`help sp-hide ${
            checkedHelp ? 'checkedHelp' : 'notCheckedHelp'
          }`}
          onMouseOver={() => setCheckedHelp(true)}
        >
          {props.helpTipe === 'help' || !props.helpTipe ? '?' : 'i'}
          {props.label === '収支シミュレーション' ? (
            <p className="help_text sp-hide">
              <b>
                判断できること：物件購入による減価償却効果（課税所得への影響）
                <br />
                　　　　　　　　物件購入前後のキャッシュフロー比較
              </b>
              物件登録画面より「個人/法人」及び、税金に関わる設定をして頂き、ご覧ください。
              <br />
              シミュレーショングラフにポインタを合わせると、各年度の課税所得及び、
              <br />
              税引き後キャッシュフロー等の数値が把握でき、売却時期カーソルを変更することで、
              <br />
              各売却時期の収支状況が表示されます。
              <br />
              <b>
                収支シミュレーションでは、本年度から売却までの期間について、シミュレーションを行っています。
              </b>
            </p>
          ) : props.label === '収益分析' ? (
            <p className="help_text sp-hide">
              <b>判断できること：物件売却のタイミング</b>
              AI査定価格から自己資金額を差し引いた額が、ローン残債より大きくなったタイミングで売却する事で、キャピタル益が見込めます。
              <br />
              また、その際に総インカムで見込める額を把握できます。
              <br />
              <b>
                収益分析では、本年度から売却までの期間について、シミュレーションを行っています。
              </b>
            </p>
          ) : (
            <p className="help_text sp-hide">
              キャッシュフローとは、「現金の流れ」です。
              <br />
              不動産投資から得られる帳簿上の利益とキャッシュフローの金額は異なるため、
              <br />
              収支との比較及び、資金繰りの観点で活用できます。
              <br />
              全期間利回り（IRR）とは、投資期間内における1年あたりのインカムと
              <br />
              キャピタルによる利回りです。投資プラン（ローン条件、期間等）によって
              <br />
              変化するため、検討物件に対する様々な投資プランを検討できます。
              <br />
              <b>
                キャッシュフローでは、本年度から売却までの期間について、シミュレーションを行っています。
              </b>
            </p>
          )}
        </span>
      ) : (
        ''
      )}
    </SectionTitleElem>
  )
}

export default SectionTitle
