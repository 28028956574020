import React from 'react'
import FieldInput from '../Field/Input'
import FieldLabel from '../Field/Label'
import { PanelForm, PanelArea } from '../Layout/Panel'
import PanelTitle from '../Layout/PanelTitle'
import RegistrationForm from './Form'

interface IProps {
  form: { [key: string]: string | undefined }
  updateField: any
}

class RegistrationOnlyOne extends React.Component<IProps> {
  public render() {
    return (
      <PanelForm>
        <PanelArea>
          <PanelTitle
            icon={3}
            label="物件の基本情報を登録しましょう。"
            description="物件名・所在地・最寄駅などの物件に関する基本的な情報を登録しましょう。"
          />
        </PanelArea>
        <RegistrationForm>
          <FieldLabel text="延床面積" />
          <div className="field-area">
            <FieldInput
              name="total_floor_space"
              unit_text="㎡"
              value={this.props.form.total_floor_space || ''}
              onChange={this.props.updateField}
              placeholder="200"
            />
          </div>
        </RegistrationForm>
      </PanelForm>
    )
  }
}

export default RegistrationOnlyOne
