import React from 'react'
import colors from 'style/colors'
import styled from 'styled-components'

const VacancyRateElem = styled.div`
  width: 100%;
  max-width: 500px;
  height: 120px;
  margin: 10px auto;
  padding: 50px 45px;
  @media screen and (max-width: 800px) {
    padding: 50px 25px;
    margin: 0 auto;
  }
  .area {
    position: relative;
  }
  .rates {
    position: absolute;
    left: 0;
    top: -25px;
    width: 100%;
  }
  .rate {
    position: absolute;
    top: 0;
    font-size: 10px;
    font-weight: bold;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -13px;
      margin: auto;
      width: 0;
      display: block;
      border: 4px solid transparent;
      border-top-width: 6px;
      border-top-color: ${colors.graph_blue};
    }
  }
  .rate-blue {
    color: ${colors.graph_blue};
    margin-left: -10px;
  }
  .rate-min {
    left: ${(props: DataProps) => props.rate_min}%;
  }
  .rate-mid {
    left: ${(props: DataProps) => props.rate_mid}%;
    top: -5px;
    white-space: nowrap;
    margin-left: -33px;
    background: #575757;
    color: white;
    padding: 5px;
    border-radius: 5px;
    &::after {
      bottom: -9px;
      border-top-color: #575757;
    }
  }
  .rate-max {
    left: ${(props: DataProps) => props.rate_max}%;
  }
  .bar {
    width: 100%;
    height: 20px;
    background: ${colors.graph_orange};
  }
  .bar2,
  .bar3,
  .bar4 {
    position: absolute;
    top: 0;
    width: 25%;
    height: 20px;
  }
  .bar2 {
    left: 0;
    background: ${colors.graph_blue};
  }
  .bar3 {
    left: 50%;
    background: ${colors.graph_olive};
  }
  .bar4 {
    right: 0;
    background: ${colors.graph_mazenda};
  }
  .axis {
    position: absolute;
    bottom: -25px;
    display: flex;
    justify-content: space-between;
    width: calc(100% + 20px);
    margin: 0 -10px 0 -10px;
  }
  .tick {
    width: 30px;
    text-align: center;
  }
  .unit {
    position: absolute;
    right: -25px;
    bottom: -15px;
  }
`

interface DataProps {
  rate_min?: number
  rate_max?: number
  rate_mid?: number
}

export const NewVacancyRate: React.FC<DataProps> = ({
  rate_max = 15.1,
  rate_min = 4.3,
  rate_mid = 6.5,
}) => {
  return (
    <VacancyRateElem
      rate_max={rate_max}
      rate_min={rate_min}
      rate_mid={rate_mid}
    >
      <div className="area">
        <div className="rates">
          <div className="rate rate-blue rate-min">{rate_min}%</div>
          <div className="rate rate-blue rate-max">{rate_max}%</div>
          <div className="rate rate-mid">稼働率{rate_mid}%</div>
        </div>
        <div className="bar"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
        <div className="bar4"></div>
        <div className="axis">
          <div className="tick">0</div>
          <div className="tick">25</div>
          <div className="tick">50</div>
          <div className="tick">75</div>
          <div className="tick">100</div>
        </div>
        <div className="unit">%</div>
      </div>
    </VacancyRateElem>
  )
}

export default NewVacancyRate
