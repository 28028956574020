import styled from 'styled-components'
import colors from 'style/colors'

export const HelpPanel = styled.div`
  padding: 15px;
  background: ${colors.base_gray};
  border-radius: 5px;
`

export const HelpTitle = styled.h1`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 15px;
  &::before {
    margin-right: 5px;
    content: url('/img/common/ic_check.svg');
  }
`

export const HelpText = styled.p`
  font-size: 12px;
  line-height: 1.5;
  .help-link {
    text-decoration: underline;
    color: ${colors.main};
    font-weight: bold;
  }
`

export const HelpButton = styled.p`
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 11px;
  margin: 10px 0;
  text-align: right;
  color: ${colors.main};
  text-decoration: underline;
  &::before {
    margin-right: 5px;
    content: url('/img/common/help_blue.svg');
  }
`
