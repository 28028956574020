import React from 'react'
import { BaseModal } from './Symbols/Modal'
import styled from 'styled-components'

const LoadModal = styled(BaseModal)`
  &.open {
    background: rgba(255, 255, 255, 0.75);
    z-index: 101;
  }
  .modal-img {
    margin: 0 auto;
  }
`

interface IProps {
  isView: boolean
}

const LoadView: React.FC<IProps> = (props: IProps) => {
  return (
    <LoadModal className={props.isView ? 'open' : ''}>
      <img alt="読み込み中" className="modal-img" src="/img/common/load.svg" />
    </LoadModal>
  )
}

export default LoadView
