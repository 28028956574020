import styled from 'styled-components'

const RegistrationForm = styled.div`
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 10px 25px;
  align-items: center;
  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
  .field-area {
    padding: 10px 20px;
    overflow: hidden;
  }
  /* 内部の要素を要素幅に合わせて横並びにする。 */
  .field {
    display: flex;
    align-items: center;
  }
  /* 内部の要素を20px開けて縦並びにする。 */
  .field-rows {
    display: grid;
    grid-gap: 20px;
  }
  /* 内部の要素を横並びにし、スマホの時は縦に並びにする。 */
  .field-rows-sp {
    display: flex;
    align-items: center;
    @media screen and (max-width: 800px) {
      display: grid;
      grid-gap: 20px;
    }
  }
  .field-text {
    margin-right: 10px;
  }
`

export default RegistrationForm
