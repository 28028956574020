import { DashboardActions } from 'containers/DashboardContainer'
import { PlanGroup } from 'domain/plan'
import {
  derivedPlanGroups,
  derivedPortfolioSumValue,
  PortfolioGroupType,
} from 'domain/portfolio'
import React, { useState } from 'react'
import { isMobileOnly } from 'react-device-detect'
import { DashboardState } from 'reducers/DashboardReducer'
import { EstateState } from 'reducers/EstateReducer'
import styled from 'styled-components'
import { PortfolioAcodion } from './Acodion'
import { PortfolioBarGraph } from './Graph/BarGraph'
import { PortfolioGraphBox } from './Graph/GraphBox'
import { PortfolioLineSingleGraph } from './Graph/LineSingleGraph'
import { GridTabLayout } from './GridTabLayout'
import { PlanManagerPC } from './PlanManager/PlanManagerPC'
import { PortfolioTable, PortfolioTableComment } from './Table/Foundation'
import { PortfolioTableBody } from './Table/TableBody'
import { PortfolioTableHeader } from './Table/TableHeader'
import {
  PortfolioTableBreakDown,
  PortfolioTableProperty,
} from './Table/TableTitles'

const GuideForMobileUser = styled.div`
  padding: 2px 8px 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  color: #4f5e9c;
  background: #edeff5;
  font-size: 11px;
  font-weight: bold;
`
type PortfolioProps = Pick<DashboardState, 'planGroups' | 'portfolio'> &
  Pick<EstateState, 'userPlans'> &
  Pick<DashboardActions, 'createUserPlanGroups' | 'deletePlanFromPlanGroup'>

export const PortfolioGroups: React.FC<PortfolioProps> = (
  props: PortfolioProps
) => {
  const [isOpenPMPC, setIsOpenPMPC] = useState(false)

  if (!props.portfolio || !props.planGroups) {
    return null
  }

  const result = derivedPlanGroups(props.planGroups)

  return (
    <>
      <PlanManagerPC
        isOpen={isOpenPMPC}
        onClosePMPC={() => setIsOpenPMPC(!isOpenPMPC)}
        estates={props.portfolio.estates}
        userPlans={props.userPlans}
        planGroups={props.planGroups}
        createUserPlanGroups={props.createUserPlanGroups}
        deletePlanFromPlanGroup={props.deletePlanFromPlanGroup}
      />
      <PortfolioAcodion
        label="投資プラングループⅠ"
        icon={1}
        isOpen={true}
        onOpenPMPC={() => setIsOpenPMPC(!isOpenPMPC)}
        theme={'dark'}
        header={
          !isMobileOnly && result[1] && result[1].result.length > 0 ? (
            <>
              <PortfolioTableHeader />
              <PortfolioTableBody
                value={derivedPortfolioSumValue([...result[1].portfolioValue])}
              />
            </>
          ) : null
        }
      >
        {result[1] && result[1].result.length > 0 ? (
          <PortfolioAcodionBody
            groupNum={1}
            groupValue={props.planGroups[1]}
            derivedPlanGroup={result[1]}
          />
        ) : (
          <p>投資プランがありません。</p>
        )}
      </PortfolioAcodion>
      <PortfolioAcodion
        label="投資プラングループⅡ"
        icon={2}
        isOpen={false}
        onOpenPMPC={() => setIsOpenPMPC(!isOpenPMPC)}
        theme={'light'}
        header={
          !isMobileOnly && result[2] && result[2].result.length > 0 ? (
            <>
              <PortfolioTableHeader />
              <PortfolioTableBody
                value={derivedPortfolioSumValue([...result[2].portfolioValue])}
              />
            </>
          ) : null
        }
      >
        {result[2] && result[2].result.length > 0 ? (
          <PortfolioAcodionBody
            groupNum={2}
            groupValue={props.planGroups[2]}
            derivedPlanGroup={result[2]}
          />
        ) : (
          <p>投資プランがありません。</p>
        )}
      </PortfolioAcodion>
      <PortfolioAcodion
        label="投資プラングループⅢ"
        icon={3}
        isOpen={false}
        onOpenPMPC={() => setIsOpenPMPC(!isOpenPMPC)}
        theme={'dark'}
        header={
          !isMobileOnly && result[3] && result[3].result.length > 0 ? (
            <>
              <PortfolioTableHeader />
              <PortfolioTableBody
                value={derivedPortfolioSumValue([...result[3].portfolioValue])}
              />
            </>
          ) : null
        }
      >
        {result[3] && result[3].result.length > 0 ? (
          <PortfolioAcodionBody
            groupNum={3}
            groupValue={props.planGroups[3]}
            derivedPlanGroup={result[3]}
          />
        ) : (
          <p>投資プランがありません。</p>
        )}
      </PortfolioAcodion>
    </>
  )
}

type PortfolioAcodionBodyProps = {
  groupNum: number
  groupValue: PlanGroup
  derivedPlanGroup: PortfolioGroupType
}

const PortfolioAcodionBody: React.FC<PortfolioAcodionBodyProps> = ({
  ...props
}) => {
  return (
    <>
      {!isMobileOnly && props.derivedPlanGroup && (
        <PortfolioTable>
          <PortfolioTableBreakDown />
          {props.derivedPlanGroup.portfolioValue.map((value, i) => {
            if (!value || !props.groupValue.plans) return null
            return (
              <div key={`PortfolioTableProperty${i}`}>
                <PortfolioTableProperty value={props.groupValue.plans[i]} />
                <PortfolioTableBody value={value} />
              </div>
            )
          })}
          <PortfolioTableComment>
            ※帳簿価額やローンは現在の査定結果を反映しています。
          </PortfolioTableComment>
        </PortfolioTable>
      )}

      <GridTabLayout
        labels={['AI査定価格', '減価償却費', '累積純営業利益']}
        itemHeader={
          isMobileOnly ? (
            <GuideForMobileUser>
              PC向け画面表示では、投資プラングループの詳細を確認できます
            </GuideForMobileUser>
          ) : (
            <></>
          )
        }
        items={[
          <PortfolioGraphBox key="PortfolioGraphBox1" title="AI査定価格">
            <PortfolioLineSingleGraph
              groupNum={props.groupNum}
              value={props.derivedPlanGroup}
              resultKey="estimated_selling_prices"
            />
          </PortfolioGraphBox>,
          <PortfolioGraphBox key="PortfolioGraphBox2" title="減価償却費">
            <PortfolioBarGraph
              groupNum={props.groupNum}
              value={props.derivedPlanGroup}
            />
          </PortfolioGraphBox>,
          <PortfolioGraphBox key="PortfolioGraphBox3" title="累積純営業利益">
            <PortfolioLineSingleGraph
              groupNum={props.groupNum}
              value={props.derivedPlanGroup}
              resultKey="accumulation_income"
            />
          </PortfolioGraphBox>,
        ]}
      />
    </>
  )
}
