// 数字を指定した数の配列にする。
export const toArray = (number: number, length = 10) => {
  let result = []
  for (let i = 0; i < length; i++) {
    result.push(number)
  }
  return result
}

export const createRange = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (v, k) => k + start)
