export default {
  red: '#D0021B',
  base: '#4A4A4A',
  black_middle: '#6C7178',
  black_alpha: '#707070',
  base_gray: '#F5F5F5',
  border_gray: '#c2c2c2',
  border_gray2: '#E4E4E4',
  bg_gray: '#FAFAFA',
  main: '#4F5E9C',
  blue_deep: '#0060B0',
  priority: '#434260',
  blue_bg_light: '#EDEFF5',
  blue_bg_table_attention: '#E6ECF5', // テーブルの売却年度やホバーなど
  graph_blue: '#3375bf',
  graph_orange: '#f3a83b',
  graph_olive: '#d7df67',
  graph_mazenda: '#cf5678',
  simulate_blue: '#3176FE',
  simulate_blue_thin: '#98BAFE',
  simulate_orange: '#FFA500',
  simulate_orange_thin: '#FFC900',
  simulate_orange_bg: '#FFF9EE',
  simulate_gray: '#C6C6C6',
  simutate_yellow: '#FEFF00',
  separate: ['#0673c8', '#378fd3', '#69abde', '#9bc7e9', '#ffa600', '#ffc04d'],
  separate_4: ['#3375bf', '#f3a83b', '#d7df67', '#c2c2c2'],
  modal_bg: 'rgba(237,239,245,0.85)',
}
