// import classNames from 'classnames'
// import colors from 'style/colors'
import React from 'react'
import styled, { css } from 'styled-components'
import { GrayButton } from 'components/Symbols/Button'
import Number from './Field/Number'
import { createPortal } from 'react-dom'
import { SimulationPayload } from 'domain/simulation'
import { separateComma } from 'helper/number'
import { Link } from 'react-router-dom'
import { isMobileOnly } from 'react-device-detect'

type IProps = {
  payload: SimulationPayload
  value: number
  onChange: any
  max: number
}

export const SellingBar: React.FC<IProps> = (props) => {
  const container = document.querySelector('#sellingBarContainer')

  if (container) {
    return createPortal(
      <>
        <Dummy />

        <SellingBarElem>
          <div className="container">
            <Link to={`/simulation/${props.payload.estate._id}/#step1`}>
              <GrayButton width={130}>物件情報入力</GrayButton>
            </Link>
            <div>
              <span style={{ marginRight: 15 }}>売却想定時期</span>
              <Number
                style={{ display: 'inline-flex', padding: 0 }}
                inputStyle={{ width: '60px', height: '40px' }}
                name="priorities_when_selling"
                unit_text=""
                value={props.value}
                onChange={props.onChange}
                max={props.max}
                min={1}
              />
              <span>年後</span>
              {!isMobileOnly && (
                <>
                  <span style={{ marginLeft: 25, marginRight: 10 }}>
                    推定結果
                  </span>
                  <span style={{ marginRight: 5 }}>約</span>
                  <span style={{ marginRight: 5 }}>
                    {separateComma(
                      Math.round(props.payload.result.final_asset / 10000)
                    )}
                    万円
                  </span>
                  <span>の</span>
                  <span>投資収益が見込めます。</span>
                </>
              )}
            </div>
          </div>
        </SellingBarElem>
      </>,
      container
    )
  }
  return null
}

const SellingBarElem = styled.div`
  width: 100%;
  background: white;
  margin: 0;
  position: fixed;
  top: 89px;
  left: 0;
  z-index: 10;
  margin: -20px 0;
  padding: 5px 10px;
  height: 55px;
  font-size: 12px;
  background: #777;
  color: white;
  display: flex;
  align-items: center;
  .container {
    width: 1152px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  span {
    color: white;
    font-weight: bold;
  }
  ${!isMobileOnly &&
  css`
    top: 77px;
    height: 70px;
    padding: 10px;
    font-size: 14px;
  `}
`

const Dummy = styled.div`
  height: 50px;
`
