import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { HogeState, hogeReducer } from './hogeReducer'
import { AddressState, AddressReducer } from './AddressReducer'
import { DashboardState, DashboardReducer } from './DashboardReducer'
import { EstateState, EstateReducer } from './EstateReducer'
import { AuthState, AuthReducer } from './AuthReducer'

export type AppState = {
  router: any
  hoge: HogeState
  address: AddressState
  dashboard: DashboardState
  estate: EstateState
  auth: AuthState
}

export default (history: any) =>
  combineReducers<AppState>({
    router: connectRouter(history),
    hoge: hogeReducer,
    address: AddressReducer,
    dashboard: DashboardReducer,
    estate: EstateReducer,
    auth: AuthReducer,
  })
