import React, { useState } from 'react'
import styled from 'styled-components'

type PlanTableProps = {
  rows: string[]
  items: (React.ReactElement | string)[][]
  headerItems: (React.ReactElement | string)[]
}

export const PlanTable: React.FC<PlanTableProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false) // 3件以上のデータはアコーディオンで非表示
  return (
    <>
      <PlanTableElem>
        <thead>
          <PlanTableRow items={props.headerItems} rows={props.rows} />
        </thead>
        <tbody>
          {props.items.map((item, index) =>
            index > 2 && !isOpen ? (
              <></>
            ) : (
              <PlanTableRow key={index} items={item} rows={props.rows} />
            )
          )}
        </tbody>
      </PlanTableElem>
      {props.items.length > 3 && (
        <PlanTableAcodionButton
          onClick={() => setIsOpen(!isOpen)}
          className={isOpen ? 'close' : 'open'}
        />
      )}
    </>
  )
}

const PlanTableElem = styled.table`
  width: 100%;
  thead {
    background: #4f5e9c;
    td {
      vertical-align: middle;
      text-align: center;
      font-size: 11px;
      font-weight: bold;
      color: white;
      border: 1px solid white;
      border-top: none;
      border-bottom: 1px solid #4f5e9c;
    }
    td:first-child {
      border-left-color: #4f5e9c;
    }
    td:last-child {
      border-right-color: #4f5e9c;
    }
  }
  tbody {
    tr {
      background: white;
    }
    tr:nth-child(2n) {
      background: #fafafa;
    }
    td {
      border: 1px solid #e0e0e0;
      padding: 10px;
      font-size: 11px;
      vertical-align: middle;
    }
  }
`

type PlanTableRowProps = {
  rows: string[] // 横幅
  items: (React.ReactElement | string)[] // tdの中身のelement
}
export const PlanTableRow: React.FC<PlanTableRowProps> = (props) => {
  return (
    <PlanTableRowElem>
      {props.items.map((item, index) => (
        <PlanTableCell key={index} width={props.rows[index]}>
          {item}
        </PlanTableCell>
      ))}
    </PlanTableRowElem>
  )
}
const PlanTableRowElem = styled.tr`
  height: 48px;
`
const PlanTableCell = styled.td<{ width: string }>`
  width: ${(props) => props.width};
`

const PlanTableAcodionButton = styled.div`
  height: 20px;
  border: 1px solid #dbdeeb;
  border-top: 0;
  background: #fafafa url('/img/common/ic_arrow_top.svg') center center / 10px
    10px no-repeat;
  &.open {
    background: #fafafa url('/img/common/ic_arrow_bottom.svg') center center /
      10px 10px no-repeat;
  }
`
