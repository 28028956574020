import React from 'react'
import colors from 'style/colors'
import styled from 'styled-components'

const FormNumber = styled.div`
  margin-right: 15px;
  display: flex;
  align-items: center;
  padding: 10px 0;
  .field {
    position: relative;
    img {
      display: block;
      position: absolute;
      right: 5px;
      padding: 5px;
      cursor: pointer;
    }
    .up {
      top: 7px;
      transform: rotateZ(180deg);
    }
    .down {
      bottom: 7px;
    }
  }
  input {
    display: inline-block;
    width: 120px;
    height: 50px;
    line-height: 50px;
    border: 1px solid ${colors.border_gray};
    border-radius: 3px;
    padding: 0 8px 0 14px;
    font-size: 15px;
  }
  .unit {
    margin-left: 15px;
    font-size: 15px;
    font-weight: normal;
  }
`

interface IProps {
  name: string // Input name属性
  unit_text: string // 単位を後ろに表示
  value?: number | string | undefined // なくてもいいように？をつける
  step?: number
  min?: number
  max?: number
  sumId?: string
  sumValue?: number
  childSumId?: string
  style?: any
  inputStyle?: any
  onChange?: (e?: any) => void
}

const getAttributes = (props: IProps) => {
  const value = props.value
  return {
    name: props.name,
    value: value !== undefined ? value.toString() : undefined,
    step: props.step || 1,
    min: props.min,
    max: props.max,
    style: {},
    inputStyle: {},
    onChange: props.onChange,
  }
}

class NumberField extends React.Component<IProps, {}> {
  public static defaultProps = {
    unit_text: '',
    step: 1,
  }

  constructor(props: IProps) {
    super(props)
    this.inputRef = React.createRef()
  }

  private inputRef: React.RefObject<HTMLInputElement>

  // フィールド++する
  up = () => {
    this.inputRef.current!.stepUp()
    const ev = new Event('input', { bubbles: true })
    this.inputRef.current!.dispatchEvent(ev)
  }

  down = () => {
    this.inputRef.current!.stepDown()
    const ev = new Event('input', { bubbles: true })
    this.inputRef.current!.dispatchEvent(ev)
  }

  public render() {
    const attributes: React.InputHTMLAttributes<HTMLInputElement> = getAttributes(
      this.props
    )

    return (
      <FormNumber style={this.props.style}>
        <span className="field">
          <input
            ref={this.inputRef}
            data-sumid={this.props.sumId}
            data-childsumid={this.props.childSumId}
            data-sumvalue={this.props.sumValue}
            type="number"
            {...attributes}
            style={this.props.inputStyle}
          />
          <img
            onClick={this.up}
            alt="Up"
            className="up"
            src="/img/common/ic_number.svg"
          />
          <img
            onClick={this.down}
            alt="Down"
            className="down"
            src="/img/common/ic_number.svg"
          />
        </span>

        {this.props.unit_text ? (
          <span className="unit">{this.props.unit_text}</span>
        ) : (
          ''
        )}
      </FormNumber>
    )
  }
}

export default NumberField
