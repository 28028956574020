import actionCreatorFactory from 'typescript-fsa'
import { reducerWithInitialState } from 'typescript-fsa-reducers'

const actionCreator = actionCreatorFactory()

export const AddressActions = {
  getCities: actionCreator<string>('ACTIONS_GET_CITIES'),
  setCities: actionCreator<any>('ACTIONS_SET_CITIES'),
}

export interface AddressState {
  cities: any
}

const initialState: AddressState = {
  cities: [],
}

export const AddressReducer = reducerWithInitialState(initialState).case(
  AddressActions.setCities,
  (state, cities) => {
    return Object.assign({}, state, { cities })
  }
)
