import styled from 'styled-components'

interface TooltipProps {
  color?: 'white'
  top?: string
  right?: string
  bottom?: string
  left?: string
  text_left?: string
  text_right?: string
  pcHide?: boolean
  spView?: boolean
  spTextTop?: string
  spTextLeft?: string
  spTextRight?: string
  spTextBottom?: string
}

export const TooltipBase = styled.span`
  position: relative;
  top: ${(props: TooltipProps) => (props.top ? props.top : 0)};
  display: ${(props: TooltipProps) => (props.pcHide ? 'none' : 'inline-block')};
  margin: -6px 0 -6px 6px;
  width: 21px;
  height: 21px;
  line-height: 18px;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  color: ${(props: TooltipProps) => (props.color ? props.color : '#0673c8')};
  border: 2px solid
    ${(props: TooltipProps) => (props.color ? props.color : '#0673c8')};
  cursor: pointer;
  z-index: 2;
  @media screen and (max-width: 800px) {
    display: ${(props: TooltipProps) =>
      props.spView ? 'inline-block' : 'none'};
  }
  .tooltip-text {
    display: none;
    position: absolute;
    z-index: 10000;
    top: 30px;
    right: ${(props: TooltipProps) =>
      props.text_right ? props.text_right : '-30px'};
    left: ${(props: TooltipProps) =>
      props.text_left ? props.text_left : 'auto'};
    white-space: nowrap;
    text-align: left;
    padding: 12px;
    background: white;
    border: 2px solid #0673c8;
    color: #0673c8;
    font-size: 13px;
    pointer-events: none;
    font-weight: normal;
    z-index: 1;
    b {
      color: #0673c8;
    }
    @media screen and (max-width: 800px) {
      max-width: 350px;
      white-space: pre-wrap;
      top: ${(props: TooltipProps) =>
        props.spTextTop ? props.spTextTop : '30px'};
      left: ${(props: TooltipProps) =>
        props.spTextLeft ? props.spTextLeft : 'auto'};
      right: ${(props: TooltipProps) =>
        props.spTextRight ? props.spTextRight : 'auto'};
      bottom: ${(props: TooltipProps) =>
        props.spTextBottom ? props.spTextBottom : 'auto'};
    }
  }
  &:hover .tooltip-text {
    display: block;
  }
`
