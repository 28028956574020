import { Action } from 'typescript-fsa'
import { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { AppState } from '../reducers/AppReducer'
import { EstateActions, SaveNewPlanForm } from '../reducers/EstateReducer'
import SimurationPage from '../components/SimulationPage'
import { PlanPayload } from 'domain/plan'

function mapDispatchToProps(dispatch: Dispatch<Action<any>>) {
  return {
    getPlanningEstate: (id: string) =>
      dispatch(EstateActions.getPlanningEstate(id)),
    savePlan: (params: PlanPayload) => dispatch(EstateActions.savePlan(params)),
    setPlans: (plans: any[]) => dispatch(EstateActions.setPlans(plans)),
    deletePlan: (estateId: number, planId: number) =>
      dispatch(EstateActions.deletePlan({ estateId, planId })),
    saveNewPlan: (saveNewPlanpayload: SaveNewPlanForm) =>
      dispatch(EstateActions.saveNewPlan(saveNewPlanpayload)),
  }
}

export type SimulationContainerActions = ReturnType<typeof mapDispatchToProps>

function mapStateToProps(appState: AppState) {
  return {
    ...appState.estate,
  }
}

export type SimulationContainerProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps, mapDispatchToProps)(SimurationPage)
