/** Estate */
// export type Estate = { [key: string]: any; rents: any[] }
import { isInteger } from 'helper/number'
import { NullablePartial } from 'helper/types'
import moment from 'moment'
// import { Moment } from 'moment'

// TODO: 基本情報と、readonlyのデータを分ける
export type EstateJSON = EstateInfoJSON & EstateEditableJSON

export type EstateInfoJSON = {
  property_type: 1 | 2
  purchased_date: DateTime
  built_date: DateTime
  updated: DateTime
}

export type EstateEditableJSON = {
  data_source_tel: string
  vacancy_rates: number[]
  catch_copy: string
  months_rent: number
  market_gross_rate_error_rate: number
  large_scale_repair_history: string
  land_assessments_for_property_tax: null
  terminal_market_gross_rate: number
  is_old_seismic_criterion: boolean
  use_district_2: null
  months_other_management_fees: null
  repair_fund_normality: number
  sub_source_estate_id: string
  selling_status: null
  photos: null
  market_sell_price: number
  actual_rate: null
  depreciation_main_body_rate: null
  regression_market_gross_rate: number
  real_rent_error_rate: number
  market_gross_rate: number
  parking_garage: null
  form_of_transaction: null
  maintenance_costs: number[]
  building_structure_notes: string
  total_floor_space: number
  eismic_diagnosis: string
  is_owner_change: boolean
  management_cost_normality: number
  selling_expectation_rate: null
  management_commission_expense: null
  is_premium: boolean
  has_cadastral_map_submitted: null
  city: string
  estate_name: string
  depreciation_land_rate: null
  market_rent_profits: number[]
  market_rent: number
  contract_price: null
  max_years_rent: number
  right_on_land: number
  country: string
  auction_status: null
  has_important_instructions_submitted: null
  months_management_fees: number
  building_structure: number
  user_id: number
  land_category: null
  asbestos_research: string
  repair_fund_median_error_rate_score: null
  land_assessments_for_inheritance_tax: null
  occupancy_status: null
  cashflows: number[]
  market_sell_prices: number[]
  lon: null
  has_flyer_submitted: null
  has_AT: boolean
  tax_rate: number
  market_management_costs: number[]
  max_vacancy_rates: number[]
  balance_of_repair_reserve_fund: null
  property_tax: null
  regression_market_sell_price: number
  market_repair_fund_error_rate: null
  rents: number[]
  created: string
  long_term_repair_plan: null
  IRR: number
  similar_estate_market_sell_price: number
  address: string
  management_cost_median_error_rate_score: null
  profits: number[]
  gross_rate_median_error_rate_score: null
  depreciation_building_rate: null
  property_handover: null
  chiban: string
  depreciation_equipment_rate: null
  months_repair_reserve_fund_fees: null
  users_favorited: any[]
  management_costs: number[]
  has_rental_agreement_submitted: null
  data_source: null
  aza: string
  real_gross_rate_error_rate: number
  market_rent_gross_rate: number
  source_updated: null
  public_status: number
  arrears_of_management_or_reserve_fund_fees: null
  recommended_point: string
  market_rent_cashflows: number[]
  market_repair_fund: null
  city_planning_area: null
  original_regression_market_rent: number
  similar_estate_market_gross_rate: null
  estimated_source_created: null
  land_area: null
  layout_structure: number
  real_repair_fund_error_rate: null
  other_management_company: string
  is_BT_separated: boolean
  has_EV: boolean
  jikauri_id: number
  expected_selling_price_per_tsubo: number
  sell_price_normality: number
  nearest_station_name: string
  has_purchase_and_sale_agreement_submitted: null
  is_corner_room: boolean
  walking_minutes_from_nearest_station: number
  number_of_empty_rooms: null
  release_date: null
  transportation_others: string
  market_rents_all_years: number[]
  room_number: string
  market_rent_actual_rate: null
  expected_minimum_selling_price: null
  market_rent_error_rate: number
  market_management_cost: null
  final_account_month: number
  private_estate_type: number
  real_management_cost_error_rate: null
  min_vacancy_rates: number[]
  age_of_building: number
  contact_road_status: string
  management_method: null
  management_company: null
  land_equity_ownership: string
  expected_selling_price_per_square_meter: number
  source_created: null
  rent_median_error_rate_score: null
  use_district_1: number
  market_gross_rates_all_years: number[]
  diff_selling_price: number
  location: null
  estate_class_name: string
  lat: null
  market_sell_prices_all_years: number[]
  floor_located: number
  _id: number
  market_management_cost_error_rate: null
  railways: Railway[]
  max_gross_rate: number
  gross_rate: number
  trade_type: number
  other_notices: string
  repair_funds: null
  data_source_name: string
  rent_normality: number
  sell_prices: number[]
  balcony_area: null
  building_coverage: null
  concierge_comment: string
  depreciation_expense: null
  personal_company_type: number
  regression_market_rent: number
  zip1: string
  other_occupancy_status: string
  source_estate_id: string
  priorities_when_selling: number
  market_rent_IRR: number
  direction: null
  rooms: null
  occupation_area: number
  pref_code: string
  floor_area_ratio: null
  max_actual_rate: null
  reason_to_sell: null
  zip2: string
  diff_rent: number
  similar_estate_market_rent: null
  max_IRR: number
  market_gross_rates: number[]
  expected_selling_price: number
  has_investigative_report_submitted: null
  number_of_houses: number
  other_income: number
  company_id: null
  layout_one_building: string
  number_of_floors: number
  market_rents: number[]
  data_input_type: null
  market_repair_funds: number[]
  chome: number
  layout_rooms: number
  market_maintenance_cost: null
}

type NullableEstateJSON = NullablePartial<EstateJSON>

export type DateTime = {
  $date: number
}

export type SearchEstateJSON = {
  address: string
  aza: string
  building_structure: number
  built_date: DateTime
  chiban: string
  chome: number
  city: string
  created: DateTime
  elementary_school_district: string
  estate_name: string
  jikauri_id: number
  junior_high_school_district: string
  management_company: string
  number_of_basement_floors: number
  number_of_floors: number
  number_of_houses: number
  other_management_company: string
  pref_code: string
  railways: Railway
  right_on_land: number
  updated: DateTime
  use_district_1: number
  use_district_2: number
  zip1: string
  zip2: string
  _id: number
}

export type SearchEstateResponse = {
  predictions: SearchEstateJSON[]
}

export type Railway = {
  station_group_code: string
  walking_minutes: number
  station_name: string
  line_code: string
  time_to_reach: number
  line_name: string
  station_code: string
}

/** Estateを編集する際のPayload */
export type EstatePayload = {
  _id?: number
  property_type: number
  trade_type: number
  estate_name: string
  expected_selling_price: number
  zip1: string
  zip2: string
  pref_code: string
  city: string
  aza: string
  chome: string
  chiban: string
}

/** シミュレーション可能な入力が完了したEstate */
export type EstateSimulatable = EstateMinimum & {
  // IRRがあるかどうかで判断しているコードがあったのでとりあえずこれで
} & NullableEstateJSON &
  Pick<EstateJSON, 'IRR'>

export type EstateMinimum = EstateMinimumProps & {
  _id: number
} & NullableEstateJSON

/** 新しい物件を追加した後の最低限ある項目
 *
 * (ポートフォリオのAPI調べ)
 */
export type EstateMinimumProps = {
  property_type: 1 | 2
  estate_name: string
  zip1: string
  zip2: string
  pref_code: string
  city: string
  aza: string
  chome: number
  chiban: string

  // サーバが保存してくれるやつ
  public_status: number
  users_favorited: any[]
  private_estate_type: number
  user_id: number
  created: DateTime
  updated: DateTime
}

/** ポートフォリオAPIに含まれるEstate */
export type PortfolioEstate = EstateMinimum | EstateSimulatable

export type EstatePropertyPayload = Partial<EstateEditableJSON> & {
  transactionType: string
  ban: string
  gou: string
  property_type: string
  age_year: number
  age_month: number
  purchased_year: number
  purchased_month: number
  near_line_1?: string | null
  near_line_2?: string | null
  near_line_3?: string | null
  near_station_1?: string | null
  near_station_2?: string | null
  near_station_3?: string | null
  near_station_minutes_1?: number | null
  near_station_minutes_2?: number | null
  near_station_minutes_3?: number | null
}

/** シミュレーションに進んでいいestateかどうかを判別する */
export const isEstateSimulatable = (
  estate: EstateMinimum | EstateSimulatable | EstateJSON
) => {
  if (!isInteger(estate.IRR)) {
    return false
  }
  if (!(estate.railways && estate.railways.length > 0)) {
    return false
  }
  if (!estate.purchased_date) {
    return false
  }
  return true
}

export const loadEstatePropertyPayload = (
  estate: EstateJSON
): EstatePropertyPayload => {
  let bangou = estate.chiban ? estate.chiban.split('-') : []

  return {
    ...estate,
    _id: estate._id,
    transactionType: '購入希望',
    ban: bangou[0] || '',
    gou: bangou[1] || '',
    chiban: estate.chiban,
    property_type: estate.property_type.toString(),
    age_year: estate.built_date ? moment(estate.built_date.$date).year() : 1980,
    age_month: estate.built_date
      ? moment(estate.built_date.$date).month() + 1
      : 1,
    purchased_year: estate.purchased_date
      ? moment(estate.purchased_date.$date).year()
      : 1980,
    purchased_month: estate.purchased_date
      ? moment(estate.purchased_date.$date).month() + 1
      : 1,
    near_line_1:
      estate.railways && estate.railways[0]
        ? estate.railways[0].line_code
        : null,
    near_line_2:
      estate.railways && estate.railways[1]
        ? estate.railways[1].line_code
        : null,
    near_line_3:
      estate.railways && estate.railways[2]
        ? estate.railways[2].line_code
        : null,
    near_station_1:
      estate.railways && estate.railways[0]
        ? estate.railways[0].station_code
        : null,
    near_station_2:
      estate.railways && estate.railways[1]
        ? estate.railways[1].station_code
        : null,
    near_station_3:
      estate.railways && estate.railways[2]
        ? estate.railways[2].station_code
        : null,
    near_station_minutes_1:
      estate.railways && estate.railways[0]
        ? estate.railways[0].time_to_reach
        : null,
    near_station_minutes_2:
      estate.railways && estate.railways[1]
        ? estate.railways[1].time_to_reach
        : null,
    near_station_minutes_3:
      estate.railways && estate.railways[2]
        ? estate.railways[2].time_to_reach
        : null,
  }
}
