export const tradeTypeToText = (number: number | null | undefined) => {
  const typeNum = number === 1 || number === 2 || number === 3 ? number : 1
  const tradeTypes = {
    1: '購入',
    2: '売却',
    3: '保有',
  }
  return tradeTypes[typeNum]
}

export const tradeTypeToColor = (number: number | null | undefined) => {
  const typeNum = number === 1 || number === 2 || number === 3 ? number : 1
  const tradeTypes = {
    1: 'blue',
    2: 'yellow',
    3: 'green',
  }
  return tradeTypes[typeNum]
}

export const buildingStructureToText = (number: number) => {
  switch (number) {
    case 1:
      return 'SRC'
    case 2:
      return 'RC'
    case 3:
      return '木'
    case 4:
      return '鉄'
    default:
      return 'その他'
  }
}

export const LayoutStructureToText = (number: number) => {
  switch (number) {
    case 1:
      return 'R'
    case 2:
      return 'K'
    case 3:
      return 'DK'
    case 4:
      return 'LDK'
    default:
      return 'R'
  }
}

export const propertyTypeToText = (number: number) =>
  number === 1 ? '区分' : '一棟'

export const personalCompanyTypeToText = (number: number) =>
  number === 1 ? '個人' : '法人'

export type NullablePartial<T> = { [P in keyof T]?: T[P] | null }
