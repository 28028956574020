import styled from 'styled-components'
import colors from 'style/colors'

export const BaseModal = styled.div`
  display: none;
  &.open {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 15;
    background: ${colors.modal_bg};
  }
  .modal-box {
    padding: 20px;
    width: 100%;
    @media screen and (min-width: 801px) {
      max-width: 1152px;
      max-height: 100vh;
      margin: 0 auto;
    }
  }
  .modal-body {
    background: white;
    border: 1px solid ${colors.main};
    border-radius: 5px;
    overflow: hidden;
  }
  .modal-header {
    padding: 15px 20px;
    background: ${colors.main};
    color: white;
    font-size: 18px;
    line-height: 1.5;
    font-weight: bold;
    &.large {
      border-radius: 5px 5px 0 0;
      padding: 15px 20px;
      text-align: center;
      font-size: 24px;
    }
  }
  .modal-header.question {
    padding-left: 60px;
    background: ${colors.main} url('/img/common/help.svg') 30px center no-repeat;
    @media screen and (max-width: 800px) {
      padding-left: 50px;
      background: ${colors.main} url('/img/common/help.svg') 20px 20px no-repeat;
    }
  }
  .modal-panel {
    overflow-y: scroll;
    padding: 0 0 15px;
    &.pc {
      padding: 0 0;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    max-height: ${window.outerHeight - 200}px;
    @media screen and (min-width: 801px) {
      min-height: 300px;
      max-height: ${window.outerHeight - 400}px;
      padding: 25px 50px;
      &.pc {
        padding: 25px 50px;
      }
    }
    .bold {
      font-weight: bold;
    }
  }
  .modal-area {
    padding: 15px 20px;
    font-size: 15px;
    line-height: 1.7;
    .bold {
      font-weight: bold;
    }
  }
  .modal-area-title {
    font-size: 17px;
    color: ${colors.main};
  }
  .modal-cancel {
    display: block;
    text-align: center;
    color: #929292;
    text-decoration: underline;
  }
  .modal-comment {
    padding: 0 20px;
    height: 30px;
    line-height: 30px;
    background: ${colors.blue_bg_light};
    color: ${colors.main};
    font-size: 11px;
    font-weight: bold;
    @media screen and (min-width: 801px) {
      height: 45px;
      line-height: 45px;
      font-size: 15px;
    }
  }
  .modal-close-top {
    display: block;
    width: 24px;
    height: 24px;
    border-radius: 3px;
    background: white url('/img/common/close.svg') center center / 14px 14px
      no-repeat;
    margin: 15px 0 15px auto;
    cursor: pointer;
  }
  .modal-close-bottom {
    margin: 20px 0 0;
    text-align: center;
    color: ${colors.main};
    cursor: pointer;
    @media screen and (min-width: 801px) {
      margin: 30px 0 0;
    }
    &::before {
      content: url('/img/common/close.svg');
      margin-right: 7px;
      display: inline-block;
      width: 13px;
      height: 13px;
    }
  }

  .modal-more-title {
    padding: 10px 25px;
    border-top: 1px dashed ${colors.main};
    background: ${colors.blue_bg_light};
    color: ${colors.main};
  }
`
