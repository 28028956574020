import React, { ReactElement } from 'react'
import styled from 'styled-components'
import colors from 'style/colors'

interface titleProps {
  id?: string
  label: string
  icon?: number | 'arw'
  marginBotton: number
  marginLeft: number
  description: string
  spDesign?: 'bar' | 'flex'
  spLabel?: string
  help: boolean // ヘルプがあるかどうか
  helpTipe: 'info' | 'help' // インフォマークかヘルプマークか
  help_text: ReactElement // ヘルプテキスト
  toolTipElem: ReactElement // ヘルプテキスト
}
interface ComponentProps {
  marginBottom: number
  marginLeft: number
}

const PanelTitleElem = styled.div`
  width: 100%;
  margin-bottom: ${(props: ComponentProps) => props.marginBottom}px;
  position: relative;
  .body-title {
    display: flex;
    align-items: center;
    @media screen and (min-width: 801px) {
      margin-left: ${(props: ComponentProps) => props.marginLeft}px;
    }
    .panel-title-pc {
      @media screen and (max-width: 800px) {
        display: none;
      }
    }
    .title-icon {
      display: inline-block;
      text-align: center;
      height: 24px;
      line-height: 24px;
      width: 24px;
      margin-right: 10px;
      border-radius: 24px;
      background: ${colors.main};
      color: white;
      font-size: 14px;
      @media screen and (max-width: 800px) {
        height: 18px;
        line-height: 18px;
        width: 18px;
        font-size: 10px;
      }
    }
    .title-icon-arw {
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      background: url('/img/common/ic_arrow.svg') center center / 14px 14px
        no-repeat;
      transform: rotateZ(180deg);
      @media screen and (max-width: 800px) {
        margin-left: 20px;
      }
    }
    .title-text {
      font-size: 24px;
      display: flex;
      align-items: center;
      @media screen and (max-width: 800px) {
        display: block;
        font-size: 18px;
        width: calc(100% - 28px);
      }
    }
    @media screen and (min-width: 801px) {
      .title-label {
        margin-right: 10px;
      }
    }
    .panel-title-highlight {
      font-size: 30px;
      color: ${colors.main};
    }
  }
  .body-description {
    margin-top: 10px;
    line-height: 1.4;
    font-size: 13px;
    font-weight: normal;
    @media screen and (max-width: 800px) {
      width: calc(100vw - 84px);
      margin-left: 28px;
    }
  }

  .help {
    position: relative;
    margin: 0 0 0 10px;
    width: 21px;
    height: 21px;
    line-height: 18px;
    border-radius: 10px;
    text-align: center;
    font-size: 14px;
    color: #0673c8;
    border: 2px solid #0673c8;
    cursor: pointer;
    @media screen and (max-width: 800px) {
      right: 80px;
    }
  }
  .help_text {
    display: none;
    position: absolute;
    z-index: 10000;
    top: 30px;
    left: -30px;
    white-space: nowrap;
    text-align: left;
    padding: 12px;
    background: white;
    border: 2px solid #0673c8;
    color: #0673c8;
    font-size: 13px;
    font-weight: normal;
    pointer-events: none;
    @media screen and (min-width: 801px) {
      &::before {
        content: '';
      }
    }
    @media screen and (max-width: 800px) {
      top: -10px;
    }
  }
  .help:hover .help_text {
    display: block;
  }

  &.bar {
    .title-sp-label {
      display: none;
    }
    @media screen and (max-width: 800px) {
      width: calc(100% + 40px);
      margin: 0 -20px;
      .body-title {
        display: block;
      }
      .title-text {
        width: 100%;
      }
      .title-label {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 50px;
        line-height: 50px;
        color: white;
        background: ${colors.main};
        margin-top: -20px;
      }
      .title-sp-label {
        display: block;
        color: white;
      }
      .title-icon {
        display: none;
      }
    }
  }
`

class PanelTitle extends React.Component<titleProps, {}> {
  public static defaultProps = {
    id: '',
    label: '',
    labelElem: <></>,
    toolTipElem: <></>,
    description: '',
    marginBotton: 25,
    marginLeft: 0,
    help: false,
    helpTipe: 'help',
    help_text: '',
  }
  render() {
    const titleIcon = this.props.icon ? (
      typeof this.props.icon === 'number' ? (
        <span className="title-icon">{this.props.icon}</span>
      ) : (
        <span className="title-icon-arw"></span>
      )
    ) : null
    return (
      <PanelTitleElem
        id={this.props.id}
        className={this.props.spDesign}
        marginBottom={this.props.marginBotton}
        marginLeft={this.props.marginLeft}
      >
        <h2 className="body-title">
          {titleIcon}
          <span className="title-text">
            <span className="title-label">
              {this.props.label}
              <span className="title-sp-label">{this.props.spLabel}</span>
              {this.props.toolTipElem}
            </span>
            {this.props.children}
          </span>

          {this.props.help && (
            <span className="help sp-hide">
              {this.props.helpTipe === 'help' ? '?' : 'i'}
              {this.props.help_text ? this.props.help_text : ''}
            </span>
          )}
        </h2>
        {this.props.description ? (
          <p className="body-description">{this.props.description}</p>
        ) : (
          ''
        )}
      </PanelTitleElem>
    )
  }
}

export default PanelTitle
