import { push } from 'connected-react-router'
import { ldebug } from 'helper/log'
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import * as api from './apis'
import { AddressActions } from './reducers/AddressReducer'
import { authActions } from './reducers/AuthReducer'
import { DashboardActions } from './reducers/DashboardReducer'
import { EstateActions } from './reducers/EstateReducer'
import { isEstateSimulatable } from 'domain/estate'
import { mergeSimulationParams } from 'domain/simulation'

// function* fetchUser(action: any) {
//   try {
//     yield put(hogeActions.requestSuccess('aaaa'))
//   } catch (e) {
//     yield put(hogeActions.requestFailed('bbbb'))
//   }
// }

function* fetchPortfolio(action: any) {
  try {
    const response = yield call(api.getEstateList)
    yield put(DashboardActions.requestPortfolioSuccess(response.data))
  } catch (e) {
    const statusCode = e.response.status

    if (statusCode === 401) {
      yield put(push('/sflogin'))
      return
    }

    alert('通信エラーが発生しました。時間を置いて再度お試しください。')
  }
}

function* deleteEstate(action: any) {
  try {
    const response = yield call(api.deleteEstate, action.payload)
    ldebug(response)
    // ポートフォリオを読み込みなおす
    yield put(DashboardActions.requestPortfolio())
  } catch (e) {
    if (e.response.status === 401) {
      yield put(push('/sflogin'))
      return
    }
    alert('通信エラーが発生しました。時間をおいて再度お試しください。')
  }
}

function* registerNewEstate(action: any) {
  try {
    const payload = {
      ...action.payload,
      expected_selling_price: action.payload.expected_selling_price * 10000,
    }
    const response = yield call(api.registerNewEstate, payload)
    yield put(push(`/simulation/${response.data.estate._id}`))
  } catch (e) {
    yield put(DashboardActions.requestPortfolioFailed(e))
  }
}

function* getEstate(action: any) {
  try {
    const response = yield call(api.getEstate, action.payload)
    ldebug(response)
    const estate = response.data.estate
    yield put(
      EstateActions.setEstateDraft({
        ...estate,
        expected_selling_price: estate.expected_selling_price / 10000,
        months_rent: estate.months_rent / 10000,
        months_management_fees: estate.months_management_fees / 10000,
      })
    )
  } catch (e) {
    console.log(`getEstate status code: ${e.response.status}`)
    if (e.response.status === 401) {
      yield put(push('/sflogin'))
    }
    alert('通信エラーが発生しました。時間をおいて再度お試しください。')
  }
}

function* estateSearch(action: any) {
  try {
    const response = yield call(api.estateSearch, action.payload.estate_name)

    yield put(
      EstateActions.showSearchModal({
        keyword: action.payload.estate_name,
        data: response.data.predictions,
        isShow: true,
      })
    )
  } catch (e) {}
}

function* setPlanFromSearchResult(action: any) {
  try {
    const response = yield call(api.registerNewEstate, action.payload)
    yield put(push(`/simulation/${response.data.estate._id}`))
  } catch (e) {}
  // yield console.log(action)
}

function* getPlanningEstate(action: any) {
  try {
    let response = yield call(api.getEstate, action.payload)
    let estate = response.data.estate

    // シミュレーション可能な場合は再度シミュレーションする
    // Leewaysモデル更新対応のため
    if (isEstateSimulatable(estate)) {
      yield call(api.simurateEstate, action.payload)
      const response = yield call(api.getEstate, action.payload)
      estate = {
        ...response.data.estate,
        expected_selling_price:
          response.data.estate.expected_selling_price / 10000,
        months_rent: response.data.estate.months_rent / 10000,
        months_management_fees:
          response.data.estate.months_management_fees / 10000,
      }

      // >>> デフォルトプランの追加
      // 新規登録した物件はシミュレーションパラメータのデフォルト値で
      // １つデフォルトプランを作成する
      const plans = (yield call(api.getPlans, action.payload)).data
      if (plans.length === 0) {
        ldebug(plans)
        const planParams = {
          ...mergeSimulationParams(estate),
          label: 'デフォルト',
          estate,
          estateId: action.payload,
        }
        ldebug(planParams)
        yield call(api.savePlan, planParams)
      }
      // <<< デフォルトプランの追加
    } else {
      estate = {
        ...response.data.estate,
        expected_selling_price:
          response.data.estate.expected_selling_price / 10000,
      }
    }

    const plans = (yield call(api.getPlans, action.payload)).data

    yield put(EstateActions.setPlanningEstate(estate))
    yield put(EstateActions.setPlans(plans))
  } catch (e) {
    console.error(e)
    // yield put(push('/sflogin'))
  }
}

function* getCities(action: any) {
  try {
    const response = yield call(api.getCities, action.payload)
    if (!response.data.error) {
      yield put(
        AddressActions.setCities(response.data.map((city: any) => city.name))
      )
    }
  } catch (e) {
    // Do nothing
  }
}

function* getAddressFromZip(action: any) {
  try {
    const response = yield call(api.getAddressFromZip, action.payload)
    if (!response.data.error) {
      // 先に市町村の選択肢を取得してから
      yield put(AddressActions.getCities(response.data.pref_code))
      yield put(
        EstateActions.setEstateDraft({
          pref_code: response.data.pref_code,
          city: response.data.city,
          aza: response.data.aza,
        })
      )
    }
  } catch (e) {
    // Do nothing
  }
}

function* getRailways(action: any) {
  try {
    const response = yield call(api.getRailways, action.payload)
    yield put(EstateActions.setRailways(response.data))
  } catch (e) {
    // Do nothing
  }
}

function* getStations(action: any) {
  try {
    let response1 = yield call(api.getStations, action.payload.near_line_1)
    let response2 = yield call(api.getStations, action.payload.near_line_2)
    let response3 = yield call(api.getStations, action.payload.near_line_3)

    if (response1.data.error) {
      response1.data = []
    }
    if (response2.data.error) {
      response2.data = []
    }
    if (response3.data.error) {
      response3.data = []
    }

    yield put(
      EstateActions.setStations({
        stations1: response1.data,
        stations2: response2.data,
        stations3: response3.data,
      })
    )
  } catch (e) {
    // Do nothing
  }
}

function* saveAndSimulation(action: any) {
  yield put(EstateActions.setSimulationLoading(true))
  try {
    let railways = [
      {
        line_code: action.payload.near_line_1,
        station_code: action.payload.near_station_1,
        time_to_reach: parseInt(action.payload.near_station_minutes_1),
        walking_minutes: parseInt(action.payload.near_station_minutes_1),
      },
      {
        line_code: action.payload.near_line_2,
        station_code: action.payload.near_station_2,
        time_to_reach: parseInt(action.payload.near_station_minutes_2),
        walking_minutes: parseInt(action.payload.near_station_minutes_2),
      },
      {
        line_code: action.payload.near_line_3,
        station_code: action.payload.near_station_3,
        time_to_reach: parseInt(action.payload.near_station_minutes_3),
        walking_minutes: parseInt(action.payload.near_station_minutes_3),
      },
    ]

    let occupation_area = parseFloat(action.payload.occupation_area)

    if (action.payload.property_type === '2') {
      occupation_area =
        parseFloat(action.payload.total_floor_space) /
        parseFloat(action.payload.number_of_houses)
      ldebug(action.payload.total_floor_space)
      ldebug(action.payload.number_of_houses)
    }
    const payload = {
      ...action.payload,
      zip1: '150',
      zip2: '0021',
      occupation_area,
      property_type: parseInt(action.payload.property_type),
      trade_type: parseInt(action.payload.trade_type),
      personal_company_type: parseInt(action.payload.personal_company_type),
      layout_structure: parseInt(action.payload.layout_structure),
      railways,
      built_date: `${action.payload.age_year}/${(
        '0' + action.payload.age_month
      ).slice(-2)}/01`,
      purchased_date: `${action.payload.purchased_year}/${(
        '0' + action.payload.purchased_month
      ).slice(-2)}/01`,
      expected_selling_price: action.payload.expected_selling_price * 10000,
      months_rent: action.payload.months_rent * 10000,
      months_management_fees: action.payload.months_management_fees * 10000,
    }
    const response = yield call(api.editEstate, payload)
    ldebug(response)

    const estateResponse = (yield call(api.getEstate, action.payload._id)).data
      .estate
    // 表示を万円にする処理
    const estate = {
      ...estateResponse,
      expected_selling_price: estateResponse.expected_selling_price / 10000,
      months_rent: estateResponse.months_rent / 10000,
      months_management_fees: estateResponse.months_management_fees / 10000,
    }
    ldebug(estate)

    yield put(EstateActions.setPlanningEstate(estate))

    // >>> デフォルトプランの追加
    // 新規登録した物件はシミュレーションパラメータのデフォルト値で
    // １つデフォルトプランを作成する
    const plans = (yield call(api.getPlans, action.payload._id)).data
    if (plans.length === 0) {
      ldebug(plans)
      const planParams = {
        ...mergeSimulationParams(estate),
        label: 'デフォルト',
        estate,
        estateId: action.payload._id,
      }
      ldebug(planParams)
      yield call(api.savePlan, planParams)

      const newPlans = (yield call(api.getPlans, action.payload._id)).data
      yield put(EstateActions.setPlans(newPlans))
    }
    // <<< デフォルトプランの追加

    yield put(EstateActions.setSimulationLoading(false))
    yield put(push(`/simulation/${action.payload._id}/result`))
  } catch (e) {
    console.error(e)
    yield put(
      EstateActions.saveSimulationFailed(
        'シミュレーションに失敗しました。入力値を再度ご確認ください。'
      )
    )
  }
}

function* savePlan(action: any) {
  try {
    yield call(api.savePlan, action.payload)
    const plansResponse = yield call(api.getPlans, action.payload.estateId)
    yield put(EstateActions.setPlans(plansResponse.data))
  } catch (e) {
    if (e.response.status === 401) {
      put(push('/sflogin'))
      return
    }
    alert('通信エラーが発生しました。時間をおいて再度お試しください。')
  }
}

function* saveNewPlan(action: any) {
  try {
    const params = {
      ...action.payload.payload.params,
      label: action.payload.planLabel,
    }

    const plan = {
      estateId: action.payload.payload.estate._id,
      params,
    }

    const payload = {
      ...action.payload.payload.params,
      label: action.payload.planLabel,
      estate: action.payload.payload.estate,
      estateId: plan.estateId.toString(),
    }

    const planData = yield call(api.savePlan, payload)
    const plansResponse = yield call(api.getPlans, planData.data.estate_id)
    yield put(EstateActions.setPlans(plansResponse.data))
  } catch (e) {
    if (e.response.status === 401) {
      put(push('/sflogin'))
      return
    }
    alert('通信エラーが発生しました。時間をおいて再度お試しください。')
  }
}

function* deletePlan(action: any) {
  try {
    yield call(api.deletePlan, action.payload.estateId, action.payload.planId)
    const plansResponse = yield call(api.getPlans, action.payload.estateId)
    yield put(EstateActions.setPlans(plansResponse.data))
  } catch (e) {
    if (e.response.status === 401) {
      put(push('/sflogin'))
      return
    }
    alert('通信エラーが発生しました。時間をおいて再度お試しください。')
  }
}

function* getUserPlan() {
  try {
    const res = yield call(api.getUserPlan)
    console.log('getUserPlan Response start')
    console.dir(res)
    console.log('getUserPlan Response end')
    yield put(EstateActions.setUserPlan(res.data))
  } catch (e) {
    console.log('getUserPlan Error')
    console.dir(e)
  }
}

function* getUserPlanGroup() {
  try {
    const res = yield call(api.getUserPlanGroups)
    yield put(DashboardActions.setUserPlanGroups(res.data))
  } catch (e) {
    console.log('getUserPlanGroup Error')
    console.dir(e)
  }
}

function* createUserPlanGroups(action: any) {
  try {
    // 相続税評価額と固定資産税を保存する
    yield call(
      api.savePlan,
      action.payload.plan.params,
      action.payload.plan._id
    )
    const userPlans = yield call(api.getUserPlan)
    yield put(EstateActions.setUserPlan(userPlans.data))

    // 投資プラングループを更新する
    const state = yield select()
    const planGroup =
      state.dashboard.planGroups[action.payload.order.toString()].plan_ids

    yield call(
      api.createUserPlanGroups,
      action.payload.order,
      planGroup.find((id: number) => id === action.payload.plan._id)
        ? planGroup
        : planGroup.concat(action.payload.plan._id)
    )
    const res = yield call(api.getUserPlanGroups)
    yield put(DashboardActions.setUserPlanGroups(res.data))
  } catch (e) {
    console.log('createUserPlanGroups Error')
    console.dir(e)
  }
}

function* deletePlanFromPlanGroup(action: any) {
  try {
    const state = yield select()
    const planGroup = state.dashboard.planGroups

    for (const order of Object.keys(action.payload)) {
      // 現在のstoreからpayloadのplanId削除
      console.log(planGroup[order])
      const updatePlanIds = planGroup[order].plan_ids.filter(
        (current_id: number) =>
          !action.payload[order].find(
            (removePlanID: number) => removePlanID === current_id
          )
      )

      // 削除したplanIdの配列を保存する
      yield call(api.createUserPlanGroups, order, updatePlanIds)

      const res = yield call(api.getUserPlanGroups)
      yield put(DashboardActions.setUserPlanGroups(res.data))
    }
  } catch (e) {
    console.log('deletePlanFromPlanGroup Error')
    console.dir(e)
  }
}

function* logout(action: any) {
  try {
    const response = yield call(api.logout)
    ldebug(response)
    yield push('/')
  } catch (e) {
    // Do nothing
  }
}

function* devLogin(action: any) {
  try {
    const response = yield call(
      api.devLogin,
      action.payload.email,
      action.payload.password
    )
    if (response.data.error) {
      yield put(
        authActions.devLoginFailed('メールアドレスまたはパスワードが違います')
      )
      return
    }
    yield put(push('/'))
  } catch (e) {
    console.error(e)
    yield put(
      authActions.devLoginFailed(
        'ログイン処理に失敗しました。時間をおいてお試しください。'
      )
    )
  }
}

function* mySaga() {
  yield takeEvery(DashboardActions.requestPortfolio, fetchPortfolio)
  yield takeEvery(DashboardActions.registerNewEstate, registerNewEstate)
  yield takeEvery(DashboardActions.deleteEstate, deleteEstate)
  yield takeEvery(DashboardActions.getUserPlanGroups, getUserPlanGroup)
  yield takeEvery(DashboardActions.createUserPlanGroups, createUserPlanGroups)
  yield takeEvery(
    DashboardActions.deletePlanFromPlanGroup,
    deletePlanFromPlanGroup
  )
  yield takeLatest(AddressActions.getCities, getCities)
  yield takeLatest(EstateActions.getAddressFromZip, getAddressFromZip)
  yield takeLatest(EstateActions.getEstate, getEstate)
  yield takeLatest(EstateActions.searchEstate, estateSearch)
  yield takeEvery(
    EstateActions.setPlanFromSearchResult,
    setPlanFromSearchResult
  )
  yield takeLatest(EstateActions.getPlanningEstate, getPlanningEstate)
  yield takeLatest(EstateActions.getRailways, getRailways)
  yield takeLatest(EstateActions.getStations, getStations)
  yield takeLatest(EstateActions.saveAndSimulation, saveAndSimulation)
  yield takeEvery(EstateActions.savePlan, savePlan)
  yield takeEvery(EstateActions.getUserPlan, getUserPlan)
  yield takeEvery(EstateActions.saveNewPlan, saveNewPlan)
  yield takeEvery(EstateActions.deletePlan, deletePlan)
  yield takeEvery(authActions.devLogin, devLogin)
  yield takeEvery(authActions.logout, logout)
}

export default mySaga
