import React from 'react'

import LoadView from './Load'

const SuLoginComponent: React.FC<any> = (id: any) => {
  if (window.location.host === 'stg-app.value-ai.jp') {
    window.location.href = `https://stg.value-ai.jp/backoffice/users/${id.match.params.id}/login`
  } else {
    window.location.href = `https://value-ai.jp/backoffice/users/${id.match.params.id}/login`
  }

  return (
    <div>
      <LoadView isView={true} />
    </div>
  )
}

export default SuLoginComponent
