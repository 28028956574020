import React, { useState } from 'react'
import { SpToggleListItem } from '../../Symbols/List'
import { ConditionModalProps } from './SettingsBase'
import Grid from '../Layout/Grid'
import { MainButton, GrayButton } from 'components/Symbols/Button'

const SettingsModalListItem: React.FC<ConditionModalProps> = ({
  estate_id = '0',
  planIndex = 0,
  data,
  conditionChange = (a: any, b: any) => false,
  conditionDelete = (a: any, b: any) => false,
  closeModal = () => false,
}) => {
  const [isItemOpen, changeOpen] = useState(false)
  const readCondition = () => {
    alert('投資プランを適用しました。')
    conditionChange(estate_id, planIndex)
    closeModal()
  }
  const deleteCondition = () => {
    if (window.confirm('投資プランを削除しますか？')) {
      conditionDelete(estate_id, planIndex)
    }
  }
  return (
    <SpToggleListItem className={isItemOpen ? 'open' : ''}>
      <div className="list-title" onClick={() => changeOpen(!isItemOpen)}>
        {data.plan_label}
      </div>
      <div className="list-body">
        <Grid>
          <Grid column={['1fr', '1fr']} className="sp">
            <dl>
              <dt>売却時期</dt>
              <dd>{data.priorities_when_selling}年目</dd>
            </dl>
            <dl>
              <dt>借入期間</dt>
              <dd>{data.borrowing_period}年</dd>
            </dl>
            {/* <dl>
            <dt>売却価格</dt>
            <dd>{data.sold_year_selling_price}万円</dd>
          </dl> */}
            <dl>
              <dt>購入価格</dt>
              <dd>{data.selling_price}万円</dd>
            </dl>
            <dl>
              <dt>自己資金額</dt>
              <dd>{data.own_resources}万円</dd>
            </dl>
            <dl>
              <dt>ローン利用額</dt>
              <dd>{data.loan_usage_amount}万円</dd>
            </dl>
            <dl>
              <dt>借入金利</dt>
              <dd>{data.borrowing_interest_rate}%</dd>
            </dl>
          </Grid>
          <Grid className="any" column={['1fr', '70px']}>
            <MainButton onClick={() => readCondition()}>呼び出す</MainButton>
            <GrayButton onClick={() => deleteCondition()}>削除</GrayButton>
          </Grid>
        </Grid>
        {/* <dl>
          <dt>最終資産額</dt>
          <dd className={data.calculate_final_asset < 0 ? 'red' : ''}>
            {data.calculate_final_asset}万円
          </dd>
        </dl>
        <dl>
          <dt>全期間利回り</dt>
          <dd className={data.irr < 0 ? 'red' : ''}>{data.irr}%</dd>
        </dl> */}
      </div>
    </SpToggleListItem>
  )
}

export default SettingsModalListItem
