import React from 'react'
import styled from 'styled-components'
import colors from 'style/colors'
import { PageButtonElem } from 'components/Simulation/Field/PageButton'

// シミュレーション共通のProps
export interface GraphCommonProps {
  selectYear: number
  roanPeriod: number
  depreciationPeriod: number
  personal_company_type?: number
  include_other_income?: boolean
  page?: number
}

// シミュレーショングラフの共通エレメント
export const SimulationGraphElm = styled.div`
  width: 100%;
  max-width: 1112px;
  height: 600px;
  max-height: 100%;
`

// X軸の目盛り
export interface PayloadProps {
  value: number
  offset: number
}

// X軸の目盛り
export interface TickProps {
  x: number
  y: number
  payload: PayloadProps
}

// ツールチップ
export const CustomToolTip = styled.div`
  position: relative;
  padding: 10px;
  border-radius: 10px;
  width: 180px;
  background: #575757;
  margin-top: 10px;
  * {
    color: white;
    font-size: 10px;
    font-weight: bold;
  }
  .tooltip-title {
    margin-bottom: 10px;
    ::before {
      content: '';
      display: inline-block;
      margin-right: 6px;
      width: 8px;
      height: 8px;
      background: white;
    }
  }
  .tooltip-item {
    width: 130px;
    dt,
    dd {
      display: block;
      margin: 2px 0;
    }
  }
  .flex-item {
    display: flex;
    justify-content: space-between;
  }
  .rows-item {
    dd {
      text-align: right;
    }
  }
  /*
  FIX 仕様的にグラフの下に常にツールチップを置くのが難しいので、吹き出しは非表示
  ::after {
    content: "";
    display: block;
    position: absolute;
    top: -10px;
    width: 0;
    height: 0;
    left: 0;
    right: 0;
    margin: auto;
    border: 6px solid transparent;
    border-bottom: 6px solid #575757;
  } */
`

// 凡例
export const CustomLegendElm = styled.div`
  font-size: 12px;
  margin: 10px;
  @media screen and (max-width: 800px) {
    font-size: 10px;
    margin: 10px 0;
  }
  ul {
    max-width: 650px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
    justify-content: space-between;
  }
  li {
    margin: 0 15px;
    display: flex;
    align-items: center;
    @media screen and (max-width: 800px) {
      margin: 0 2px 5px;
    }
  }
  li::before {
    content: '';
    margin-right: 3px;
    display: inline-block;
    width: 12px;
    height: 12px;
    background: ${colors.base};
    @media screen and (max-width: 800px) {
      width: 10px;
      height: 10px;
    }
  }
  .circle-icon::before {
    border-radius: 10px;
  }
  .icon-blue-thin::before {
    background: ${colors.simulate_blue_thin};
  }
  .icon-blue::before {
    background: ${colors.simulate_blue};
  }
  .icon-orange::before {
    background: ${colors.simulate_orange};
  }
  .icon-gray::before {
    background: ${colors.simulate_gray};
  }
  .icon-yellow::before {
    background: ${colors.simutate_yellow};
  }
  .icon-orange-line::before {
    border: 2px solid ${colors.simulate_orange_thin};
  }
`

// グラフの下の目盛り 1,5,10...50
export const renderYearTick = (tickProps: TickProps) => {
  const { x, y, payload } = tickProps
  const { value } = payload
  const new_y = y + 15
  const tickFontSize = 10

  if (value === 1 || value % 5 === 0) {
    return (
      <text
        fontSize={tickFontSize}
        fontWeight="bold"
        x={x}
        textAnchor={'middle'}
        y={new_y}
        fill={'#333'}
      >
        {value}
      </text>
    )
  }
}

// グラフの下の目盛り 1,5,10... 選択年度以前は灰色に、以後は黒になる。 選択年度は青色になり、【今年度】ラベルを下に表示する。
// 今年度ラベルは廃止したため、使わない
// export const renderYearTick = (
//   tickProps: TickProps,
//   selectYear: number,
//   firstYear: number
// ) => {
//   const { x, y, payload } = tickProps
//   const { value } = payload
//   const new_y = y + 15
//   const tickFontSize = 10

//   if (value === selectYear) {
//     const tickFill = colors.blue_deep
//     return (
//       <g>
//         <text
//           fontSize={tickFontSize}
//           fontWeight="bold"
//           x={x}
//           textAnchor={'middle'}
//           y={new_y}
//           fill={tickFill}
//         >
//           {value}
//         </text>
//         <rect
//           fontSize={tickFontSize}
//           x={x - 22}
//           y={new_y + 4}
//           rx={7}
//           ry={7}
//           width={44}
//           height={15}
//           fill="#1575C5"
//         ></rect>
//         <text
//           fontSize={tickFontSize}
//           fontWeight="bold"
//           x={x}
//           textAnchor={'middle'}
//           y={new_y + 15}
//           fill="white"
//         >
//           今年度
//         </text>
//       </g>
//     )
//   } else if (value === firstYear || value % 5 === 0) {
//     const tickFill = value < selectYear ? colors.simulate_gray : '#333'
//     return (
//       <text
//         fontSize={tickFontSize}
//         fontWeight="bold"
//         x={x}
//         textAnchor={'middle'}
//         y={new_y}
//         fill={tickFill}
//       >
//         {value}
//       </text>
//     )
//   }
// }

// ローン返済期間・減価償却期間のエリアを表示する。
export const renderAreaTick = (
  tickProps: TickProps,
  TickType: 'depreciation' | 'roan',
  areaYear: number,
  dataLength: number
) => {
  const { x, y, payload } = tickProps
  const { value, offset } = payload
  const areaYearCap = areaYear <= dataLength ? areaYear : dataLength

  // value: 目盛りのX座標, offset: 目盛り間の距離の半分
  if (value === areaYearCap) {
    const TickStyle = {
      depreciation: {
        fillColor: '#EF7D22',
        bgColor: colors.simulate_orange_bg,
        areaLabel: '減価償却期間',
        pathY0: y - 20, // 上端
      },
      roan: {
        fillColor: '#4270C7',
        bgColor: '#F8FBFF',
        areaLabel: 'ローン返済期間',
        pathY0: y - 50, // 上端
      },
    }
    const { fillColor, bgColor, areaLabel, pathY0 } = TickStyle[TickType]

    const pathX = Math.floor(x + offset * 2) + 0.5 // 右端

    const pathX0 = 80
    const pathY = pathY0 + 12 // 減価償却期間の下端
    const pathY2 = y + 435 // 下端
    // 中間
    const centerX = (pathX + pathX0) / 2

    return (
      <g>
        <pattern
          id={`${TickType}_area_pattern`}
          patternUnits="userSpaceOnUse"
          width="4"
          height="20"
          patternTransform="rotate(60)"
        >
          <rect fill="white" x="0" y="0" width="2" height="20" />
          <rect fill={fillColor} x="0" y="0" width="2" height="20" />
        </pattern>
        <path
          d={`M${pathX0},${pathY0} ${pathX},${pathY0} ${pathX},${pathY2} ${pathX0},${pathY2}`}
          fill={bgColor}
        />
        <path
          d={`M${pathX0},${pathY0} ${pathX},${pathY0} ${pathX},${pathY} ${pathX0},${pathY}`}
          fill={`url(#${TickType}_area_pattern)`}
        />
        <rect
          x={centerX - 50}
          y={pathY0}
          width={100}
          height={12}
          fill={bgColor}
        ></rect>
        <text
          x={centerX}
          y={pathY0}
          alignmentBaseline="text-before-edge"
          textAnchor="middle"
          fontWeight="bold"
          fill={fillColor}
          fontSize="11"
        >
          {areaLabel}
        </text>
      </g>
    )
  }
  return <></>
}

// y軸テキスト
export const renderMoneyTick = (tickProps: TickProps) => {
  const { x, y, payload } = tickProps
  const { value } = payload
  return (
    <text x={x} y={y} textAnchor={'end'} fontSize={10} fontWeight={'bold'}>
      {value} {/* FIX Format*/}
    </text>
  )
}

export const SimulationButtonsStyle = styled.div`
  display: none;
  @media screen and (max-width: 800px) {
    margin: 20px 0 15px;
    display: flex;
    justify-content: space-between;
  }
`

interface SimulationButtonProps {
  page?: number
  up?: () => void
  down?: () => void
  dataLength?: number
}

export const SimulationButtons: React.FC<SimulationButtonProps> = ({
  page = 1,
  up,
  down,
  dataLength = 10,
}: SimulationButtonProps) => {
  const BackButton =
    page > 1 ? (
      <PageButtonElem onClick={down} className="back">
        前の10年
      </PageButtonElem>
    ) : (
      <div />
    )
  const NextButton =
    page < Math.ceil(dataLength / 10) ? (
      <PageButtonElem onClick={up} className="next">
        次の10年
      </PageButtonElem>
    ) : (
      <div />
    )
  return (
    <>
      <SimulationButtonsStyle>
        {BackButton}
        {NextButton}
      </SimulationButtonsStyle>
    </>
  )
}
