export const arrToCSV = (arr: any[]) => {
  return arr
    .map((row) =>
      row.map((cell: any) => {
        const comvertStr = String(cell)
        return '"' + (comvertStr ? comvertStr.replace(/"/g, '""') : '') + '"'
      })
    )
    .map((row) => row.join(','))
    .join('\n')
}

export function csvDownload(data: string, name: string) {
  // utf8
  const bom = '\uFEFF'

  const blob = new Blob([bom, data], { type: 'text/csv' })
  const anchor: any = document.createElement('a')

  // ie
  if (window.navigator.msSaveBlob) {
    window.navigator.msSaveBlob(blob, name)

    // chrome, firefox, etc.
  } else if (window.URL && anchor.download !== undefined) {
    anchor.download = name
    anchor.href = window.URL.createObjectURL(blob)
    document.body.appendChild(anchor)
    anchor.click()
    anchor.parentNode.removeChild(anchor)
  } else {
    window.location.href =
      'data:attachment/csv;charset=utf-8,' + encodeURIComponent(bom + data)
  }
}
