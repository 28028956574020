import cn from 'classnames'
import React, { memo, useMemo } from 'react'
import { RouteComponentProps } from 'react-router'
import colors from 'style/colors'
import styled from 'styled-components'
import { changeStepNum, mapTabToStep } from '../StepList'

interface StyleProps {
  ResultPage: number
}
interface IProps extends StyleProps {
  ChangeResultPage: any
}

const ResultTabElem = styled.div`
  display: none;
  @media screen and (max-width: 800px) {
    display: block;
    overflow-x: scroll;
    ul {
      display: flex;
      width: 730px;
      padding: 0 10px;
    }
    li {
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
      margin-right: 5px;
      background: white;
      font-weight: bold;
      border: 1px solid ${colors.border_gray2};
      width: 100px;
      border-radius: 5px 5px 0 0;
      border-bottom: none;
      color: #929292;
      font-size: 11px;
    }
    li.small-tab {
      font-size: 12px;
    }
    li.active {
      background: ${colors.main};
      color: white;
    }
  }
`

export const TabNameToIndex: { [key: string]: number } = {
  '#ResultInfo': 1,
  '#ResultPlan': 2,
  '#ResultIncome': 3,
  '#ResultCashflow': 4,
  '#ResultService': 5,
  '#ResultSummary': 6,
  '#ResultCapital': 7,
}

type NewProps = IProps & Pick<RouteComponentProps, 'history'>

const ResultTab: React.FC<NewProps> = memo(
  ({ history, ChangeResultPage, ResultPage }: NewProps) => {
    const changePage = useMemo<
      { [K in keyof typeof TabNameToIndex]: () => void }
    >(() => {
      const goTo = (pageNum: number) => () => {
        // history.push(TabIndexToName[pageNum])
        ChangeResultPage(pageNum)
        changeStepNum(mapTabToStep(pageNum))
      }
      return Object.fromEntries(
        Object.entries(TabNameToIndex).map(([k, v]) => [k, goTo(v)])
      )
    }, [ChangeResultPage])

    return (
      <ResultTabElem>
        <ul>
          <li
            className={cn('small-tab', {
              active: ResultPage === 1,
            })}
            onClick={changePage['#ResultInfo']}
          >
            物件情報
          </li>
          <li
            className={cn('small-tab', {
              active: ResultPage === 2,
            })}
            onClick={changePage['#ResultPlan']}
          >
            投資プラン
          </li>
          <li
            className={cn('small-tab', {
              active: ResultPage === 7,
            })}
            onClick={changePage['#ResultCapital']}
          >
            収益分析
          </li>
          <li
            className={ResultPage === 3 ? 'active' : ''}
            onClick={changePage['#ResultIncome']}
          >
            収支シミュ
            <br />
            レーション
          </li>
          <li
            className={ResultPage === 4 ? 'active' : ''}
            onClick={changePage['#ResultCashflow']}
          >
            キャッシュ
            <br />
            フロー表
          </li>
          <li
            className={cn('small-tab', {
              active: ResultPage === 6,
            })}
            onClick={changePage['#ResultSummary']}
          >
            推定結果
          </li>
          <li
            className={cn('small-tab', {
              active: ResultPage === 5,
            })}
            onClick={changePage['#ResultService']}
          >
            各種サービス
          </li>
        </ul>
      </ResultTabElem>
    )
  }
)

export default ResultTab
