import React from 'react'
import { ConditionModalProps } from './SettingsBase'
import { MainButton, GrayButton } from 'components/Symbols/Button'
import LayoutBetween from '../Layout/Between'
import styled from 'styled-components'

const EditCell = styled.td`
  width: 130px;
`

const CallButton = styled(MainButton)`
  width: 60px;
  margin-right: 10px;
`

const DeleteButton = styled(GrayButton)`
  width: 60px;
`

const SettingsModalTableItem: React.FC<ConditionModalProps> = ({
  estate_id = '0',
  planIndex = 0,
  data,
  deletable,
  conditionChange = (a: any, b: any) => false,
  conditionDelete = (a: any, b: any) => false,
  closeModal = () => false,
}) => {
  const readCondition = () => {
    alert('投資プランを適用しました。')
    conditionChange(estate_id, planIndex)
    closeModal()
  }
  const deleteCondition = () => {
    console.log(data)
    if (!deletable) {
      alert('投資プランが１つの時は削除できません。')
      return
    }
    if (window.confirm('投資プランを削除しますか？')) {
      conditionDelete(planIndex)
    }
  }
  return (
    <tr className="canRead">
      <td className="data-name">{data.plan_label}</td>
      <td>{data.priorities_when_selling}年目</td>
      {/* <td>{data.sold_year_selling_price}万円</td> */}
      <td>{data.selling_price}万円</td>
      <td>{data.own_resources}万円</td>
      <td>{data.loan_usage_amount}万円</td>
      <td>{data.borrowing_interest_rate}%</td>
      <td>{data.borrowing_period}年</td>
      <EditCell>
        <LayoutBetween>
          <CallButton height={25} onClick={() => readCondition()}>
            呼び出す
          </CallButton>
          <DeleteButton height={25} onClick={() => deleteCondition()}>
            削除
          </DeleteButton>
        </LayoutBetween>
      </EditCell>

      {/* <td
        className={
          'bold ' + (data.calculate_final_asset < 0 ? 'red' : '')
        }
      >
        {data.calculate_final_asset}万円
      </td>
      <td className={'bold ' + (data.irr < 0 ? 'red' : '')}>
        {data.irr}%
      </td> */}
    </tr>
  )
}

export default SettingsModalTableItem
