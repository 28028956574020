import styled from 'styled-components'

interface ComponentProps {
  screenWidth?: string
  marginBottom?: number
}

// 両端寄せのレイアウト
export const LayoutBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props: ComponentProps) => props.marginBottom}px;
  @media screen and (max-width: 800px) {
    .sp-title_accordion {
      background: #727eaf url('/img/common/ic_arrow_white.svg')
        calc(100% - 20px) center / 13px no-repeat;
      color: #fff;
      width: calc(100% + 40px);
      margin: 0 -20px 0 -20px;
      font-size: 13px;
      text-align: center;
      line-height: 45px;
      &.open {
        background: #727eaf url('/img/common/ic_arrow_top_white.svg')
          calc(100% - 20px) center / 13px no-repeat;
      }
    }
    .next {
      display: none;
    }
  }
`

export default LayoutBetween
