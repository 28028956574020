import React, { useState } from 'react'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'
import colors from 'style/colors'
import {
  CustomLegendElm,
  CustomToolTip,
  GraphCommonProps,
  renderAreaTick,
  renderMoneyTick,
  renderYearTick,
  SimulationGraphElm,
  TickProps,
  SimulationButtons,
} from './Simulate'
import { isMobileOnly } from 'react-device-detect'
import { NanToZero } from 'helper/number'
import { separateComma } from 'helper/number'

export interface CashflowDataProps {
  year: number
  btcf: number
  tax: number
  other_income: number
  atcf: number
  tax_rate: number
  tax_amount?: number
}
export interface CashflowDatasProps {
  data: CashflowDataProps[]
}

type GraphProps = CashflowDatasProps & GraphCommonProps

export class CashflowGraph extends React.Component<GraphProps> {
  render() {
    const page = this.props.page ? this.props.page : 1
    const depreciationData = isMobileOnly
      ? this.props.data.slice((page - 1) * 10, page * 10)
      : this.props.data
    const { roanPeriod, depreciationPeriod } = this.props

    const toolTipContent = (tooltipProps: TooltipProps) => {
      const { active, payload, label } = tooltipProps
      if (active && payload) {
        return (
          <CustomToolTip className="custom-tooltip">
            <p className="tooltip-title">{label}年後</p>
            <dl className="tooltip-item rows-item">
              <dt>税引前キャッシュフロー</dt>
              <dd>{separateComma(NanToZero(payload[0].payload.btcf))}</dd>
            </dl>
            <dl className="tooltip-item flex-item">
              <dt>課税所得</dt>
              <dd>{separateComma(NanToZero(payload[0].payload.tax))}</dd>
            </dl>
            <dl className="tooltip-item flex-item">
              <dt>納税額({payload[0].payload.tax_rate}%)</dt>
              <dd>{separateComma(NanToZero(payload[0].payload.tax_amount))}</dd>
            </dl>
            <hr />
            <dl className="tooltip-item rows-item">
              <dt>税引後キャッシュフロー</dt>
              <dd>{separateComma(NanToZero(payload[0].payload.atcf))}</dd>
            </dl>
          </CustomToolTip>
        )
      }
    }

    const CustomRenderYearTick = (tickProps: TickProps) =>
      renderYearTick(tickProps)

    const renderDepreciationTick = (tickProps: TickProps) => {
      return renderAreaTick(
        tickProps,
        'depreciation',
        depreciationPeriod,
        this.props.data.length
      )
    }
    const renderRoanTick = (tickProps: TickProps) => {
      return renderAreaTick(
        tickProps,
        'roan',
        roanPeriod,
        this.props.data.length
      )
    }

    const CustomLegend = () => {
      return (
        <CustomLegendElm>
          <ul>
            <li className="rect-icon icon-blue-thin">税引前キャッシュフロー</li>
            <li className="rect-icon icon-gray">納税額</li>
            {this.props.personal_company_type === 1 &&
              this.props.include_other_income && (
                <li className="rect-icon icon-blue circle-icon">
                  その他所得(単独税引後CF)
                </li>
              )}
            <li className="rect-icon icon-yellow icon-orange-line circle-icon">
              税引後キャッシュフロー
            </li>
          </ul>
        </CustomLegendElm>
      )
    }

    return (
      <SimulationGraphElm>
        <ResponsiveContainer>
          <ComposedChart
            data={depreciationData}
            stackOffset="sign"
            margin={{
              top: 80,
              right: 20,
              bottom: 30,
              left: 20,
            }}
          >
            {/* 減価償却期間 */}
            <XAxis
              dataKey="year"
              orientation="top"
              axisLine={false}
              tickLine={false}
              interval={0}
              tick={renderRoanTick}
              height={1}
              scale="band"
              xAxisId="quartera"
            />
            <XAxis
              dataKey="year"
              orientation="top"
              axisLine={false}
              tickLine={false}
              interval={0}
              tick={renderDepreciationTick}
              height={1}
              scale="band"
              xAxisId="quarter"
            />
            <XAxis
              dataKey="year"
              tick={CustomRenderYearTick}
              tickLine={false}
              axisLine={false}
            />
            <CartesianGrid
              stroke="#d8d8d8"
              strokeWidth={1.5}
              vertical={false}
            />
            <YAxis tickLine={false} tick={renderMoneyTick} axisLine={false} />
            <Tooltip content={toolTipContent} />
            <Legend
              wrapperStyle={{ bottom: 0, fontSize: 12, fontWeight: 'bold' }}
              iconSize={12}
              content={CustomLegend}
            />

            <Bar
              name="税引前キャッシュフロー"
              dataKey="btcf"
              stackId="a"
              fill={colors.simulate_blue_thin}
              barSize={10}
            />
            <ReferenceLine y={0} strokeWidth={2.5} />

            <Bar
              name="納税額"
              dataKey="tax_amount"
              stackId="a"
              fill={colors.simulate_gray}
            />
            {this.props.personal_company_type === 1 &&
              this.props.include_other_income && (
                <Line
                  name="その他所得"
                  dataKey="other_income"
                  strokeWidth={2}
                  stroke={colors.simulate_blue}
                  isAnimationActive={false}
                  dot={({ dataKey: _, ...props }: any) => (
                    <circle
                      {...props}
                      r={6}
                      fill={colors.simulate_blue}
                      stroke="0"
                    />
                  )}
                  activeDot={false}
                />
              )}
            <Line
              name="税引後キャッシュフロー"
              dataKey="atcf"
              strokeWidth={2}
              stroke={colors.simulate_orange_thin}
              isAnimationActive={false}
              dot={({ dataKey: _, ...props }: any) => (
                <circle
                  {...props}
                  r={6}
                  fill={colors.simutate_yellow}
                  stroke={colors.simulate_orange_thin}
                  strokeWidth={2}
                />
              )}
              activeDot={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </SimulationGraphElm>
    )
  }
}

export const DepreciationGraphWrapper: React.FC<GraphProps> = (
  props: GraphProps
) => {
  const dataLength = props.data.length
  const [page, setPage] = useState(1)
  return (
    <>
      <CashflowGraph {...props} page={page} />
      <SimulationButtons
        page={page}
        up={() => setPage(page + 1)}
        down={() => setPage(page - 1)}
        dataLength={dataLength}
      />
    </>
  )
}

export default DepreciationGraphWrapper
