import classNames from 'classnames'
import { BaseModal } from 'components/Symbols/Modal'
import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'
import FieldInput from './Simulation/Field/Input'
import SelectField from './Simulation/Field/Select'
import FieldTextArea from './Simulation/Field/Textarea'
import Grid from './Simulation/Layout/Grid'
import { MainButton } from './Symbols/Button'
import colors from 'style/colors'

const ContactFrom = styled.form`
  span {
    display: block;
    font-size: 14px;
    margin-bottom: 3px;
    font-weight: bold;
  }
`

interface IProps {
  isOpen: boolean
  onRequestClose: (touched: boolean) => void
  onSubmit?: React.FormEventHandler<HTMLFormElement>
}

const ContactLabels = [
  '（現在の相場を反映した）より正確な売却査定額を知りたい',
  '売買についてコンサルティングしてほしい',
  '現在の賃料が適切か検証してほしい',
  '収益改善（空室対策含む）の提案がほしい',
  '管理について相談したい',
  '事業承継・税務に関する相談がしたい',
  '修繕や建替えを検討している',
  '不動産運用プランを一緒につくってほしい',
]

export const ContactModal: React.FC<IProps> = ({
  onSubmit,
  onRequestClose,
  ...props
}) => {
  const touched = useRef<Set<string>>()
  const handleBlur = useCallback<React.FormEventHandler<any>>((e) => {
    const name = e.currentTarget.name
    if (!touched.current) touched.current = new Set()
    touched.current!.add(name)
  }, [])

  const handleRequestClose = useCallback(() => {
    onRequestClose &&
      onRequestClose(!!(touched.current && touched.current.size > 0))
  }, [onRequestClose])

  return (
    <BaseModal className={classNames({ open: props.isOpen })}>
      <div className="modal-box">
        <div className="modal-close-top" onClick={handleRequestClose} />
        <div className="modal-body">
          <h2 className="modal-header">お問い合わせフォーム</h2>
          <div className="modal-area">
            <ContactFrom onSubmit={onSubmit}>
              <Grid>
                <label>
                  <span>お問い合わせ種別</span>
                  <SelectField
                    name="contact_type"
                    width={500}
                    margin_right={0}
                    required
                    onBlur={handleBlur}
                    items={ContactLabels.map((label, index) => {
                      return {
                        label: label,
                        value: index,
                        key: label,
                      }
                    })}
                  />
                </label>
                <label>
                  <span>お名前</span>
                  <FieldInput
                    name="contact_name"
                    type="text"
                    required
                    width={500}
                    margin_right={0}
                    onBlur={handleBlur}
                  />
                </label>
                <label>
                  <span>メールアドレス</span>
                  <FieldInput
                    name="contact_email"
                    type="email"
                    required
                    placeholder="mail@example.com"
                    width={500}
                    margin_right={0}
                    onBlur={handleBlur}
                  />
                </label>
                <label>
                  <span>お問い合わせ内容</span>
                  <FieldTextArea
                    name="contact_textarea"
                    required
                    type="text"
                    width={500}
                    margin_right={0}
                    onBlur={handleBlur}
                  />
                </label>
                <MainButton
                  background={colors.priority}
                  fontSize="14px"
                  height={45}
                >
                  送信
                </MainButton>
              </Grid>
            </ContactFrom>
          </div>
        </div>
        <div onClick={handleRequestClose} className="modal-close-bottom">
          閉じる
        </div>
      </div>
    </BaseModal>
  )
}

export default ContactModal
