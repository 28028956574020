import React from 'react'
import styled from 'styled-components'
import colors from 'style/colors'
import { zipWith } from 'lodash'
import { isMobileOnly } from 'react-device-detect'

const FormSelect = styled.div`
  margin-right: ${(props: ComponentProps) =>
    !(props.margin_right === undefined) ? props.margin_right + 'px' : '15px'};
  display: inline-flex;
  align-items: center;
  select {
    max-width: ${(props: ComponentProps) =>
      typeof props.width === 'number'
        ? props.width + 'px'
        : typeof props.width === 'string'
        ? props.width
        : ''};
    min-width: 88px;
    width: 100%;
    height: 35px;
    border: 1px solid ${colors.border_gray};
    border-radius: 3px;
    padding: 0 20px 0 10px;
    font-size: 14px;
    background: white url('/img/common/ic_number.svg') calc(100% - 8px) center
      no-repeat;
  }
  span {
    margin-left: 15px;
    font-size: 15px;
    font-weight: normal;
    white-space: nowrap;
  }
  &.pc {
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
`

export type ISelectItem<T = any> = {
  value: T
  key: string
  label?: string
}

type IProps<T = string | number> = {
  name: string // name属性

  unit_text: string // 単位を後ろに表示
  width: number | string // セレクトの横幅
  className?: string
  margin_right?: number
  changedSelect?: (e?: any) => void
  onBlur?: React.FormEventHandler<HTMLSelectElement>
  required?: boolean
  selectedValue?: T
} & (
  | {
      items: ISelectItem<T>[]
    }
  | {
      /** @deprecated */
      deprecated_texts: string[]
      /** @deprecated */
      deprecated_values: T[]
    }
)

interface ComponentProps {
  width: number | string // セレクトの横幅
  className?: string
  margin_right?: number
}

class SelectField extends React.Component<IProps, {}> {
  public static defaultProps = {
    unit_text: '',
    width: 120,
  }

  public render() {
    const items =
      'items' in this.props
        ? this.props.items
        : zipWith(
            this.props.deprecated_texts,
            this.props.deprecated_values,
            (label, value) =>
              ({
                label,
                key: value,
                value: value,
              } as ISelectItem)
          )
    return (
      <FormSelect
        className={`this.props.className ${isMobileOnly ? 'isMobileOnly' : ''}`}
        width={this.props.width}
        margin_right={this.props.margin_right}
      >
        <select
          required={this.props.required}
          name={this.props.name}
          onChange={this.props.changedSelect}
          value={this.props.selectedValue}
          onBlur={this.props.onBlur}
        >
          {items.map((v) => (
            <option key={v.key} value={v.value}>
              {v.label || v.value}
            </option>
          ))}
          <optgroup label=""></optgroup>
        </select>
        {this.props.unit_text ? (
          <span className="help">{this.props.unit_text}</span>
        ) : (
          ''
        )}
      </FormSelect>
    )
  }
}

export default SelectField
