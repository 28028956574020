import { EstatePropertyPayload } from 'domain/estate'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { Action } from 'typescript-fsa'
import PropertyForm from '../components/PropertyForm'
import { AppState } from '../reducers/AppReducer'
import { EstateActions } from '../reducers/EstateReducer'
import { AddressActions } from '../reducers/AddressReducer'

function mapDispatchToProps(dispatch: Dispatch<Action<any>>) {
  return {
    getCities: (prefCode: string) =>
      dispatch(AddressActions.getCities(prefCode)),
    getEstate: (id: string) => dispatch(EstateActions.getEstate(id)),
    getRailways: (prefCode: string) =>
      dispatch(EstateActions.getRailways(prefCode)),
    getStations: (lineCodes: any) =>
      dispatch(EstateActions.getStations(lineCodes)),
    saveAndSimulation: (estate: EstatePropertyPayload) =>
      dispatch(EstateActions.saveAndSimulation(estate)),
  }
}

export type PropertyFormContainerActions = ReturnType<typeof mapDispatchToProps>

function mapStateToProps(appState: AppState) {
  return {
    ...appState.estate,
    ...appState.address,
    planningEstate: appState.estate.planningEstate!, // 取得するまでマウントされない
  }
}

export type PropertyFormContainerProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps, mapDispatchToProps)(PropertyForm)
