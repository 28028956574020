import React, { useState } from 'react'
import styled from 'styled-components'
import PanelTitle from 'components/Simulation/Layout/PanelTitle'
import { isMobileOnly } from 'react-device-detect'
import { GrayButton } from 'components/Symbols/Button'

type PortfolioAcodionProps = {
  label: string
  icon: number
  isOpen: boolean
  header?: React.ReactElement | null
  onOpenPMPC?: () => void
  theme?: 'dark' | 'light'
}

// ポートフォリオアコーディオンのコンポーネント
export const PortfolioAcodion: React.FC<PortfolioAcodionProps> = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen)
  return (
    <>
      <AcodionElem className={`${isMobileOnly ? 'mobile' : ''} ${props.theme}`}>
        <AcodionHeader className={isMobileOnly ? 'mobile' : ''}>
          <PanelTitle label={props.label} icon={props.icon} marginBotton={0} />
          <PortfolioGroupOption className={isMobileOnly ? 'mobile' : ''}>
            {!isMobileOnly && (
              <GrayButton onClick={props.onOpenPMPC}>
                投資プラングループを編集
              </GrayButton>
            )}
            <span
              onClick={() => setIsOpen(!isOpen)}
              className={`icon-arw ${isOpen ? 'open' : ''}`}
            ></span>
          </PortfolioGroupOption>
        </AcodionHeader>
        {props.header}
        {isOpen && props.children}
      </AcodionElem>
    </>
  )
}

const PortfolioGroupOption = styled.div`
  display: flex;
  align-items: center;
  width: 230px;
  justify-content: space-between;
  &.mobile {
    width: auto;
  }
  .icon-arw {
    margin-left: 15px;
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    background: url('/img/common/ic_arrow.svg') center center / 14px 14px
      no-repeat;
    transform: rotateZ(-90deg);
    &.open {
      transform: rotateZ(90deg);
    }
  }
`

const AcodionElem = styled.div`
  padding: 20px;
  border-top: 5px solid #4f5e9c;
  background: white;
  &.dark {
    background: #f8f9fb;
  }
  &.mobile {
    padding: 0;
    background: white;
  }
`

const AcodionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 10px;
  &.mobile {
    padding: 10px 20px;
    margin: 0;
  }
`
