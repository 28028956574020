import React from 'react'
import { Helmet } from 'react-helmet'

export type IPageTypeProps = {
  title: string
  prefix?: string
}

export const DefaultPageTitle = 'VALUE AI'
export const DefaultPageTitlePrefix = 'VALUE AI: '

const PageTitle: React.FC<IPageTypeProps> = ({
  prefix = DefaultPageTitlePrefix,
  title,
}) => (
  <Helmet>
    <title>
      {prefix}
      {title}
    </title>
  </Helmet>
)

export default PageTitle
