import React from 'react'
import { TickProps } from 'components/Simulation/Result/Graph/Simulate'
import styled from 'styled-components'
import { TooltipProps } from 'recharts'

const CustomToolTipElem = styled.div`
  border-radius: 3px;
  padding: 4px 8px;
  font-size: 12px;
  background: rgba(74, 74, 74, 0.9);
  .label {
    color: white;
    line-height: 1.5;
    font-weight: bold;
  }
`

export const CustomToolTip: React.FC<TooltipProps> = ({
  active,
  payload,
  label,
}) => {
  if (active) {
    return (
      <CustomToolTipElem>
        <p className="label">{`${payload && payload[0].value}`}万円</p>
      </CustomToolTipElem>
    )
  } else {
    return null
  }
}

export const CustomGroupToolTip: React.FC<TooltipProps> = ({
  active,
  payload,
  label,
}) => {
  if (active) {
    return (
      <CustomToolTipElem>
        {payload && payload[0] && (
          <p className="label">
            {`${payload[0].name} : ${payload[0].value}`}
            万円
          </p>
        )}
        {payload && payload[1] && (
          <p className="label">
            {`${payload[1].name} : ${payload[1].value}`}
            万円
          </p>
        )}
        {payload && payload[2] && (
          <p className="label">
            {`${payload[2].name} : ${payload[2].value}`}
            万円
          </p>
        )}
      </CustomToolTipElem>
    )
  } else {
    return null
  }
}

export const GraphContainer = styled.div`
  width: 100%;
  height: 230px;
`

export const groupColor = ['#0673C8', '#FFA600', '#BECD25']

export const yAxisTick = (props: TickProps) => {
  return (
    <text
      x={props.x - 4}
      y={props.y}
      fontSize={12}
      fill="#999"
      fontWeight="bold"
      textAnchor={'end'}
    >
      {props.payload.value}
    </text>
  )
}

export const xAxisTick = (props: TickProps) => {
  return (
    <text
      x={props.x - 4}
      y={props.y + 8}
      fontSize={12}
      fill="#999"
      fontWeight="bold"
    >
      {props.payload.value}
    </text>
  )
}
